import React, { Component } from 'react'
import PiechartSkeleton from 'components/skeleton/PiechartSkeleton'
import PieChart from 'components/commons/PieChart'
import { getTotalAttendance } from 'libs/apis/smallGroup'

class MemberAttendanceRecord extends Component {
	state = {
		isLoading: false,
		data: [],
		total: 0,
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				let data = await this.handleGetPie()

				this.setState({ ...this.state, data, isLoading: false })
			} catch (error) {
				this.setState({ ...this.state, isLoading: false })
			}
		})
	}

	handleGetPie = async () => {
		let labels = ['Total Member', 'Total Member Attendance']

		let values
		try {
			let res = await getTotalAttendance()

			this.setState({ ...this.state, total: res.data.data[0].count_member })

			const getData = (data) => {
				let r = []
				data.map((v) =>
					r.push.apply(r, [
						parseInt(v.count_member),
						parseInt(v.count_total_member_attendance),
					])
				)
				return r
			}

			values = getData(res.data.data)

			let data = [{ values, labels, type: 'pie' }]

			return data
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.props.history.push('/login')
				localStorage.removeItem('token')
			}
		}
	}

	renderData() {
		const { data, total } = this.state

		if (data.length === 0) return <div className="text-center">No data available</div>

		return (
			<div>
				<p className="font-bold">
					From Total Attendance <span className="text-red-500">{total}</span>
				</p>
				<PieChart data={data} />
			</div>
		)
	}

	render() {
		const { isLoading } = this.state
		return (
			<>
				{isLoading ? (
					<PiechartSkeleton height={355} width={400} />
				) : (
					<div className="bg-white rounded-lg shadow-md text-center mt-5">
						<h3 className="font-bold mb-6">Member Attendance and Record</h3>
						{this.renderData()}
					</div>
				)}
			</>
		)
	}
}

export default MemberAttendanceRecord
