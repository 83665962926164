import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'

import { getUserList, deactiveUser } from 'libs/apis/usermanagement'
import { helperSelectFilter } from 'libs/utils/utils'

import PaginationPage from 'components/commons/PaginationPage'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import Searchbar from 'components/commons/Searchbar'
import UserManagementFilter from 'components/listUsers/UserManagementFilter'
import UsersTable from 'components/listUsers/UsersTable'
import UserManagementExport from 'components/listUsers/UserManagementExport'
import { UsersHeader } from './styles'
import alert from 'services/alert'

export default function ListUsers(props) {
	const [listUsers, setListUsers] = useState([])
	const [roleId, setRoleId] = useState('')
	const [campusId, setCampusId] = useState('')
	const [genders, setGenders] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [keyword, setKeyword] = useState('')

	const [sortBy, setSortBy] = useState('')
	const [sortDir, setSortDir] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		fetchData()
	}, [props.location.search, roleId, campusId, genders, searchValue, sortBy, sortDir])

	const fetchData = async () => {
		const { search } = props.location
		const page = search ? queryString.parse(search).page : 1

		setIsLoadingData(true)
		try {
			const res = await getUserList(10, roleId.toString(), campusId.toString(), genders.toString(), searchValue, sortBy, sortDir, page)

			const response = res.data.data.user

			const list = response.data.map((d) => {
				const temp = {}

				if (d.userroles.length) {
					temp.id = d.id
					temp.name = d.fullname
					temp.email = d.email
					temp.phone = d.phone
					temp.gender = d.gender
					temp.role = d.userroles[0].role.showname
					temp.status = d.deleted_at
					temp.lastActivity = ''
				} else {
					temp.id = d.id
					temp.name = d.fullname
					temp.email = d.email
					temp.phone = d.phone
					temp.gender = d.gender
					temp.role = ''
					temp.status = d.deleted_at
					temp.lastActivity = ''
				}

				return temp
			})

			setListUsers(list)
			setCurrentPage(response.current_page)
			setLastPage(response.last_page)
			setIsLoadingData(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage('Cannot retrieve any data at this moment')
					setIsLoadingData(false)
				}
			}
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'roleId') {
			helperSelectFilter(roleId, event.target.value, setRoleId)
		}
		if (event.target.name === 'campusId') {
			helperSelectFilter(campusId, event.target.value, setCampusId)
		}
		if (event.target.name === 'gender') {
			helperSelectFilter(genders, event.target.value, setGenders)
		}
	}

	function handleSort(sort) {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDir('ASC')
		} else {
			if (sortDir === 'ASC') {
				setSortBy(sort)
				setSortDir('DESC')
			} else if (sortDir === 'DESC') {
				setSortBy(sort)
				setSortDir('ASC')
			}
		}
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleDeactiveUser = async (id) => {
		const res = await alert.confirmDeactiveUser()
		if (res.value) {
			try {
				const res = await deactiveUser(id)
				if (res.status === 200) {
					alert.succeed('Success')
					fetchData()
				}
			} catch (error) {
			}
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	function handleClearFilter() {
		setRoleId('')
		setCampusId('')
		setGenders('')
		props.history.push(`${props.location.pathname}?paginate=10&page=1`)
	}

	const parsed = queryString.parse(props.location.search)
	const active = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`

	const church = JSON.parse(localStorage.getItem('church'))
	const churchName = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of All Users' }]

	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage && listUsers.length !== 0
	const displayNoData = !isLoadingData && !errorMessage && !listUsers.length
	const displayError = !isLoadingData && errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{churchName} - List of All Users</title>
			</Helmet>
			<PageBreadcrumbs icon={UserIcon} title="List of All Users" breads={breads} />

			<div>
				<UsersHeader>
					<UserManagementFilter
						roleId={roleId}
						campusId={campusId}
						gender={genders}
						handleSelectFilter={handleSelectFilter}
						handleClearFilter={handleClearFilter}
						handleApplyFilter={fetchData}
					/>
					<UserManagementExport />
					<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} placeholder="Search by name" />
				</UsersHeader>
				{displayLoading && <Loading />}
				{displayData && (
					<div>
						<UsersTable list={listUsers} handleSort={handleSort} handleDeactiveUser={handleDeactiveUser} />
						<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={listUsers.length} url={props.location.pathname} />
					</div>
				)}
				{displayNoData && <div className="text-center">No data available</div>}
				{displayError && <div className="text-center">{errorMessage}</div>}
			</div>
		</div>
	)
}
