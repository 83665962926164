import React, {Component} from 'react'
import {Tooltip} from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import ImageIframe from "./ImageIframe";
import Button from "./Button";
import * as DescriptionBox from "./DescriptionBox";


class ImageUpload extends Component {
    state = {
        imagesUrl: []
    };

    handleOnChange = event => {
        const {field, form} = this.props;
        form.setFieldValue(field.name, event.currentTarget.files[0]);
        this.setState({...this.state, imagesUrl: [...this.state.imagesUrl, event.currentTarget.files[0]]});
    };

    handleUseButtonClick = (imageUrl) => {
        const {form} = this.props;

        const prevEmailDescriptionValue = form.getFieldProps('content').value;
        const additionalEmailDescriptionValue = '![]('.concat(imageUrl.name).concat(')');
        form.setFieldValue('markDown', additionalEmailDescriptionValue);

        const prevEmailPreviewValue = form.getFieldProps('emailPreviewContent').value;
        const additionalEmailPreviewValue = '<img src={'.concat('\'').concat(URL.createObjectURL(imageUrl)).concat('\'').concat('}/>');
        form.setFieldValue('emailPreviewContent', prevEmailPreviewValue + additionalEmailPreviewValue);
    };

    handleRemoveButtonClick = (imageUrl) => {
        const toBeDeletedImageUrlIndex = this.state.imagesUrl.findIndex((url) => url.name === imageUrl.name);
        const updatedImagesUrl = [...this.state.imagesUrl.splice(toBeDeletedImageUrlIndex, 1)];
        this.setState({...this.state, imageUrl: updatedImagesUrl})
    };

    render() {
        const {field} = this.props;
        const fileName = field.value
            ? field.value.name || field.value.substring(field.value.lastIndexOf('/') + 1)
            : 'Click to upload or drop your image here';

        const renderImagesUrl = () => {
            let imageUrlEl = [];

            for (const imageUrl of this.state.imagesUrl) {
                imageUrlEl.push(
                    <div className="flex items-center my-6" key={imageUrl.name}>
                        <div className="w-1/2">
                            <div className="w-40">{imageUrl && <ImageIframe img={imageUrl}/>}</div>
                        </div>
                        <div className="w-1/2">
                            <div className="flex">
                                <Button type="primary" handleClick={() => this.handleUseButtonClick(imageUrl)}>
                                    Use
                                </Button>
                                <Button size="ml-4" type="secondary"
                                        handleClick={() => this.handleRemoveButtonClick(imageUrl)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            }

            return imageUrlEl;
        };

        return (
            <Tooltip
                title="Recommended image size 900x506 pixels"
                position="bottom"
                trigger="mouseenter"
            >
                <div className="p-0 rounded-none border-0 relative inline-block w-full mb-5">
                    <input
                        type="file"
                        name={field.name}
                        accept="image/*"
                        multiple={false}
                        onChange={this.handleOnChange}
                        className="relative z-10 w-full h-full opacity-0 py-6 cursor-pointer"
                    />
                    <label
                        className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
                        <span className="text-secondary">{fileName}</span>
                    </label>
                </div>
                {renderImagesUrl()}

                {/*<div className="flex items-center my-6">*/}
                {/*	<div className="w-1/2">*/}
                {/*		<div className="w-40">{<ImageIframe img={'https://i.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U'}/>}</div>*/}
                {/*	</div>*/}
                {/*	<div className="w-1/2">*/}
                {/*		<div className="flex">*/}
                {/*			<Button type="primary" handleClick={this.handleUseButtonClick}>*/}
                {/*				Use*/}
                {/*			</Button>*/}
                {/*			<Button size="ml-4" type="cancel" handleClick={this.handleRemoveButtonClick}>*/}
                {/*				Remove*/}
                {/*			</Button>*/}
                {/*		</div>*/}
                {/*	</div>*/}
                {/*</div>*/}

                {/*<div className="flex items-center">*/}
                {/*    <div className="w-1/2">*/}
                {/*        <div className="w-40">{<ImageIframe img={'https://i.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U'}/>}</div>*/}
                {/*    </div>*/}
                {/*    <div className="w-1/2">*/}
                {/*        <div className="flex">*/}
                {/*            <Button type="primary" handleClick={this.handleUseButtonClick}>*/}
                {/*                Use*/}
                {/*            </Button>*/}
                {/*            <Button size="ml-4" type="cancel" handleClick={this.handleRemoveButtonClick}>*/}
                {/*                Delete*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Tooltip>

        )
    }
}

export default ImageUpload
