import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { partial } from 'libs/utils/utils'

function MinistryTable({ data, handleSort, handleCloseGroup, levels }) {
	const handleSortName = partial(handleSort, 'name')
	const handleSortUpperLevel = partial(handleSort, 'upperLevel')
	const handleSortHeads = partial(handleSort, 'headMember')
	const handleSortMembers = partial(handleSort, 'totalMember')

	return (
		<div className="bg-white shadow-md">
			<table className="w-full rounded-lg">
				<MinistryTableHead handleSortName={handleSortName} handleSortUpperLevel={handleSortUpperLevel} handleSortHeads={handleSortHeads} handleSortMembers={handleSortMembers} />
				<MinistryTableBody data={data} handleCloseGroup={handleCloseGroup} levels={levels} />
			</table>
		</div>
	)
}

export default MinistryTable

const MinistryTableHead = ({ handleSortName, handleSortUpperLevel, handleSortHeads, handleSortMembers }) => {
	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr className="text-gray">
				<th className="text-left pl-8 py-3">
					NAME
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortName} />
				</th>
				<th className="text-left">LEVEL</th>
				<th className="text-center">
					UPPER LEVEL
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortUpperLevel} />
				</th>
				<th className="text-center">
					HEAD(S)
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortHeads} />
				</th>
				<th className="text-center">
					MEMBERS
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortMembers} />
				</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const MinistryTableBody = ({ data, handleCloseGroup, levels }) => {
	return (
		<tbody className="border-2 border-solid">
			{data.map((value, idx) => (
				<MinistryTableRow key={idx} {...value} levels={levels} handleCloseGroup={() => handleCloseGroup(value.ministry_id)} />
			))}
		</tbody>
	)
}

const MinistryTableRow = ({ id, name, level, upperLevel, headMember, totalMember, handleCloseGroup, levels }) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`

	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="text-left px-2 py-4 pl-8 capitalize">{name}</td>
			<td className="text-left px-2 py-4 capitalize">{level}</td>
			<td className="text-center px-2 py-4 capitalize">{upperLevel ? upperLevel : '-'}</td>
			<td className="text-center px-2 py-4 capitalize">{headMember ? headMember : '-'}</td>
			<td className="text-center px-2 py-4 capitalize">{totalMember}</td>
			{/* 
			<td className="text-center capitalize">
				{created_at}
				<p className="text-primary capitalize">{created_by_name}</p>
			</td> */}

			<td className="px-2 py-4">
				{levels == 'Ministry' ? (
					<div className="cursor-pointer" onClick={handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={wrapperRef} className="bg-white">
								<li>
									<Link className="px-4 py-2 block hover:bg-blueSecondary" to={`/ministry/detail/${id}`}>
										View
									</Link>
								</li>
								<li>
									<Link className="px-4 py-2 block hover:bg-blueSecondary" to={`/ministry/edit/${id}`}>
										Edit
									</Link>
								</li>
							</ul>
						</div>
					</div>
				) : (
					<div></div>
				)}
			</td>
		</tr>
	)
}
