import React, {useEffect, useState} from 'react';
import {getCampaignDetail, sendCampaignEmail} from "../../libs/apis/emailCampaign";
import {Helmet} from "react-helmet";
import {ReactComponent as EventIcon} from 'assets/icons/events.svg'
import PageBreadcrumbs from "../../components/commons/PageBreadcrumbs";
import Loading from "../../components/commons/Loading";
import TestEmailCampaign from "./TestEmailCampaign";
import EmailFooter from "./EmailFooter";
import {
    confirmSendEmail,
    deleteItemConfirmation,
    emailSuccess,
    failedMessage,
    successMessage
} from "../../libs/utils/confirmation";
import {removeRegistrant} from "../../libs/apis/events";

const ViewEmailCampaign = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));

    const name = church.church_name ? church.church_name : '-';
    const breads = [{url: '', title: 'View Email Campaign'}];

    const campaignId = props.match.params.id;

    const [descriptionEmail, setDescriptionEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSendLoading, setIsSendLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // setFieldValue('campaignId', campaignId);
        fetchEmailCampaignDetail();
    }, []);

    const fetchEmailCampaignDetail = async () => {
        setIsLoading(true);
        try {
            let response = await getCampaignDetail(campaignId, 'Email');
            let emailDescriptionData = response.data.data.campaign.data[0].description;
            const nameVariablePattern = /\$\(([^)]+)\)/g;
            const names = emailDescriptionData.match(nameVariablePattern);
            let formattedNames = [];
            if (names) {
                for (const name of names) {
                    let formattedName = name.replace('$(', '');
                    formattedName = formattedName.replace(')', '');
                    formattedNames.push(
                        formattedName
                    )
                }

                for (let i = 0; i < names.length; i++) {
                    emailDescriptionData = emailDescriptionData.replace(names[i], formattedNames[i])
                }
            }
            setDescriptionEmail(emailDescriptionData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleSendButtonClick = async (campaignId, email) => {
        console.log('campaignId: ', campaignId, email);
        const confirm = await confirmSendEmail();
        if (confirm.value) {
            setIsSendLoading(true);
            try {
                let formdata = new FormData();
                formdata.append('email', email);
                formdata.append('campaignId', campaignId);
                let response = await sendCampaignEmail(formdata);
                console.log('response: ', response);
                setIsSendLoading(false);
                await emailSuccess();
            } catch (error) {
                setIsSendLoading(false);
                await failedMessage(error.message)
                // setErrorMessage(error.message);
            }
        }
    };

    return (
        <div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 lg:w-5/6">
            <Helmet>
                <title>{name} - View Email Campaign</title>
            </Helmet>
            <PageBreadcrumbs icon={EventIcon} title="View Email Campaign" breads={breads}/>
            <div className="flex md:-mx-2 flex-wrap">
                <div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
                    <div className="bg-white shadow-md p-6">
                        {isLoading && <Loading/>}
                        {!isLoading &&
                        <div>
                            <div dangerouslySetInnerHTML={{__html: descriptionEmail}}/>
                            <EmailFooter/>
                        </div>
                        }

                    </div>

                </div>
                <div className="md:w-1/3 w-full px-2">
                    <TestEmailCampaign campaignId={campaignId}
                                       handleSendButtonClick={handleSendButtonClick}
                                       isSendLoading={isSendLoading}
                    />
                </div>
            </div>
        </div>
    )
};

export default ViewEmailCampaign;
