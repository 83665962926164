import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class Breadcrumb extends Component {
  renderPaths = (bread) => {
    if (bread.key) return `${bread.label}`

    return <Link to={bread.path}>{bread.label}</Link>
  }

  render() {
    const { title, icon: Icon, breads } = this.props
    return (
      <div className="md:flex md:justify-between mb-6">
        <div className="font-bold text-xl">{title}</div>
        <div className="flex items-center">
          {Icon && <Icon className="w-3 mr-2" />}
          <div className="text-sm">
            {breads && breads.map((bread) => <React.Fragment key={bread.path || bread.key}> / {this.renderPaths(bread)}</React.Fragment>)}
          </div>
        </div>
      </div>
    )
  }
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  breads: PropTypes.array
}

export default Breadcrumb
