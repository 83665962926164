import React, { useState } from 'react'
import Modal from 'react-modal'
import { exportUserManagement } from 'libs/apis/usermanagement'

import FilterItem from 'components/commons/FilterItem'
import { Button, ButtonContainer, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'
import Loading from 'components/commons/Loading'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '180px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

export default function UserManagementExport(props) {
	const [status, setStatus] = useState('')
	const [tab, setTab] = useState('status')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('status')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'status') {
			setStatus(event.target.value)
		}
	}

	const handleExport = async () => {
		try {
			setIsLoading(true)
			const res = await exportUserManagement(status)
			if (res.data.api_status) {
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setIsLoading(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
				}
			}
			setIsLoading(false)
		}
	}

	const handleCancelExport = () => {
		setStatus('')
	}

	const options = [
		{ value: '1', title: 'Active' },
		{ value: '0', title: 'Non Active' },
	]

	const statusTab = tab === 'status'

	const length = status.length !== 0

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				<ButtonFilter onClick={handleToggleModal}>Export</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="User Management Export Modal" ariaHideApp={false}>
				<Header>
					<Title>Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'status'} type="button" value="status" onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{statusTab &&
								options.map(({ title, value }) => (
									<div className="pr-2" key={title}>
										<FilterItem
											classname={`${status.includes(value.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="status"
											title={title}
											value={value}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleCancelExport}>
						{isLoading ? <Loading secondary /> : <Span>Cancel</Span>}
					</Button>
					<Button onClick={handleExport}>{isLoading ? <Loading secondary /> : <Span>Export</Span>}</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
