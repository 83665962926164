import styled from 'styled-components'

export const Flex = styled.div`
	display: flex;
`

export const NewsfeedContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		margin-right: -0.5rem;
		margin-left: -0.5rem;
	}
`

export const Form = styled.div`
	width: 100%;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		width: 66.666667%;
	}
`

export const Preview = styled.div`
	width: 100%100%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;

	@media (min-width: 768px) {
		width: 33.333333%;
	}
`
