import React from 'react'
import Label from 'components/commons/Label'
import ReactQuill from 'react-quill'

function InputHTML({ label, additionalLabel, value, onChange, errors, name }) {
	return (
		<div className="mb-6">
			<div className="flex flex-row items-center">
				{label && <Label>{label}</Label>}
				{additionalLabel && <span className="mb-2 ml-2">{additionalLabel}</span>}
			</div>
			<ReactQuill value={value} onChange={onChange} name={name} />
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}

export default InputHTML
