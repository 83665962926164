import styled from 'styled-components'

export const RegisAttendanceQRContainer = styled.div`
	display: flex;
	min-height: 500px;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`

export const ViewQRContainer = styled.div`
	display: flex;
	background-color: #f2f6f9;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	align-items: center;
`

export const TitleQR = styled.h1`
	font-size: 53px;
`

export const Header = styled.div`
	display: flex;
	width: 100%;

	div:last-of-type {
		margin-left: auto;
	}
`
