import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'
import { Table, TableContainer, TBody, THead, TRow } from 'components/commons/newsfeed/Styles'

function RegistrantTable({ registrants, handleRemoveRegistrant, handleResendConfirmEmail }) {
	return (
		<TableContainer>
			<Table>
				<THead>
					<tr style={{ height: '50px' }}>
						<th className="text-left pl-6">NAME</th>
						<th>GROUP NAME</th>
						<th>ROLE</th>
						<th>REGISTERED AT</th>
						<th>UPDATED AT</th>
						<th>STATUS</th>
						<th className="px-2 py-4" />
					</tr>
				</THead>
				<TBody>
					{registrants.map((v,index) => (
						<RegistrantRow key={index} {...v} handleRemoveRegistrant={handleRemoveRegistrant} handleResendConfirmEmail={handleResendConfirmEmail} />
					))}
				</TBody>
			</Table>
		</TableContainer>
	)
}

export default RegistrantTable

function RegistrantRow({ id, user_id, name, role, groupName, registeredAt, updatedAt, status, handleRemoveRegistrant, handleResendConfirmEmail }) {
	const [open, setOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleToggle() {
		setOpen(!open)
	}

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setOpen(false)
	}

	const styleDropDownOpen = `shadow-md absolute right-20px ${open ? 'block' : 'hidden'}`
	const roles = (role || []).map((v) => v.name).join()
	const groupNames = (groupName || []).map((v) => v.name).join()

	return (
		<TRow>
			<td className="text-left pl-4 px-4 py-2">{name}</td>
			<td className="text-left px-4 py-2" style={{ width: '220px' }}>
				{groupNames}
			</td>
			<td style={{ width: '220px' }}>{roles}</td>
			<td className="px-4 py-2">
				<div> {registeredAt}</div>
				<div className="text-primary text-sm">{moment(new Date(registeredAt), 'DD/MM/YYYY HH:mm').fromNow()}</div>
			</td>
			<td className="px-4 py-2">{updatedAt}</td>
			<td className="px-4 py-2">
				<div className="flex">
					{status === 'Approved' && <i className="fas fa-circle text-green-300 mr-2" />}
					{status === 'Applied' && <i className="fas fa-circle text-yellow-500 mr-2" />}
					{status === 'Pending' && <i className="fas fa-circle text-grey-300 mr-2" />}
					{status === 'Rejected' && <i className="fas fa-circle text-red-500 mr-2" />}
					<div>{status}</div>
				</div>
			</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={node} className="bg-white">
							<li className="px-4 py-2 block hover:bg-darkSkyBlue" onClick={() => handleResendConfirmEmail(id, user_id)}>
								Resend Confirmation Email
							</li>

							<li className="px-4 py-2 block hover:bg-darkSkyBlue" onClick={() => handleRemoveRegistrant(id)}>
								Remove Registrant
							</li>
						</ul>
					</div>
				</div>
			</td>
		</TRow>
	)
}

RegistrantTable.propTypes = {
	registrants: PropTypes.array.isRequired,
	handleRemoveRegistrant: PropTypes.func.isRequired,
	handleResendConfirmEmail: PropTypes.func.isRequired
};

RegistrantTable.defaultProps = {
	registrants: [],
};
