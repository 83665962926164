import React, { Component } from 'react'
import PiechartSkeleton from 'components/skeleton/PiechartSkeleton'
import PieChart from 'components/commons/PieChart'
import { genderData } from 'services/reportServices'

class GenderContainer extends Component {
	state = { isLoading: false, data: [] }

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: result },
				} = await genderData()
				const values = result.map((item) => item.total)
				const labels = result.map((item) => item.gender_desc)

				const data = [{ values, labels, type: 'pie' }]
				this.setState({ isLoading: false, data })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	renderData() {
		const { length: count } = this.state.data
		const { data } = this.state
		if (!count) return <div className="text-center">No data available</div>

		return <PieChart data={data} />
	}

	render() {
		const { isLoading } = this.state
		return (
			<>
				{isLoading ? (
					<PiechartSkeleton height={355} width={400} />
				) : (
					<div className="bg-white shadow-md rounded-lg text-center mt-5">
						<h3 className="font-bold mb-6">Gender</h3>
						{this.renderData()}
					</div>
				)}
			</>
		)
	}
}

export default GenderContainer
