import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import InputDate from 'components/commons/InputDate'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Input } from 'components/smallGroup/Styles'
import FilterItem from 'components/commons/FilterItem'
import Label from 'components/commons/Label'
import { exportRegistrantClass } from 'services/courseServices'
import Loading from 'components/commons/Loading'

export default function ClassRegistrantFilter({ id, startDate, endDate, handleClearFilter, handleStartDate, handleEndDate, handleSelectFilter, statusRegistrantExport }) {
	const [tab, setTab] = useState('time')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const customStyles = {
		content: {
			top: '10%',
			left: '17%',
			right: '25%',
			width: '1101px',
			height: '230px',
			backgroundColor: 'white',
			paddingLeft: '2px',
			paddingRight: '0px',
			overflow: 'hidden',
			borderRadius: '12px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('time')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const handleExport = async () => {
		try {
			setIsLoading(true)
			const res = await exportRegistrantClass(id, startDate, endDate, statusRegistrantExport.toString())
			if (res.data.api_status) {
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setModalIsOpen(false)
				handleClearFilters()
				setIsLoading(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					handleClearFilters()

					setIsLoading(false)
					setModalIsOpen(false)
				}
			}
			handleClearFilters()

			setIsLoading(false)
			setModalIsOpen(false)
		}
	}

	const length = startDate || endDate
	const timeTab = tab === 'time'
	const statusTab = tab === 'status'

	const listStatus = ['Pending', 'Approved', 'Rejected']

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Export
				</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Class Registrant Export Modal" ariaHideApp={false}>
				<Header>
					<Title>Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<div>
						<Input tab={tab === 'time'} type="button" value="time" onClick={handleOnTab} />
						<Input tab={tab === 'status'} type="button" value="status" onClick={handleOnTab} />
					</div>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div className="flex flex-wrap justify-arround">
						{timeTab && (
							<div className="flex mt-2">
								<div className="flex">
									<Label>Start Date</Label>
									<InputDate name="startTime" handleChange={handleStartDate} withSec={true} />
								</div>
								<div className="flex ml-4">
									<Label>End Date</Label>
									<InputDate name="endTime" handleChange={handleEndDate} withSec={true} />
								</div>
							</div>
						)}
						{statusTab &&
							listStatus.map((v) => (
								<div key={v}>
									<FilterItem
										classname={`${statusRegistrantExport.includes(v) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
										name="status"
										title={v}
										value={v}
										onClick={handleSelectFilter}
									/>
								</div>
							))}
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						{isLoading ? <Loading secondary /> : <Span>Cancel</Span>}
					</Button>
					<Button onClick={handleExport}>{isLoading ? <Loading secondary /> : <Span>Export</Span>}</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
