import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as CampaignIcon } from 'assets/icons/campaign.svg'
import Loading from 'components/commons/Loading'
import { getPushNotifDetail } from 'libs/apis/pushNotif'
import CampaignPushNotifFormik from 'components/formik/CampaignPushNotifFormik'
import { Redirect } from 'react-router-dom'

function EditPushNotif(props) {
	const { pushNotifDetails, isLoading, isRedirect, errorMessage } = usePushNotifDetails(props.match.params.id)

	const { id, title, body, schedule, status, filter_gender, filter_marital_status, filter_roles } = pushNotifDetails

	const breads = [
		{ url: '/pushnotif/', title: 'Notification' },
		{ url: '', title: 'Edit Notification' },
	]
	const shouldDisplayError = !isLoading && errorMessage
	const shouldDisplayLoad = isLoading && !errorMessage
	const shouldDisplayForm = !isLoading && !errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>Edit Push Notification</title>
			</Helmet>
			<PageBreadcrumbs icon={CampaignIcon} title="Edit Notification" breads={breads} />
			{shouldDisplayLoad && <Loading />}
			{shouldDisplayForm && (
				<CampaignPushNotifFormik
					{...props}
					id={id}
					title={title}
					body={body}
					schedule={schedule}
					status={status}
					filter_gender={filter_gender}
					filter_marital_status={filter_marital_status}
					filter_roles={filter_roles}
					isCreateForm={false}
					isEdit={true}
				/>
			)}
			{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
		</div>
	)
}

export default EditPushNotif

function usePushNotifDetails(pushNotifID) {
	const [pushNotif, setPushNotif] = useState({
		id: '',
		title: '',
		body: '',
		schedule: '',
		status: '',
		filter_gender: '',
		filter_marital_status: '',
		filter_roles: [],
	})
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		const fetchPushNotifDetails = async () => {
			try {
				setIsLoading(true)

				const response = await getPushNotifDetail(pushNotifID)

				const { id, title, body, status, schedule, gender, marital, role_id } = response.data.data[0]

				setPushNotif({
					id,
					title,
					body,
					status,
					schedule,
					filter_gender: gender,
					filter_marital_status: marital,
					filter_roles: role_id,
				})
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						setIsRedirect(true)
					} else if (error.response.status >= 500) {
						setErrorMessage('Cannot retrieve data from server, our team is currently working on it')
						setIsLoading(false)
					}
				}
			}
		}
		fetchPushNotifDetails()
	}, [pushNotifID])

	return {
		pushNotifDetails: { ...pushNotif },
		errorMessage,
		isLoading,
		isRedirect,
	}
}
