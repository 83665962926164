import React, { useState } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import CreateEventFormik from 'components/formik/CreateEventFormik'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import EditEventFormik from "../../components/formik/EditEventFormik";

function EditEvent(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [isRedirect, setIsRedirect] = useState(false);
	const [error, setError] = useState('');

	const church = JSON.parse(localStorage.getItem('church'));
	const name = church.church_name ? church.church_name : '-';
	const breads = [
		{ url: '/events/list', title: 'List of All' },
		{ url: '', title: 'Edit Event' },
	];
	const displayLoading = isLoading && !error;
	const displayForm = !isLoading && !error;
	const displayError = !isLoading && error;

	if (isRedirect) {
		return <Redirect to="/login" />
	}
	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 lg:w-5/6">
			<Helmet>
				<title>{name} - Edit Event</title>
			</Helmet>
			<PageBreadcrumbs icon={EventIcon} title="Edit Event" breads={breads} />
			{displayLoading && <Loading />}
			{displayError && <div className="text-center">{error}</div>}
			{displayForm && <EditEventFormik {...props} />}
		</div>
	)
}

export default EditEvent
