import React, { useState, useEffect } from 'react'
import PrayerItem from './PrayerItem'

export default function PrayerList ({ data }) {
	const [dataList, setDataList] = useState([])

	useEffect (() => {
		setDataList(data)
	}, [])

	return (
		<div>
			{dataList.map((item,index) => (
				<PrayerItem
					key={index}
					category={item.prayercategory.name}
					created={item.created_at}
					praise={item.description}
					prayer={item.prayerprev && item.prayerprev.description}
				/>
			))}
		</div>
	)
}