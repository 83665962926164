import React, {useEffect, useRef, useState} from 'react'
import {partial} from 'libs/utils/utils'
import Tippy from "@tippy.js/react";
import moment from "moment";
import {Link} from "react-router-dom";

const PushNotifTable = ({listPushNotifCampaign, handleOnSort}) => {
    const handleSortByCreatedOn = partial(handleOnSort, 'created_at');
    const handleSortBySchedule = partial(handleOnSort, 'sendstart_at');

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const styleTableRow = `border border-solid bt-blueSecondary`;
    const styleDropDown = 'z-20 shadow-md absolute right-20px ';
    const styleNullData = ' text-red-500 text-xs italic';

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/campaign/view-pushnotif/') ||
            String(event.target).includes('/campaign/edit-pushnotif/')) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const renderListPushNotifCampaignHead = () => {
        return (
            <thead className="bt-blueSecondary border-2 border-solid">
            <tr>
                <th className="px-2 py-4"/>
                <th className="px-4 py-4">NO.</th>
                <th className="px-4 py-4">SUBJECT</th>
                <th className="px-4 py-4">
                    CREATED ON
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByCreatedOn}/>
                </th>
                <th className="px-4 py-4">
                    SCHEDULE
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortBySchedule}/>
                </th>
                <th className="px-4 py-4">
                    STATUS
                </th>
                <th className="px-4 py-4">
                    TOTAL
                </th>
                <th className="px-2 py-4"/>
            </tr>
            </thead>
        )
    };

    const renderListPushNotifCampaignBody = () => {
        let listPushNotifCampaignBodyEl = [];

        listPushNotifCampaign.forEach((pushNotifCampaign, index) => {
            const styleTdStatus = pushNotifCampaign.isHighlight ? 'relative mr-6' : 'absolute mr-6';
            const styleStatusArchive = `${pushNotifCampaign.status === 'Archived' ? 'opacity-50' : 'opacity-100'}`;

            listPushNotifCampaignBodyEl.push(
                <tr className={styleTableRow} key={index}>
                    <td className={styleTdStatus}>
                        {pushNotifCampaign.notifStatus === 'Draft' && (
                            <Tippy content="Draft">
                                <div className="status-post-tag">
                                    <div className="ribbon-black-triagle"/>
                                </div>
                            </Tippy>
                        )}
                        {pushNotifCampaign.notifStatus === 'Archived' && (
                            <Tippy content="Archived">
                                <div className="status-post-tag">
                                    <div className={styleStatusArchive}>
                                        <div className="ribbon-black-triagle"/>
                                    </div>
                                </div>
                            </Tippy>
                        )}
                        {pushNotifCampaign.isHighlight && (
                            <Tippy content="Highlight" theme="star">
                                <i className="fas fa-star text-yellow-300 self-center flex"/>
                            </Tippy>
                        )}
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{pushNotifCampaign.no}</div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{pushNotifCampaign.subject}</div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            {
                                pushNotifCampaign.createdOn === 'unknown' ?
                                    <div className={styleNullData}>
                                        {pushNotifCampaign.createdOn}
                                    </div>
                                    :
                                    <div>{moment(pushNotifCampaign.createdOn, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            }
                            {
                                pushNotifCampaign.createdBy === 'unknown' ?
                                    <div className={styleNullData}>
                                        {pushNotifCampaign.createdBy}
                                    </div>
                                    :
                                    <div className="text-primary">by {pushNotifCampaign.createdBy}</div>
                            }
                        </div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        {
                            pushNotifCampaign.schedule === 'unknown' ?
                                <div className={styleNullData}>
                                    {pushNotifCampaign.schedule}
                                </div>
                                :
                                <div className={styleStatusArchive}>
                                    {moment(pushNotifCampaign.schedule, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}
                                </div>
                        }
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{pushNotifCampaign.notifStatus}</div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{`${pushNotifCampaign.totalSent} sent /`}</div>
                        <div className={styleStatusArchive}>{`${pushNotifCampaign.totalRecipient} total`}</div>
                    </td>

                    <td className="px-2 py-4">
                        <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>
                            <i className="dropdown-trigger fas fa-ellipsis-v"/>
                            <div
                                className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>
                                <ul ref={dropdownRef} className="bg-white">
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        <Link to={`/campaign/view-pushnotif/${pushNotifCampaign.no}`}>
                                            View
                                        </Link>
                                    </li>
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        <Link to={`/campaign/edit-pushnotif/${pushNotifCampaign.no}`}>
                                            Edit
                                        </Link>
                                    </li>
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });

        return listPushNotifCampaignBodyEl;
    };

    return (
        <div className="bg-white shadow-md rounded">
            <table className="w-full">
                {renderListPushNotifCampaignHead()}
                <tbody>
                {renderListPushNotifCampaignBody()}
                </tbody>
            </table>
        </div>
    )
};

export default PushNotifTable
