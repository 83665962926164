import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Content } from './styles'
import { ChurchProfileForm, CampusSection } from 'components/churchProfile/index'

export default class ChurchProfile extends Component {
  render() {
    const church = JSON.parse(localStorage.getItem('church'))
    const name = church.church_name ? church.church_name : '-'

    return (
      <div className="main-content flex-1 bg-gray-100 pb-24 md:px-6 md:py-0">
        <Helmet>
          <title>{name} - Church Profile</title>
        </Helmet>
        <Content>
          <ChurchProfileForm />
          <CampusSection />
        </Content>
      </div>
    )
  }
}
