import React, { Component } from 'react'
import Flatpickr from 'react-flatpickr'
import ReactQuill from 'react-quill'
import moment from 'moment'
import Joi from 'joi-browser'
import InputCheckBox from './InputCheckBox'
import Input from './Input'
import TextArea from '../TextArea.js'
import Label from '../Label'
import InvalidFeedback from '../InvalidFeedback'

class Form extends Component {
	handleValidate = (callback) => {
		const {
			title,
			shortTitle,
			description,
			startDate,
			endDate,
			startTime,
			endTime,
			venueName,
			venueAddress,
			regisStartDate,
			regisEndDate,
			participantQuota,
			numberSessions,
			minSessions,
			startShare,
			endShare,
			categoryId,
			gender,
			visibleToAll,
			maritalStatusAll,
			filter_role,
			marital_status,
			isOpenRegis,
			days,
		} = this.state.data

		let errors = []
		if (!title) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, title: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, title: '' },
			}))
			errors = []
		}

		if (!shortTitle) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, shortTitle: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, shortTitle: '' },
			}))
			errors = []
		}

		if (!description) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, description: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, description: '' },
			}))
			errors = []
		}

		if (!startDate) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startDate: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startDate: '' },
			}))
			errors = []
		}

		if (!endDate) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endDate: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endDate: '' },
			}))
			errors = []
		}

		if (!startTime) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startTime: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startTime: '' },
			}))
			errors = []
		}

		if (!endTime) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endTime: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endTime: '' },
			}))
			errors = []
		}

		if (!isOpenRegis) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, isOpenRegis: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, isOpenRegis: '' },
			}))
			errors = []
		}

		if (days.length === 0) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, days: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, days: '' },
			}))
			errors = []
		}

		if (!regisStartDate) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, regisStartDate: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, regisStartDate: '' },
			}))
			errors = []
		}

		if (!regisEndDate) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, regisEndDate: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, regisEndDate: '' },
			}))
			errors = []
		}

		if (!participantQuota) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, participantQuota: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, participantQuota: '' },
			}))
			errors = []
		}

		if (!numberSessions) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, numberSessions: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, numberSessions: '' },
			}))
			errors = []
		}

		if (!minSessions) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, minSessions: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, minSessions: '' },
			}))
			errors = []
		}

		if (!startShare) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startShare: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, startShare: '' },
			}))
			errors = []
		}

		if (!endShare) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endShare: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, endShare: '' },
			}))
			errors = []
		}

		if (!categoryId) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, categoryId: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, categoryId: '' },
			}))
			errors = []
		}

		if (!gender) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, gender: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, gender: '' },
			}))
			errors = []
		}

		if (!venueName) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, venueName: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, venueName: '' },
			}))
			errors = []
		}

		if (!venueAddress) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, venueAddress: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, venueAddress: '' },
			}))
			errors = []
		}

		if (marital_status.length === 0 && !maritalStatusAll) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, marital_status: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, marital_status: '' },
			}))
			errors = []
		}

		if (filter_role.length === 0 && !visibleToAll) {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, filter_role: 'Required' },
			}))
			errors.push('Required')
		} else {
			this.setState((prevState) => ({
				errors: { ...prevState.errors, filter_role: '' },
			}))
			errors = []
		}

		if (errors.length === 0) {
			callback()
		}
	}

	validate = () => {
		const result = Joi.validate(this.state.data, this.schema, { abortEarly: false })
		if (!result.error) return null
		const errors = {}
		for (let item of result.error.details) errors[item.path[0]] = item.message
		return errors
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const errors = this.validate()
		this.setState({ errors: errors || {} })
		if (errors) return
		this.doSubmit()
	}

	handleChange = ({ currentTarget: input }) => {
		const data = { ...this.state.data }
		data[input.name] = input.value

		if (input.name === 'categoryId') {
			if (input.value === '1') {
				data[input.name] = input.value
				data['gender'] = 'M'
			}
			if (input.value === '2') {
				data[input.name] = input.value
				data['gender'] = 'F'
			}
			if (input.value !== '1' && input.value !== '2') {
				data[input.name] = input.value
				data['gender'] = ''
			}
		}
		this.setState({ data })
	}

	handleChecked = ({ currentTarget: input }) => {
		const data = { ...this.state.data }
		data[input.name] = !JSON.parse(input.value)
		this.setState({ data })
	}

	handleFocus = ({ currentTarget: input }) => {
		input.placeholder = ''
	}

	handleBlur = ({ currentTarget: input }) => {
		if (input.name === 'email') input.placeholder = 'your@email.com'
		if (input.name === 'password') input.placeholder = 'your password'
		if (input.name === 'newPassword') input.placeholder = 'new password'
	}

	handleChangeHTML(content, delta, source, editor, name) {
		const data = { ...this.state.data }
		data[name] = content
		this.setState({ data })
	}

	handleChangeDate = (selectedDates, dateStr, instance, type) => {
		const data = { ...this.state.data }
		data[instance.element.name] = moment(selectedDates[0]).format(type)
		this.setState({ data })
	}

	renderCheckBoxDay(name, label, value, checked) {
		return (
			<div className="inline-flex items-center">
				<InputCheckBox name={name} value={value} onChange={this.handleChangeCourseDay} checked={checked} />
				<span className="text-gray-700 text-sm font-bold ml-2">{label}</span>
			</div>
		)
	}

	renderCheckBoxRole(name, label, value, checked) {
		return (
			<div className="inline-flex items-center">
				<InputCheckBox name={name} value={value} onChange={this.handleChangeCourseRole} checked={checked} />
				<span className="text-gray-700 text-sm font-bold ml-2">{label}</span>
			</div>
		)
	}

	renderCheckMarital(name, label, value, checked) {
		return (
			<div className="inline-flex items-center">
				<InputCheckBox name={name} value={value} onChange={this.handleChangeMarital} checked={checked} />
				<span className="text-gray-700 text-sm font-bold ml-2">{label}</span>
			</div>
		)
	}

	renderCheckBox(name, value, checked, label, onChange) {
		return (
			<div className="inline-flex items-center block text-gray-700 text-sm font-bold">
				<InputCheckBox name={name} value={value} onChange={onChange} checked={checked} />
				<span className="text-gray-700 text-sm font-bold ml-2">{label}</span>
			</div>
		)
	}

	renderInput(name, label, type = 'text') {
		const { data, errors } = this.state
		return (
			<div className="mb-6">
				<Label>{label}</Label>
				<Input name={name} value={data[name]} onChange={this.handleChange} type={type} />
				{errors[name] && <InvalidFeedback message={errors[name]} />}
			</div>
		)
	}

	renderTextArea(name, label) {
		const { data, errors } = this.state
		return (
			<div className="mb-6">
				<Label>{label}</Label>
				<TextArea name={name} value={data[name]} onChange={this.handleChange} error={errors[name]} />
			</div>
		)
	}

	renderInputHTML(name, label) {
		const { data, errors } = this.state
		return (
			<div className="mb-6">
				<Label>{label}</Label>
				<ReactQuill value={data[name]} onChange={(content, delta, source, editor) => this.handleChangeHTML(content, delta, source, editor, name)} />
				{errors[name] && <InvalidFeedback message={errors[name]} />}
			</div>
		)
	}

	renderSelect(name, label, options, disabled) {
		const { data, errors } = this.state
		return (
			<div className="mb-6 w-1/2">
				<Label>{label}</Label>
				<select className={`form-select block w-full border-2 rounded-lg border-secondary`} name={name} value={data[name]} onChange={this.handleChange}>
					<option value="" disabled={disabled}>
						Choose
					</option>
					{options.map((option) => (
						<option key={option.id || option.value} value={option.id || option.value} disabled={disabled}>
							{option.title}
						</option>
					))}
				</select>
				{errors[name] && <InvalidFeedback message={errors[name]} />}
			</div>
		)
	}

	renderDateInput(name, label) {
		const { data, errors } = this.state
		const classes = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		return (
			<div className="mb-6">
				<Label>{label}</Label>
				<Flatpickr
					className={classes}
					name={name}
					value={data[name]}
					placeholder="Choose date"
					onChange={(date, dateString, instance) => this.handleChangeDate(date, dateString, instance, 'DD/MM/YYYY')}
					options={{
						dateFormat: 'd/m/Y',
						altInput: true,
						altFormat: 'd M Y',
						enableTime: false,
					}}
				/>
				{errors[name] && <InvalidFeedback message={errors[name]} />}
			</div>
		)
	}

	renderTimeInput(name, label) {
		const { data, errors } = this.state
		const classes = `border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`

		return (
			<div className="mb-6 flex-row">
				<Label>{label}</Label>
				<Flatpickr
					className={classes}
					name={name}
					value={data[name]}
					onChange={(date, dateString, instance) => this.handleChangeDate(date, dateString, instance, 'HH:mm')}
					onValueUpdate={(date, dateString, instance) => this.handleChangeDate(date, dateString, instance, 'HH:mm')}
					options={{
						enableTime: true,
						noCalendar: true,
						time_24hr: true,
						altInput: false,
					}}
				/>
				{errors[name] && <InvalidFeedback message={errors[name]} />}
			</div>
		)
	}

	renderDateTimeInput(name, label, defaultHour = moment().format('H'), defaultMinute = moment().format('m')) {
		const { data, errors } = this.state
		const classes = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`

		return (
			<div>
				<Label text={label} />
				<Flatpickr
					name={name}
					value={data[name]}
					className={classes}
					placeholder="Choose date"
					onChange={(date, dateString, instance) => this.handleChangeDate(date, dateString, instance, 'DD/MM/YYYY HH:mm')}
					options={{
						dateFormat: 'd/m/Y H:i',
						altInput: true,
						altFormat: 'd M Y H:i',
						enableTime: true,
						time_24hr: true,
						defaultHour,
						defaultMinute,
					}}
				/>
				{errors[name] && <div className="text-red-500 text-xs italic">{errors[name]}</div>}
			</div>
		)
	}
}

export default Form
