import React from 'react'
import styled from 'styled-components'

const BillingBreadContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 30px;
`

const BillingPageTitle = styled.h1`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
`

const BillingBread = () => (
  <BillingBreadContainer>
    <BillingPageTitle>Billing</BillingPageTitle>
  </BillingBreadContainer>
)

export default BillingBread
