import React, { useState, useRef, useEffect, useCallback } from 'react'
import moment from 'moment'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import styled from 'styled-components'
import { getMinistryMemberDetail } from 'libs/apis/ministry'
import ChangeRole from 'components/commons/ChangeRole'

const RoleRibbon = styled.span`
	color: ${(props) => (props.value ? 'white' : 'white')};
	position: relative;
	top: ${(props) => (props.value ? '-60px' : '-50px')};
	top: -60px;
	width: 0;
	left: 20px;
	text-align: center;
	font-size: 18px;
	font-family: arial;
	display: block;
	transform: rotate(-45deg);
`

const RoleContainer = styled.div`
	width: 0;
	height: 0;
	border-top: 10px solid;
	border-bottom: 50px solid transparent;
	border-right: 90px solid transparent;
	margin-bottom: -100px;
	border-top: 90px solid ${(props) => (props.value ? '#1f3c88' : '#5893d4')};
`

const MinistryMemberCard = (props) => {
	const [state, setState] = useState({
		birthdate: '',
		phonenumber: '',
		email: '',
		maritalstatus: '',
		baptismdate: '',
		smallgroup: '',
		joinsmallgroup: '',
		smallgrouprole: '',
		joinministry: '',
		classes: [],
	})
	const [openDetails, setOpenDetails] = useState(false)
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	const handleClickOutside = useCallback((event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}, [])

	useEffect(() => {
		const fetchMemberDetail = async () => {
			try {
				const res = await getMinistryMemberDetail(props.id)
				const d = res.data.data.ministrymember

				setState({
					...state,
					birthdate: moment(d.user.birthdate).format('LL'),
					phonenumber: d.user.phone,
					email: d.user.email,
					baptismdate: moment(d.user.baptismdate).format('LL'),
					maritalstatus: d.user.maritalstatus_id ? d.user.maritalstatus.name : '',
					smallgrouprole: d.user.smallgroupmembers.length ? d.user.smallgroupmembers[0].smallgroupmemberrole.name : '',
					smallgroup: d.user.smallgroupmembers.length ? d.user.smallgroupmembers[0].smallgroup.name : '',
					joinsmallgroup: d.user.smallgroupmembers.length ? moment(d.user.smallgroupmembers[0].smallgroup.created_at).format('LL') : '',
					joinministry: moment(d.created_at).format('LL'),
				})
			} catch (error) {
			}
		}
		fetchMemberDetail()
	}, [])

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false)
		return () => {
			document.removeEventListener('click', handleClickOutside, false)
		}
	}, [handleClickOutside])

	const handleOpenUserDetails = () => {
		setOpenDetails(!openDetails)
	}

	const handleDropDown = () => {
		setDropDownOpen(!dropDownOpen)
	}

	return (
		<div className="w-2/6">
			<div className="bg-white mb-4 mr-2 pb-2 relative">
				{props.leader || props.keyvolunteer ? (
					<RoleContainer value={props.leader}>
						<RoleRibbon value={props.leader}>{props.leader ? 'MH' : 'KV'}</RoleRibbon>
					</RoleContainer>
				) : (
					<div className="h-10" />
				)}
				<div className="pt-4 flex justify-center">
					<img src={props.profile_photo || photoplaceholder} className="rounded-full flex items-center justify-center w-32 h-32" alt="ministryMember" />
					{props.member ? (
						<div className="cursor-pointer absolute top-0 right-0 mr-4 text-xl mt-4" onClick={handleDropDown} ref={wrapperRef}>
							<i className="dropdown-trigger fas fa-ellipsis-v" />
							<div className={`z-20 shadow-md ${dropDownOpen ? 'block' : 'hidden'}`} style={{ position: 'absolute', right: '-17px', top: '21px' }}>
								<ul className="bg-white" style={{ width: '130px' }}>
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm">
										<ChangeRole listRoles={props.listRoles} memberId={props.id} handleMinistryDetail={props.handleMinistryDetail} />
									</li>
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => props.handleRemoveMember(props.id)}>
										Remove
									</li>
								</ul>
							</div>
						</div>
					) : (
						<div className="cursor-pointer absolute top-0 right-4 mr-4 text-xl mt-4" onClick={handleDropDown} ref={wrapperRef}>
							<i className="dropdown-trigger fas fa-ellipsis-v" />
							<div className={`z-20 shadow-md ${dropDownOpen ? 'block' : 'hidden'}`} style={{ position: 'absolute', right: '-17px', top: '21px' }}>
								<ul className="bg-white">
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => props.handleAccept(props.id)}>
										Accept
									</li>
									<li className="px-4 py-2 block hover:bg-blueSecondary text-sm" onClick={() => props.handleReject(props.id)}>
										Decline
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
				<div className="font-bold text-center my-2">{props.user ? props.user.fullname : '-'}</div>
				<hr className="border-2 border-blueSecondary mx-4" />

				<i className={`fa ${openDetails ? 'fa-sort-up' : 'fa-sort-down'} flex justify-center`} onClick={handleOpenUserDetails} />
				{openDetails ? <MinistryCardDetails {...props} {...state} /> : null}
			</div>
		</div>
	)
}

export default MinistryMemberCard

export const MinistryCardDetails = (props) => {
	return (
		<div className="ml-5">
			<div className="mt-4">
				<label className="text-sm font-bold">Birtddate :</label>
				<p>{props.birthdate || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Phone Number :</label>
				<p>{props.phonenumber || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Email :</label>
				<p>{props.user.email || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Marital Status :</label>
				<p>{props.maritalstatus || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Small Group :</label>
				<p>{props.smallgroup || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Joined Small Group :</label>
				<p>{props.joinsmallgroup || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Small Group Role :</label>
				<p>{props.smallgrouprole || '-'}</p>
			</div>

			<div className="mt-4">
				<label className="text-sm font-bold">Joined Current Ministry :</label>
				<p>{props.joinministry || '-'}</p>
			</div>

			{/* <div className="mt-4">
				<label className="text-sm font-bold">Classes :</label>
				<ul>
					{props.class === 0 || props.class === undefined ? (
						<div />
					) : (
						props.class.map((val) => (
							<li key="classname" className="list-disc ml-8">
								{val.class_name} - {moment(val.class_date).format('ll')}
							</li>
						))
					)}
				</ul>
			</div>  */}
		</div>
	)
}
