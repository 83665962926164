import styled from 'styled-components'

export const Container = styled.div`
  grid-area: main;
  background-color: #f2f6f9;
  padding: 0 25px;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  height: 100%;
`
