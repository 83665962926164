import React, { useState, useEffect } from 'react'
import Loading from 'components/commons/Loading'

const InputCheckBox = (props) => {
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setLoading(true)
		try {
			await setData(props)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		loading ? <Loading /> :
		<div className={data.styles}>
			<input
				type="checkbox"
				className="form-checkbox border-2"
				name={data.name}
				value={data.value}
				onChange={data.onChange}
				checked={data.checked}
			/>
			{data.label && <span className="text-gray-700 font-bold text-sm ml-2">{data.label}</span>}
		</div>
	)
}

export default InputCheckBox
