import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import { converDate, partial } from 'libs/utils/utils'
import { Table, TableContainer, TBody, THead, THeader, TRow } from './Styles'

function NewsfeedTable({ listNewsfeed, handleOnSort }) {
	const handleSortTitle = partial(handleOnSort, 'name')
	const handleSortCategory = partial(handleOnSort, 'informationcategory_id')
	const handleSorteCreatedOn = partial(handleOnSort, 'created_at')

	return (
		<TableContainer>
			<Table>
				<THead>
					<tr style={{ height: '50px' }}>
						<th className="text-left pr-2" />
						<th className="text-left pl-6">
							title
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortTitle} />
						</th>
						<th>
							category
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortCategory} />
						</th>
						<th>
							created on
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSorteCreatedOn} />
						</th>
						<th className="px-2 py-4" />
					</tr>
				</THead>
				<TBody>
					{listNewsfeed.map(({ id, title, campus, category, createdOn, status }) => (
						<NewsfeedRow key={id} id={id} title={title} campus={campus} category={category.name} createdOn={createdOn} status={status} />
					))}
				</TBody>
			</Table>
		</TableContainer>
	)
}

export default NewsfeedTable

function NewsfeedRow({ id, title, category, createdOn, status }) {
	const [open, setOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleToggle() {
		setOpen(!open)
	}

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setOpen(false)
	}

	const styleDropDownOpen = `shadow-md absolute right-20px ${open ? 'block' : 'hidden'}`
	const styleTdStatus = true ? 'relative mr-6' : 'absolute mr-6'
	const styleStatusArchive = `${status === 'Archived' ? 'opacity-50' : 'opacity-100'}`

	return (
		<TRow>
			<td className={styleTdStatus}>
				{status === 'Draft' && (
					<Tippy content="Draft">
						<div className="status-post-tag">
							<div className="ribbon-black-triagle" />
						</div>
					</Tippy>
				)}
				{status === 'Archived' && (
					<Tippy content="Archived">
						<div className="status-post-tag">
							<div className={styleStatusArchive}>
								<div className="ribbon-black-triagle" />
							</div>
						</div>
					</Tippy>
				)}
			</td>
			<td className="text-left pl-6">{title}</td>
			<td>{category}</td>
			<td>{converDate(createdOn)}</td>

			<td className="px-2 py-4">
				{status !== 'Closed' && (
					<div className="cursor-pointer" onClick={handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={node} className="bg-white">
								<li>
									<Link className="px-4 py-2 block hover:bg-darkSkyBlue" to={`/newsfeed/edit/${id}`}>
										View & Edit
									</Link>
								</li>

								{/* <li className="px-4 py-2 block hover:bg-darkSkyBlue">Archive</li> */}
							</ul>
						</div>
					</div>
				)}
			</td>
		</TRow>
	)
}
