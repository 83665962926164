import React, {useCallback, useEffect, useState} from 'react';
import {constantCampaignGenderOption} from "../../constants/constant";
import {getCampaignDetail, getRecipientsBasic} from "../../libs/apis/emailCampaign";
import {
    cancelConfirmation,
    failedMessage,
    publishConfirmation,
    saveDraftConfirmation
} from "../../libs/utils/confirmation";
import {getRequirements, getRoleList} from "../../libs/apis/events";
import InputCheckBox from "../../components/commons/form/InputCheckBox";
import moment from "moment";
import TextInput from "../../components/commons/TextInput";
import SelectOptions from "../../components/commons/SelectOptions";
import InputDate from "../../components/commons/InputDate";
import Button from "../../components/commons/Button";
import Loading from "../../components/commons/Loading";
import TextArea from "../../components/commons/TextArea";

const EditPushNotifCampaignForm = (props) => {
    const {setFieldValue, errors, handleSubmit, isSubmitting, isRedirect} = props;

    const {
        title,
        body,
        basicRecipientMethodList,
        nonSmallGroupCheck,
        recipientsGender,
        recipientsMaritalStatus,
        schedule
    } = props.values;

    const [roleOption, setRoleOption] = useState([]);
    const [listMaritalStatus, setListMaritalStatus] = useState([]);
    const [totalRecipient, setTotalRecipient] = useState(0);

    const [recipientMethodValid, setRecipientMethodValid] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isLoadingRoles, setIsLoadingRoles] = useState(false);
    const [isMaritalStatusLoading, setIsMaritalStatusLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isTitleValid, setIsTitleValid] = useState(undefined);
    const [isBodyValid, setIsBodyValid] = useState(undefined);
    const [isGenderValid, setIsGenderValid] = useState(undefined);
    const [isMaritalValid, setIsMaritalValid] = useState(undefined);

    const [genderOption] = useState(constantCampaignGenderOption);

    const campaignId = props.match.params.id;
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);

    useEffect(() => {
        setFieldValue('campaignId', campaignId);
        fetchPushNotifCampaignDetail();
        // fetchPushNotifCampaignRecipients();
    }, []);

    const fetchPushNotifCampaignDetail = async () => {
        setIsLoadingDetail(true);
        try {
            let response = await getCampaignDetail(campaignId, 'Notif');
            let pushNotifData = response.data.data.campaign.data[0];
            setFieldValue('title', pushNotifData.title);
            setFieldValue('body', pushNotifData.description);
            setFieldValue('schedule', pushNotifData.sendstart_at);
            console.log('pushNotifData: ', pushNotifData);
            setIsLoadingDetail(false);
        } catch (error) {
            setIsLoadingDetail(false);
            setErrorMessage(error.message);
        }
    };

    const fetchPushNotifCampaignRecipients = () => {
        const responseGender = 'Female';
        const responseMartialStatus = [3];
        const responseSgmRoles = [];
        const responseMmRoles = [3];
        const responseMinistryGroup = null;
        const responseUserRoles = [];
        const responseNsmRoles = false;

        let updatedBasicRecipients = [];

        for (const role of responseSgmRoles) {
            updatedBasicRecipients.push('smallgroupmemberrole_id.'.concat(role))
        }

        for (const role of responseUserRoles) {
            updatedBasicRecipients.push('userrole_id.'.concat(role))
        }

        for (const role of responseMmRoles) {
            updatedBasicRecipients.push('ministrymemberrole_id.'.concat(role))
        }

        console.log('updatedBasicRecipients: ', updatedBasicRecipients);

        setFieldValue('basicRecipientMethodList', updatedBasicRecipients);
        setFieldValue('nonSmallGroupCheck', responseNsmRoles);
        setFieldValue('recipientsGender', responseGender);
        setFieldValue('recipientsMaritalStatus', responseMartialStatus)
    };

    useEffect(() => {
        fetchRoles();
        fetchMaritalStatus();
    }, []);

    useEffect(() => {
        fetchRecipients();
    }, [basicRecipientMethodList, recipientsGender, recipientsMaritalStatus, nonSmallGroupCheck]);

    const fetchRecipients = async () => {
        let updatedRecipientsGender = recipientsGender;
        if (recipientsGender === 'Both') {
            updatedRecipientsGender = null;
        }
        let smallgroupmemberrole_id = [];
        let userrole_id = [];
        let ministrymemberrole_id = [];
        if (basicRecipientMethodList.length > 0) {
            for (const recipient of basicRecipientMethodList) {
                console.log('recipient: ', recipient);
                const splittedId = recipient.split('.');
                if (recipient.includes('smallgroupmemberrole_id')) {
                    smallgroupmemberrole_id.push(splittedId[1])
                } else if (recipient.includes('userrole_id')) {
                    userrole_id.push(splittedId[1])
                } else if (recipient.includes('ministrymemberrole_id')) {
                    ministrymemberrole_id.push(splittedId[1])
                }
            }
        }
        if ((updatedRecipientsGender !== '' || recipientsMaritalStatus !== '' || basicRecipientMethodList.length > 0)) {
            let updatedMaritalStatus = recipientsMaritalStatus;
            if (updatedMaritalStatus === 'all') {
                updatedMaritalStatus = null;
            }
            try {
                setIsLoadingFilter(true);
                let responseTotal = await getRecipientsBasic(updatedRecipientsGender, updatedMaritalStatus ? updatedMaritalStatus.toString() : null,
                    smallgroupmemberrole_id.toString(), ministrymemberrole_id.toString(),
                    userrole_id.toString(), nonSmallGroupCheck, 'GetRecipients');
                let totalRecipient = responseTotal.data.data.recipient;
                setFieldValue('recipientParams',
                    {
                        gender: updatedRecipientsGender,
                        maritalStatus: updatedMaritalStatus ? updatedMaritalStatus.toString() : null,
                        smallGroupMemberRole: smallgroupmemberrole_id.toString(),
                        ministryMemberRole: ministrymemberrole_id.toString(),
                        userRole: userrole_id.toString(),
                        nonSmallGroup: nonSmallGroupCheck,
                    }
                );
                setTotalRecipient(totalRecipient);

                setIsLoadingFilter(false);
            } catch (error) {
                setIsLoadingFilter(false);
                await failedMessage(error.message);
            }
        }
    };

    const fetchRoles = async () => {
        setIsLoadingRoles(true);
        try {
            let response = await getRoleList();
            const roles = response.data.data;
            const regularRole = roles.role;
            const smallGroupMemberRole = roles.smallgroupmemberrole;
            const ministryMemberRole = roles.ministrymemberrole;
            let listRoles = [];
            if (regularRole) {
                for (const role of regularRole) {
                    listRoles.push(
                        {
                            id: 'userrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'userrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (smallGroupMemberRole) {
                for (const role of smallGroupMemberRole) {
                    listRoles.push(
                        {
                            id: 'smallgroupmemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'smallgroupmemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (ministryMemberRole) {
                for (const role of ministryMemberRole) {
                    listRoles.push(
                        {
                            id: 'ministrymemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'ministrymemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            setRoleOption(listRoles);
            setIsLoadingRoles(false);
        } catch (error) {
            setIsLoadingRoles(false);
            setErrorMessage(error.message);
        }
    };

    const handleCancel = async () => {
        const res = await cancelConfirmation();
        if (res.value) {
            props.history.push('/campaign/list-pushnotif')
        }
    };

    const checkValidation = useCallback(
        () => {
            if (!title) {
                setIsTitleValid(false);
            } else {
                setIsTitleValid(true);
            }

            if (!body) {
                setIsBodyValid(false);
            } else {
                setIsBodyValid(true);
            }

            if (basicRecipientMethodList.length === 0 &&
                !nonSmallGroupCheck) {
                setRecipientMethodValid(false);
            } else {
                setRecipientMethodValid(true);
            }

            if (!recipientsGender) {
                setIsGenderValid(false)
            } else {
                setIsGenderValid(true)
            }

            if (!recipientsMaritalStatus) {
                setIsMaritalValid(false)
            } else {
                setIsMaritalValid(true)
            }

            console.log('recipientsGender && recipientsMaritalStatus: ', recipientsGender && recipientsMaritalStatus);
            console.log('totalRecipient !== 0: ', totalRecipient !== 0);

            if (title && body && (basicRecipientMethodList.length !== 0 ||
                basicRecipientMethodList.length === 0 && nonSmallGroupCheck
            ) && totalRecipient !== 0) {
                if (recipientsGender && recipientsMaritalStatus && totalRecipient !== 0) {
                    handleSubmit();
                }
            }
        }, [
            title, body, basicRecipientMethodList, recipientsGender, recipientsMaritalStatus, totalRecipient
        ]
    );

    const handleOnDraft = async () => {
        const res = await saveDraftConfirmation();
        if (res.value) {
            setFieldValue('isDraft', true);
            checkValidation();
        }
    };

    const handleOnSubmit = async () => {
        const res = await publishConfirmation('Push Notif Campaign');
        if (res.value) {
            setFieldValue('isDraft', false);
            checkValidation();
        }
    };

    const handleTitleChange = (event) => {
        setFieldValue('title', event.target.value);
    };

    const handleBodyChange = (event) => {
        // const value = event.target.value;
        //
        // console.log('value: ', value);
        // const decodedHtml = value.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>');
        setFieldValue('body', event.target.value)
    };

    const handleRecipientChange = (recipient) => {
        const cloneBasicRecipientMethodList = [...basicRecipientMethodList];
        const toDeleteIndex = cloneBasicRecipientMethodList.findIndex((itrRecipient) => itrRecipient === recipient);

        if (toDeleteIndex < 0) {
            cloneBasicRecipientMethodList.push(recipient);
            console.log('cloneBasicRecipientMethodList: ', cloneBasicRecipientMethodList);
            setFieldValue('basicRecipientMethodList', cloneBasicRecipientMethodList)
        } else {
            cloneBasicRecipientMethodList.splice(toDeleteIndex, 1);
            console.log('cloneBasicRecipientMethodList: ', cloneBasicRecipientMethodList);
            setFieldValue('basicRecipientMethodList', cloneBasicRecipientMethodList)
        }
    };

    const handleNonSmallGroupChange = () => {
        setFieldValue('nonSmallGroupCheck', !nonSmallGroupCheck);
    };

    const renderRecipients = () => {
        let basicRecipientsMethodEl = [];

        for (const role of roleOption) {
            basicRecipientsMethodEl.push(
                <div className="w-1/2" key={role.id}>
                    <InputCheckBox value={role.value}
                                   checked={basicRecipientMethodList.includes(role.value)}
                                   onChange={() => handleRecipientChange(role.value)}
                                   label={role.label}/>
                </div>
            )
        }

        basicRecipientsMethodEl.push(
            <div className="w-1/2" key={'partner'}>
                <InputCheckBox value={false}
                               checked={nonSmallGroupCheck}
                               onChange={handleNonSmallGroupChange}
                               label={'Partner'}/>
            </div>
        );

        return basicRecipientsMethodEl;
    };

    const handleRecipientsGenderChange = (event) => {
        // console.log('event.target.value: ', event.target.value);
        setFieldValue('recipientsGender', event.target.value)
    };

    const fetchMaritalStatus = async () => {
        setIsMaritalStatusLoading(true);
        try {
            let response = await getRequirements("Event");
            const maritalStatusData = response.data.data.maritalstatuses;

            if (maritalStatusData) {
                let listMaritalStatus = [];
                for (const maritalStatus of maritalStatusData) {
                    listMaritalStatus.push(
                        {
                            id: maritalStatus.id,
                            label: maritalStatus.name,
                            value: maritalStatus.id
                        }
                    )
                }
                setListMaritalStatus(listMaritalStatus);
            }
            setIsMaritalStatusLoading(false);
        } catch (error) {
            setIsMaritalStatusLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleMaritalStatusChange = (event) => {
        setFieldValue('recipientsMaritalStatus', event.target.value)
    };

    const handleScheduleChange = (event) => {
        setFieldValue('schedule', moment(event[0], 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
    };

    return (
        <div>
            {
                isLoadingDetail ?
                    <Loading/>
                    :
                    <div className="flex md:-mx-2 flex-wrap">
                        <div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
                            <div className="bg-white shadow-md p-6">
                                <div>
                                    <TextInput label="TITLE" size="large" onChange={handleTitleChange} name="title"
                                               value={title}
                                               errors={isTitleValid === false && isTitleValid !== undefined ? 'Required' : undefined}/>
                                </div>

                                <div className="mb-6">
                                    <TextArea label="BODY" value={body}
                                              handleOnChange={handleBodyChange} name="body"
                                              errors={isBodyValid === false && isBodyValid !== undefined ? 'Required' : undefined}
                                    />
                                </div>

                                <div>
                                    <label className="block text-gray-700 font-bold pr-6 ">RECIPIENTS</label>
                                    <div className="mb-4 flex flex-row flex-wrap w-full">
                                        {
                                            isLoadingRoles || isLoadingFilter ?
                                                <Loading/> :
                                                renderRecipients()
                                        }
                                    </div>
                                </div>
                                <div className="mb-6 flex">
                                    <div className="w-full pr-2">
                                        {
                                            isLoadingFilter ?
                                                <Loading/>
                                                :
                                                <SelectOptions label="RECIPIENTS GENDER"
                                                               value={recipientsGender}
                                                               options={genderOption}
                                                               onChange={handleRecipientsGenderChange}
                                                               errors={isGenderValid === false && isGenderValid !== undefined ? 'Required' : undefined}
                                                />
                                        }
                                    </div>
                                    <div className="w-full pr-2">
                                        {
                                            isMaritalStatusLoading || isLoadingFilter ?
                                                <Loading/>
                                                :
                                                <SelectOptions label="RECIPIENTS MARIAGE STATUS"
                                                               value={recipientsMaritalStatus}
                                                               options={listMaritalStatus}
                                                               onChange={handleMaritalStatusChange}
                                                               errors={isMaritalValid === false && isMaritalValid !== undefined ? 'Required' : undefined}
                                                />
                                        }
                                    </div>
                                </div>

                                <div className="text-blue-500 text-xs italic mb-6">Total
                                    Recipient: {totalRecipient}</div>

                                <div className="w-3/6">
                                    <label className="block text-gray-700 font-bold mb-2">SCHEDULE</label>
                                    <InputDate handleChange={handleScheduleChange} withSec={true} value={schedule}
                                               isEdit={true}
                                               errors={errors.schedule}
                                    />
                                </div>

                                <div className="flex py-5">
                                    <div className="w-1/2">
                                        <button type="submit"
                                                className="font-bold bg-grey-300 text-white rounded-lg py-2 px-6 focus:outline-none"
                                                onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="w-1/2">
                                        <div className="flex justify-end">
                                            <Button
                                                handleClick={handleOnDraft}
                                                type="secondary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? <Loading/> : 'Save as Draft'}
                                            </Button>
                                            <Button
                                                size="ml-4"
                                                handleClick={handleOnSubmit}
                                                type="primary"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? <Loading secondary/> : 'Publish'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="md:w-1/3 w-full px-2">*/}
                        {/*    <PushNotifPreview {...props}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
            }
        </div>
    )
};

export default EditPushNotifCampaignForm;
