import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import Tippy from '@tippy.js/react'
import {partial} from 'libs/utils/utils'

const EventListTable = ({listEvent, handleOnSort, handleOnArchive, handleOnHighlight, handleOnUnhighlight}) => {

    const handleSortByStartDate = partial(handleOnSort, 'programstart_at');
    const handleSortByEndDate = partial(handleOnSort, 'programend_at');
    const handleSortByCreatedOn = partial(handleOnSort, 'created_at');
    const handleSortByLastUpdated = partial(handleOnSort, 'updated_at');
    // const handleArchiveClick= partial(handleOnArchive, id);

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const history = useHistory();

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/events/registrant/') ||
            String(event.target).includes('/events/edit/') ||
            event.target.innerHTML.includes('Archive')
        ) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const handleRegistrantClick = (index) => {
        history.push('/events/registrant/'.concat(index));
    };

    const renderListEventHead = () => {
        return (
            <thead className="bt-blueSecondary border-2 border-solid">
            <tr>
                <th className="px-2 py-4"/>
                <th className="px-2 py-4 text-left">ID</th>
                <th className="px-2 py-4 text-left">TITLE</th>
                <th className="px-2 py-4">
                    START DATE
                    <i className="fas fa-sort cursor-pointer ml-1" onClick={handleSortByStartDate}/>
                </th>
                <th className="px-2 py-4">
                    END DATE
                    <i className="fas fa-sort cursor-pointer ml-1" onClick={handleSortByEndDate}/>
                </th>
                <th className="px-2 py-4">
                    CREATED ON
                    <i className="fas fa-sort cursor-pointer ml-1" onClick={handleSortByCreatedOn}/>
                </th>
                <th className="px-2 py-4">
                    LAST UPDATED
                    <i className="fas fa-sort cursor-pointer ml-1" onClick={handleSortByLastUpdated}/>
                </th>
                <th className="px-2 py-4"/>
            </tr>
            </thead>
        );
    };

    const renderListEventBody = () => {
        let listEventBodyEl = [];

        listEvent.forEach((event, index) => {
            const styleTableRow = `border border-solid bt-blueSecondary`;
            const styleTdStatus = event.isHighlight ? 'relative mr-6' : 'absolute mr-6';
            const styleDropDown = 'z-20 shadow-md absolute right-20px ';
            const styleStatusArchive = `${event.status === 'Archived' ? 'opacity-50' : 'opacity-100'}`;
            const styleNullData = ' text-red-500 text-xs italic';

            listEventBodyEl.push(
                <tr key={index} className={styleTableRow}>
                    <td className={styleTdStatus}>
                        {event.status === 'Draft' && (
                            <Tippy content="Draft">
                                <div className="status-post-tag">
                                    <div className="ribbon-black-triagle"/>
                                </div>
                            </Tippy>
                        )}
                        {event.status === 'Archived' && (
                            <Tippy content="Archived">
                                <div className="status-post-tag">
                                    <div className={styleStatusArchive}>
                                        <div className="ribbon-black-triagle"/>
                                    </div>
                                </div>
                            </Tippy>
                        )}
                        {event.isHighlight && (
                            <Tippy content="Highlight" theme="star">
                                <i className="fas fa-star text-yellow-300 self-center flex"/>
                            </Tippy>
                        )}
                    </td>
                    <td className="px-2 py-4">
                        <div className={styleStatusArchive}>{event.id}</div>
                    </td>
                    <td className="px-2 py-4 max-w-xs w-1 text-left truncate">
                        {
                            event.title === 'unknown' ?
                                <div className={styleStatusArchive.concat(styleNullData)}>
                                    {event.title}
                                </div>
                                :
                                <div className={styleStatusArchive}>
                                    <Link to={`/events/edit/${event.id}`} title={event.title}>
                                        {event.title}
                                    </Link>
                                </div>
                        }
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div
                            className={styleStatusArchive}>
                            {
                                event.startDate === 'unknown' ?
                                    <div className={styleNullData}>
                                        {event.startDate}
                                    </div>
                                    :
                                    <div>
                                        <div>{moment(event.startDate, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                                    </div>
                            }
                        </div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div
                            className={styleStatusArchive}>
                            {
                                event.endDate === 'unknown' ?
                                    <div className={styleNullData}>
                                        {event.endDate}
                                    </div>
                                    :
                                    <div>{moment(event.endDate, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            }
                        </div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            {
                                event.createdOn === 'unknown' ?
                                    <div className={styleNullData}>
                                        {event.createdOn}
                                    </div>
                                    :
                                    <div>{moment(event.createdOn, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            }
                            {
                                event.createdBy === 'unknown' ?
                                    <div className={styleNullData}>
                                        {event.createdBy}
                                    </div>
                                    :
                                    <div className="text-primary">by {event.createdBy}</div>
                            }
                        </div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            <div>{moment(event.lastUpdated, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            {
                                event.lastUpdated === 'unknown' ?
                                    <div className={styleNullData}>
                                        {event.lastUpdated}
                                    </div>
                                    :
                                    <div
                                        className="text-primary">{moment(new Date(event.lastUpdated), 'DD/MM/YYYY HH:mm').fromNow()}</div>
                            }
                        </div>
                    </td>
                    <td className="px-2 py-4">
                        <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>
                            <i className="dropdown-trigger fas fa-ellipsis-v"/>
                            <div
                                className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>
                                <ul ref={dropdownRef} className="bg-white">
                                    <li>
                                        <Link className="px-4 py-2 block hover:bg-blueSecondary"
                                              to={`/events/registrant/${event.id}`}>
                                            Registrant List
                                        </Link>
                                    </li>
                                    {event.status !== 'Archived' && (
                                        <Link
                                            className="px-4 py-2 block hover:bg-blueSecondary"
                                            to={{
                                                pathname: `/events/edit/${event.id}`,
                                                state: {status: event.status},
                                            }}
                                        >
                                            View & Edit
                                        </Link>
                                    )}
                                    {event.status !== 'Archived' && (
                                        <li
                                            className="px-4 py-2 block hover:bg-blueSecondary"
                                            onClick={() => handleOnArchive(event.id)}
                                        >
                                            Archive
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        });

        return listEventBodyEl;
    };

    return (
        <div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0">
            <table className="w-full">
                {renderListEventHead()}
                <tbody>
                {renderListEventBody()}
                </tbody>
            </table>
        </div>
    )

};

export default EventListTable
