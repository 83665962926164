import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { getFileList, deleteFile, deleteFolder, getFileRole } from 'libs/apis/file'
import { getMinistryRole } from 'libs/apis/ministry'
import { ReactComponent as CampaignIcon } from 'assets/icons/file.svg'

import FileAdd from 'screens/file/FileAdd'
import Loading from 'components/commons/Loading'
import FileListTable from 'components/listFile/FileListTable'
import Searchbar from 'components/commons/Searchbar'
import ButtonExport from 'components/commons/ButtonDropdown'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import FolderAdd from './FolderAdd'
import alert from 'services/alert'

import { SmallGroupHeader } from 'screens/smallGroup/styles'

export default function FileList(props) {
	const [data, setData] = useState([])
	const [folders, setFolders] = useState([])
	const [filterKeyword, setFilterKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [sortBy, setSortBy] = useState('')
	const [sortDir, setSortDir] = useState('')
	const [smallgroupMembersRoles, setSmallgroupMembersRoles] = useState([])
	const [ministryRole, setMinistryRole] = useState([])
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List File' }]

	const { search } = props.location

	const handleLoadData = async () => {
		setIsLoading(true)
		try {
			let res = await getFileList()
			let docGroups = res.data.data.documentgroups.map((val) => {
				let temp = {}
				temp.id = val.id
				temp.created_at = val.created_at
				temp.name = val.name
				temp.isFolder = true
				return temp
			})
			const result = docGroups.concat(res.data.data.documents)
			setData(result)
			setFolders(docGroups)
			setIsLoading(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setIsLoading(false)
					setError('Cannot retrieve data from server')
				}
			}
		}
	}

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getFileRole()
				setSmallgroupMembersRoles(res.data.data.smallgroupmemberroles)
			} catch (error) {
			}
		}
		fetchRoles()
	}, [])

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getMinistryRole()
				setMinistryRole(res.data.data.ministrymemberrole)
			} catch (error) {
			}
		}
		fetchRoles()
	}, [])

	const handleSearch = (event) => {
		setFilterKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			setFilterKeyword(event.target.value)
			setSearchValue(event.target.value)
		}
	}

	const handleDelete = async (type, id) => {
		const { value } = await alert.confirmDelete()
		if (value) {
			try {
				if (type === 'Folder') {
					const res = await deleteFolder('Delete', type, id)
					if (res.data.api_status) {
						alert.succeed(`Success`, `Your file has been deleted`)
						handleLoadData()
					}
				} else {
					const res = await deleteFile('Delete', type, id)
					if (res.data.api_status) {
						alert.succeed(`Success`, `Your file has been deleted`)
						handleLoadData()
					}
				}
			} catch (error) {
				alert.failed(`Failed`, `Cannot delete file`)
			}
		}
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDir('ASC')
		} else {
			if (sortDir === 'ASC') {
				setSortBy(sort)
				setSortDir('DESC')
			} else if (sortDir === 'DESC') {
				setSortBy(sort)
				setSortDir('ASC')
			}
		}
	}

	useEffect(() => {
		handleLoadData()
	}, [search])

	const displayLoading = isLoading && !error
	const displayData = !isLoading && !error && data.length > 0
	const displayNoData = !isLoading && !error && !data.length
	const displayError = !isLoading && error

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List File</title>
			</Helmet>
			<PageBreadcrumbs icon={CampaignIcon} title="List File And Folder" breads={breads} />

			<SmallGroupHeader>
				<ButtonExport
					classname="mr-6"
					options={[
						<FileAdd handleLoadData={handleLoadData} smallgroupMembersRoles={smallgroupMembersRoles} ministryRole={ministryRole} />,
						<FolderAdd handleLoadData={handleLoadData} />,
					]}
				>
					Add
				</ButtonExport>
				{/* <ButtonExport classname="mr-6" options={[<FileAdd />, <FolderAdd />]}>
					Export
				</ButtonExport> */}
				<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
			</SmallGroupHeader>

			{displayLoading && <Loading />}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayError && <div className="text-center">{error}</div>}

			{displayData && (
				<>
					<FileListTable search={searchValue} data={data} handleLoadData={handleLoadData} handleDelete={handleDelete} handleOnSort={handleSort} folders={folders} />
				</>
			)}
		</div>
	)
}
