import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import queryString from 'query-string'
import { helperSelectFilter } from 'libs/utils/utils'

import ClassRegistrantExport from './ClassRegistrantExport'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import Loading from 'components/commons/Loading'
import Pagination from 'components/commons/Pagination'
import CourseInfoCard from 'components/courses/CourseInfoCard'
import CourseStatusCard from 'components/courses/CourseStatusCard'
import RegistrantTable from 'components/registrants/RegistrantTable'
import AttendanceTable from 'components/registrants/AtttendanceTable'
import QrCodeList from 'components/registrants/QrCodeList'
import SingleRegistrant from 'screens/courses/SingleRegistrant'

import { ReactComponent as CourseIcon } from 'assets/icons/classes.svg'
import { ReactComponent as AppliedIcon } from 'assets/icons/applied.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/check-approved.svg'
import { ReactComponent as QuotaIcon } from 'assets/icons/quota.svg'
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as PassFailIcon } from 'assets/icons/pass-fail.svg'
import { getCoursesRegistrants, exportRegistrantClass } from 'services/courseServices'
import { removeRegistrant, confirmResendEmail } from 'libs/apis/courses'

import Searchbar from 'components/commons/Searchbar'
import Button from 'components/commons/Button'
import PaginationPage from 'components/commons/PaginationPage'
import ButtonExport from 'components/commons/ButtonDropdown'
import alert from 'services/alert'

function ClassRegistrants() {
	const [state, setState] = useState({
		courseName: '',
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		venueName: '',
		venueAddress: '',
		participantQouta: 0,
		pending: '',
		applied: '',
		approved: '',
		rejected: '',
		adminApproval: '',
	})
	const [program, setProgram] = useState({})
	const [registrantList, setRegistrantList] = useState({})
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [statusRegistrantExport, setStatusRegistrantExport] = useState('')
	const [completeClass, setCompleteClass] = useState(false)
	const [registrants, setRegistrants] = useState([])
	const [attendances, setAttendances] = useState([])

	const [attendanceCount, setAttendanceCount] = useState(0)
	const [listClassAttendance, setListClassAttendance] = useState([])

	const [session, setSession] = useState(0)
	const [qrcodes, setQrcodes] = useState([])
	const [registrantstartat, setRegistrantstartat] = useState('')
	const [registrantendat, setRegistrantendat] = useState('')
	const [status, setStatus] = useState('')
	const [navTab, setNavTab] = useState('registrant')

	const [sortBy, setSortBy] = useState('')
	const [sortDirection, setSortDirection] = useState('')
	const [filterKeyword, setFilterKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')

	const [registrantStartPage, setRegistrantStartPage] = useState(1)
	const [registrantEndPage, setRegistrantEndPage] = useState(1)
	const [isLoading, setIsLoading] = useState(true)

	const { id } = useParams()
	const locations = useLocation()
	const history = useHistory()
	const page = locations.search ? queryString.parse(locations.search).page : 1

	useEffect(() => {
		setIsLoading(true)
		fetchRegistrant()

	}, [locations.search, navTab, searchValue, page])

	useEffect(() => {
		getDataRegistrant()

		return () => {
			setIsLoading(false)
		};
	}, [program, registrantList])

	const extractAttendance = (attendances, id) => {
		let attendanceList = []

		for (const attendance of attendances) {
			if (id === attendance.programticket_id) {
				attendanceList.push(!!attendance.ispresent)
			} else {
			}
		}

		return attendanceList
	}

	const fetchRegistrant = async () => {

		try {
			const res = await getCoursesRegistrants(id, 10, sortBy, sortDirection, registrantstartat, registrantendat, status, '', searchValue, 'Web', page)
			setProgram(res.data.data.program)
			setRegistrantList(res.data.data.registrantlists)
			setAttendanceCount(
				res.data.data.program.programbreakout ? 
					res.data.data.program.programbreakout.programbreakoutdates_count ? 
					res.data.data.program.programbreakout.programbreakoutdates_count
					: 0
				: 0
			)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const getDataRegistrant = () => {
		try {
			let registrantsData = []
			let listAttendance = {}

			if (registrantList.data) {
				registrantsData = registrantList.data.map((v) => {
					const temp = {}
					temp.id = v.id
					temp.user_id = v.user_id
					temp.name = v.user && (v.user.fullname || '')
					temp.role = v.user && (v.user.smallgroupmemberroles || [])
					temp.groupName = v.user && (v.user.smallgroups || [])
					temp.registeredAt = v.paymentdetails.length ? moment(v.paymentdetails[0].payment.transacted_at).format('DD MMM YYYY') : ''
					temp.updatedAt = v.paymentdetails.length ? moment(v.paymentdetails[0].payment.updated_at).format('DD MMM YYYY') : ''
					temp.status = v.status

					return temp
				})

				listAttendance = registrantList.data.map((v, idx) => {
					let t = {}
					t.id = v.id
					t.attendance = extractAttendance(v.user && (v.user.programticketattendance || []).length ? v.user.programticketattendance : [], registrantList.data[idx].id)
					t.fullname = v.user && (v.user.fullname || '')
					t.groupName = v.user && v.user.smallgroups.length ? v.user.smallgroups[0].name : '-'
					t.status = v.status
					return t
				})
			}

			setState({
				...state,
				courseName: `${program.programcode && program.programcode.codename} ${program.name}`,
				startDate: program.programstart_at ? moment(program.programstart_at).format('DD MM YYYY') : '',
				endDate: program.programend_at ? moment(program.programend_at).format('DD MM YYYY') : '',
				startTime: program.programstart_at ? moment(program.programstart_at).format('HH:mm') : '',
				endTime: program.programend_at ? moment(program.programend_at).format('HH:mm') : '',
				venueName: program.location,
				venueAddress: program.locationaddress,
				participantQouta: program.quotaPrograms,
				pending: program.pendingPayment ? program.pendingPayment.name : '',
				applied: program.pending,
				approved: program.approved,
				rejected: program.rejected,
				adminApproval: program.adminapprovals,
			})
			
			// setSession(program.programbreakout.programbreakoutdates_count)
			setAttendances(listAttendance)
			setRegistrants(registrantsData)
			setQrcodes(program.programbreakout && program.programbreakout.programbreakoutdates)
		} catch (error) {
			console.error(error)
		}
	}

	const handleExport = async () => {
		try {
			const res = await exportRegistrantClass(id, startDate, endDate, statusRegistrantExport.toString())
			if (res.data.api_status) {
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
				}
			}
		}
	}

	const handleOnToogle = (event) => {
		setNavTab(event.target.id)
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDirection('ASC')
		} else {
			if (sortDirection === 'ASC') {
				setSortBy(sort)
				setSortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setSortBy(sort)
				setSortDirection('ASC')
			}
		}
	}

	const handleOnSearch = (event) => {
		setFilterKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			history.push(`${locations.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setFilterKeyword(event.target.value)
		}
	}

	const handleStartDate = (date) => {
		setStartDate(moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleEndDate = (date) => {
		setEndDate(moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleClearFilter = () => {
		setStartDate('')
		setEndDate('')
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'status') {
			helperSelectFilter(statusRegistrantExport, event.target.value, setStatusRegistrantExport)
		}
	}

	const handleCompleteClass = async () => {
		const res = await alert.confirmComplete(!completeClass ? 'Are sure want to complete this class?' : 'Are you sure you want to activated this class?')
		if (res.value && !completeClass) {
			setCompleteClass(!completeClass)
		} else {
			setCompleteClass(!true)
		}
	}

	const handleRemoveRegistrant = async (programTicketId) => {
		try {
			const isRemove = await alert.confirmDelete()

			if (isRemove.value) {
				const res = await removeRegistrant(programTicketId)
				if (res.status === 200) {
					alert.succeed('Succeed')
					fetchRegistrant()
				}
			}
		} catch (error) {
		}
	}

	const handleResendConfirmEmail = async (programTicketId, userId) => {
		const type = 'Class' // type of resend conffirmation email
		try {
			const isResend = await alert.confirmResendEmail()

			if (isResend.value) {
				const res = await confirmResendEmail(programTicketId, userId, type)
				if (res.status === 200) {
					alert.succeed('Succeed')
					fetchRegistrant()
				}
			}
		} catch (error) {
		}
	}

	const handleUnCheck = () => {
		// try {
		// 	const id = this.props.match.params.id
		// 	const { data: response } = await attendanceToggle(id, registrant.id, session)
		// 	if (response.api_status) {
		// 		const attendances = [...this.state.attendances]
		// 		const index = attendances.indexOf(registrant)
		// 		attendances[index] = { ...attendances[index] }
		// 		const indexArr = attendances[index].attendance.indexOf(session)
		// 		if (indexArr > -1) {
		// 			attendances[index].attendance.splice(indexArr, 1)
		// 		}
		// 		this.setState({ attendances })
		// 	}
		// } catch (error) {
		// 	if (error.response) {
		// 		if (error.response.status === 401) {
		// 			return this.props.history.replace('/login')
		// 		}
		// 	}
		// }
	}
	const handleCheck = (user, session) => {
		// try {
		// 	const id = this.props.match.params.id
		// 	const { data: response } = await attendanceToggle(id, registrant.id, session)
		// 	if (response.api_status) {
		// 		const attendances = [...this.state.attendances]
		// 		const index = attendances.indexOf(registrant)
		// 		attendances[index] = { ...attendances[index] }
		// 		attendances[index].attendance.push(session)
		// 		this.setState({ attendances })
		// 	}
		// } catch (error) {
		// 	if (error.response) {
		// 		if (error.response.status === 401) {
		// 			return this.props.history.replace('/login')
		// 		}
		// 	}
		// }
	}

	const RenderOverview = () => {
		const { courseName, startDate, endDate, venueAddress, venueName, startTime, endTime, participantQouta, pending, adminApproval, applied, approved, rejected } = state

		return (
			<div className="flex -mx-2">
				<div className="md:w-4/12 px-2">
					<CourseInfoCard
						color="bg-greyExport"
						courseName={courseName}
						startDate={startDate}
						endDate={endDate}
						startTime={startTime}
						endTime={endTime}
						venueName={venueName}
						venueAddress={venueAddress}
					/>
				</div>
				<div className="md:w-8/12 px-2">
					<div className="flex flex-wrap -mb-4 content-between h-full">
						<CourseStatusCard color="bg-grey-300" title="Leader's Approval" value={pending} icon={<PendingIcon fill="#989898" />} />
						<CourseStatusCard color="bg-black" title="Admin's Approval" value={adminApproval} icon={<PassFailIcon fill="#404040" />} />
						<CourseStatusCard color="bg-primary" title="Quota" value={participantQouta} icon={<QuotaIcon fill="#4da5dc" />} />
						<CourseStatusCard color="bg-yellow-300" title="Pending Payment" value={applied} icon={<AppliedIcon fill="#fde81a" />} />
						<CourseStatusCard color="bg-green-300" title="Approved" value={approved} icon={<ApprovedIcon fill="#58c340" />} />
						<CourseStatusCard color="bg-red-500" title="Rejected" value={rejected} icon={<CancelIcon fill="#e34f4f" />} />
					</div>
				</div>
			</div>
		)
	}

	const RenderRegistrantHelper = () => {
		return (
			<div className="flex mb-6 mt-6">
				<ClassRegistrantExport
					id={id}
					handleStartDate={handleStartDate}
					handleEndDate={handleEndDate}
					handleClearFilter={handleClearFilter}
					handleExport={handleExport}
					startDate={startDate}
					endDate={endDate}
					statusRegistrantExport={statusRegistrantExport}
					handleSelectFilter={handleSelectFilter}
				/>
				{/* <Button type="tertiary" size="ml-4" handleClick={handleCompleteClass}>
					{completeClass ? <span className="text-yellow-700">Class Completed</span> : 'Complete Class'}
				</Button> */}

				<div className="inline-block m-auto" />
				{/* <div className="mr-16">
					<ButtonExport options={[<SingleRegistrant />]}>Registrant</ButtonExport>
				</div> */}
				<div className="inline-block">
					<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
				</div>
			</div>
		)
	}

	const RenderNavTab = () => {
		const registrantStyle = `nav-link ${navTab === 'registrant' ? 'active bg-white shadow-md' : ''}`.trim()
		const attendanceStyle = `nav-link ${navTab === 'attendance' ? 'active bg-white shadow-md' : ''}`.trim()
		const checkInStyle = `nav-link ${navTab === 'checkin' ? 'active bg-white shadow-md' : ''}`.trim()

		return (
			<ul className="nav nav-tabs -mt-6">
				<li className="nav-item bg-white">
					<div className={registrantStyle} id="registrant" onClick={handleOnToogle}>
						REGISTRANT
					</div>
				</li>
				<li className="nav-item bg-white">
					<div className={attendanceStyle} id="attendance" onClick={handleOnToogle}>
						ATTENDANCE
					</div>
				</li>
				<li className="nav-item bg-white">
					<div className={checkInStyle} id="checkin" onClick={handleOnToogle}>
						CHECK-IN
					</div>
				</li>
			</ul>
		)
	}

	const RenderData = () => {
		const count = registrants.length
		const parsed = queryString.parse(locations.search)
		const registrantActivePage = `${locations.pathname}?paginate=10&page=${parsed.page || 1}`

		return (
			<div>
				{ isLoading && < Loading />}
				{
					!isLoading && !!count &&
					<div>
						<RegistrantTable registrants={registrants} handleOnSort={handleSort} handleRemoveRegistrant={handleRemoveRegistrant} handleResendConfirmEmail={handleResendConfirmEmail} />
						<PaginationPage currentPage={registrantList.current_page} lastPage={registrantList.last_page} activeLink={registrantActivePage} length={registrants.length} url={locations.pathname} />
					</div>
				}
				{!isLoading && !count && <div className="text-center">No data available</div>}
			</div>
		)
	}

	const RenderAttendance = () => {
		const count = attendances.length
		const parsed = queryString.parse(locations.search)
		const registrantActivePage = `${locations.pathname}?paginate=10&page=${parsed.page || 1}`

		if (isLoading) return <Loading />
		if (!count && !isLoading) return <div className="text-center">No data available</div>
		return (
			<div>
				<AttendanceTable
					session={attendanceCount}
					attendances={attendances}
					onSort={handleSort}
					attendanceCount={attendanceCount}
					listClassAttendance={listClassAttendance}
					handleCheck={handleCheck}
					handleUnCheck={handleUnCheck}
				/>
				<PaginationPage currentPage={registrantStartPage} lastPage={registrantEndPage} activeLink={registrantActivePage} length={registrants.length} url={locations.pathname} />
			</div>
		)
	}

	const RenderQR = () => {
		const count = qrcodes && (qrcodes.length || 0)

		if (isLoading) return <Loading />
		if (!count) return <div className="text-center">No data available</div>
		return (
			<div className="bg-white shadow-md">
				<div className="p-4">
					<div className="flex flex-wrap">
						{qrcodes.map((qrcode, idx) => (
							<QrCodeList key={idx} qrcode={qrcode} />
						))}
					</div>
				</div>
			</div>
		)
	}

	const registrantTab = navTab === 'registrant'
	const attendanceTab = navTab === 'attendance'
	const checkinTab = navTab === 'checkin'

	const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
	const breads = [
		{ path: '/classes', label: 'List of All Classes' },
		{
			key: 'listofall',
			label: 'Registrant List',
		},
	]

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<HeadTitle title={`${name} - Class`} />
			<BreadCrumb title="Registrant List" breads={breads} icon={CourseIcon} />
			{RenderOverview()}
			{RenderRegistrantHelper()}
			{RenderNavTab()}
			{registrantTab && RenderData()}
			{attendanceTab && RenderAttendance()}
			{checkinTab && RenderQR()}
		</div>
	)
}

export default ClassRegistrants
