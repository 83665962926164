import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import alert from 'services/alert'
import { getRole } from 'libs/apis/smallGroup'
import { uploadFolder } from 'libs/apis/file'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import InputCheckBox from 'components/commons/form/InputCheckBox'

const customStyles = {
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '200px',
		width: '600px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
}

export default function FolderAdd(props) {
	const [folderName, setFolderName] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { handleLoadData } = props

	const handleTogleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleChange = (e) => {
		setFolderName(e.target.value)
	}

	const handleSubmit = async () => {
		setIsLoading(true)
		try {
			let res = await uploadFolder('Create', 'Folder', folderName)

			if (res.data.api_status) {
				alert.succeed(`Success`, `Your file has been uploaded`)
				setIsLoading(false)
				setModalIsOpen(false)
				handleLoadData()
			} else {
				alert.failed(`Ooops`, `Cannot upload your file`)
				setIsLoading(false)
				setModalIsOpen(false)
				handleLoadData()
			}
		} catch (error) {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<button className="focus:outline-none" onClick={handleTogleModal}>
				Folder
			</button>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Folder Add Modal" appElement={document.getElementById('root')}>
				<div className="flex-col flex mt-3">
					<div className="font-bold text-sm mb-2">ADD FOLDER</div>
					<input className="py-2 pl-3 border-secondary border-2 rounded-lg" type="text" name="fileName" onChange={handleChange} placeholder="Type folder name" />
				</div>

				<div className="flex justify-center mt-4">
					<Button isLoading={isLoading} type="cancel" handleClick={handleTogleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" style="ml-6" handleClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
