import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

import { getSmallGroupList, closeSmallGroup } from 'libs/apis/smallGroup'
import { confirmCloseGroup } from 'libs/utils/confirmation'
import { helperSelectFilter } from 'libs/utils/utils'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import SmallGroupTable from 'components/smallGroup/SmallGroupTable'
import PaginationPage from 'components/commons/PaginationPage'
import SmallGroupFilter from 'components/smallGroup/SmallGroupFilter'
import Searchbar from 'components/commons/Searchbar'
import ButtonExport from 'components/commons/ButtonDropdown'
import SmallGroupExport from 'components/smallGroup/SmallGroupExport'
import SmallGroupMemberExport from 'components/smallGroup/SmallGroupMemberExport'
import SmallGroupExportRequest from 'components/smallGroup/SmallGroupExportRequest'

import alert from 'services/alert'
import { SmallGroupHeader } from './styles'

function ListSmallGroup(props) {
	const [listSmallGroup, setListSmallGroup] = useState([])
	const [locations, setLocations] = useState('')
	const [days, setDays] = useState('')
	const [categories, setCategories] = useState('')
	const [sglevel, setSglevel] = useState('')
	const [campuses, setCampuses] = useState('')
	const [roles, setRoles] = useState('')
	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [sortBy, setSortBy] = useState('created_at')
	const [sortDir, setSortDir] = useState('DESC')
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect] = useState(false)
	const [errorMessage] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)

	const { search } = props.location
	const page = search ? queryString.parse(search).page : 1

	/* fetch smallgroup list */
	useEffect(() => {
		handleFetchData()
	}, [page, locations, days, categories, sglevel, searchValue, campuses, sortBy, sortDir])

	/* func get smallgroup list */
	const handleFetchData = async () => {
		setIsLoading(true)

		try {
			let res = await getSmallGroupList(10, locations.toString(), sglevel.toString(), categories.toString(), campuses.toString(), days.toString(), searchValue, sortBy, sortDir, page)

			let responseData = res.data.data.smallgroup.data

			if (res.data.data.smallgroup !== null) {
				let listSG = responseData.map((data) => {
					let obj = {}
					obj.id = data.id
					obj.campus = data.campus ? data.campus.name : 'No Campus'
					obj.sgName = data.name
					obj.sgCategory = data.smallgroupcategory.name
					obj.sgLeaders = []

					if (data.smallgroupleader !== null && data.smallgroupleader2 !== null) {
						obj.sgLevel = data.smallgroupleader.smallgroupmemberrole.name
						obj.sgLeaders[0] = data.smallgroupleader.user ? data.smallgroupleader.user.fullname : ''
						obj.sgLeaders[1] = data.smallgroupleader2.user ? data.smallgroupleader2.user.fullname : ''
					}
					if (data.smallgroupleader === null && data.smallgroupleader2 === null) {
						obj.sgLevel = ''
						obj.sgLeaders[0] = ''
					}
					if (data.smallgroupleader !== null && data.smallgroupleader2 === null) {
						obj.sgLevel = data.smallgroupleader.smallgroupmemberrole.name
						obj.sgLeaders[0] = data.smallgroupleader.user ? data.smallgroupleader.user.fullname : ''
					}

					obj.members = data.smallgroupmembers_count
					obj.day = data.appointmentdefaultday
					obj.time = data.appointmentdefaulttime
					obj.status = data.status

					return obj
				})
				setListSmallGroup(listSG)

				setCurrentPage(res.data.data.smallgroup.current_page)
				setLastPage(res.data.data.smallgroup.last_page)
				setIsLoading(false)
			} else {
				setListSmallGroup([])
			}
		} catch (error) {
			setIsLoading(false)
		}
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDir('ASC')
		} else {
			if (sortDir === 'ASC') {
				setSortBy(sort)
				setSortDir('DESC')
			} else if (sortDir === 'DESC') {
				setSortBy(sort)
				setSortDir('ASC')
			}
		}
	}

	const handleCloseSmallGroup = async (id) => {
		const confirm = await confirmCloseGroup()
		if (confirm.value) {
			setIsLoading(true)
			try {
				const response = await closeSmallGroup(id)
				if (response.data.api_status) {
					alert.succeed(response.data.data.message)
					handleFetchData()
					setIsLoading(false)
				}
			} catch (error) {
				setIsLoading(false)
			}
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'location') {
			helperSelectFilter(locations, event.target.value, setLocations)
		}

		if (event.target.name === 'day') {
			helperSelectFilter(days, event.target.value, setDays)
		}

		if (event.target.name === 'category') {
			helperSelectFilter(categories, event.target.value, setCategories)
		}

		if (event.target.name === 'sgLevel') {
			helperSelectFilter(sglevel, event.target.value, setSglevel)
		}

		if (event.target.name === 'campus') {
			helperSelectFilter(campuses, event.target.value, setCampuses)
		}

		if (event.target.name === 'allCampus') {
			helperSelectFilter(campuses, event.target.value, setCampuses)
		}

		if (event.target.name === 'role') {
			helperSelectFilter(roles, event.target.value, setRoles)
		}
	}

	const handleApplyFilter = () => {
		props.history.push(`${props.location.pathname}?paginate=10&page=1`)
		handleFetchData()
	}

	function handleClearFilter() {
		setLocations('')
		setDays('')
		setCategories('')
		setSglevel('')
		setCampuses('')
		setRoles('')
		props.history.push(`${props.location.pathname}?paginate=10&page=1`)
	}

	const parsed = queryString.parse(props.location.search)
	const active = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of All Groups' }]

	const displayLoading = isLoading
	const displayError = !isLoading && errorMessage
	const displayNoData = !isLoading && !errorMessage && listSmallGroup.length === 0
	const displayData = !isLoading && !errorMessage && listSmallGroup.length > 0

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Small Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="List of All Groups" breads={breads} />
			<SmallGroupHeader>
				<SmallGroupFilter
					handleSelectFilter={handleSelectFilter}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					locations={locations}
					days={days}
					categories={categories}
					sgLevels={sglevel}
					campuses={campuses}
				/>
				<ButtonExport options={[<SmallGroupExport listRoles={sglevel} />, <SmallGroupMemberExport listRoles={sglevel} />, <SmallGroupExportRequest listRoles={sglevel} />]}>
					Export
				</ButtonExport>
				<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} placeholder="Search by smallgroup name" />
			</SmallGroupHeader>
			{displayLoading && <Loading />}
			{displayError && <div className="text-center">{errorMessage}</div>}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayData && (
				<>
					<SmallGroupTable listSmallGroup={listSmallGroup} handleOnSort={handleSort} handleOnClose={handleCloseSmallGroup} />
					<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={listSmallGroup.length} url={props.location.pathname} />
				</>
			)}
		</div>
	)
}

export default ListSmallGroup
