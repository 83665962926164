import React from 'react'

export default function Button({ type, handleClick, children, style, size, disabled }) {
	const defaultStyle = 'font-bold shadow-md rounded-lg py-2 px-6 text-white focus:outline-none'

	const buttonType = {
		primary: 'bg-primary border-primary border-2 text-white',
		secondary: 'border-primary border-solid border-2 text-primary',
		tertiary: 'bg-white border-white border-solid border-2 text-primary',
		cancel: 'border-2 border-grey-300 bg-grey-300 text-white',
		export: 'bg-greyExport text-white',
	};

	const classname = [defaultStyle, buttonType[type]].join(' ');

	return (
		<div className={size}>
			<div className={style}>
				<button className={classname} onClick={handleClick} disabled={disabled}>
					{children}
				</button>
			</div>
		</div>
	)
}
