import * as Yup from 'yup'

const validationSchema = () =>
	Yup.object().shape({
		file: Yup.mixed().when('media', {
			is: 'Image',
			then: Yup.mixed()
				.required('Required')
				.test('imageSize', 'Image size is too large, max 5 MB', (value) => (value && value.size <= 5242880) || typeof value === 'string'),
			otherwise: Yup.mixed().notRequired(),
		}),
		video: Yup.string().when('media', {
			is: 'Video',
			then: Yup.string()
				.matches(/^https()?:\/\/?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/, {
					message: 'Invalid Youtube URL',
				})
				.required('Required'),
			otherwise: Yup.string().notRequired(),
		}),
		title: Yup.string().required('Required'),
		description: Yup.string().required('Required'),
		startDate: Yup.string().required('Required'),
		endDate: Yup.string().required('Required'),
	})

export default validationSchema
