import React from 'react'
import { removeMinistryMember } from 'libs/apis/ministry'
import MinistryCard from './MinistryCard'
import alert from 'services/alert'

function MinistryMemberList(props) {
	const { handleMinistryDetail, ministryheads, searchvalue, keyvolunteers, listRoles, volunteers, intern, trainee } = props
	const a = ministryheads.filter((v) => v.user.fullname.toLowerCase().includes(searchvalue))
	const b = keyvolunteers.filter((v) => v.user.fullname.toLowerCase().includes(searchvalue))
	const c = volunteers.filter((v) => v.user.fullname.toLowerCase().includes(searchvalue))
	const d = intern.filter((v) => v.user.fullname.toLowerCase().includes(searchvalue))
	const e = trainee.filter((v) => v.user.fullname.toLowerCase().includes(searchvalue))

	const handleRemoveMember = async (e) => {
		try {
			const res = await removeMinistryMember(e)
			if (res.data.api_status) {
				alert.succeed('Succeed')
				handleMinistryDetail()
			}
		} catch (error) {
			alert.failed('Failed')
		}
	}

	return (
		<div>
			<div className="flex flex-wrap">
				{a.map((data) => (
					<MinistryCard
						key={data.user_id}
						leader={true}
						member={true}
						{...data}
						handleMinistryDetail={handleMinistryDetail}
						listRoles={listRoles}
						handleRemoveMember={handleRemoveMember}
					/>
				))}
				{b.map((keyvolunteer) => (
					<MinistryCard
						key={keyvolunteer.user_id}
						keyvolunteer={true}
						member={true}
						{...keyvolunteer}
						handleMinistryDetail={handleMinistryDetail}
						listRoles={listRoles}
						handleRemoveMember={handleRemoveMember}
					/>
				))}
				{c.map((volunteer) => (
					<MinistryCard
						key={volunteer.user_id}
						{...volunteer}
						member={true}
						handleMinistryDetail={handleMinistryDetail}
						listRoles={listRoles}
						handleRemoveMember={handleRemoveMember}
					/>
				))}

				{d.map((intern) => (
					<MinistryCard key={intern.user_id} {...intern} member={true} handleMinistryDetail={handleMinistryDetail} listRoles={listRoles} handleRemoveMember={handleRemoveMember} />
				))}

				{e.map((trainee) => (
					<MinistryCard
						key={trainee.user_id}
						{...trainee}
						member={true}
						handleMinistryDetail={handleMinistryDetail}
						listRoles={listRoles}
						handleRemoveMember={handleRemoveMember}
					/>
				))}
			</div>
		</div>
	)
}

export default MinistryMemberList
