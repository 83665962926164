import React, {useState} from 'react'
import {Tooltip} from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import ImageIframe from "./ImageIframe";
import Button from "./Button";
import {failedMessage, somethingWrong, successMessage} from "../../libs/utils/confirmation";
import {uploadImage} from "../../libs/apis/emailCampaign";
import Loading from "./Loading";
import TextInputMarkDown from "./TextInputMarkDown";

const ImageUploadCampaign = (props) => {
    const {field, form} = props;
    const prevImagesValue = form.getFieldProps('attachedImages').value;
    const prevDescriptionValue = form.getFieldProps('description').value;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const displayLoading = isLoading && !errorMessage;
    const displayError = !isLoading && errorMessage;

    const fileName = field.value
        ? field.value.name || field.value.substring(field.value.lastIndexOf('/') + 1)
        : 'Click to upload or drop your image here';

    const handleOnChange = async (event) => {
        form.setFieldValue('attachedImages', [...prevImagesValue, event.currentTarget.files[0]]);

        let formdata = new FormData();
        formdata.append('ppFile', event.currentTarget.files[0]);
        formdata.append('type', 'Email');

        try {
            setIsLoading(true);
            let res = await uploadImage(formdata);
            if (res.data.api_status === true) {
                const resImageUrl = res.data.data.url;
                form.setFieldValue('attachedImages', [...prevImagesValue, resImageUrl]);
                successMessage();
            } else {
                failedMessage(res.data.message)
            }
            setIsLoading(false);
        } catch (error) {
            somethingWrong();
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleUseButtonClick = (markDownImage) => {
        form.setFieldValue('markDown', markDownImage);
        const prevDescriptionValue = form.getFieldProps('description').value;
        form.setFieldValue('description', prevDescriptionValue.concat(markDownImage));
    };

    const handleRemoveButtonClick = (index, markDownImage) => {
        const cloneImages = [...prevImagesValue];
        const cloneDescription = prevDescriptionValue;

        cloneImages.splice(index, 1);
        let updatedDescription = cloneDescription.replaceAll(markDownImage, '');

        form.setFieldValue('attachedImages', cloneImages);
        form.setFieldValue('description', updatedDescription);
    };

    const renderImagesUrl = () => {
        let imageUrlEl = [];
        prevImagesValue.forEach((imageUrl, index) => {
            const markDownImage = '[![]('.concat(imageUrl).concat(')]()');
            imageUrlEl.push(
                <div className="flex items-center my-6" key={imageUrl}>
                    <div className="w-1/3">
                        <div className="w-40">{imageUrl && <ImageIframe img={imageUrl}/>}</div>
                    </div>
                    <div className="w-2/3">
                        <div className="flex flex-col">
                            <div className="flex flex-row">
                                <Button type="primary" handleClick={() => handleUseButtonClick(markDownImage)}>
                                    Use
                                </Button>
                                <Button size="ml-4" type="secondary"
                                        handleClick={() => handleRemoveButtonClick(index, markDownImage)}>
                                    Delete
                                </Button>
                            </div>
                            {
                                markDownImage ?
                                    <div className="mt-2">
                                        <TextInputMarkDown value={markDownImage}/>
                                    </div>
                                    :
                                    <div/>
                            }
                        </div>
                    </div>
                </div>
            )
        });

        return imageUrlEl;
    };

    return (
        <Tooltip
            title="Recommended image size 900x506 pixels"
            position="bottom"
            trigger="mouseenter"
        >
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayLoading && <Loading/>}
            {
                !displayLoading &&
                <div>
                    <div className="p-0 rounded-none border-0 relative inline-block w-full mb-5">
                        <input
                            type="file"
                            name={field.name}
                            accept="image/*"
                            multiple={false}
                            onChange={handleOnChange}
                            className="relative z-10 w-full h-full opacity-0 py-6 cursor-pointer"
                        />
                        <label
                            className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
                            <span className="text-secondary">{fileName}</span>
                        </label>
                    </div>
                    {renderImagesUrl()}
                </div>
            }
        </Tooltip>

    )
};

export default ImageUploadCampaign
