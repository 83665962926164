import React from 'react'

function Select({ name, value, onChange, options, disabled }) {
	return (
		<div className="mb-2">
			<select className="form-select w-full h-11 block w-auto border-2 rounded-lg focus:border-secondary " name={name} value={value} onChange={onChange} disabled={disabled}>
				<option value="">Choose</option>
				{options.map((option, idx) => (
					<option disabled={disabled} key={idx} value={option.id}>
						{option.label || option.title || option.name || option.fullname || option.show_as}
					</option>
				))}
			</select>
		</div>
	)
}

export default Select
