import React, { useState } from 'react'
import Modal from 'react-modal'

import FilterItem from 'components/commons/FilterItem'
import InputDateFilterExport from 'components/commons/InputDateFilterExport'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from './Styles'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '410px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

export default function AppointmentsFilter(props) {
	const [tab, setTab] = useState('category')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const listCampus = JSON.parse(localStorage.getItem('campuses'))

	const {
		handleSelectFilter,
		appointmentType,
		sgLevel,
		campuses,
		categories,
		dateFrom,
		dateTo,
		handleClearFilter,
		handleApplyFilter,
		handleDateFrom,
		handleDateTo,
		listCategory,
		allLevel,
		appointmentTypes,
	} = props

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('category')
	}

	const handleApply = () => {
		handleApplyFilter()
		setModalIsOpen(!modalIsOpen)
	}

	const handleClear = () => {
		handleClearFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const categoryTab = tab === 'category'
	const levelTab = tab === 'level'
	const campusTab = tab === 'campus'
	const typeTab = tab === 'type'
	const dateTab = tab === 'date'

	const length = categories.length !== 0 || sgLevel.length !== 0 || campuses.length !== 0 || appointmentType.length !== 0 || dateFrom || dateTo

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClear}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Filter
				</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Small Group Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Appointment Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'category'} type="button" value={'category'} onClick={handleOnTab} />
						<Input tab={tab === 'level'} type="button" value={'level'} onClick={handleOnTab} />
						<Input tab={tab === 'campus'} type="button" value="campus" onClick={handleOnTab} />
						<Input tab={tab === 'type'} type="button" value="type" onClick={handleOnTab} />
						<Input tab={tab === 'date'} type="button" value="date" onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{categoryTab &&
								listCategory.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="category"
											value={id}
											title={name}
											classname={`${categories.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{levelTab &&
								allLevel.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="level"
											title={name}
											value={id}
											classname={`${sgLevel.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{campusTab &&
								listCampus.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="campus"
											title={name}
											value={id}
											classname={`${campuses.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{typeTab &&
								appointmentTypes.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="type"
											title={name}
											value={id}
											classname={`${appointmentType.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{dateTab && (
								<div className="pt-2 flex flex-row ">
									<InputDateFilterExport label="DATE FROM" value={dateFrom} name="dateFrom" handleChange={handleDateFrom} />
									<InputDateFilterExport label="TO" name="dateTo" value={dateTo} startDate={dateFrom} handleChange={handleDateTo} />
								</div>
							)}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClear}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
