import React, { useState, useEffect } from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { getClassList } from 'libs/apis/courses'
import Loading from 'components/commons/Loading'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import CourseTable from 'components/courses/CourseTable'
import PaginationPage from 'components/commons/PaginationPage'
import { ReactComponent as CourseIcon } from 'assets/icons/classes.svg'
import { archieveClass, completeCourse } from 'services/courseServices'
import alert from 'services/alert'
import Searchbar from 'components/commons/Searchbar'
import CourseFilter from 'screens/courses/CourseFilter'
import { SmallGroupHeader } from 'screens/smallGroup/styles'

function Courses(props) {
	const [courses, setCourses] = useState([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')

	const [sortBy, setsortBy] = useState('created_at')
	const [sortDirection, setsortDirection] = useState('DESC')

	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const location = useLocation()

	const { search } = location
	const page = search ? queryString.parse(search).page : 1

	const fetchData = async () => {
		setIsLoading(true)

		try {
			const res = await getClassList(10, searchValue, startDate, endDate, sortBy, sortDirection, 'Class', page)
			const data = res.data.data.programs.data.map((v) => {
				const temp = {}
				temp.id = v.id
				temp.code = v.programcode.name
				temp.title = v.name
				temp.startDate = v.programstart_at
				temp.endDate = v.programend_at
				temp.createdAt = v.created_at
				temp.lastUpdate = v.updated_at
				temp.status = v.status
				temp.isHighlights = v.ishighlight
				return temp
			})
			setCourses(data)
			setCurrentPage(res.data.data.programs.current_page)
			setLastPage(res.data.data.programs.last_page)
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [location.search, startDate, endDate, sortBy, sortDirection, searchValue, currentPage])

	const handleSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
		}
	}

	const handleComplete = async (course) => {
		const { value } = await alert.confirmComplete('Are you sure?')
		if (value) {
			try {
				setIsLoading(true)
				const { data } = await completeCourse(course.class_id)
				if (data.api_status) {
					const courseses = [...courses]
					const index = courseses.indexOf(course)
					courseses[index] = { ...courseses[index] }
					courseses[index].status = 'Completed'
					setCourses(courseses)
				} else {
					alert.warningMessage(data.message)
				}
				setIsLoading(false)
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return props.history.replace('/login')
					}
				}
				setIsLoading(false)
			}
		}
	}

	const handleArchive = async (course) => {
		try {
		const { value } = await alert.confirmArchive()
		
		if (value) {
			
			let formdata = new FormData()
			formdata.append('id', course)
			const { data } = await archieveClass(formdata)
			if (data.api_status) {
				alert.succeed('Success')
				fetchData()
			} else {
				alert.failed('Failed', data.message)
			}
		}
		} catch (error) {
			console.error(error)
			alert.failed('failed')
		}
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setsortBy(sort)
			setsortDirection('ASC')
		} else {
			if (sortDirection === 'ASC') {
				setsortBy(sort)
				setsortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setsortBy(sort)
				setsortDirection('ASC')
			}
		}
	}

	const handleStartDate = (date) => {
		setStartDate(moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleEndDate = (date) => {
		setEndDate(moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleApplyFilter = () => {
		props.history.push(`${props.location.pathname}?paginate=10&page=1`)
		fetchData()
	}

	const handleClearFilter = () => {
		setStartDate('')
		setEndDate('')
	}

	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`

	const name = JSON.parse(localStorage.getItem('church')).church_name ? JSON.parse(localStorage.getItem('church')).church_name : '-'
	const breads = [{ url: '', title: 'List Class' }]
	const displayNoData = !isLoading && !courses.length
	const displayData = !isLoading && courses.length

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Class</title>
			</Helmet>
			<PageBreadcrumbs icon={CourseIcon} title="List Class" breads={breads} />

			<SmallGroupHeader>
				<CourseFilter
					handleStartDate={handleStartDate}
					handleEndDate={handleEndDate}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					startDate={startDate}
					endDate={endDate}
				/>
				<Searchbar filterKeyword={keyword} handleOnSearch={handleSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
			</SmallGroupHeader>
			{isLoading && <Loading />}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayData && (
				<>
					<CourseTable courses={courses} handleOnSort={handleSort} onArchive={handleArchive} onComplete={handleComplete} />
					<PaginationPage currentPage={currentPage} lastPage={lastPage} length={courses.length} activeLink={active} url={location.pathname} />
				</>
			)}
		</div>
	)
}

export default Courses
