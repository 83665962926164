import axios from 'axios'

export const deactiveUser = async (userId) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/user/deactivate`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			userId,
		},
	})

export const exportUserManagement = async (isactive) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			isactive,
		},
	})

export const getUserList = async (paginate, roleId, campusId, gender, name, sortBy, sortDir, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			roleId,
			campusId,
			gender,
			name,
			sortBy,
			sortDir,
			page,
		},
	})

export const getRoles = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/role/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getUserDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/profile`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})
