import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

function EndDate({ name, label, value, handleChange, errors, styles, startDate, withSec }) {
	let temp = startDate ? moment(startDate).format('Y-MM-DD') : 'today'

	let optionsWithsec = {
		dateFormat: 'Y-m-d H:i',
		altFormat: 'd M Y H:i',
		altInput: true,
		enableTime: true,
		time_24hr: true,
		defaultHour: moment().format('HH'),
		defaultMinute: moment().format('mm'),
		minDate: temp,
	}

	let optionsNosec = {
		dateFormat: 'Y-m-d H:i',
		altFormat: 'd M Y',
		altInput: true,
		// enableTime: true,
		time_24hr: true,
		defaultHour: moment().format('HH'),
		defaultMinute: moment().format('mm'),
		minDate: temp,
	}

	return (
		<div className={`${styles ? styles : ''} w-full`}>
			{label && <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>}
			<div className="flex justify-between bg-white border-2 border-secondary rounded-lg pl-2 leading-tight focus:outline-none" style={{ padding: '10px 10px 10px 0' }}>
				<Flatpickr name={name} value={value} onChange={handleChange} options={withSec ? optionsWithsec : optionsNosec} placeholder="Choose date" className="w-full" />
				<div className="input-group-prepend -mr-1">
					<i className="far fa-calendar-alt" onClick={handleChange} />
				</div>
			</div>

			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}

export default EndDate
