import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as MinistryIcon } from 'assets/icons/agreement.svg'

class MinistryMenu extends Component {
	state = {
		isOpenSub: false,
	}

	componentDidMount() {
		const {
			location: { pathname },
		} = this.props

		const menus = ['/ministry/create', '/ministry'].filter((menu) => menu === pathname)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			location: { pathname },
		} = this.props
		const { isOpenSub } = this.state
		const menus = ['/ministry/create', '/ministry'].filter((menu) => menu === pathname)
		const icon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkList = `${pathname === '/ministry' || pathname === '/ministry/detail/:id' ? 'text-primary' : 'text-grey-500'}`
		const styleLinkCreate = `${pathname === '/ministry/create' ? 'text-primary' : 'text-grey-500'}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<MinistryIcon className={icon} />
					</div>
					<div className="font-bold text-grey-500">Ministry</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/ministry/create" className={styleLinkCreate}>
									Create Ministry
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/ministry" className={styleLinkList}>
									List of Ministry
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default MinistryMenu
