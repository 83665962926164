import React, { useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'

import { changeRoleMinistryMember } from 'libs/apis/ministry'
import alert from 'services/alert'
import Loading from 'components/commons/Loading'

const customStyles = {
	content: {
		top: '30%',
		left: '30%',
		right: '0px',
		bottom: '0px',
		width: '550px',
		padding: '0px',
		margin: '0px',
		height: '300px', // <-- This sets the height
		overlfow: 'scroll', // <-- This tells the modal to scrol
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

function ChangeRole({ listRoles, memberId, handleMinistryDetail }) {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [role, setRole] = useState('')
	const [loading, setLoading] = useState(false)

	const handleTogleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleCancel = () => {
		setRole({ ...role, value: '', label: '' })
		setModalIsOpen(false)
	}

	const handleSelectRole = (e) => {
		if (e !== null) {
			setRole({ ...role, value: e.value, label: e.label })
		} else {
			setRole({ ...role, value: '', label: '' })
		}
	}

	const handleSubmit = async () => {
		setLoading(true)
		try {
			const res = await changeRoleMinistryMember(memberId, role.value)
			if (res.data.api_status) {
				alert.succeed('Succeed')
				handleMinistryDetail()
				setLoading(false)
			}
		} catch (error) {
			alert.failed('Failed')
			setLoading(false)
		}
	}

	const styles = {
		control: (styles, state) => ({
			...styles,
			border: 'none',
			text: 'white',
			backgroundColor: state.isDisabled && 'white',
		}),
	}

	return (
		<div>
			<div onClick={handleTogleModal}>Change Role</div>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Change Role" appElement={document.getElementById('root')}>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center mt-2 text-primary pl-2 text-md font-bold active:bg-red-700">Change Role</div>
					<div className="flex justify-center mt-1 text-primary pr-2 cursor-pointer text-xl font-bold" onClick={handleTogleModal}>
						X
					</div>
				</div>
				<div className="bg-gray-200 p-4 px-6 w-full h-24 pt-4">
					<div className="mt-4">
						<Select
							styles={styles}
							className="rounded-lg pl-2 border-secondary border-2 w-full bg-white"
							onChange={handleSelectRole}
							value={role}
							options={listRoles}
							placeholder="Choose Role"
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									primary25: '#4da5dc',
									primary: '#4da5dc',
								},
							})}
							maxMenuHeight={100}
						/>
					</div>
				</div>
				<div className="flex bg-gray-200" style={{ paddingTop: '110px' }}>
					<div className="w-1/2 flex h-16 pt-2 justify-center bg-gray-500 inline-block cursor-pointer" onClick={handleCancel}>
						{loading ? <Loading secondary /> : <span className="text-center font-bold text-white">Cancel</span>}
					</div>
					<div className="w-1/2 flex h-16 pt-2 bg-primary inline-block cursor-pointer justify-center" onClick={handleSubmit}>
						{loading ? <Loading secondary /> : <span className="text-center font-bold text-white">Save</span>}
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default ChangeRole
