import React, { useState, useRef, useEffect } from 'react'
import Tippy from '@tippy.js/react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { partial } from 'libs/utils/utils'

function CourseTable({ courses, handleOnSort, onArchive }) {
	const sortStartDate = partial(handleOnSort, 'registerstart_at')
	const sortEndDate = partial(handleOnSort, 'registerend_at')
	const sortCreatedOn = partial(handleOnSort, 'created_at')
	const sortLastUpdate = partial(handleOnSort, 'updated_at')

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0">
			<table className="w-full">
				<thead className="bt-blueSecondary border-2 border-solid">
					<tr>
						<th className="px-2 py-4" />
						<th className="px-2 py-4 text-left">CODE</th>
						<th className="px-2 py-4 text-left">TITLE</th>
						<th className="px-2 py-4">
							START DATE
							<i className="fas fa-sort cursor-pointer ml-1" onClick={sortStartDate} />
						</th>
						<th className="px-2 py-4">
							END DATE
							<i className="fas fa-sort cursor-pointer ml-1" onClick={sortEndDate} />
						</th>
						<th className="px-2 py-4">
							CREATED ON
							<i className="fas fa-sort cursor-pointer ml-1" onClick={sortCreatedOn} />
						</th>
						<th className="px-2 py-4">
							LAST UPDATED
							<i className="fas fa-sort cursor-pointer ml-1" onClick={sortLastUpdate} />
						</th>
						<th className="px-2 py-4" />
					</tr>
				</thead>
				<tbody>
					{courses.map((value) => (
						<ListClassBody key={value.id} {...value} onArchive={onArchive} />
					))}
				</tbody>
			</table>
		</div>
	)
}

export default CourseTable

const ListClassBody = ({ id, code, title, startDate, endDate, createdAt, lastUpdate, isHighlights, status, onArchive }) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleTableRow = `border border-solid bt-blueSecondary`
	const styleTdStatus = isHighlights ? 'relative mr-6' : 'absolute mr-6'
	const styleDropDownOpen = `shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`
	const styleStatusArchive = `${status === 'Archived' ? 'opacity-50' : 'opacity-100'}`

	return (
		<tr className={styleTableRow}>
			<td className={styleTdStatus}>
				{status === 'Draft' && (
					<Tippy content="Draft">
						<div className="status-post-tag">
							<div className="ribbon-black-triagle" />
						</div>
					</Tippy>
				)}
				{status === 'Archived' && (
					<Tippy content="Archived">
						<div className="status-post-tag">
							<div className={styleStatusArchive}>
								<div className="ribbon-black-triagle" />
							</div>
						</div>
					</Tippy>
				)}
				{isHighlights && (
					<Tippy content="Highlight" theme="star">
						<i className="fas fa-star text-yellow-300 self-center flex" />
					</Tippy>
				)}
			</td>
			<td className="px-2 py-4">
				<div className={styleStatusArchive}>{code}</div>
			</td>
			<td className="px-2 py-4 max-w-xs w-1 text-left truncate">
				<div className={styleStatusArchive}>{title}</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={styleStatusArchive}>
					<div>
						<div>{moment(startDate, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
					</div>
				</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={styleStatusArchive}>
					<div>{moment(endDate, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
				</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={styleStatusArchive}>
					<div>{moment(createdAt, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
				</div>
			</td>
			<td className="px-2 py-4 text-center">
				<div className={styleStatusArchive}>
					<div> {lastUpdate ? moment(lastUpdate, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY') : '-'}</div>

					<div className="text-primary">{lastUpdate ? moment(new Date(lastUpdate), 'DD/MM/YYYY HH:mm').fromNow() : '-'}</div>
				</div>
			</td>
			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={wrapperRef} className="bg-white">
							<li>
								<Link className="px-4 py-2 block hover:bg-darkSkyBlue" to={`/classes/registrant/${id}`}>
									Registration List
								</Link>
							</li>
							{status !== 'Archived' && (
								<>
									<li>
										<Link className="px-4 py-2 block hover:bg-darkSkyBlue" to={`/classes/edit/${id}`}>
											View & Edit
										</Link>
									</li>
									<li className="px-4 py-2 block hover:bg-darkSkyBlue" onClick={() => onArchive(id)}>
										Archieve
									</li>
								</>
							)}
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
