import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ministryDetail, getMinistryRole, exportMinistryMember, getHeadsAndDeputy } from 'libs/apis/ministry'

import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as MinistryIcon } from 'assets/icons/ministry.svg'
import Loading from 'components/commons/Loading'
import MinistryDetails from 'components/ministry/MinistryDetails'
import MinistryMemberList from 'components/ministry/MinistryMemberList'
import InviteVolunteer from 'components/ministry/InviteVolunteer'
import MinistryRequestList from 'components/ministry/MinistryRequestList'
import Searchbar from 'components/commons/Searchbar'
import Button from 'components/commons/Button'
import { SmallGroupHeader } from '../../screens/smallGroup/styles'
import alert from 'services/alert'

function ViewMinistry(props) {
	const [ministry, setMinistry] = useState({
		ministryid: 0,
		name: '',
		description: '',
		status: '',
		imageurl: null,
		level: '',
		upperlevel: '',
		establisheddate: '',
		ministrymembers: [],
		ministryrequests: [],
		ministryheads: [],
		volunteers: [],
		keyvolunteers: [],
		intern: [],
		trainee: [],
	})
	const [listRoles, setListRoles] = useState([])
	const [volunteerRoles, setVolunteerRoles] = useState([])
	const [allVolunteer, setAllVolunteer] = useState([])
	const [filterKeyword, setFilterKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [loadingVolunteer, setLoadingVolunteer] = useState(false)
	const { id } = useParams()
	const location = useLocation()

	const handleMinistryDetail = async () => {
		const message = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
		try {
			setIsLoadingData(true)
			const response = await ministryDetail(id)
			const d = response.data.data.ministry

			if (response.data.api_status) {
				const heads = d.ministrymembers.filter((v) => v.ministrymemberrole.id == 5)
				const volunteer = d.ministrymembers.filter((v) => v.ministrymemberrole.id == 3)
				const keyvolunteer = d.ministrymembers.filter((v) => v.ministrymemberrole.id == 4)
				const intern = d.ministrymembers.filter((v) => v.ministrymemberrole.name === 'Intern')
				const trainee = d.ministrymembers.filter((v) => v.ministrymemberrole.name === 'Trainee')

				setMinistry({
					ministryid: d.id,
					name: d.name,
					description: d.description,
					status: d.status,
					imageurl: '',
					ministryheads: heads,
					volunteers: volunteer,
					ministryrequests: d.ministryrequests,
					keyvolunteers: keyvolunteer,
					upperlevel: d.ministrygroup ? d.ministrygroup.name : '',
					level: response.data.data.level,
					intern: intern,
					trainee: trainee,
				})
				setIsLoadingData(false)
			} else {
				setIsLoadingData(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage(message)
					setIsLoadingData(false)
				}
			}
		}
	}

	useEffect(() => {
		setLoadingVolunteer(true)
		const fetchHeadsDeputy = async () => {
			try {
				const res = await getHeadsAndDeputy(100, 1)
				const datas = res.data.data.user.data.map((v) => {
					let t = {}
					t.value = v.id
					t.label = v.fullname
					return t
				})

				setAllVolunteer(datas)
				setLoadingVolunteer(false)
			} catch (error) {
				console.log(error)
				setLoadingVolunteer(false)
			}
		}
		fetchHeadsDeputy()
	}, [])

	const handleMinistrySearch = async (e) => {
		setFilterKeyword(e.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			setFilterKeyword(event.target.value)
			setSearchValue(event.target.value)
		}
	}

	const handleExport = async () => {
		let res = await alert.confirmExport('ministry')
		if (res.value) {
			try {
				let res = await exportMinistryMember(id)
				if (res.data.api_status) {
					const link = document.createElement('a')
					link.href = res.data.data.path
					link.download = res.data.data.path
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					alert.succeed('Succed')
				}
			} catch (error) {
				alert.failed('Failed', 'There is an issue. Cannot export this ministry members')
			}
		}
	}

	useEffect(() => {
		handleMinistryDetail()
	}, [])

	useEffect(() => {
		const fetchMinistryRole = async () => {
			try {
				const res = await getMinistryRole()
				const a = res.data.data.ministrymemberrole.map((v) => {
					const temp = {}
					temp.value = v.id
					temp.label = v.name
					return temp
				})
				const b = res.data.data.ministrymemberrole.map((v) => {
					const temp = {}
					temp.id = v.id
					temp.name = v.name
					return temp
				})
				setListRoles(a)
				setVolunteerRoles(b)
			} catch (error) {
				console.log(error)
			}
		}
		fetchMinistryRole()
	}, [])

	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?page=${parsed.page || 1}`

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const displayLoading = isLoadingData && !errorMessage
	const displayError = !isLoadingData && errorMessage
	const displayData = !isLoadingData && !errorMessage
	const breads = [
		{ url: '/ministry', title: 'List Ministry' },
		{ url: '', title: 'View Ministry' },
	]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-200 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View Ministry</title>
			</Helmet>
			<PageBreadcrumbs icon={MinistryIcon} title="View Ministry" breads={breads} />

			<SmallGroupHeader>
				<InviteVolunteer
					volunteerRoles={volunteerRoles}
					handleMinistryDetail={handleMinistryDetail}
					volunteers={ministry.ministryrequests}
					ministryid={id}
					allVolunteer={allVolunteer}
					loadingVolunteer={loadingVolunteer}
				/>
				<Button style="ml-6" type={'tertiary'} handleClick={handleExport}>
					Export
				</Button>
				<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleMinistrySearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
			</SmallGroupHeader>

			{displayLoading && <Loading />}
			{displayData && (
				<div className="flex mb-4 mt-4">
					<div className="w-1/4">
						<MinistryDetails {...ministry} />
					</div>

					<div className="w-3/4 ml-4">
						<MinistryMemberList
							searchvalue={searchValue}
							ministryheads={ministry.ministryheads}
							volunteers={ministry.volunteers}
							keyvolunteers={ministry.keyvolunteers}
							intern={ministry.intern}
							trainee={ministry.trainee}
							listRoles={listRoles}
							handleMinistryDetail={handleMinistryDetail}
						/>
						<hr className="border-2 blue mx-4" />

						<hr className="w-full border-2 border-blueSecondary" />
						<MinistryRequestList requests={ministry.ministryrequests} url={location.pathname} handleMinistryDetail={handleMinistryDetail} />
					</div>
				</div>
			)}
			{displayError && <div className="align-center">{errorMessage}</div>}
		</div>
	)
}

export default ViewMinistry
