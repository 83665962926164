import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import RequestHistoryProfile from 'components/prayerRequest/RequestHistoryProfile'
import PrayerList from 'components/prayerRequest/PrayerList'
import PaginationPage from 'components/commons/PaginationPage'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'
import Loading from 'components/commons/Loading'

import { getRequestHistory } from 'libs/apis/prayerpraise'
import { getUserDetail } from 'libs/apis/usermanagement'

export default function RequestHistory() {
	const paginate = 10
	const [page, setPage] = useState(1)
	const [dataList, setDataList] = useState({})
	const [dataProfile, setDataProfile] = useState({})
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoadingData, setIsLoadingData] = useState(true)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const location = useLocation()
	const { id } = useParams()

	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`
	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage && dataList.length !== 0
	const displayNoData = !isLoadingData && !errorMessage && !dataList.length
	const displayError = !isLoadingData && errorMessage

	useEffect(() => {
		clearData()
		fetchDataList()
	}, [page, location.search])

	const fetchDataList = async () => {
		const { search } = location
		setPage(search ? queryString.parse(search).page : 1)

		setIsLoadingData(true)
		try {
			const params = {
				page: page,
				paginate: paginate,
				userId: id
			}
			const resProfile = await getUserDetail(id)
			setDataProfile(resProfile.data.data.user)
			const resList = await getRequestHistory(params)
			const response = resList.data.data.prayers
			setDataList(response.data)
			setCurrentPage(response.current_page)
			setLastPage(response.last_page)
		} catch (error) {
			console.error(error)

			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage('Cannot retrieve any data at this moment')
					setIsLoadingData(false)
				}
			}
		} finally {
			setIsLoadingData(false)
		}
	}

	const clearData = () => {
		setDataList('')
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const breads = [{ url: '', title: 'Request & Report History' }]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{church.church_name} - Request & Report History</title>
			</Helmet>
			<PageBreadcrumbs icon={PrayerPraiseIcon} title="Request & Report History" breads={breads} />

			{displayLoading && <Loading />}
			{!displayLoading && <RequestHistoryProfile data={dataProfile} />}
			{displayData && !displayLoading && (
				<div>
					<PrayerList data={dataList} />
					<PaginationPage
						currentPage={currentPage}
						lastPage={lastPage}
						activeLink={active}
						length={dataList.length}
						url={location.pathname}
					/>
				</div>
			)}
			{displayNoData && <div className="text-center">No data available</div>}
			{displayError && <div className="text-center">{errorMessage}</div>}
		</div>
	)
}
