import * as Yup from 'yup'

export const CreateClassValidationSchema = () =>
	Yup.object().shape({
		classTitle: Yup.string().nullable().required('Required'),
		codeClass: Yup.string().nullable().required('Required'),
		classType: Yup.string().nullable().required('Required'),
		classDescription: Yup.string().nullable().required('Required'),
		classStartDate: Yup.string().required('Required'),
		classEndDate: Yup.string().required('Required'),
		classVenue: Yup.string().required('Required'),
		classStartRegis: Yup.string().nullable().required('Required'),
		classEndRegis: Yup.string().nullable().required('Required'),
		classPaymentPerRole: Yup.string().required('Required'),
		classSpeaker: Yup.string().required('Required'),
		classParticipantQuota: Yup.string().required('Required'),
		classMinSession: Yup.string().required('Required'),
		startDateShare: Yup.string().nullable().required('Required'),
		endDateShare: Yup.string().nullable().required('Required'),
        classSessionDate: Yup.string().required('Required'),
		classSessionStartTime: Yup.string().required('Required'),
		classSessionEndTime: Yup.string().required('Required'),
	})
