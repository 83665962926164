import React from 'react'

function EligibleTo({ label, options, selectedRoles, handleCheckboxRole, errors }) {
	return (
		<div>
			<label className="block text-gray-700 text-sm font-bold mb-2">
				{label}
				{errors && <label className="text-red-500 text-xs italic">{errors}</label>}
			</label>
			<div className="flex justify-between mb-5">
				<div className="flex-col">
					{options.slice(0, options.length / 2).map((val, idx) => (
						<div key={idx}>
							<div className="flex flex-row items-center mb-2">
								<input type="checkbox" className="form-checkbox border-2 mr-2" value={val.id} checked={selectedRoles.includes(val.id)} onChange={handleCheckboxRole} />
								<label className="block text-gray-700 text-sm font-bold">{val.name}</label>
							</div>
						</div>
					))}
				</div>
				{options.slice(options.length / 2).map((val, idx) => (
					<div key={idx}>
						<div className="flex flex-row items-center mb-2">
							<input type="checkbox" className="form-checkbox border-2 mr-2" value={val.id} checked={selectedRoles.includes(val.id)} onChange={handleCheckboxRole} />
							<label className="block text-gray-700 text-sm font-bold">{val.name}</label>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default EligibleTo
