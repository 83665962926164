import React, { useState } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { helperSelectFilter } from 'libs/utils/utils'
import { exportAppointments } from 'libs/apis/smallGroup'
import FilterItem from 'components/commons/FilterItem'
import InputDateFilterExport from 'components/commons/InputDateFilterExport'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from './Styles'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '410px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

export default function AppointmentsExport(props) {
	const [categories, setCategories] = useState([])
	const [sglevel, setSglevel] = useState([])
	const [appointmentType, setAppointmentType] = useState([])
	const [campuses, setCampuses] = useState([])
	const [dateFrom, setdateFrom] = useState('')
	const [dateTo, setDateTo] = useState('')

	const [tab, setTab] = useState('category')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const listCampus = JSON.parse(localStorage.getItem('campuses'))
	const history = useHistory()
	const { listCategory, allLevel, appointmentTypes } = props

	const handleSelectFilter = (event) => {
		if (event.target.name === 'category') {
			helperSelectFilter(categories, event.target.value, setCategories)
		}
		if (event.target.name === 'level') {
			helperSelectFilter(sglevel, event.target.value, setSglevel)
		}

		if (event.target.name === 'campus') {
			helperSelectFilter(campuses, event.target.value, setCampuses)
		}

		if (event.target.name === 'type') {
			helperSelectFilter(appointmentType, event.target.value, setAppointmentType)
		}
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('category')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleDateFrom = (date) => {
		let myDate = new Date(date)
		let result = moment(myDate).format('YYYY/MM/DD')
		setdateFrom(result)
	}

	const handleDateTo = (date) => {
		let myDate = new Date(date)
		let result = moment(myDate).format('YYYY/MM/DD')
		setDateTo(result)
	}

	const handleApplyExport = async () => {
		try {
			let res = await exportAppointments(campuses.toString(), categories.toString(), sglevel.toString(), appointmentType.toString(), dateFrom, dateTo)
			if (res.data.api_status) {
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					return history.replace('/login')
				}
			}
		}
	}

	const handleClearExport = () => {
		setSglevel([])
		setCategories([])
		setCampuses([])
		setAppointmentType([])
		setDateTo([])
		setdateFrom([])
	}

	const categoryTab = tab === 'category'
	const levelTab = tab === 'level'
	const campusTab = tab === 'campus'
	const typeTab = tab === 'type'
	const dateTab = tab === 'date'

	return (
		<div>
			<div className="flex flex-row relative cursor-pointer">
				<ButtonFilter onClick={handleToggleModal}>Export</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Appointment Export Modal" ariaHideApp={false}>
				<Header>
					<Title>Appointment Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'category'} type="button" value={'category'} onClick={handleOnTab} />
						<Input tab={tab === 'level'} type="button" value={'level'} onClick={handleOnTab} />
						<Input tab={tab === 'campus'} type="button" value="campus" onClick={handleOnTab} />
						<Input tab={tab === 'type'} type="button" value="type" onClick={handleOnTab} />
						<Input tab={tab === 'date'} type="button" value="date" onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{categoryTab &&
								listCategory.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="category"
											value={id}
											title={name}
											classname={`${categories.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{levelTab &&
								allLevel.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="level"
											title={name}
											value={id}
											classname={`${sglevel.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{campusTab &&
								listCampus.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="campus"
											title={name}
											value={id}
											classname={`${campuses.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{typeTab &&
								appointmentTypes.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="type"
											title={name}
											value={id}
											classname={`${appointmentType.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											onClick={handleSelectFilter}
										/>
									</div>
								))}

							{dateTab && (
								<div className="pt-2 flex flex-row ">
									<InputDateFilterExport label="DATE FROM" value={dateFrom} name="dateFrom" handleChange={handleDateFrom} />
									<InputDateFilterExport label="TO" name="dateTo" value={dateTo} handleChange={handleDateTo} />
								</div>
							)}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearExport}>
						<Span>Cancel</Span>
					</Button>
					<Button onClick={handleApplyExport}>
						<Span>Export</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
