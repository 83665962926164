import React from 'react'
import Flatpickr from 'react-flatpickr'

function InputTime({ name, label, placeholder, handleChange, value, errors, styles }) {
	return (
		<div className={`${styles ? styles : ''} w-full`}>
			{label && <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>}
			<div className="flex justify-between bg-white appearance-none border-2 rounded-lg w-full py-2 px-3 focus:outline-none border-secondary">
				<Flatpickr
					value={value}
					name={name}
					className="w-full"
					onChange={handleChange}
					onClose={handleChange}
					placeholder={placeholder ? placeholder : 'Choose Time'}
					options={{
						enableTime: true,
						noCalendar: true,
						time_24hr: true,
						altInput: false,
					}}
				/>
				<div className="input-group-prepend -mr-1">
					<i className="far fa-clock" onClick={handleChange} />
				</div>
			</div>

			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}

export default InputTime
