import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { exportMinistry } from 'libs/apis/ministry'
import FilterItem from 'components/commons/FilterItem'
import Loading from 'components/commons/Loading'
import { constants } from 'constants/constant'
import { helperSelectFilter } from 'libs/utils/utils'
import alert from 'services/alert'
import { Button, ButtonContainer, X, Span, Header, Title, Body, Content, Input, ButtonFilter } from 'components/smallGroup/Styles'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '240px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

export default function MinistriesExport(props) {
	const [levels, setLevels] = useState('')
	const [subGroups, setSubGroups] = useState('')
	const [groups, setGroups] = useState('')
	const [listLevel, setListLevel] = useState([
		{
			value: 'Ministry',
			name: 'Ministry',
		},
		{ value: 'Subgroup', name: 'Sub Group' },
		{ value: 'Group', name: 'Groups' },
	])

	const [tab, setTab] = useState('subGroups')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleSelectFilter = (event) => {
		if (event.target.name === 'level') {
			setLevels(event.target.value)
		}
		if (event.target.name === 'subGroups') {
			helperSelectFilter(subGroups, event.target.value, setSubGroups)
		}
		if (event.target.name === 'groups') {
			helperSelectFilter(groups, event.target.value, setGroups)
		}
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('subGroups')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearExport = () => {
		setLevels('')
		setSubGroups('')
		setGroups('')
		setModalIsOpen(false)
	}

	const handleExport = async () => {
		try {
			setLoading(true)
			let res = await exportMinistry(groups.toString(), subGroups.toString())
			if (res.data.api_status) {
				setLoading(false)
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
			setLoading(false)
		} catch (error) {
			alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
			setLoading(false)
		}
	}

	const subGroupTab = tab === 'subGroups'
	const groupTab = tab === 'groups'
	return (
		<div>
			<div className="flex flex-row relative cursor-pointer">
				<ButtonFilter onClick={handleToggleModal}>Export</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Export Modal" ariaHideApp={false}>
				<Header>
					<Title>Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'subGroups'} type="button" value="subGroups" onClick={handleOnTab} />
						<Input tab={tab === 'groups'} type="button" value="groups" onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{subGroupTab &&
								props.ministrySubGroups.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											classname={`${subGroups.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="subGroups"
											title={name}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{groupTab &&
								props.ministryGroups.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											classname={`${groups.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="groups"
											title={name}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearExport}>
						{loading ? <Loading secondary /> : <Span>Cancel</Span>}
					</Button>
					<Button onClick={handleExport}>{loading ? <Loading secondary /> : <Span>Export</Span>}</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
