import React from 'react'
import { Field } from 'formik'
import Label from 'components/commons/Label'

function SelectInput({ label, name, options, styles }) {
	return (
		<div className={styles}>
			<Label>{label}</Label>
			<Field component="select" className="form-select block w-full border-secondary border-2 rounded-lg" name={name}>
				{options.length !== 0 &&
					options.map((option) => (
						<option key={option.label} value={option.id}>
							{option.label}
						</option>
					))}
			</Field>
		</div>
	)
}

export default SelectInput
