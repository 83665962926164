import React from 'react'
import moment from 'moment'
import YoutubeIframe from '../commons/YoutubeIframe'
import getYoutubeId from 'libs/utils/getYoutubeId'
import DefaultIframe from '../commons/DefaultIframe'
import ImageIframe from '../commons/ImageIframe'

const NewsfeedPreview = ({ values }) => {
	const youtubeId = getYoutubeId(values.video)

	return (
		<div>
			<h3 className="font-bold mb-4">MOBILE SCREEN PREVIEW</h3>
			<div className="bg-blueSecondary">
				{(values.media === '2' || values.media === 2) && !values.image && <DefaultIframe />}
				{(values.media === '2' || values.media === 2) && values.image && <ImageIframe img={values.image} />}
				{(values.media === '1' || values.media === 1) && youtubeId && <YoutubeIframe id={youtubeId} />}
				<div className="p-3">
					<div className="font-bold break-all">{values.title || 'Title'}</div>
					<div className="flex">
						<div className="text-gray-500">{values.startDate ? moment(values.startDate, 'YYYY/MM/DD kk:ss:mm').format('DD MMM YYYY') : 'Post Date'}</div>
						<div> - </div>
						<div className="text-gray-500">{values.endDate ? moment(values.endDate, 'YYYY/MM/DD kk:ss:mm').format('DD MMM YYYY') : 'End Date'}</div>
					</div>

					<div className="text-base">
						{values.description ? <div dangerouslySetInnerHTML={{ __html: values.description }} className="body-description break-all overflow-auto h-32 " /> : 'Article'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewsfeedPreview
