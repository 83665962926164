import React from 'react'
import { Helmet } from 'react-helmet'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import NewsfeedFormik from 'components/formik/NewsfeedFormik'

function CreateNews(props) {
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create News Item' }]
	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create News Item</title>
			</Helmet>
			<PageBreadcrumbs icon={NewsIcon} title="Create News Item" breads={breads} />
			<NewsfeedFormik {...props} isCreateForm={true} />
		</div>
	)
}

export default CreateNews
