import moment from 'moment'

export const converDate = (param) => {
	let result
	var myDate = new Date(param)
	result = moment(myDate).format('DD MMM YYYY')
	return result
}

export const converTime = (param) => {
	let result
	var myTime = new Date(param)
	result = moment(myTime).format('HH:mm')
	return result
}

const _pipe = (f, g) => (...args) => g(f(...args))

export const partial = (fn, ...args) => fn.bind(null, ...args)

export const pipe = (...fns) => fns.reduce(_pipe)

export const helperSelectFilter = (array, value, callback) => {
	if (array.includes(value)) {
		let selectFilter = array.filter((val) => val !== value)
		callback(selectFilter)
	} else {
		callback([...array, value])
	}
}

export const removeItem = (arr, value) => {
	return arr.filter(function (ele) {
		return ele !== value
	})
}

export const bytesToMegaBytes = (bytes) => {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	if (bytes == 0) return '0 Byte'
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}
