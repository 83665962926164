import React from 'react';
import {Helmet} from "react-helmet";
import {ReactComponent as EmailIcon} from 'assets/icons/megaphone.svg'
import PageBreadcrumbs from "../../components/commons/PageBreadcrumbs";
import EditPushNotifCampaignFormik from "../../components/formik/EditPushNotifCampaignFormik";

const EditPushNotifCampaign = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const breads = [{url: '', title: 'Create Campaign'}];

    return (
        <div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - Edit Push Notif Campaign</title>
            </Helmet>
            <PageBreadcrumbs icon={EmailIcon} title="Edit Push Notif Campaign" breads={breads}/>
            <EditPushNotifCampaignFormik {...props} isCreatedForm={true}/>
        </div>
    )
};

export default EditPushNotifCampaign;
