import axios from 'axios'

const ADMIN_PASS = process.env.REACT_APP_PASSWORD

export const removeRegistrant = async (programTicketId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/remove`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			programTicketId,
		},
	})

export const confirmResendEmail = async (programTicketId, userId, type) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/resend-email`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			programTicketId,
			userId,
			type
		},
	})

export const editClassCode = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const editClass = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/program/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const getRequirements = async (type) =>
	await axios({
		method: 'GET',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/requirements`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			type: type,
		},
	})

export const getClassCodeDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const getReqRoles = async () =>
	await axios({
		method: 'GET',
		url: `${process.env.REACT_APP_ENDPOINT}/program/available-role`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const createClassCode = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const getClassDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/program/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			type: 'Web',
			paginate: 1,
			adminPass: ADMIN_PASS
		},
	})

export const getClassType = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}	/programtype/get`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			type: 'Class',
		},
	})

export const getListCode = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/all-list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			type: 'Class',
		},
	})

export const getVenues = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/church/all-campus`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const createClass = async (data) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/program/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data,
	})

export const getClassList = async (paginate, programnameorcode, durationstartat, durationendat, sortBy, sortDirection, type, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/program/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			programnameorcode,
			durationstartat,
			durationendat,
			sortBy,
			sortDirection,
			type,
			page,
		},
	})

export const getClassCodeList = async (paginate, programnameorcode, sortBy, sortDirection, type, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programcode/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			programnameorcode,
			sortBy,
			sortDirection,
			type,
			page,
		},
	})

export const getClassRole = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/program/available-role`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
