import React, { Component } from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const TBody = styled.tbody`
	border: 2px solid #f2f6f9;
	color: #3e3e3e;
`

class TableBody extends Component {
	renderCell = (item, column, index) => {
		if (column.content) return column.content(item, index)
		return (
			<td
				className={`px-2 py-4 ${
					item.status === 'Archived' || item.status === 'Completed'
						? 'opacity-25'
						: 'opacity-100'
				}`}
			>
				{_.get(item, column.path)}
			</td>
		)
	}

	createKey = (column) => column.label + column.key

	render() {
		const { data, columns } = this.props

		return (
			<TBody>
				{data.map((item,indexData) => {
					return (
						<tr
							key={indexData}
							className="border border-solid bt-blueSecondary"
						>
							{columns.map((column,indexCollumn) => {
								if (column.length) {
									return column.map((x, index) => {
										return (
											<Cell
												key={index}
												cells={this.renderCell(item, x, index)}
											/>
										)
									})
								}
								return (
									<Cell
										key={indexCollumn}
										cells={this.renderCell(item, column)}
									/>
								)
							})}
						</tr>
					)
				})}
			</TBody>
		)
	}
}

const Cell = ({ cells }) => {
	return cells
}

export default TableBody
