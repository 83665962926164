import http from './httpServices'
import axios from 'axios'

const ADMIN_PASS = process.env.REACT_APP_PASSWORD

export const archieveClass = async (data) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/program/archive`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data,
	})

export const exportRegistrantClass = async (id, registantstartat, registantendat, status) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/export/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			registantstartat,
			registantendat,
			status,
			id,
		},
	})

export const getCoursesRegistrants = async (id, paginate, sortBy, sortDirection, registantstartat, registantendat, status, roleId, name, type, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/program/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			paginate,
			sortBy,
			sortDirection,
			registantstartat,
			registantendat,
			status,
			roleId,
			name,
			type,
			page,
			adminPass: ADMIN_PASS
		},
	})

export const coursesList = async (page, filter_status, filter_keyword, order_by, order_dir) => {
	return await http.search(
		'/classes',
		{
			page,
			filter_status,
			filter_keyword,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const courseCategories = async () => {
	return await http.get('/classes/categories', {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const createCourse = async (formData) => {
	return await http.post('/classes/create', formData, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const courseDetail = async (id) => {
	return await http.get(`/classes/detail/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const updateCourse = async (formData) => {
	return await http.post('/classes/update', formData, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const archiveCourse = async (id) => {
	return await http.post(`/classes/archive/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const completeCourse = async (id) => {
	return await http.post(`/classes/complete/${id}`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
}

export const courseDashboard = async (id) => {
	return await http.get(`/classes/dashboard/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const registranstList = async (id, paginate, sortBy, sortDirection, registantstartat, registantendat, status, roleId, name, type) => {
	return await http.search(
		`/program/detail`,
		{
			id,
			paginate,
			sortBy,
			sortDirection,
			registantstartat,
			registantendat,
			status,
			roleId,
			name,
			type,
		},
		localStorage.getItem('token')
	)
}

export const attendanceList = async (id, page, filter_keyword, filter_status, order_by, order_dir) => {
	return await http.search(
		`/classes/attendance/${id}`,
		{
			page,
			filter_keyword,
			filter_status,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const attendanceToggle = async (class_id, registrant_id, session_number) => {
	return await http.post(
		'/classes/attendance',
		{
			class_id,
			registrant_id,
			session_number,
		},
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const resendEmail = async (registrant_id) => {
	return await http.post(
		'/classes/registrant/reconfirm',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const rejectRegistrant = async (registrant_id) => {
	return await http.post(
		'/classes/registrant/remove',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const qrcodelist = async (id) => {
	return await http.get(`/classes/qrcode/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const registrantExport = async (id, filter_keyword, filter_status, order_by, order_dir) => {
	return await http.get(`/classes/registrant/${id}/export`, {
		params: {
			filter_keyword,
			filter_status,
			order_by,
			order_dir,
		},
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getCourseRole = async (id) => {
	return await http.get(`/classes/role-class/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getMaritalStatus = async (id) => {
	return await http.get(`/classes/marital-class/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}
