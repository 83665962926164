import React, {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {partial} from "../../libs/utils/utils";

const EventRegistrantTable = ({listEventRegistrant, handleOnSort, handleCheckAttendance, handleResendConfirmationEmail, handleRemoveRegistrant}) => {
    const handleSortByAppliedOn = partial(handleOnSort, 'created_at');
    const handleSortByPaymentValidUntil = partial(handleOnSort, 'paymentValidUntil ');
    const handleSortByInvoiceId = partial(handleOnSort, 'bookingcode');
    const handleSortByPaymentStatus = partial(handleOnSort, 'paymentStatus');
    const handleSortByStatus = partial(handleOnSort, 'status');

    const styleTableRow = `border border-solid bt-blueSecondary`;
    const styleDropDown = 'z-20 shadow-md absolute right-20px ';
    const styleNullData = ' text-red-500 text-xs italic';
    const styleData = 'px-2 py-4 text-center';

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/events/edit-code/') ||
            event.target.innerHTML.includes('Resend Confirmation Email') ||
            event.target.innerHTML.includes('Remove Registrant')
        ) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const renderListEventRegistrantHead = () => {
        return (
            <thead className="bt-blueSecondary py-4  uppercase text-gray">
            <tr>
                <th className="px-4 py-4">
                    NAME
                </th>
                <th className="px-4 py-4">
                    GROUP NAME
                </th>
                <th className="px-4 py-4">
                    ROLE
                </th>
                <th className="px-4 py-4">
                    APPLIED ON
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByAppliedOn}/>
                </th>
                <th className="px-4 py-4">
                    PAYMENT VALID UNTIL
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByPaymentValidUntil}/>
                </th>
                <th className="px-4 py-4">
                    INVOICE ID
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByInvoiceId}/>
                </th>
                <th className="px-4 py-4">
                    PAYMENT STATUS
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByPaymentStatus}/>
                </th>
                <th className="px-4 py-4">
                    STATUS
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByStatus}/>
                </th>
                <th className="px-4 py-4"/>
            </tr>
            </thead>
        );
    };

    const renderListEventRegistrantBody = () => {
        const registrantBodyEl = [];

        listEventRegistrant.forEach((registrant, index) => {
            let style = '';
            switch (registrant.status) {
                case 'Approved':
                    style = 'text-green-500';
                    break;
                case 'Applied':
                    style = 'text-yellow-500';
                    break;
                case 'Pending':
                    style = 'text-grey-300';
                    break;
                case 'Rejected':
                    style = 'text-red-500';
                    break;
                case 'Failed':
                    style = 'text-red-500';
                    break;
                case 'Passed':
                    style = 'text-green-500';
                    break;
                default:
                    break
            }

            registrantBodyEl.push(
                <tr key={index} className={styleTableRow}>
                    <td className="px-2 py-4 text-center">
                        <div>{registrant.name.concat(registrant.name2 ? ', '.concat(registrant.name2) : '')}</div>
                    </td>
                    <td className={styleData.concat(registrant.role === '' ? styleNullData : '')}>
                        {
                            registrant.groupName !== '' ?
                                <div>{registrant.groupName}</div>
                                :
                                <div>unknown</div>
                        }

                    </td>
                    <td className={styleData.concat(registrant.role === '' ? styleNullData : '')}>
                        {
                            registrant.role !== '' ?
                                <div
                                    className={registrant.role === 'Admin' ? 'text-primary' : ''}>{registrant.role}</div>
                                :
                                <div>unknown</div>
                        }
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div>{moment(registrant.appliedOn, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}</div>
                        <div
                            className="text-primary">{moment(registrant.appliedOn, 'YYYY-MM-DD HH:mm:ss').fromNow()}</div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        {
                            registrant.paymentValidUntil === 'unknown' ?
                                <div className={styleNullData}>
                                    {registrant.paymentValidUntil}
                                </div>
                                :
                                <div>
                                    {moment(registrant.paymentValidUntil, 'DD-MM-YYYY HH:mm:ss').format('DD MMM YYYY')}
                                </div>
                        }
                        {
                            registrant.paymentValidUntil2 === 'unknown' ?
                                <div/>
                                :
                                <div>
                                    , {moment(registrant.paymentValidUntil2, 'DD-MM-YYYY HH:mm:ss').format('DD MMM YYYY')}
                                </div>
                        }
                    </td>
                    <td className={styleData.concat(registrant.invoiceId === 'unknown' ? styleNullData : '')}>
                        <div>{registrant.invoiceId}</div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        {
                            registrant.paymentStatus === "unknown" ?
                                <div className={styleNullData}>
                                    {registrant.paymentStatus}
                                </div>
                                :
                                <div>
                                    <div>
                                        {registrant.paymentStatus}
                                    </div>
                                    <div
                                        className="text-primary">{moment(registrant.updatedAtPaymentStatus, 'DD-MM-YYYY HH:mm:ss').format('DD MMM YYYY')}</div>
                                </div>
                        }
                        {
                            registrant.paymentStatus2 === "unknown" ?
                                <div/>
                                :
                                <div>
                                    <div>
                                        , {registrant.paymentStatus2}
                                    </div>
                                    <div
                                        className="text-primary">{moment(registrant.updatedAtPaymentStatus2, 'DD-MM-YYYY HH:mm:ss').format('DD MMM YYYY')}</div>
                                </div>
                        }
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={style.concat(" px-2 py-4 flex flex-row items-center")}>
                            <i className="fa fa-circle mr-3" aria-hidden="true"/>
                            {
                                registrant.status === 'unknown' ?
                                    <div className={styleNullData}>{registrant.status}</div>
                                    :
                                    <div>{registrant.status}</div>
                            }
                        </div>
                    </td>

                    <td className="px-2 py-4">
                        <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>
                            <i className="dropdown-trigger fas fa-ellipsis-v"/>
                            <div
                                className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>
                                <ul ref={dropdownRef} className="bg-white">
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left"
                                        onClick={() => handleResendConfirmationEmail(registrant.id, registrant.userId)}>
                                        Resend Confirmation Email
                                    </li>
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left"
                                        onClick={() => handleRemoveRegistrant(registrant.id)}>
                                        Remove Registrant
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        });

        return registrantBodyEl;
    };

    return (
        <div className="bg-white shadow-md rounded">
            <table className="w-full">
                {renderListEventRegistrantHead()}
                <tbody>
                {renderListEventRegistrantBody()}
                </tbody>
            </table>
        </div>
    )
};

export default EventRegistrantTable
