import React from 'react'

const Input = ({ name, value, onChange, type, error }) => {
	const classes = `appearance-none border-2 rounded-lg ${
		name !== 'class_price' ? 'w-full' : 'w-1/2'
	} p-3 leading-tight focus:outline-none ${error ? 'border-red-500' : 'border-secondary'} `
	return <input className={classes} name={name} value={value} onChange={onChange} type={type} />
}

export default Input
