import React, { useState, useEffect } from 'react'
import {Helmet} from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'
import moment from 'moment'

import { getPrayerList } from 'libs/apis/prayerpraise'
import { helperSelectFilter } from 'libs/utils/utils'

import Loading from 'components/commons/Loading'
import Searchbar from 'components/commons/Searchbar'
import PrayerFilter from 'components/prayerRequest/PrayerFilter'
import PrayerExport from 'components/prayerRequest/PrayerExport'
import PaginationPage from 'components/commons/PaginationPage'
import PrayerTable from 'components/prayerRequest/PrayerTable'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import {SmallGroupHeader} from 'screens/smallGroup/styles'

export default function PrayerRequest(props) {

	const paginate = 10
	const [page, setPage] = useState(1)
	const [listPrayers, setListPrayers] = useState([])
	const [searchValue, setSearchValue] = useState('')
	const [prayerCategoryId, setPrayerCategoryId] = useState('')
	const [campusId, setCampusId] = useState('')
	const [dateSubmitStartAt, setDateSubmitStartAt] = useState('')
	const [dateSubmitEndAt, setDateSubmitEndAt] = useState('')
	const [keyword, setKeyword] = useState('')

	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [sortBy, setSortBy] = useState('created_at')
	const [sortDirection, setSortDirection] = useState('DESC')

	const church = JSON.parse(localStorage.getItem('church'));
	const breads = [{ url: '', title: 'Prayer Request' }];
	const parsed = queryString.parse(props.location.search);
	const active = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage && listPrayers.length !== 0
	const displayNoData = !isLoadingData && !errorMessage && !listPrayers.length
	const displayError = !isLoadingData && errorMessage

	useEffect(() => {
		fetchData()
	}, [props.location.search, page, searchValue, campusId, prayerCategoryId, dateSubmitStartAt, dateSubmitEndAt, sortBy, sortDirection])

	const fetchData = async () => {
		const { search } = props.location
		setPage(search ? queryString.parse(search).page : 1)
		let pageTemp = search ? queryString.parse(search).page : 1
		
		setIsLoadingData(true)
		try {
			const res = await getPrayerList(
				paginate,
				pageTemp,
				searchValue,
				campusId.toString(),
				prayerCategoryId.toString(),
				dateSubmitStartAt,
				dateSubmitEndAt,
				sortBy,
				sortDirection
			);
			const response = res.data.data.prayers;
			const list = response.data;

			setListPrayers(list);
			setCurrentPage(response.current_page);
			setLastPage(response.last_page);

			// untuk validasi kalo pageny kelebihan dari yg ada
			if (response.total > 0) {
				// datanya ada
				if (!response.data.length) {
					// dan datanya kosong
					// fetch lagi untuk page terakhir
					let goToPage = Math.ceil(response.total / response.per_page);

					const paramPage = active.indexOf("&page="); 
					const getParam = active.substr(0, paramPage + 6);
					goToPage = getParam + goToPage;

					// goToPage page paling akhir
					props.history.push(goToPage);
				}
			}

			setIsLoadingData(false)
		} catch (error) {
			console.error(error)

			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					setIsRedirect(true)
				} else if (error.response.status >= 500) {
					setErrorMessage('Cannot retrieve any data at this moment')
					setIsLoadingData(false)
				}
			}
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'campusId') {
			helperSelectFilter(campusId, event.target.value, setCampusId)
		} else if (event.target.name === 'categoryId') {
			helperSelectFilter(prayerCategoryId, event.target.value, setPrayerCategoryId)
		}
	}

	const handleDateFrom = (date) => {
		let myDate = new Date(date)
		let result = `${moment(myDate).format('YYYY-MM-DD')} 00:00:00`
		setDateSubmitStartAt(result)
	}

	const handleDateTo = (date) => {
		let myDate = new Date(date)
		let result = `${moment(myDate).format('YYYY-MM-DD')} 23:59:59`
		setDateSubmitEndAt(result)
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDirection('ASC')
		} else {
			if (sortDirection === 'ASC') {
				setSortBy(sort)
				setSortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setSortBy(sort)
				setSortDirection('ASC')
			}
		}
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	function handleClearFilter() {
		setCampusId('')
		setPrayerCategoryId('')
		setDateSubmitStartAt('')
		setDateSubmitEndAt('')
		props.history.push(`${props.location.pathname}?paginate=10&page=1`)
	}

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{church.church_name} - Prayer Request</title>
			</Helmet>
			<PageBreadcrumbs icon={PrayerPraiseIcon} title="Prayer Request " breads={breads}/>
			<>
				<SmallGroupHeader>
					<PrayerFilter
						campusId={campusId}
						services={[]}
						categoryId={prayerCategoryId}
						dateFrom={dateSubmitStartAt}
						dateTo={dateSubmitEndAt}
						handleSelectFilter={handleSelectFilter}
						handleClearFilter={handleClearFilter}
						handleApplyFilter={fetchData}
						handleDateFrom={handleDateFrom}
						handleDateTo={handleDateTo}
					/>
					<PrayerExport />
					<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
				</SmallGroupHeader>

				{displayLoading && <Loading />}
				{displayData && (
					<div>
						<PrayerTable list={listPrayers} handleSort={handleSort} />

						<PaginationPage
							currentPage={currentPage}
							lastPage={lastPage}
							activeLink={active}
							length={listPrayers.length}
							url={props.location.pathname}
						/>
					</div>
				)}
				{displayNoData && <div className="text-center">No data available</div>}
				{displayError && <div className="text-center">{errorMessage}</div>}
			</>
		</div>
	)
};
