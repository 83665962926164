import React from 'react'

export default function RequestHistoryProfile ({data}) {
	const styleNoImage = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#ccc',
		height: '100%',
		color: '#fff'
	}
	return (
		<div className="rounded-l-full bg-white mb-6 inline-block w-full">
			<div className="flex">
				<div className="mr-2 float-left w-24">
					{
						data.document && data.document.url ? 
							<div style={styleNoImage} className="rounded-full">
								<img className="rounded-full" src={data.document && data.document.url} alt="avatar" /> 
							</div>
							:
							<div style={styleNoImage} className="rounded-full">
								<div>
									No Image
								</div>
							</div>
					}
				</div>

				<div className="flex w-full">
					<div className="p-4 w-full">
						<p className="font-bold">{data.fullname}</p>
						<p className="font-bold">{data.email}</p>
					</div>
					<div className="p-4 w-full">
						<p className="font-bold">{data.smallgroupmemberrole_highest && `Small Group Name | ${data.smallgroupmemberrole_highest.name}`}</p>
						<p className="font-bold">
							{
								data.campusservice && `${data.campusservice.campus.name} | ${data.campusservice.servicetime}`
							}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
