import React, {useEffect, useState} from 'react'
import styles from 'styled-components'
import TextInput from 'components/commons/TextInput'
import Label from 'components/commons/Label'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import Button from 'components/commons/Button'
import {cancelConfirmation} from 'libs/utils/confirmation'
import {dummyTypes} from "../events/EventDummyObject";
import InputNumber from "../commons/InputNumber";
import Loading from "../commons/Loading";
import InputRadioButton from "../commons/InputRadioButton";
import {getRequirements} from "../../libs/apis/events";
import {saveConfirmation} from "../../libs/utils/confirmation";

const Eligibility = styles.div`
    background-color: #9d9d9d;
    width: 6rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
`;

function CreateEventCodeForm(props) {
    const [listProgramCode, setListProgramCode] = useState([]);
    const [listMaritalStatus, setListMaritalStatus] = useState([]);
    const [listSmallGroupMemberRole, setListSmallGroupMemberRole] = useState([]);
    const [listMinistry, setListMinistry] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const displayLoading = isLoading && !errorMessage;
    const displayError = !isLoading && errorMessage;

    const {setFieldValue, isSubmitting, handleSubmit, errors} = props;
    const {
        eventCode,
        eventName,
        ageFrom,
        ageTo,
        genderList,
        maritalStatusList,
        roleList,
        ministryList,
        typeList,
        registrationCheck,
        isGenderValid,
        isAgeValid
    } = props.values;

    useEffect(() => {
        genderList.length === 0 ? setFieldValue('isGenderValid', false) : setFieldValue('isGenderValid', true);
    }, [genderList]);

    useEffect(() => {
        ageFrom >= ageTo ? setFieldValue('isAgeValid', false) : setFieldValue('isAgeValid', true);
    }, [ageFrom, ageTo]);

    useEffect(() => {
        fetchRequirements();
    }, []);

    const fetchRequirements = async () => {
        setIsLoading(true);
        try {
            let response = await getRequirements("Event");
            const programCodeData = response.data.data.preprogramcodes;
            const maritalStatusData = response.data.data.maritalstatuses;
            const smallGroupMemberData = response.data.data.smallgroumemberproles;
            const ministryData = response.data.data.ministrymemberroles;

            if (programCodeData) {
                let listProgramCode = [];
                for (const programCode of programCodeData) {
                    listProgramCode.push(
                        {
                            id: programCode.id,
                            name: programCode.name
                        }
                    )
                }
                setListProgramCode(listProgramCode);
            }

            if (maritalStatusData) {
                let listMaritalStatus = [];
                for (const maritalStatus of maritalStatusData) {
                    listMaritalStatus.push(
                        {
                            id: maritalStatus.id,
                            name: maritalStatus.name
                        }
                    )
                }
                setListMaritalStatus(listMaritalStatus);
            }

            if (maritalStatusData) {
                let listSmallGroup = [];
                for (const smallGroupMember of smallGroupMemberData) {
                    listSmallGroup.push(
                        {
                            id: smallGroupMember.id,
                            name: smallGroupMember.name
                        }
                    )
                }
                setListSmallGroupMemberRole(listSmallGroup);
            }

            if (maritalStatusData) {
                let listMinistryGroup = [];
                for (const ministry of ministryData) {
                    listMinistryGroup.push(
                        {
                            id: ministry.id,
                            name: ministry.name
                        }
                    )
                }
                setListMinistry(listMinistryGroup);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleEventCodeChange = (event) => {
        setFieldValue('eventCode', event.target.value)
    };

    const handleEventNameChange = (event) => {
        setFieldValue('eventName', event.target.value)
    };

    const handleGenderCheck = (gender) => {
        const cloneGenderList = [...genderList];
        const toDeleteIndex = cloneGenderList.findIndex((itrGender) => itrGender === gender);

        if (toDeleteIndex < 0) {
            cloneGenderList.push(gender);
            setFieldValue('genderList', cloneGenderList)
        } else {
            cloneGenderList.splice(toDeleteIndex, 1);
            setFieldValue('genderList', cloneGenderList)
        }
    };

    const handleMaritalStatusChange = (status) => {
        const cloneMaritalStatusList = [...maritalStatusList];
        const toDeleteIndex = cloneMaritalStatusList.findIndex((itrMaritalStatus) => itrMaritalStatus === status);

        if (toDeleteIndex < 0) {
            cloneMaritalStatusList.push(status);
            setFieldValue('maritalStatusList', cloneMaritalStatusList)
        } else {
            cloneMaritalStatusList.splice(toDeleteIndex, 1);
            setFieldValue('maritalStatusList', cloneMaritalStatusList)
        }
    };

    const renderMaritalStatus = () => {
        let maritalStatusEl = [];

        for (const itrMaritalStatus of listMaritalStatus) {
            maritalStatusEl.push(
                <InputCheckBox key={itrMaritalStatus.id} value={itrMaritalStatus.id}
                               checked={maritalStatusList.includes(itrMaritalStatus.id)}
                               onChange={() => handleMaritalStatusChange(itrMaritalStatus.id)} name="maritalStatus"
                               label={itrMaritalStatus.name}/>
            )
        }

        return maritalStatusEl;
    };

    const handleRoleChange = (role) => {
        const cloneRoleList = [...roleList];
        const toDeleteIndex = cloneRoleList.findIndex((itrRole) => itrRole === role);

        if (toDeleteIndex < 0) {
            cloneRoleList.push(role);
            setFieldValue('roleList', cloneRoleList)
        } else {
            cloneRoleList.splice(toDeleteIndex, 1);
            setFieldValue('roleList', cloneRoleList)
        }
    };

    const renderRoles = () => {
        let rolesEl = [];

        for (const itrRole of listSmallGroupMemberRole) {
            rolesEl.push(
                <div className="w-1/2" key={itrRole.id}>
                    <InputCheckBox value={itrRole.id} checked={roleList.includes(itrRole.id)}
                                   onChange={() => handleRoleChange(itrRole.id)}
                                   name="role"
                                   label={itrRole.name}/>
                </div>
            )
        }

        return rolesEl;
    };

    const handleMinistryChange = (ministry) => {
        const cloneMinistryList = [...ministryList];
        const toDeleteIndex = cloneMinistryList.findIndex((itrMinistry) => itrMinistry === ministry);

        if (toDeleteIndex < 0) {
            cloneMinistryList.push(ministry);
            setFieldValue('ministryList', cloneMinistryList)
        } else {
            cloneMinistryList.splice(toDeleteIndex, 1);
            setFieldValue('ministryList', cloneMinistryList)
        }
    };

    const renderMinistries = () => {
        let ministriesEl = [];

        for (const itrMinistry of listMinistry) {
            ministriesEl.push(
                <div className="w-1/2" key={itrMinistry.id}>
                    <InputCheckBox value={itrMinistry} checked={ministryList.includes(itrMinistry.id)}
                                   onChange={() => handleMinistryChange(itrMinistry.id)} name="ministry"
                                   label={itrMinistry.name}/>
                </div>
            )
        }

        return ministriesEl;
    };

    const handleTypeChange = (type) => {
        const cloneTypeList = [...typeList];
        const toDeleteIndex = cloneTypeList.findIndex((itrType) => itrType === type);

        if (toDeleteIndex < 0) {
            cloneTypeList.push(type);
            if (type === 'type2' && !cloneTypeList.includes('type1')) {
                cloneTypeList.push('type1');
            }
            setFieldValue('typeList', cloneTypeList)
        } else {
            cloneTypeList.splice(toDeleteIndex, 1);
            if (type === 'type1' && cloneTypeList.includes('type2')) {
                const toDeleteIndexType2 = cloneTypeList.findIndex((itrType) => itrType === 'type2');
                if (toDeleteIndexType2 >= 0) {
                    cloneTypeList.splice(toDeleteIndexType2, 1);
                }
            }
            setFieldValue('typeList', cloneTypeList)
        }
    };

    const renderTypes = () => {
        let typesEl = [];

        for (const itrType of dummyTypes) {
            typesEl.push(
                <InputCheckBox key={itrType.id} value={itrType.id} checked={typeList.includes(itrType.id)}
                               onChange={() => handleTypeChange(itrType.id)}
                               name="type" label={itrType.label}/>
            )
        }

        return typesEl;
    };

    const handleMemberRegistrationCheck = () => {
        setFieldValue('registrationCheck', !registrationCheck)
    };

    const handleAgeFromChange = (event) => {
        if (event.target.value) {
            setFieldValue('ageFrom', Number(event.target.value));
        } else {
            setFieldValue('ageFrom', 0);
        }
    };

    const handleAgeToChange = (event) => {
        if (event.target.value) {
            setFieldValue('ageTo', Number(event.target.value));
        } else {
            setFieldValue('ageTo', 0);
        }
    };

    const handleCancel = async () => {
        const res = await cancelConfirmation();
        if (res.value) {
            props.history.push('/events/list-event-code')
        }
    };

    const handleOnSubmit = async () => {
        const res = await saveConfirmation('Event Code Form');
        if (res.value) {
            handleSubmit();
        }
    };

    return (
        <div>
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayLoading && <Loading/>}
            {!displayLoading &&
            <div className="w-full">
                <TextInput styles="pr-4 mb-4" value={eventCode} name="eventCode" label="EVENT CODE"
                           onChange={handleEventCodeChange} errors={errors.eventCode}/>
                <TextInput styles="pr-4 mb-4" value={eventName} name="eventName" label="EVENT NAME"
                           onChange={handleEventNameChange} errors={errors.eventName}/>

                <Eligibility>ELIGIBILITY</Eligibility>
                <div className="mb-4">
                    <Label>GENDER</Label>
                    <InputCheckBox value={"Male"} checked={genderList.includes("Male")} name="gender" label="Male"
                                   onChange={() => handleGenderCheck("Male")}/>
                    <InputCheckBox value={"Female"} checked={genderList.includes("Female")} name="gender" label="Female"
                                   onChange={() => handleGenderCheck("Female")}/>

                    {
                        !isGenderValid ?
                            <span className="text-red-500 text-xs italic">Choose at least one gender</span>
                            :
                            <div/>
                    }
                </div>


                <div className="mb-4">
                    <div className="flex flex-wrap md:-mx-2">
                        <div className="md:w-1/2 w-full mb-6 md:mb-0 md:pr-6">
                            <InputNumber label="AGE FROM" value={ageFrom}
                                         handleChange={handleAgeFromChange} min={0}/>
                        </div>
                        <div className="flex-col md:w-1/2 w-full md:pr-6">
                            <div>
                                <InputNumber label="TO" value={ageTo}
                                             handleChange={handleAgeToChange} min={0}/>
                            </div>
                            {
                                !isAgeValid && (
                                    <span className="text-red-500 text-xs italic">Age To should be greater than Age From
                                </span>
                                )
                            }
                        </div>
                    </div>

                </div>

                <div className="mb-4">
                    <Label>MARITAL STATUS</Label>
                    {renderMaritalStatus()}
                </div>
                <div className="mb-4">
                    <Label>ROLE</Label>
                    <div className="flex flex-row flex-wrap w-1/2">
                        {renderRoles()}
                    </div>
                </div>
                <div className="mb-4">
                    <Label>MINISTRY</Label>
                    <div className="flex flex-row flex-wrap w-1/2">
                        {renderMinistries()}
                    </div>
                </div>
                <div className="mb-4">
                    <Label>TYPE</Label>
                    {renderTypes()}
                </div>

                <div className="mb-4">
                    <Label>NON MEMBER REGISTRATION</Label>
                    <div className="flex w-1/4 flex-row justify-between">
                        <InputRadioButton value={registrationCheck} checked={registrationCheck} name="registrationCheck"
                                          label="Yes"
                                          onChange={handleMemberRegistrationCheck}/>
                        <InputRadioButton value={!registrationCheck} checked={!registrationCheck}
                                          name="registrationCheck"
                                          label="No"
                                          onChange={handleMemberRegistrationCheck}/>
                    </div>
                </div>

                <div className="flex justify-between mb-6 pr-4 mt-4">
                    <Button type="cancel" handleClick={handleCancel}>
                        {isSubmitting ? <Loading secondary/> : 'Cancel'}
                    </Button>
                    <Button type="primary" handleClick={handleOnSubmit}>
                        {isSubmitting ? <Loading secondary/> : 'Save'}
                    </Button>
                </div>
            </div>
            }
        </div>
    )
}

export default CreateEventCodeForm
