import React, { useState, useEffect } from 'react'
import { getSmallGroupByCampus, smallGroupTranserMember, getUpperByParrentId } from 'libs/apis/smallGroup'
import Modal from 'react-modal'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import DropdownItem from 'components/commons/DropdownItem'
import alert from 'services/alert'
import { useParams } from 'react-router-dom'

const customStyles = {
	content: {
		top: '15%',
		left: '30%',
		right: '',
		bottom: '',
		marginRight: '-50%',
		height: '460px',
		width: '700px',
		// transform: 'translate(-50%, -50%)',
		backgroundColor: '#edf2f7',
		padding: '0px',
		margin: '0px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

function TransferMember(props) {
	const [smallGroups, setSmallGroups] = useState([])
	const [member, setMember] = useState('')
	const [smallGroup, setSmallGroup] = useState('')
	const [campus, setCampus] = useState('')
	const [transferMembers, setTransferMembers] = useState([])

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loadingSg, setLoadingSg] = useState(false)
	const listCampus = JSON.parse(localStorage.getItem('campuses'))
	const { id } = useParams()

	useEffect(() => {
		const getSmallGroup = async () => {
			setLoadingSg(true)
			try {
				let res = await getSmallGroupByCampus(campus.value)
				let data = res.data.data.smallgroup.map((val) => {
					let obj = {}
					obj.id = val.id
					obj.name = val.name
					return obj
				})
				setSmallGroups(data)
				setLoadingSg(false)
			} catch (error) {
				setLoadingSg(false)
			}
		}
		getSmallGroup()
	}, [campus.value])

	useEffect(() => {
		const fetchMembers = async () => {
			try {
				const res = await getUpperByParrentId(id)
				const transfers = res.data.data.smallgroupmembers.map((val) => {
					const temp = {}
					temp.id = val.id
					temp.name = val.user.fullname
					return temp
				})
				setTransferMembers(transfers)
			} catch (error) {
				setTransferMembers([])
			}
		}

		fetchMembers()
	}, [smallGroup.value])

	const handleTransferMember = async () => {
		try {
			setLoading(true)
			let res = await smallGroupTranserMember(member.value, smallGroup.value, 'Transfered by admin')

			if (res.data.api_status) {
				alert.succeed(res.data.message)
				setLoading(false)
				setModalIsOpen(false)
				setMember({ value: '', label: '' })
				setSmallGroup({ value: '', label: '' })

				setTimeout(() => {
					props.refresh()
				}, 1500)
			} else {
				alert.failed(res.data.message)
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
		}
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleSelectMember = (e) => {
		if (e !== null) {
			setMember({ ...member, value: e.value, label: e.label })
		} else {
			setMember({ ...member, value: '', label: '' })
		}
	}

	const handleSelectSmallGroup = (e) => {
		if (e !== null) {
			setSmallGroup({ ...smallGroup, value: e.value, label: e.label })
		} else {
			setSmallGroup({ ...smallGroup, value: '', label: '' })
		}
	}

	const handleSelectCampus = (e) => {
		if (e !== null) {
			setCampus({ ...campus, value: e.value, label: e.label })
		} else {
			setCampus({ ...campus, value: '', label: '' })
		}
	}

	return (
		<div>
			<Button handleClick={handleToggleModal} type={'tertiary'}>
				Transfer Member
			</Button>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Transfer Member Modal" appElement={document.getElementById('root')}>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center mt-2 text-primary pl-2 text-md font-bold active:bg-red-700">Transfer Member</div>
					<div className="flex justify-center mt-1 text-primary pr-2 cursor-pointer text-xl font-bold" onClick={handleToggleModal}>
						X
					</div>
				</div>
				<div className="bg-gray-200 p-4 pb-6 pt-8 w-full">
					<div className="mt-2 mb-2">
						<DropdownItem label="Member Name" placeholder="Search here" value={member} options={transferMembers} handleChange={handleSelectMember} />
					</div>
					<div className="mb-2">
						<DropdownItem label="Choose Campus" placeholder="Search here" value={campus} options={listCampus} handleChange={handleSelectCampus} />
					</div>
					<div className="mb-2">
						<DropdownItem
							label="Search Small Group"
							value={smallGroup}
							placeholder="Search here"
							options={smallGroups}
							handleChange={handleSelectSmallGroup}
							isLoading={loadingSg}
						/>
					</div>
				</div>
				<div className="flex bg-primary h-auto pt-4 pb-4 inline-block cursor-pointer justify-center" style={{ height: '20px', marginTop: '60px' }} onClick={handleTransferMember}>
					{loading ? <Loading secondary styles="mt-2" /> : <span className="text-center font-bold text-white">Transfer</span>}
				</div>
			</Modal>
		</div>
	)
}

export default TransferMember
