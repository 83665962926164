import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { getAppointmentDetails, postAppointmentAttendance } from 'libs/apis/smallGroup'
import { removeItem } from 'libs/utils/utils'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import Button from 'components/commons/Button'
import alert from 'services/alert'

function AppointmentDetails() {
	const [appointmentData, setAppointmentData] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [attendanceId, setAttendanceId] = useState([])
	let params = useParams()

	useEffect(() => {
		fetchAppointmentDetails()
	}, [params.id])

	const fetchAppointmentDetails = async () => {
		setIsLoading(true)
		try {
			let res = await getAppointmentDetails(params.id)
			let data = res.data.data.smallgroupappointment

			let obj = {}
			obj.category = data.smallgroup.smallgroupcategory.name
			obj.date = data.appointmentdatetime
			obj.time = data.appointmentdatetime
			// obj.rsvp = ''
			obj.note = data.note
			obj.title = data.name

			obj.attendances = data.smallgroupappointmentattendances.map((val) => {
				let attendances = {}

				if (val.smallgroupmember_id !== null) {
					attendances.name = `${val.smallgroupmember.user2_id ? val.smallgroupmember.user.fullname + val.smallgroupmember.user2.fullname : val.smallgroupmember.user.fullname}`
				} else if (val.user_id !== null) {
					attendances.name = val.user.fullname
				} else {
					attendances.name = val.name
				}

				attendances.attendee = 0
				attendances.id = val.id
				attendances.status = val.status

				if (val.status === 'Present') {
					setAttendanceId((attendanceId) => [...attendanceId, val.id])
				}

				return attendances
			})

			setAppointmentData(obj)

			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	const handleCheckAttendance = async (e) => {
		if (!e.target.checked) {
			setAttendanceId(removeItem(attendanceId, parseInt(e.target.id)))
		} else {
			setAttendanceId([...attendanceId, parseInt(e.target.id)])
		}
	}

	const displayLoading = (isLoading && appointmentData === undefined) || appointmentData === null
	const breads = [
		{ url: '/smallgroup/list-appointments', title: 'List of All Appointments' },
		{ url: '', title: 'View Appointment Detail' },
	]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title className="ml-4">View Details</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="View Details" breads={breads} />
			{displayLoading ? (
				<Loading />
			) : (
				<div className="flex">
					<div className="flex flex-col ml-4">
						<AppointmentDescription data={appointmentData} />
						<AppointmentDescription2 data={appointmentData} />
					</div>
					<div className="flex flex-col w-full pl-12">
						<div>
							<AttendeeList data={appointmentData} handleCheck={handleCheckAttendance} attendanceId={attendanceId} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default AppointmentDetails

const AppointmentDescription = ({ data }) => {
	if (data === undefined) {
		return <Loading />
	}
	return (
		<div className="bg-white rounded" style={{ height: 'auto', width: '400px' }}>
			<div className="pl-6">
				<h1 className="text-bold mb-4">{data.title}</h1>
				<div className="mb-4">
					<label className="text-sm font-bold">Category</label>
					<p className="text-sm mb-4">{data.category}</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Date</label>
					<p className="text-sm mb-4">{moment(data.date).format('DD MMM YYYY')}</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Time</label>
					<p className="text-sm mb-4">{moment(data.date).format('HH:mm:ss')}</p>
				</div>
				<div className="mb-4">
					<label className="text-sm font-bold">Additional Info</label>
					<p className="text-sm mb-4">{data.note}</p>
				</div>
			</div>
		</div>
	)
}

const AppointmentDescription2 = ({ data }) => {
	if (data === undefined || data.note === null) {
		return <></>
	}
	return (
		<div className="bg-white rounded mt-6" style={{ height: 'auto' }}>
			<div className="pl-6">
				<div className="mb-4">
					<p className="text-sm mb-4">{data.note}</p>
				</div>
			</div>
		</div>
	)
}

const AttendeeList = (props) => {
	if (props.data === undefined) {
		return <></>
	}
	return (
		<table className="bg-white shadow-md rounded md:ml-6 w-full">
			<thead>
				<tr className="text-center">
					<th className="px-4 py-4">NAME</th>

					<th className="px-4 py-4">ATTENDANCE</th>
				</tr>
			</thead>
			<tbody>
				{props.data.attendances.map((val) => (
					<tr key={val.id} className="text-center" style={{ borderTop: '3px solid #f8f9fb' }}>
						<td className="px-4 py-4">{val.name}</td>
						<td className="px-4 py-4">
							<input
								name={val.name}
								type="checkbox"
								checked={props.attendanceId.includes(val.id)}
								id={val.id}
								onChange={props.handleCheck}
								className="form-radio text-primary border-2"
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
