import React from 'react'
import InvalidFeedBack from 'components/commons/InvalidFeedback'

function TextInput(props, { label, handleChange, value, isActive, name, marginBottom, errors = props.errors }) {
	return (
		<div className={props.styles}>
			<div className="flex flex-col w-full mb-6">
				<label className="block text-gray-700 text-sm font-bold mb-2">{label || props.label}</label>
				<input
					type="text"
					name={name || props.name}
					className={`py-2 pl-3 border-secondary border-2 rounded-lg ${props.disabled ? 'bg-gray-200' : 'bg-white'}`}
					value={value || props.value || ''}
					onChange={handleChange || props.onChange}
					placeholder={props.placeholder}
					disabled={props.disabled}
				/>
				{errors && <InvalidFeedBack message={errors} />}
			</div>
		</div>
	)
}

export default TextInput
