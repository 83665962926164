import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TreeContainer, Tag } from '../../screens/smallGroup/styles'

const Collapsible = styled.div`
	height: ${(p) => (p.isOpen ? '0' : 'auto')};
	overflow: hidden;
`

const LinkContainer = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
`
const LinkStyle = styled(Link)`
	text-transform: uppercase;
	margin: 1rem;

	:hover {
		border-bottom: 1px solid black;
	}
`

const Span = styled.div`
	color: ${(props) => (props.gray ? '#989898' : props.color)};
	padding: 0 2rem;
	cursor: pointer;
`

const Tree = ({ id, data, children, role, color, padding, lvl, handlePromote }) => {
	const [isOpen, setIsOpen] = useState(true)

	const handleToggle = (e) => {
		e.preventDefault()
		setIsOpen(!isOpen)
	}

	return (
		<>
			<TreeContainer role={role} color={color} padding={padding}>
				<div className="flex flex-row w-full" onClick={handleToggle}>
					<Tag role={role} color={color}>
						{role}
					</Tag>
					<LinkContainer onClick={handleToggle}>
						{data.map((d, idx) =>
							d !== null ? (
								<LinkStyle key={idx} to={`/small-group/view-member/${id}`}>
									{d.fullname}
								</LinkStyle>
							) : (
								<></>
							)
						)}
					</LinkContainer>
				</div>

				<div className="items-center ml-auto font-bold justify-end flex flex-row">
					{lvl !== 1 ? (
						<Span color={color}>
							<div id={id} onClick={handlePromote}>
								Promote
							</div>
						</Span>
					) : (
						<></>
					)}
					{/* <Span>|</Span>
					<Span id={id} color={color}>
						<div id={id} onClick={handleDemote}>
							Demote
						</div>
					</Span> */}
				</div>
			</TreeContainer>
			<Collapsible isOpen={isOpen}>{children}</Collapsible>
		</>
	)
}
export default Tree
