import React from 'react'
import styled from 'styled-components'

const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: end;
	margin-bottom: 1.5rem;

	@media (min-width: 768px) {
		width: 33.33%;
	}
`;

const Input = styled.input`
	width: 44px;
	height: 44px;
	margin-left: -30px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding-left: 50px;
	background: #fff url('./search.png') no-repeat center;
	background-size: 20px 20px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;

	:focus {
		width: 350px;
		color: #000;
		background-color: #fff;
		background-position: 4%;
		cursor: auto;
		padding-right: 10px;
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #adadad;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: #adadad;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		color: #adadad;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: #adadad;
	}
`;

const SearchWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
`;

const Searchbar = ({ filterKeyword, handleOnSearch, handleSeachInputKeyPress, name, placeholder, noFilter }) => {
	return (
		<SearchContainer>
			<SearchWrapper>
				<Input
					type="search"
					placeholder={placeholder ? placeholder : 'Search here'}
					name={name}
					value={filterKeyword}
					onChange={handleOnSearch}
					onKeyPress={handleSeachInputKeyPress}
					autoComplete="off"
				/>
			</SearchWrapper>
		</SearchContainer>
	)
};

export default Searchbar
