import axios from 'axios'

export const getMinistryMemberDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrymember/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const editMinistry = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const inviteVolunteer = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministryinvite/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})

export const exportMinistry = async (ministrygroupId, ministrysubgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			ministrygroupId,
			ministrysubgroupId,
		},
	})

export const exportMinistryMember = async (ministryId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrymember/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			ministryId,
		},
	})

export const acceptRequest = async (id, userId, ministryId) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministryrequest/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			action: 'Approve',
			reason: 'Approve by admin',
		},
	})

export const rejectRequest = async (id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministryrequest/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			action: 'Reject',
			reason: 'Reject by admin',
		},
	})

export const changeRoleMinistryMember = async (id, ministryMemberRoleId) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrymember/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			ministryMemberRoleId,
		},
	})

export const removeMinistryMember = async (id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrymember/remove`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const getMinistryHeadsAndDeputy = async (name, showColoumn) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/get`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			name,
			showColoumn: 'fullname',
		},
	})

export const getHeadsAndDeputy = async (paginate, withMember) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			withMember,
		},
	})

export const getUsers = async (name) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/get`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			name: name,
			showColoumn: 'fullname',
		},
	});

export const getMinistry = async (level) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/all/filtered-admin`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate: 100,
			level,
		},
	})

export const getGroups = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/filters`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getMinistryRole = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrymemberrole/get`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getMinistryList = async (level, paginate, ministrysubgroupId, ministrygroupId, sortBy, sorDir, name, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/all/filtered-admin`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			level,
			paginate,
			ministrysubgroupId,
			ministrygroupId,
			sortBy,
			sortDirection: sorDir,
			name,
			page,
		},
	})

export const ministryDetail = async (id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { id },
	})
}

export const requestMinistry = async (filter_keyword, order_by, order_dir) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/request`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, order_by, order_dir },
	})
}

export const getListVolunteers = async (filter_keyword, ministry_id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, ministry_id },
	})
}

export const createMinistry = async (formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const createSubGroup = async (formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrysubgroup/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const createGroup = async (formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministrygroup/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const updateMinistry = async (formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const inviteMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/invite`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			ministry_id,
			user_id,
		},
	})
}

export const assignMember = async (ministry_id, user_id, church_role_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/assign`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id, church_role_id },
	})
}

export const rejectMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/reject`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id },
	})
}

export const removeMember = async (ministry_id, user_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/remove`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { ministry_id, user_id },
	})
}

export const closeMinistry = async (ministry_id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/close`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: ministry_id,
	})
}

export const searchVolunteers = async (filter_keyword, ministry_id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/ministry/member/search`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			ministry_id,
		},
	})
}
