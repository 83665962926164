import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import DownloadIcon from 'assets/icons/download.svg'
import FileViewEdit from 'screens/file/FileViewEdit'
import FileMove from 'screens/file/FileMove'
// import { bytesToMegaBytes } from 'libs/utils/utils'

const FolderListTable = (props) => {
	return (
		<div className="bg-white shadow-md">
			<table className="w-full rounded-lg">
				<TableHead />
				<TableBody {...props} />
			</table>
		</div>
	)
}

export default FolderListTable

const TableHead = () => {
	return (
		<thead className="text-gray">
			<tr>
				<th className="text-left justify-center pl-10 py-4">FILE NAME</th>
				{/* <th className="text-left">SIZE</th> */}
				<th className="text-center">UPLOADED ON</th>
				<th className="text-center">DOWNLOAD</th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ data, handleDelete, handleLoadData, search, folders }) => {
	const temp = data.filter((val) => val.name.toLowerCase().includes(search))
	return (
		<tbody className="borde border-solid pl-5">
			{temp.map((value) => (
				<FileListRow key={value.id} {...value} folders={folders} handleDelete={handleDelete} handleLoadData={handleLoadData} />
			))}
		</tbody>
	)
}

const FileListRow = ({ created_at, name, handleDelete, handleLoadData, url, id, documenttype_id, folders }) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`
	return (
		<tr className="border border-solid bt-blueSecondary">
			<td className="text-left pl-10">{name}</td>
			{/* <td className="text-left">{bytesToMegaBytes(size)}</td> */}

			<td className="text-center">{moment(created_at).format('LL')}</td>
			<td className="items-center">
				<a href={url} className="items-center" target={url}>
					<img className="block m-auto" src={DownloadIcon} />
				</a>
			</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div className={styleDropDownOpen}>
						<ul ref={node} className="bg-white w-32">
							<li className="px-4 py-2 block hover:bg-darkSkyBlue">
								<FileViewEdit id={id} documenttype_id={documenttype_id} fileName={name} handleLoadData={handleLoadData} />
							</li>
							<li className="px-4 py-2 block hover:bg-darkSkyBlue">
								<FileMove id={id} fileName={name} folders={folders} handleLoadData={handleLoadData} />
							</li>
							<li
								className="px-4 py-2 block hover:bg-darkSkyBlue"
								onClick={() => {
									handleDelete(id, 'Document')
								}}
							>
								Delete
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
