import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {ReactComponent as EventIcon} from 'assets/icons/events.svg'

const navigation = ['/events/create', '/events/create-event-code', '/events/list-event-code', '/events/list', '/events/edit/:id', '/events/registrant/:id']

class EventMenu extends Component {
    state = {isOpenSub: false}

    componentDidMount() {
        const {
            match: {path},
        } = this.props
        const menus = navigation.filter((menu) => menu === path)
        if (menus.length > 0) {
            this.setState({isOpenSub: true})
        }
    }

    handleOpenSub = () => {
        this.setState({isOpenSub: !this.state.isOpenSub})
    }

    render() {
        const {
            match: {path},
        } = this.props
        const {isOpenSub} = this.state
        const menus = navigation.filter((menu) => menu === path)

        const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
        const styleLinkList = `${path === '/events/list' || path === '/events/edit/:id' || path === '/events/registrant/:id' ? 'text-primary' : 'text-grey-500'}`

        const styleLinkCreate = `${path === '/events/create' ? 'text-primary' : 'text-grey-500'}`
        const styleLinkCreateEventCode = `${path === '/events/create-event-code' ? 'text-primary' : 'text-grey-500'}`
        const styleLinkClassCode = `${path === '/events/list-event-code' || path === '/events/edit-code/:id' ? 'text-primary' : 'text-grey-500'}`
        return (
            <div className="mb-6">
                <div className="flex cursor-pointer" onClick={this.handleOpenSub}>
                    <div className="mr-4">
                        <EventIcon className={styleIcon}/>
                    </div>
                    <div className="font-bold text-grey-500">Events</div>
                </div>
                {isOpenSub && (
                    <div className="ml-8 mt-2">
                        <ul>
                            <li className="mb-4">
                                <Link to="/events/create" className={styleLinkCreate}>
                                    Create Event
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link to="/events/list" className={styleLinkList}>
                                    List of All Events
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link to="/events/create-event-code" className={styleLinkCreateEventCode}>
                                    Create Event Code
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link to="/events/list-event-code" className={styleLinkClassCode}>
                                    List Event Code
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        )
    }
}

export default EventMenu
