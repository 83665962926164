import React from 'react'
import { acceptRequest, rejectRequest } from 'libs/apis/ministry'
import MinistryCard from './MinistryCard'
import alert from 'services/alert'

function MinistryRequestList({ requests, handleMinistryDetail }) {
	const handleAccept = async (user_id) => {
		let confirm = await alert.confirmAcceptReq()
		if (confirm.value) {
			try {
				const res = await acceptRequest(user_id)
				if (res.data.api_status) {
					alert.succeed('Succeed')
					handleMinistryDetail()
				}
			} catch (error) {
				alert.failed('Failed')
			}
		}
	}

	const handleReject = async (user_id) => {
		let confirm = await alert.confirmRejectReq()
		if (confirm.value) {
			try {
				const res = await rejectRequest(user_id)
				if (res.data.api_status) {
					alert.succeed('Succeed')
					handleMinistryDetail()
				}
			} catch (error) {
				alert.failed('Failed')
			}
		}
	}

	const req = requests.length ? true : false

	return (
		<div>
			<h2 className="mt-3 mb-3 font-bold">REQUEST</h2>
			{req && (
				<div className="flex flex-wrap">
					{requests.map((data, idx) => (
						<MinistryCard key={idx} {...data} handleMinistryDetail={handleMinistryDetail} handleAccept={handleAccept} handleReject={handleReject} />
					))}
				</div>
			)}
		</div>
	)
}

export default MinistryRequestList
