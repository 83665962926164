import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ErrorMessage from 'components/commons/ErrorMessage'
import HeadTitle from 'components/commons/HeadTitle'
import InputEmail from 'components/commons/InputEmail'
import InputPassword from 'components/commons/InputPassword'
import Loading from 'components/commons/Loading'
import logo from 'assets/icons/logo.svg'
import packageJson from '../../../package.json'

function LoginForm(props) {
	const [isShowPassword, setIsShowPassword] = useState(false)

	const { handleSubmit, values, handleChange } = props
	const { username, password, errorMessage, isSubmit } = values
	const errors = props.errors

	const handleShowPassword = () => {
		setIsShowPassword(!isShowPassword)
	}

	return (
		<div className="h-screen">
			<div className="text-xs text-center" style={{ color: 'gray' }}>
				version {packageJson.version}
			</div>
			<HeadTitle title="Skyportal | Login" />
			<div className="container auth-container">
				<div className="md:w-1/2">
					<div className="flex justify-center">
						<img src={logo} alt="skyporter logo" className="w-16" />
					</div>
					<h3 className="auth-title">Welcome Back</h3>

					{errorMessage && (
						<div className="mb-3 text-center">
							<ErrorMessage message={errorMessage} />
						</div>
					)}
					<form>
						<InputEmail name="username" value={username} placeholder="username" onChange={handleChange} error={errors.username} />
						<InputPassword
							type={isShowPassword ? 'text' : 'password'}
							name="password"
							value={password}
							placeholder="password"
							onChange={handleChange}
							isShow={isShowPassword}
							error={errors.password}
							onClick={handleShowPassword}
						/>

						<button disabled={isSubmit} className="bg-primary text-white flex w-full py-5 px-3 justify-center rounded-lg focus:outline-none" onClick={handleSubmit}>
							{isSubmit ? <Loading secondary /> : 'Login'}
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default LoginForm
