import React, { Component } from 'react'
import styled from 'styled-components'

const THead = styled.thead`
	border-top: 2px solid #f2f6f9;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-transform: uppercase;
	color: gray;
	text-align: left;
`

class TableHeader extends Component {
	// raiseSort = (path) => {
	// 	const sortColumn = { ...this.props.sortColumn }
	// 	if (sortColumn.path === path) {
	// 		sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc'
	// 	} else {
	// 		sortColumn.path = path
	// 		sortColumn.order = 'asc'
	// 	}
	// 	this.props.onSort(sortColumn)
	// }

	// renderSortIcon = (column) => {
	// 	const { sortColumn } = this.props
	// 	if (column.key) return null
	// 	if (column.path !== sortColumn.path) return <i className="fas fa-sort cursor-pointer" aria-hidden="true" onClick={() => this.raiseSort(column.path)}></i>
	// 	if (sortColumn.order === 'asc') return <i className="fas fa-sort-up cursor-pointer" aria-hidden="true" onClick={() => this.raiseSort(column.path)}></i>
	// 	return <i className="fas fa-sort-down cursor-pointer" aria-hidden="true" onClick={() => this.raiseSort(column.path)}></i>
	// }

	render() {
		return (
			<THead>
				<tr>
					{this.props.columns.map((column, idx) => {
						if (column.length) {
							return column.map((item,index) => (
								<th className="text-center" key={index}>
									{item.label}
								</th>
							))
						}
						return (
							<th key={idx} className="px-2 py-4 text-center">
								{column.label}
							</th>
						)
					})}
				</tr>
			</THead>
		)
	}
}

export default TableHeader
