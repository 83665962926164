import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import moment from 'moment'
import { getAppointmentList, getAppointmentType, getSmallGroupCategory, getSmallGroupLevel } from 'libs/apis/smallGroup'
import { helperSelectFilter } from 'libs/utils/utils'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import AppointmentsTable from 'components/smallGroup/AppointmentsTable'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Searchbar from 'components/commons/Searchbar'
import AppointmentsFilter from 'components/smallGroup/AppointmentsFilter'
import PaginationPage from 'components/commons/PaginationPage'
import AppointmentsExport from 'components/smallGroup/AppointmentsExport'
import Loading from 'components/commons/Loading'
import { SmallGroupHeader } from './styles'

function ListAppointments(props) {
	const [listAppointments, setListAppointments] = useState([])
	const [categories, setCategories] = useState([])
	const [sglevel, setSglevel] = useState([])
	const [appointmentType, setAppointmentType] = useState([])
	const [campuses, setCampuses] = useState([])
	const [dateFrom, setdateFrom] = useState('')
	const [dateTo, setDateTo] = useState('')
	const [sortBy, setSortBy] = useState('')
	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [sortDirection, setSortDirection] = useState('')

	const [appointmentTypes, setappointmentTypes] = useState([])
	const [listCategory, setListCategory] = useState([])
	const [allLevel, setAllLevel] = useState([])

	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	let { church_id } = JSON.parse(localStorage.getItem('church'))

	useEffect(() => {
		fetchListOfAppointments()
	}, [props.location.search, categories, sglevel, campuses, appointmentType, sortBy, sortDirection, dateFrom, dateTo, searchValue])

	/* fetch level dan categories by church id */
	useEffect(() => {
		const fetchCategory = async () => {
			try {
				let res = await getSmallGroupCategory(church_id)
				setListCategory(res.data.data.categories)
			} catch (error) {
			}
		}
		fetchCategory()
	}, [church_id])

	useEffect(() => {
		let isCancel = false
		const fetchSmallGroupLevel = async () => {
			try {
				const res = await getSmallGroupLevel()
				if (!isCancel) {
					let resData = res.data.data.smallgroup_level

					let levelData = resData.map((data) => {
						let obj = {}
						obj.id = data.level
						obj.name = data.name
						return obj
					})
					setAllLevel(levelData)
				}
			} catch (error) {
				if (!isCancel) {
				}
			}
		}

		fetchSmallGroupLevel()
		return () => {
			isCancel = true
		}
	}, [])

	/* fetch appointment type */
	useEffect(() => {
		const fetchAppointmentType = async () => {
			try {
				let res = await getAppointmentType()
				setappointmentTypes(res.data.data.smallgroupappointmenttype)
			} catch (error) {}
		}
		fetchAppointmentType()
	}, [])

	const fetchListOfAppointments = async () => {
		const { search } = props.location
		const page = search ? queryString.parse(search).page : 1

		setIsLoading(true)
		try {
			let res = await getAppointmentList(
				10,
				categories.toString(),
				sglevel.toString(),
				campuses.toString(),
				appointmentType.toString(),
				sortBy,
				sortDirection,
				dateFrom,
				dateTo,
				searchValue,
				page
			)
			let data = res.data.data.smallgroupappointment.data

			setListAppointments(data)
			setIsLoading(false)
			setCurrentPage(res.data.data.smallgroupappointment.current_page)
			setLastPage(res.data.data.smallgroupappointment.last_page)
		} catch (error) {
			setErrorMessage(true)
			setIsLoading(false)
		}
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'category') {
			helperSelectFilter(categories, event.target.value, setCategories)
		}
		if (event.target.name === 'level') {
			helperSelectFilter(sglevel, event.target.value, setSglevel)
		}

		if (event.target.name === 'campus') {
			helperSelectFilter(campuses, event.target.value, setCampuses)
		}

		if (event.target.name === 'type') {
			helperSelectFilter(appointmentType, event.target.value, setAppointmentType)
		}
	}

	const handleClearFilter = () => {
		setCategories('')
		setSglevel('')
		setCampuses('')
		setAppointmentType('')
		setdateFrom('')
		setDateTo('')
	}

	const handleApplyFilter = () => {
		fetchListOfAppointments()
	}

	const handleDateFrom = (date) => {
		let myDate = new Date(date)
		let result = moment(myDate).format('YYYY/MM/DD')
		setdateFrom(result)
	}

	const handleDateTo = (date) => {
		let myDate = new Date(date)
		let result = moment(myDate).format('YYYY/MM/DD')
		setDateTo(result)
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortDirection('ASC')
			setSortBy(sort)
		} else {
			if (sortDirection === 'ASC') {
				setSortBy(sort)
				setSortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setSortBy(sort)
				setSortDirection('ASC')
			}
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleCloseAppointment = () => {}

	const location = useLocation()
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`

	const breads = [{ url: '', title: 'Appointments' }]
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	const displayLoading = isLoading
	const displayError = !isLoading && errorMessage
	const displayNoData = !isLoading && !errorMessage && listAppointments.length === 0
	const displayData = !isLoading && !errorMessage && listAppointments.length > 0

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List of Appointments </title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="List of Appointments" breads={breads} />
			<SmallGroupHeader>
				<AppointmentsFilter
					categories={categories}
					sgLevel={sglevel}
					campuses={campuses}
					appointmentType={appointmentType}
					refreshData={fetchListOfAppointments}
					handleSelectFilter={handleSelectFilter}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					handleDateFrom={handleDateFrom}
					handleDateTo={handleDateTo}
					dateFrom={dateFrom}
					dateTo={dateTo}
					listCategory={listCategory}
					allLevel={allLevel}
					appointmentTypes={appointmentTypes}
				/>
				<AppointmentsExport listCategory={listCategory} allLevel={allLevel} appointmentTypes={appointmentTypes} />

				<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} placeholder="Search by appointment title" />
			</SmallGroupHeader>

			{displayLoading && <Loading />}
			{displayError && <div className="text-center">An unexpected error has occurred. We are currently investigating the issue, please try again later.</div>}
			{displayNoData && <div className="text-center">No data available</div>}

			{displayData && (
				<div>
					<AppointmentsTable list={listAppointments} handleCloseAppointment={handleCloseAppointment} handleSort={handleSort} />
					<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={listAppointments.length} url={location.pathname} />
				</div>
			)}
		</div>
	)
}

export default ListAppointments
