import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'react-modal'
import { getRegencyByChurchId, getSmallGroupCategory, getSmallGroupLevel, exportSmallGroupMember } from 'libs/apis/smallGroup'

import { helperSelectFilter } from 'libs/utils/utils'
import FilterItem from 'components/commons/FilterItem'
import Loading from 'components/commons/Loading'
import { Button, ButtonContainer, X, Span, Header, Title, Body, Content, Input } from './Styles'
import { constants } from 'constants/constant'
import alert from 'services/alert'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '410px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}

function SmallGroupMemberExport(props) {
	const [campus, setCampus] = useState('')
	const [roles, setRoles] = useState('')
	const [locations, setLocations] = useState('')
	const [days, setDays] = useState('')
	const [categories, setCategories] = useState('')
	const [sglevel, setSglevel] = useState('')
	const [listlocations, setListlocations] = useState([])
	const [listCategory, setListCategory] = useState([])
	const [listRoles, setListRoles] = useState([])

	const [openModal, setOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [tab, setTab] = useState('campus')

	const listCampus = JSON.parse(localStorage.getItem('campuses'))
	const { church_id } = JSON.parse(localStorage.getItem('church'))

	useEffect(() => {
		const fetchRegency = async () => {
			try {
				let res = await getRegencyByChurchId(church_id)

				let regency_data = res.data.data.regency.map((data) => {
					let obj = {}

					if (data.regency_id !== null) {
						obj.id = data.regency_id
						obj.name = data.regency.name
					} else {
						return obj
					}
					return obj
				})

				setListlocations(regency_data)
			} catch (error) {
				console.log(error)
			}
		}

		const fetchCategory = async () => {
			try {
				let res = await getSmallGroupCategory(church_id)
				setListCategory(res.data.data.categories)
			} catch (error) {
				console.log(error)
			}
		}
		fetchCategory()
		fetchRegency()
	}, [church_id])

	/* fetch smallgroup roles */
	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getSmallGroupLevel()
				let resData = res.data.data.smallgroup_level
				let levelData = resData.map((data) => {
					let obj = {}
					obj.id = data.level
					obj.name = data.name
					return obj
				})
				setListRoles(levelData)
			} catch (error) {
				console.log(error)
			}
		}
		fetchRoles()
	}, [])

	const handleSelectFilter = (event) => {
		switch (event.target.name) {
			case 'campus':
				helperSelectFilter(campus, event.target.value, setCampus)
				break

			case 'location':
				helperSelectFilter(locations, event.target.value, setLocations)
				break

			case 'day':
				helperSelectFilter(days, event.target.value, setDays)
				break

			case 'category':
				helperSelectFilter(categories, event.target.value, setCategories)
				break

			case 'sgLevel':
				helperSelectFilter(sglevel, event.target.value, setSglevel)

				break

			case 'role':
				helperSelectFilter(roles, event.target.value, setRoles)
				break

			default:
				break
		}
	}

	const handleClearFilter = () => {
		setCampus([])
		setRoles([])
		setOpenModal(false)
	}

	const handleExport = async () => {
		try {
			setLoading(true)
			let res = await exportSmallGroupMember(roles.toString(), campus.toString(), locations.toString(), days.toString(), categories.toString(), '', '', '', '')

			if (res.data.api_status) {
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setLoading(false)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setLoading(false)
				}
			}
			console.log(error)

			setLoading(false)
		}
	}

	const handleToggleModal = () => {
		setOpenModal(!openModal)
	}

	const handleOnTab = useCallback(
		(event) => {
			setTab(event.target.value)
		},
		[setTab]
	)

	const campusTab = tab === 'campus'
	const locationTab = tab === 'location'
	const dayTab = tab === 'day'
	const categoryTab = tab === 'category'
	const roleTab = tab === 'role'
	return (
		<div>
			<div onClick={handleToggleModal}>Member</div>

			<Modal isOpen={openModal} style={customStyles} contentLabel="Small Group Member Export Modal" appElement={document.getElementById('root')} onRequestClose={handleToggleModal}>
				<Header>
					<Title>Small Group Member Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'campus'} type="button" value={'campus'} onClick={handleOnTab} />
						<Input tab={tab === 'location'} type="button" value={'location'} onClick={handleOnTab} />
						<Input tab={tab === 'day'} type="button" value={'day'} onClick={handleOnTab} />
						<Input tab={tab === 'category'} type="button" value={'category'} onClick={handleOnTab} />
						<Input tab={tab === 'role'} type="button" value={'role'} onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{campusTab &&
								listCampus.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="campus"
											title={name}
											value={id}
											onClick={handleSelectFilter}
											classname={`${
												campus.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'
											} py-2 m-2 p-4 w-58 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
										/>
									</div>
								))}

							{locationTab &&
								listlocations.map(({ name, id }) => (
									<div key={name}>
										{name !== undefined ? (
											<FilterItem
												classname={`${
													locations.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'
												} py-2 m-2 p-4 w-56 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
												name="location"
												title={name}
												value={id}
												onClick={handleSelectFilter}
											/>
										) : (
											<> </>
										)}
									</div>
								))}
							{dayTab &&
								constants.days.map(({ title, id }) => (
									<div className="pr-2" key={title}>
										<FilterItem
											classname={`${
												days.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'
											} py-2 m-2 p-4 w-48 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
											name="day"
											title={title}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{categoryTab &&
								listCategory.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="category"
											value={id}
											title={name}
											onClick={handleSelectFilter}
											classname={`${
												categories.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'
											} py-2 m-2 p-4 w-48 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
										/>
									</div>
								))}
							{roleTab &&
								listRoles.map(({ id, name }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="role"
											title={name}
											value={id}
											onClick={handleSelectFilter}
											classname={`${
												roles.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'
											} py-2 m-2 p-4 w-58 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilter}>
						{loading ? <Loading secondary /> : <Span>Cancel</Span>}
					</Button>
					<Button onClick={handleExport}>{loading ? <Loading secondary /> : <Span>Export</Span>}</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}

export default SmallGroupMemberExport
