import React from 'react'
import { ServiceListContainer } from './styles'
import SpecialItem from './SpecialItem'

const SpecialList = ({ timeList, typeList, isEditSpecial, handleOnDelete, handleOnEdit, handleOnCancel }) => (
  <ServiceListContainer>
    {timeList
      .filter((time) => time.special_id)
      .map((item) => (
        <SpecialItem
          key={item.special_id}
          id={item.special_id}
          isEditSpecial={isEditSpecial}
          typeList={typeList}
          day={item.service_time_day}
          dayDesc={item.service_time_day_desc}
          hour={item.service_time_hours}
          minute={item.service_time_minutes}
          handleOnDelete={handleOnDelete}
          handleOnEdit={handleOnEdit}
          handleOnCancel={handleOnCancel}
        />
      ))}
  </ServiceListContainer>
)

export default SpecialList
