import * as Yup from 'yup'

export const CreateMinistryValidationSchema = () =>
	Yup.object().shape({
		ministryName: Yup.string().required('Required'),
		ministryHeads: Yup.string().required('Required'),
		keyVolunteers: Yup.string().required('Required'),
		ministryStatus: Yup.string().required('Required'),
		ministryLevel: Yup.string().required('Required'),
	})

export const EditMinistrySchema = () =>
	Yup.object().shape({
		ministryName: Yup.string().required('Required'),
		ministryStatus: Yup.string().required('Required'),
	})
