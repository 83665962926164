import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'
import FilterItem from 'components/commons/FilterItem'

export default function NewsfeedFilter({ campuses, categories, handleSelectFilter, handleApplyFilter, handleClearFilter, listCategori }) {
	const [tab, setTab] = useState('campus')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const listCampus = JSON.parse(localStorage.getItem('campuses'))

	const customStyles = {
		content: {
			top: '10%',
			left: '17%',
			right: '25%',
			width: '1101px',
			height: `${tab == 'category' ? '350px' : '230px'}`,
			backgroundColor: 'white',
			paddingLeft: '2px',
			paddingRight: '0px',
			overflow: 'hidden',
			borderRadius: '12px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('campus')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const handleFilter = () => {
		setModalIsOpen(false)
		handleApplyFilter()
	}

	const campusTab = tab === 'campus'
	const categoryTab = tab === 'category'

	const length = campuses.length !== 0 || categories.length !== 0

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal}>Filter</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Newsfeed Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<div>
						<Input tab={tab === 'campus'} type="button" value={'campus'} onClick={handleOnTab} />
						<Input tab={tab === 'category'} type="button" value={'category'} onClick={handleOnTab} />
					</div>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{campusTab &&
								listCampus.map((campus) => (
									<div key={campus.name} className="pr-2">
										<FilterItem
											classname={`${campuses.includes(campus.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="campus"
											title={campus.name}
											value={campus.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{categoryTab &&
								listCategori.map((category) => (
									<div key={category.name} className="pr-2">
										<FilterItem
											classname={`${categories.includes(category.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="category"
											title={category.name}
											value={category.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleFilter}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
