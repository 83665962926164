import React from 'react'
import { ReactComponent as View } from 'assets/icons/view.svg'

const InputPassword = ({ error, isShow, onClick, ...rest }) => {
	const classes = `appearance-none border-2 rounded-lg w-full py-5 px-3 leading-tight focus:outline-none text-center flex ${error ? 'border-secondary' : 'border-secondary'}`

	return (
		<div className="mb-6">
			<div className={classes}>
				<span className="w-6" />
				<input className="text-center flex-grow" {...rest} />
				<span>
					<View className={`opacity-25 w-6 cursor-pointer ${isShow ? 'opacity-50' : 'opacity-25'}`} onClick={onClick} />
				</span>
			</div>
			{error && <div className="text-red-500 text-xs italic">{error}</div>}
		</div>
	)
}

export default InputPassword
