import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useParams } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css'

import { getDetailSmallGroup } from 'libs/apis/smallGroup'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Loading from 'components/commons/Loading'
import EditNewGroupFormik from 'components/formik/EditNewGroupFormik'
import EditMultiplicationFormik from 'components/formik/EditMultiplicationFormik'

function EditSmallGroup(props) {
	const [state, setState] = useState({
		ageFrom: '',
		ageTo: '',
		category: '',
		day: '',
		description: '',
		established_date: '',
		fullAddress: '',
		name: '',
		parentCampus: '',
		postalCode: '',
		shortAddress: '',
		smallGroupCampus: '',
		smallGroupLevel: '',
		status: '',
		time: '',
	})
	const [parentSG, setParentSG] = useState({})
	const [region, setRegion] = useState({})
	const [smallGroupLeader, setSmallGroupLeader] = useState({})
	const [upperLevelLeaderName, setUpperLevelLeaderName] = useState({})
	const [campusState, setCampusState] = useState([])

	const [isRedirect, setIsRedirect] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	let { id } = useParams()

	useEffect(() => {
		let isCancel = false
		setIsLoading(true)
		const fetchDetailSmallGroup = async () => {
			try {
				let res = await getDetailSmallGroup(id)
				if (!isCancel) {
					let data = res.data.data
					setState({
						...state,
						smallGroupCampus: data.smallgroup.campus_id,
						smallGroupLevel: data.smallgroup.smallgroupleader.smallgroupmemberrole.level,
						parentCampus: data.smallgroup.campus_id,
						name: data.smallgroup.name,
						category: data.smallgroup.smallgroupcategory.id,
						fullAddress: data.smallgroup.addressfull,
						shortAddress: data.smallgroup.address,
						postalCode: data.smallgroup.addresspostalcode,
						day: data.smallgroup.appointmentdefaultday,
						time: data.smallgroup.appointmentdefaulttime,
						status: data.smallgroup.status,
						established_date: data.smallgroup.established_at,
						ageFrom: data.smallgroup.agemin,
						ageTo: data.smallgroup.agemax,
						description: data.smallgroup.description,
					})

					if (data.smallgroup.regency_id !== null) {
						setRegion({
							value: data.smallgroup.regency.id,
							label: data.smallgroup.regency.name,
						})
					} else {
						setRegion(null)
					}

					if (data.smallgroup.smallgroupleader.smallgroupmultiplicator_id) {
						setParentSG({
							value: data.smallgroup.smallgroupleader.smallgroupmultiplicatefrom.id,
							label: data.smallgroup.smallgroupleader.smallgroupmultiplicatefrom.name,
						})
					} else {
						setParentSG({ value: '', label: '' })
					}

					if (data.smallgroup.smallgroupleader2 !== null) {
						setSmallGroupLeader({
							value: data.smallgroup.smallgroupleader.id,
							label: `${data.smallgroup.smallgroupleader.user.fullname} ${
								data.smallgroup.smallgroupleader.user2 ? data.smallgroup.smallgroupleader.user2.fullname : ''
							} & ${data.smallgroup.smallgroupleader2.user.fullname} ${data.smallgroup.smallgroupleader2.user2 ? data.smallgroup.smallgroupleader2.user2.fullname : ''}`,
						})
					} else {
						setSmallGroupLeader({
							value: data.smallgroup.smallgroupleader.id,
							label: `${data.smallgroup.smallgroupleader.user.fullname}`,
						})
					}

					if (data.smallgroupmember_upperleader !== null) {
						setUpperLevelLeaderName({
							value: data.smallgroupmember_upperleader.id,
							label: data.smallgroupmember_upperleader.user2
								? `${data.smallgroupmember_upperleader.user.fullname} & ${data.smallgroupmember_upperleader.user2.fullname}`
								: `${data.smallgroupmember_upperleader.user.fullname}`,
						})
					} else {
						setUpperLevelLeaderName(null)
					}
					setIsLoading(false)
				}
			} catch (error) {
				if (!isCancel) {
					setIsLoading(false)
				}
			}
		}
		fetchDetailSmallGroup()
		return () => {
			isCancel = true
		}
	}, [])

	useEffect(() => {
		let isCancel = false
		const fetchData = async () => {
			try {
				const campuses = JSON.parse(localStorage.getItem('campuses'))
				if (!isCancel) {
					setCampusState([...campuses, { id: '0', name: 'NO CAMPUS' }])
				}
			} catch (error) {
				if (!isCancel) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							setIsRedirect(true)
						} else {
							setErrorMessage('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
						}
					}
				}
			}
		}

		fetchData()
		return () => {
			isCancel = true
		}
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const displayErrorMessage = !isLoading && errorMessage

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Small Group</title>
			</Helmet>
			<PageBreadcrumbs
				icon={SGIcon}
				title="Edit Group"
				breads={[
					{ url: '/small-group/list', title: 'List of All' },
					{ url: '', title: 'Edit Group' },
				]}
			/>
			{displayErrorMessage && <div className="text-center">{errorMessage}</div>}
			{parentSG.value ? (
				<EditMultiplicationFormik
					{...props}
					campuses={campusState}
					parentSmallGroup={parentSG}
					smallGroupLeader={smallGroupLeader}
					smallGroupUpperLevelLeaderName={upperLevelLeaderName}
					region={region}
					smallGroupCampus={state.smallGroupCampus}
					smallGroupLevel={state.smallGroupLevel}
					parentCampus={state.parentCampus}
					name={state.name}
					category={state.category}
					fullAddress={state.fullAddress}
					shortAddress={state.shortAddress}
					postalCode={state.postalCode}
					day={state.day}
					time={state.time}
					status={state.status}
					established_date={state.established_date}
					ageFrom={state.ageFrom}
					ageTo={state.ageTo}
					sgId={id}
					description={state.description}
					multiplication={true}
					isLoading={isLoading}
				/>
			) : (
				<EditNewGroupFormik
					{...props}
					campuses={campusState}
					smallGroupLeader={smallGroupLeader}
					smallGroupUpperLevelLeaderName={upperLevelLeaderName}
					region={region}
					smallGroupCampus={state.smallGroupCampus}
					smallGroupLevel={state.smallGroupLevel}
					parentCampus={state.parentCampus}
					name={state.name}
					category={state.category}
					fullAddress={state.fullAddress}
					shortAddress={state.shortAddress}
					postalCode={state.postalCode}
					day={state.day}
					time={state.time}
					status={state.status}
					established_date={state.established_date}
					ageFrom={state.ageFrom}
					ageTo={state.ageTo}
					sgId={id}
					description={state.description}
					isLoading={isLoading}
					multiplication={false}
				/>
			)}
		</div>
	)
}

export default EditSmallGroup
