import React, { useState } from 'react'
import Modal from 'react-modal'
import styled, { css } from 'styled-components'

import { inviteVolunteer, getMinistryHeadsAndDeputy } from 'libs/apis/ministry'
import alert from 'services/alert'
import Loading from 'components/commons/Loading'
import { Button, Span } from 'components/smallGroup/Styles'
import DropdownAsync from 'components/commons/DropdownAsync'

const ButtonFilter = styled.div`
	background-color: #fff;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1rem;
	font-weight: bold;
	margin-right: 20px;
	border-color: #fff;
	border-width: 2px;
	cursor: pointer;
	z-index: 10;
	:focus {
		outline: none;
	}
	${(props) =>
		props.empty &&
		css`
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		`}
`

function InviteVolunteer({ handleMinistryDetail, volunteerRoles, allVolunteer, ministryid, loadingVolunteer }) {
	const [inputVolunteer, setInputVolunteer] = useState('')
	const [selectVolunteer, setSelectVolunteer] = useState(null)

	const [modalOpen, setModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const customStyles = {
		content: {
			top: '30%',
			left: '30%',
			right: '0px',
			bottom: '0px',
			width: '550px',
			padding: '0px',
			margin: '0px',
			height: '300px', // <-- This sets the height
			overlfow: 'scroll', // <-- This tells the modal to scrol
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	const loadDeputy = async () => {
		if (inputVolunteer.length >= 3) {
			const res = await getMinistryHeadsAndDeputy(inputVolunteer)
			const data = res.data.data.user

			if (res.data.api_status) {
				const options = data.map((item) => ({
					value: item.id,
					label: item.fullname,
				}))
				return options
			}
		}
	}

	function handleTogle() {
		setModalOpen(!modalOpen)
	}

	const handleInputVolunteer = (value) => {
		setInputVolunteer(value)
	}

	const handleSelectVolunteer = (e) => {
		setSelectVolunteer(e)
	}

	const handleInvite = async () => {
		setLoading(true)
		try {
			let formdata = new FormData()
			formdata.append('userId', selectVolunteer.value)
			formdata.append('ministryId', ministryid)
			formdata.append('action', 'Invite')

			const res = await inviteVolunteer(formdata)
			if (res.data.data) {
				alert.succeed('Succeed')
				handleMinistryDetail()
				setModalOpen(false)
				setLoading(false)
			} else {
				alert.failed('failed', res.data.message)
				setModalOpen(false)
				setLoading(false)
			}
		} catch (error) {
			setLoading(false)
			alert.failed('failed')
		}
	}

	return (
		<div>
			<ButtonFilter onClick={handleTogle} type="tertiary">
				Invite Volunteer
			</ButtonFilter>

			<Modal isOpen={modalOpen} style={customStyles} contentLabel="Invite Volunteer" appElement={document.getElementById('root')}>
				<div className="flex justify-between bg-white h-10">
					<div className="flex justify-center mt-2 text-primary pl-2 text-md font-bold active:bg-red-700">Invite Volunteer</div>
					<div className="flex justify-center mt-1 text-primary pr-2 cursor-pointer text-xl font-bold" onClick={handleTogle}>
						X
					</div>
				</div>
				<div className="bg-gray-200 p-4 pb-4 w-full" style={{ paddingTop: '100px' }}>
					<DropdownAsync
						label="VOLUNTEER NAME"
						value={selectVolunteer}
						loadOptions={loadDeputy}
						defaultOptions={allVolunteer}
						handleInputChange={handleInputVolunteer}
						onChange={handleSelectVolunteer}
						isMulti={false}
					/>
				</div>
				<div className="flex bg-gray-200" style={{ paddingTop: '110px' }}>
					<Button clear>{loading ? <Loading secondary /> : <Span>Cancel</Span>}</Button>
					<Button onClick={handleInvite}>{loading ? <Loading secondary /> : <Span>Invite</Span>}</Button>
				</div>
			</Modal>
		</div>
	)
}

export default InviteVolunteer

// function Input({ name, value, label, handleChange, placeholder }) {
// 	return (
// 		<div className="mt-2 mb-2">
// 			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>

// 			<div className="flex">
// 				<input name={name} className="rounded-lg border-2 border-gray-200 w-full py-2 text-left pl-4 mb-2" placeholder={placeholder} value={value} onChange={handleChange} />
// 			</div>
// 		</div>
// 	)
// }

// function Volunteers({ user_id, profile_photo, fullname, handleRemove, handleInvite }) {
// 	return (
// 		<div>
// 			<div key={user_id}>
// 				<div className="flex flex-row justify-arround mx-5 hover:bg-gray-200 rounded-lg cursor-pointer" onClick={() => handleInvite(user_id)}>
// 					<div className="flex flex-row">
// 						{/* <img src={profile_photo === null ? photoplaceholder : profile_photo} className="rounded-full flex items-center justify-center w-12 h-12" alt="volunteer" /> */}
// 						<p className="text-gray-400 font-medium ml-4 pt-3">{fullname}</p>
// 					</div>

// 					{/* <div className="fas fa-times ml-auto mt-5 pr-5" onClick={() => handleRemove(user_id)} /> */}
// 				</div>
// 				<hr className="border-2 border-gray-200 mt-5 ml-5 mr-5" />
// 			</div>
// 		</div>
// 	)
// }
