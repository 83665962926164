import React, { useState } from 'react'
import Modal from 'react-modal'
import alert from 'services/alert'
import { renameFile, renameFolder } from 'libs/apis/file'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import { withRouter } from 'react-router-dom'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '320px',
		width: '550px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.3)',
	},
}

function FileViewEdit(props) {
	const [fileName, setFileName] = useState(props.fileName)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { id, handleLoadData, documenttype_id, fromInsideFolder } = props

	const handleTogleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleChangeFileName = (e) => {
		setFileName(e.target.value)
	}

	const handleSubmit = async () => {
		try {
			setIsLoading(true)
			const type = documenttype_id ? 'Document' : 'Folder'
			if (type === 'Folder') {
				const res = await renameFolder('Rename', type, fileName, id)
				if (res.data.api_status) {
					alert.succeed('Success', '')
					setIsLoading(false)
					setModalIsOpen(false)
					handleLoadData()
				} else {
					alert.failed('Error', 'Cannot update this file at this moment')
					setIsLoading(false)
					setModalIsOpen(false)
				}
			} else {
				const res = await renameFile('Rename', type, fileName, id)
				if (res.data.api_status) {
					alert.succeed('Success', '')
					setIsLoading(false)
					setModalIsOpen(false)
					handleLoadData()
				} else {
					alert.failed('Error', 'Cannot update this file at this moment')
					setIsLoading(false)
					setModalIsOpen(false)
				}
			}
		} catch (error) {
			setIsLoading(false)
			setModalIsOpen(false)
		}
	}

	return (
		<div>
			<button className="focus:outline-none" onClick={handleTogleModal}>
				Rename
			</button>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="File Edit Modal" ariaHideApp={false}>
				<div className="font-bold text-sm mb-2">RENAME FILE / FOLDER</div>
				<hr className="border-2 border-blueSecondary" />
				<div className="flex mt-3 ml-4">
					<div className="fa fa-file mt-1 text-gray-100" />
					<input className="ml-5 text-black w-4/5" type="text" name="file" placeholder={fileName} value={fileName} disabled={true} />
				</div>

				<hr className="border-2 border-blueSecondary mt-3" />

				<div className="flex-col flex mt-3">
					<div className="font-bold text-sm mb-2">NEW NAME</div>
					<input className="py-2 pl-3 border-secondary border-2 rounded-lg" type="text" name="fileName" onChange={handleChangeFileName} />
				</div>

				<div className="flex justify-center mt-4">
					<Button isLoading={isLoading} type="cancel" handleClick={handleTogleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" style="ml-6" handleClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}

export default withRouter(FileViewEdit)
