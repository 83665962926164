import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as CampaignIcon } from 'assets/icons/campaign.svg'

const navigation = ['/campaign/create', '/campaign', '/pushnotif']

class CampaignMenu extends Component {
	state = { isOpenSub: false }

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = navigation.filter((menu) => menu === path)

		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state
		const menus = navigation.filter((menu) => menu === path)

		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkCreate = `${
			path === '/campaign/create' ? 'text-primary' : 'text-grey-500'
		}`
		const styleListEmail = `${path === '/campaign/list-email' ? 'text-primary' : 'text-grey-500'}`
		const styleListPush = `${path === '/campaign/list-pushnotif' ? 'text-primary' : 'text-grey-500'}`
		return (
			<div className="mb-6">
				<div className="flex cursor-pointer">
					<div className="mr-4">
						<CampaignIcon className={styleIcon} />
					</div>
					<div className="font-bold text-grey-500" onClick={this.handleOpenSub}>
						Campaign
					</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/campaign/create" className={styleLinkCreate}>
									Create Campaign
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/campaign/list-email" className={styleListEmail}>
									List of Emails
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/campaign/list-pushnotif" className={styleListPush}>
									List of Push Notifications
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default CampaignMenu
