import { withFormik } from 'formik'
import { CreateClassValidationSchema } from './ClassValidationSchema'
import CreateClassForm from 'screens/courses/CreateClassForm'
import { createClass } from 'libs/apis/courses'
import { failedMessage } from 'libs/utils/confirmation'
import alert from 'services/alert'

const CreateClassFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		classTitle: '',
		codeClass: '',
		classDescription: '',
		classStartDate: '',
		classEndDate: '',
		classDay: '',
		classStartTime: '',
		classEndTime: '',
		classVenue: '',
		classVenueName: '',
		classVenueAddress: '',
		classCheckRegis: true,
		classStartRegis: '',
		classEndRegis: '',
		classParticipantQuota: '',
		startDateShare: '',
		endDateShare: '',
		isEndDateShare: true,
		classSpeaker: '',
		classMinSession: '',
		classCampaigns: '',
		isClassPayment: false,
		classPaymentValue: '',
		classPriceAll: '',
		classPaymentPerRole: false,
		classParticipants: '',
		classAditionnalInformation: '',
		classType: '',
		dateList: [],
		priceList: [],
		classVolunteers: [],
		allRoles: props.allRolesFormik,
		ishighlights: false,
		isDraft: false,
		campaignByMail: false,
		campaignByNotif: false,
		isReqCheckIn: false,
		isCheckForAll: false
	}),
	validationSchema: CreateClassValidationSchema,
	async handleSubmit(values, { props }) {
		try {
			let formdata = new FormData()

			let a = values.classVolunteers.map((val) => val.value).join()

			formdata.append('title', values.classTitle)
			formdata.append('programCodeId', values.codeClass.value)
			formdata.append('description', values.classDescription)
			formdata.append('programStartAt', values.classStartDate)
			formdata.append('programEndAt', values.classEndDate)
			formdata.append('registerStartAt', values.classStartRegis)
			formdata.append('registerEndAt', values.classEndRegis)
			formdata.append('minimumSession', values.classMinSession)
			formdata.append('startSharingAt', values.startDateShare)
			formdata.append('endSharingAt', values.endDateShare)
			formdata.append('additionalInformation', values.classAditionnalInformation)
			formdata.append('maxSeating', values.classParticipantQuota)
			formdata.append('speaker', values.classSpeaker)
			formdata.append('programTypeId', values.classType)
			formdata.append('dateList', JSON.stringify(values.dateList))
			formdata.append('volunteerList', a)

			values.isCheckForAll ? formdata.append('isCheckForAll', 1) : formdata.append('isCheckForAll', 0)
			values.isReqCheckIn ? formdata.append('reqCheckIn', 1) : formdata.append('reqCheckIn', 0)
			values.ishighlights ? formdata.append('isHighlight', 1) : formdata.append('isHighlight', 0)
			values.isDraft ? formdata.append('status', 'Draft') : formdata.append('status', 'Published')
			values.campaignByMail ? formdata.append('campaignByMail', 1) : formdata.append('campaignByMail', 0)
			values.campaignByNotif ? formdata.append('campaignByNotification', 1) : formdata.append('campaignByNotif', 0)

			if (!values.priceList) {
				let data = {}
				let toArrayData = []

				data.smallgroupmemberrole_id = null
				data.role_id = null
				data.ministrymemberrole_id = null
				data.price = values.classPriceAll.length ? values.classPriceAll : 0

				toArrayData.push(data)
				formdata.append('priceList', JSON.stringify(toArrayData))
			} else {
				const temp = values.priceList.map((item) => {
					const data = {}
					
					if (item.id.includes('ministrymemberrole_id')) {
						let itemId = item.id.split('.')

						data.ministrymemberrole_id = itemId[1]
						data.role_id = null
						data.smallgroupmemberrole_id = null
						data.price = values.classPriceAll.length ? values.classPriceAll : (item.price.length ? item.price : 0)
					}
					if (item.id.includes('role_id')) {
						let itemId = item.id.split('.')

						data.ministrymemberrole_id = null
						data.role_id = itemId[1]
						data.smallgroupmemberrole_id = null
						data.price = values.classPriceAll.length ? values.classPriceAll : (item.price.length ? item.price : 0)
					}
					if (item.id.includes('smallgroupmemberrole_id')) {
						let itemId = item.id.split('.')

						data.ministrymemberrole_id = null
						data.role_id = null
						data.smallgroupmemberrole_id = itemId[1]
						data.price = values.classPriceAll.length ? values.classPriceAll : (item.price.length ? item.price : 0)
					}
					return data
				})
				formdata.append('priceList', JSON.stringify(temp))
			}

			if (values.classVenue === 'OTHERS') {
				formdata.append('campusRoomId', '')
				formdata.append('location', values.classVenueName)
				formdata.append('locationAddress', values.classVenueAddress)
			} else {
				formdata.append('campusRoomId', values.classVenue)
				formdata.append('location', '')
				formdata.append('locationAddress', '')
			}

			// for (var pair of formdata.entries()) {
			// 	console.log(pair[0] + ', ' + pair[1]);
			// }

			try {
				const res = await createClass(formdata)
				if (res.data.api_status) {
					alert.succeed('Success')
					props.history.push('/classes')
				} else {
					failedMessage(res.data.message)
				}
			} catch (error) {
				console.log(error)
				alert.failed('Failed', 'Cannot edit this class at this moment')
			}
		} catch (error) {
			console.log(error)
		}
	},
})(CreateClassForm)

export default CreateClassFormik
