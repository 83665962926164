export const constants = {
	campaigns: [
		{ id: 0, label: 'Choose' },
		{ id: 1, label: 'Create campaign by email' },
		{ id: 2, label: 'Create campaign notification' },
		{ id: 3, label: 'Create both campaigns' },
	],
	genders: [
		{ value: 'A', gender: 'All', label: 'All' },
		{ value: 'M', gender: 'Male', label: 'Male' },
		{ value: 'F', gender: 'Female', label: 'Female' },
	],
	maritalStatus: [
		{ id: 1, label: 'Single' },
		{ id: 2, label: 'Married' },
		{ id: 3, label: 'Divorced' },
		{ id: 4, label: 'Widowed' },
	],
	days: [
		{ id: 0, title: 'Sunday' },
		{ id: 1, title: 'Monday' },
		{ id: 2, title: 'Tuesday' },
		{ id: 3, title: 'Wednesday' },
		{ id: 4, title: 'Thursday' },
		{ id: 5, title: 'Friday' },
		{ id: 6, title: 'Saturday' },
	],
	categories: [
		{ id: 2, value: 'new', label: 'New Category' },
		{ id: 3, value: 'other', label: 'Other' },
	],
	options1: [
		{
			value: 'Published',
			show_as: 'Published',
		},
		{
			value: 'Draft',
			show_as: 'Draft',
		},
		{
			value: 'Archived',
			show_as: 'Archived',
		},
	],
	options2: [
		{ id: 'Applied', value: 'Applied', title: 'Applied' },
		{ id: 'Approved', value: 'Approved', title: 'Approved' },
		{ id: 'Rejected', value: 'Rejected', title: 'Rejected' },
	],

	optionsListEventRegistrant: [
		{ id: 'allitem', value: 'allitem', title: 'All Items' },
		{ id: 'fullname', value: 'fullname', title: 'Name' },
		{ id: 'smallgroups', value: 'smallgroups', title: 'Group Name' },
		{ id: 'smallgroupmemberroles', value: 'smallgroupmemberroles', title: 'Role' },
		{ id: 'invoiceid', value: 'invoiceid', title: 'Invoice ID' },
		{ id: 'paymentStatus', value: 'paymentStatus', title: 'Payment Status' },
	],
	statuses: [
		{
			value: 'Full',
			name: 'Full',
		},
		{
			value: 'Priority',
			name: 'Priority',
		},
		{
			value: 'Open',
			name: 'Open',
		},
		{
			value: 'Closed',
			name: 'Closed',
		},
	],
	medias: [
		{ id: '1', value: 'Video', label: 'Article & Video' },
		{ id: '2', value: 'Image', label: 'Article & Image' },
	],
}

// export const campaignEmailGender = [
// 	{value: 'ALL', label: 'Both (MALE & FEMALE)'},
// 	{value: 'F', label: 'FEMALE'},
// 	{value: 'M', label: 'MALE'},
// ];

export const constantCampaignGenderOption = [
	{
		id: 'Both',
		label: 'Both (MALE & FEMALE)',
		value: 'Both',
	},
	{
		id: 'Female',
		label: 'FEMALE',
		value: 'Female',
	},
	{
		id: 'Male',
		label: 'MALE',
		value: 'Male',
	},
]

export const constantCampaignOption = [
	{
		id: 'cpg0',
		label: 'Create campaign by email',
		value: 'cpg0',
	},
	{
		id: 'cpg1',
		label: 'Create campaign notification',
		value: 'cpg1',
	},
	{
		id: 'cpg2',
		label: 'Create both campaigns',
		value: 'cpg2',
	},
]

export const dummyRecipients = [
	{ user_id: 1, name: 'test indra2', email: 'testmailinator23@mailinator.com' },
	{ user_id: 2, name: 'test indra3', email: 'indra.gunawan@idstar.co.id' },
]
