import React, {useState} from 'react'
import Loading from "../../components/commons/Loading";
import Button from "../../components/commons/Button";
import {useHistory} from 'react-router-dom';

const TestEmailCampaign = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleEditClick = () => {
        history.push(`/campaign/edit-email/${props.campaignId}`)
    };

    return (
        <div className="bg-white shadow-md p-6">
            <div className="text-md font-bold ml-2">Test Email</div>
            <input
                className="appearance-none border-2 rounded-lg p-3 mt-2 w-full leading-tight focus:outline-none"
                name="notification-mockup-input"
                placeholder="Type email here"
                onChange={handleEmailChange}
                value={null}
            />
            <div className="mt-3 flex flex-row justify-around items-center">
                <Button handleClick={() => props.handleSendButtonClick(props.campaignId, email)} type="secondary"
                        disabled={props.isSendLoading}>
                    {props.isSendLoading ? <Loading primary/> : 'SEND'}
                </Button>
                <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer"
                   href="https://www.mail-tester.com/">
                    Check Spam Score
                </a>
            </div>

            <div className="flex justify-end mt-20">
                <Button handleClick={handleEditClick} type="secondary" disabled={isLoading}>
                    {isLoading ? <Loading primary/> : 'EDIT'}
                </Button>
                <Button handleClick={() => {
                }} type="primary" size="ml-4"
                        disabled={isLoading}>
                    {isLoading ? <Loading secondary/> : 'PUBLISH'}
                </Button>
            </div>
        </div>
    )
};

export default TestEmailCampaign
