import http from './httpServices'
import axios from 'axios'

const url = `https://dev-jpcc-mobile-api.skyporter.id/api`

export const loginUser = async (data) => {
	return await axios({
		method: 'post',
		url: `${url}/auth/login`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data,
	})
}

export const forgotPassword = async (email) => {
	return await http.post('/password/forget', { email })
}

export const resetPassword = async (token, new_password) => {
	return await http.post('/password/reset', { token, new_password })
}
