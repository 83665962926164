// ----------------------------------------------- Form Event

import React from "react";

export const dummyVenueOption = [
    {
        id: '',
        label: ''
    },
    {
        id: 'Venue Option 1',
        label: 'Venue Option 1',
    },
    {
        id: 'Venue Option 2',
        label: 'Venue Option 2',
    },
    {
        id: 'Venue Option 3',
        label: 'Venue Option 3',
    },
];

export const dummyRoleOption = [
    {
        id: '',
        label: ''
    },
    {
        id: 'DATE Facilitator',
        label: 'DATE Facilitator',
    },
    {
        id: 'Head DATE Facilitator',
        label: 'Head DATE Facilitator',
    },
    {
        id: 'Pastor',
        label: 'Pastor'
    },
    {
        id: 'Smallgroup Admin',
        label: 'Smallgroup Admin'
    },
    {
        id: 'Key Volunteer',
        label: 'Key Volunteer'
    },
    {
        id: 'Volunteer',
        label: 'Volunteer'
    }
];

export const dummyCampaignOption = [
    {
        id: 'cpg0',
        label: 'Create campaign by email',
        value: 'cpg0'
    },
    {
        id: 'cpg1',
        label: 'Create campaign notification',
        value: 'cpg1'
    },
    {
        id: 'cpg2',
        label: 'Create both campaigns',
        value: 'cpg2'
    }
];

export const dummyVolunteers = [
    {id: 2, label: "Rodzzzzzzz"},
    {id: 3, label: "Aquixxxxxxxx Kurnxxxxxxxx"},
    {id: 4, label: "Enrixxxxxxxx Samuxxxxxxxx"},
    {id: 6, label: "Dennxxxxxxxx Sumaxxxxxxxx"},
    {id: 7, label: "Mervxxxxxxxx Sumaxxxxxxxx"},
    {id: 8, label: "Yohaxxxxxxxx Fernxxxxxxxx"},
    {id: 10, label: "Sisixxxxxxxx Oeixxxxxxxxx"},
    {id: 11, label: "Michxxxxxxxx Susaxxxxxxxx"},
    {id: 12, label: "Erdwxxxxxxxx Sujoxxxxxxxx"},
    {id: 13, label: "Lydixxxxxxxx Sujoxxxxxxxx"},
    {id: 14, label: "Willxxxxxxxx Palixxxxxxxx"},
    {id: 15, label: "Fennxxxxxxxx Palixxxxxxxx"},
    {id: 16, label: "Srixxxxxxxxx Hartxxxxxxxx"},
    {id: 17, label: "Yohaxxxxxxxx Donaxxxxxxxx"},
    {id: 18, label: "Sahaxxxxxxxx Paruxxxxxxxx"},
    {id: 19, label: "Marixxxxxxxx Saraxxxxxxxx"},
    {id: 20, label: "IANxxxxxxxxx Hendxxxxxxxx"},
    {id: 22, label: "Frisxxxxxxxx Taruxxxxxxxx"},
    {id: 24, label: "Lisaxxxxxxxx Limaxxxxxxxx"},
    {id: 25, label: "Aryxxxxxxxxx Mardxxxxxxxx"},
    {id: 26, label: "Tamixxxxxxxx Handxxxxxxxx"},
    {id: 27, label: "Shinxxxxxxxx P. Exxxxxxxx"},
    {id: 28, label: "Irwaxxxxxxxx Rosaxxxxxxxx"},
    {id: 29, label: "Lioexxxxxxxx Melixxxxxxxx"},
    {id: 30, label: "Srixxxxxxxxx Minaxxxxxxxx"},
    {id: 31, label: "Samyxxxxxxxx Sonaxxxxxxxx"},
    {id: 33, label: "Gilbxxxxxxxx Albexxxxxxxx"},
    {id: 35, label: "Agusxxxxxxxx Dewixxxxxxxx"},
    {id: 36, label: "Olivxxxxxxxx Yanuxxxxxxxx"},
    {id: 37, label: "Donixxxxxxxx Prilxxxxxxxx"},
    {id: 38, label: "Miraxxxxxxxx Gunaxxxxxxxx"},
    {id: 48, label: "Shelxxxxxxxx Hendxxxxxxxx"},
    {id: 50, label: "Johaxxxxxxxx Thelxxxxxxxx"},
    {id: 51, label: "Rijaxxxxxxxx Felixxxxxxxx"},
    {id: 53, label: "Herrxxxxxxxx Andaxxxxxxxx"},
    {id: 57, label: "Marnxxxxxxxx Suntxxxxxxxx"},
    {id: 58, label: "Randxxxxxxxx Manoxxxxxxxx"},
    {id: 60, label: "Hellxxxxxxxx Siskxxxxxxxx"},
    {id: 61, label: "Novixxxxxxxx Tjewxxxxxxxx"},
    {id: 62, label: "Budixxxxxxxx Suryxxxxxxxx"},
    {id: 63, label: "Feryxxxxxxxx Hotmxxxxxxxx"},
    {id: 66, label: "Perixxxxxxxx Arirxxxxxxxx"},
    {id: 67, label: "Andrxxxxxxxx Hermxxxxxxxx"},
    {id: 68, label: "Iwanxxxxxxxx Sutaxxxxxxxx"},
    {id: 69, label: "Rachxxxxxxxx Pataxxxxxxxx"},
    {id: 70, label: "Doddxxxxxxxx Ekapxxxxxxxx"},
    {id: 71, label: "Ganixxxxxxxx Djuaxxxxxxxx"},
    {id: 72, label: "Tjoaxxxxxxxx Kianxxxxxxxx"},
    {id: 74, label: "Willxxxxxxxx Laohxxxxxxxx"},
    {id: 75, label: "Chrixxxxxxxx Shenxxxxxxxx"},
    {id: 76, label: "Charxxxxxxxx Surjxxxxxxxx"},
    {id: 77, label: "Fennxxxxxxxx Lis xxxxxxxx"},
    {id: 79, label: "Zadaxxxxxxxx Ventxxxxxxxx"},
    {id: 80, label: "Anitxxxxxxxx Thelxxxxxxxx"},
    {id: 81, label: "Lennxxxxxxxx xxxxxxxxxxxx"},
    {id: 82, label: "Felixxxxxxxx Pataxxxxxxxx"},
    {id: 83, label: "Nivixxxxxxxx Pingxxxxxxxx"},
    {id: 84, label: "Ekaxxxxxxxxx Djuaxxxxxxxx"},
    {id: 85, label: "Ritaxxxxxxxx Tjoaxxxxxxxx"},
    {id: 86, label: "Nindxxxxxxxx Laohxxxxxxxx"},
    {id: 88, label: "Riklxxxxxxxx Bachxxxxxxxx"},
    {id: 89, label: "Cherxxxxxxxx Salexxxxxxxx"},
    {id: 90, label: "Stevxxxxxxxx Tabaxxxxxxxx"},
    {id: 91, label: "Franxxxxxxxx Sutexxxxxxxx"},
    {id: 92, label: "Jimmxxxxxxxx Kartxxxxxxxx"},
    {id: 93, label: "Irawxxxxxxxx Iskaxxxxxxxx"},
    {id: 94, label: "Intaxxxxxxxx Nurcxxxxxxxx"},
    {id: 95, label: "Dedixxxxxxxx Hermxxxxxxxx"},
    {id: 96, label: "Yanxxxxxxxxx Hendxxxxxxxx"},
    {id: 97, label: "Febyxxxxxxxx Veroxxxxxxxx"},
    {id: 98, label: "Auguxxxxxxxx Auyoxxxxxxxx"},
    {id: 101, label: "Novixxxxxxxx  J. xxxxxxxx"},
    {id: 102, label: "Adolxxxxxxxx jesyxxxxxxxx"},
    {id: 103, label: "Rentxxxxxxxx Ullyxxxxxxxx"},
    {id: 104, label: "Julixxxxxxxx Jadixxxxxxxx"},
    {id: 105, label: "Suwaxxxxxxxx xxxxxxxxxxxx"},
    {id: 106, label: "Ririxxxxxxxx Theoxxxxxxxx"},
    {id: 107, label: "Morixxxxxxxx Runtxxxxxxxx"},
    {id: 108, label: "Frisxxxxxxxx Angexxxxxxxx"},
    {id: 110, label: "Srixxxxxxxxx Santxxxxxxxx"},
    {id: 112, label: "Seanxxxxxxxx Ivenxxxxxxxx"},
    {id: 113, label: "Monixxxxxxxx Angexxxxxxxx"},
    {id: 114, label: "Jonyxxxxxxxx Kawixxxxxxxx"},
    {id: 116, label: "Ekyxxxxxxxxx Krisxxxxxxxx"},
    {id: 118, label: "Junixxxxxxxx Tarixxxxxxxx"},
    {id: 119, label: "Andyxxxxxxxx Hymaxxxxxxxx"},
    {id: 120, label: "Richxxxxxxxx ML Sxxxxxxxx"},
    {id: 121, label: "Ryanxxxxxxxx Antoxxxxxxxx"},
    {id: 123, label: "Ribkxxxxxxxx Lucmxxxxxxxx"},
    {id: 124, label: "Andrxxxxxxxx Cahyxxxxxxxx"},
    {id: 125, label: "Therxxxxxxxx Anasxxxxxxxx"},
    {id: 127, label: "Agatxxxxxxxx Hidaxxxxxxxx"},
    {id: 128, label: "Ivanxxxxxxxx Nyomxxxxxxxx"},
    {id: 130, label: "Aprixxxxxxxx Mokaxxxxxxxx"},
    {id: 131, label: "baraxxxxxxxx kusuxxxxxxxx"},
    {id: 133, label: "Kalexxxxxxxx Lucmxxxxxxxx"},
    {id: 137, label: "Irenxxxxxxxx Ommixxxxxxxx"},
    {id: 141, label: "A.xxxxxxxxxx Chrixxxxxxxx"},
    {id: 143, label: "Henrxxxxxxxx Gersxxxxxxxx"},
    {id: 144, label: "San xxxxxxxx -xxxxxxxxxxx"},
];

// ----------------------------------------------- Form Event Code

export const dummyMaritalStatus = [
    'Single', 'Maried', 'Widowed', 'Others'
];

export const dummyRoles = [
    'Role 1', 'Role 2', 'Role 3', 'Role 4', 'Role 5', 'Role 6'
];

export const dummyMinistries = [
    'Ministry 1', 'Ministry 2', 'Ministry 3', 'Ministry 4', 'Ministry 5', 'Ministry 6'
];

export const dummyTypes = [
    {
        id: 'type0',
        label: 'Single Couple'
    },
    {
        id: 'type1',
        label: 'Family Couple'
    },
    {
        id: 'type2',
        label: 'Family with Children'
    }
];


// ----------------------------------------------- List Event

export const dummyListEvent = [
    {
        id: 1,
        status: 'Open',
        title: 'Title1',
        startDate: new Date(),
        endDate: new Date(),
        createdOn: new Date(),
        createdBy: 'Janto',
        lastUpdated: new Date(),
        isHighlight: true
    },
    {
        id: 2,
        status: 'Archived',
        title: 'Title2',
        startDate: new Date(),
        endDate: new Date(),
        createdOn: new Date(),
        createdBy: 'Janto2',
        lastUpdated: new Date(),
        isHighlight: true
    },
    {
        id: 3,
        status: 'Draft',
        title: 'Title3',
        startDate: new Date(),
        endDate: new Date(),
        createdOn: new Date(),
        createdBy: 'Janto3',
        lastUpdated: new Date(),
        isHighlight: false
    }
];

// ----------------------------------------------- List Event Code

export const dummyListEventCode = [
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
        status: 'Archived',
        isHighlight: true
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
        status: 'Archived',
        isHighlight: true
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
        status: 'Draft',
        isHighlight: false
    },
    {
        code: 'EV123456',
        name: 'Event Code A',
        createdOn: '07 Februari 2020',
        lastUpdated: '25 Desember 2020',
        status: 'Archived',
        isHighlight: false
    },
    {
        code: 'EV654321',
        name: 'Event Code B',
        createdOn: '3 Januari 2019',
        lastUpdated: '1 Desember 2020',
        status: 'Draft',
        isHighlight: true
    }
];

// ----------------------------------------------- List Event / Registrant

export const dummyEventRegistrantInfo = {
    courseName: 'Dummy Course Name',
    startDate: new Date(),
    endDate: new Date(),
    venueName: 'Dummy Venue Name',
    venueAddress: 'Dummy Venue Address'
};

export const dummyEventRegistrantStatus = {
    applied: 2,
    approved: 3,
    quota: 20,
    pending: 5,
    rejected: 6,
    passed: 7,
    failed: 10
};

export const dummyListEventRegistrant = [
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 1',
        role: 'Admin',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Paid',
        attendance: false,
        status: 'Approved'
    },
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 2',
        role: 'Member',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Declined',
        attendance: true,
        status: 'Applied'
    },
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 3',
        role: 'Admin',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Pending',
        attendance: false,
        status: 'Pending'
    },
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 3',
        role: 'Admin',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Pending',
        attendance: false,
        status: 'Rejected'
    },
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 3',
        role: 'Admin',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Pending',
        attendance: false,
        status: 'Failed'
    },
    {
        fullName: 'Naruto Uzumaki',
        groupName: 'Small Group 3',
        role: 'Admin',
        appliedOn: new Date(),
        paymentValidUntil: new Date(),
        invoiceId: 'IVC12345',
        paymentStatus: 'Pending',
        attendance: false,
        status: 'Passed'
    }
];

export const dummyEventAttendance = [
    {
        name: 'Dummy Name 1',
        groupName: 'Dummy Group Name 1',
        attendanceStatus: [true, true, false]
    },
    {
        name: 'Dummy Name 2',
        groupName: 'Dummy Group Name 2',
        attendanceStatus: [true, false, false]
    },
    {
        name: 'Dummy Name 1=3',
        groupName: 'Dummy Group Name 3',
        attendanceStatus: [true, true, false, true, true]
    }
];

export const dummyQRCodes = [
    {
        code: 'CLASSES-20-M9raAmtFAQ',
        startTime: new Date(),
        endTime: new Date(),
        title: 'Title 1'
    },
    {
        code: 'CLASSES-20-M9raAmtFAQ',
        startTime: new Date(),
        endTime: new Date(),
        title: 'Title 2'
    },
    {
        code: 'CLASSES-20-M9raAmtFAQ',
        startTime: new Date(),
        endTime: new Date(),
        title: 'Title 3'
    },
    {
        code: 'CLASSES-20-M9raAmtFAQ',
        startTime: new Date(),
        endTime: new Date(),
        title: 'Title 4'
    }
];

// ----------------------------------------------- Campaign Form

export const dummyBasicRecipientsMethod = [
    'Pastor', 'Head DF', 'DATE Facilitator', 'DATE Leader', 'DATE Core Team', 'DATE Member',
    'Partner', 'Group Head', 'Deputy Head', 'Ministry Head', 'Ministry Head Partner', 'Key Volunteer',
    'Volunteer', 'Trainee', 'Intern', 'Yayasan', 'Business Unit', 'Staff'
];
