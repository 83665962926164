import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const PageBreadcrumbs = ({ icon: Icon, title, breads }) => (
	<div className="md:flex md:justify-between mb-6">
		<div className="font-bold text-xl">{title}</div>
		<div className="flex items-center">
			<Icon className="w-3 mr-2" />
			<div className="text-sm">
				{breads.map((bread) => (
					<Navigation key={bread.url} url={bread.url} title={bread.title} />
				))}
			</div>
		</div>
	</div>
)

const Navigation = ({ url, title }) => (
	<Fragment> / {url ? <Link to={url}>{title}</Link> : `${title}`}</Fragment>
)

export default PageBreadcrumbs
