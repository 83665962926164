import React, {useCallback, useEffect, useState} from 'react';
import {getCampaignDetail, getRecipientsBasic, getRecipientsQuery} from "../../libs/apis/emailCampaign";
import {
    cancelConfirmation,
    failedMessage,
    publishConfirmation,
    saveDraftConfirmation
} from "../../libs/utils/confirmation";
import {constantCampaignGenderOption} from "../../constants/constant";
import {getRequirements, getRoleList} from "../../libs/apis/events";
import TextArea from "../../components/commons/TextArea";
import moment from "moment";
import InputRadioButton from "../../components/commons/InputRadioButton";
import {Field} from "formik";
import ImageUploadCampaign from "../../components/commons/ImageUploadCampaign";
import ImageInput from "../../components/commons/ImageInput";
import InputDate from "../../components/commons/InputDate";
import CampaignEmailPreview from "../../components/formik/CampaignEmailPreview";
import InputCheckBox from "../../components/commons/form/InputCheckBox";
import Label from "../../components/commons/Label";
import Loading from "../../components/commons/Loading";
import SelectOptions from "../../components/commons/SelectOptions";
import Button from "../../components/commons/Button";
import TextInput from "../../components/commons/TextInput";
import DescriptionBox from "../../components/commons/DescriptionBox";

const EditEmailCampaignForm = (props) => {
    const {values, isSubmitting, setFieldValue, handleSubmit, errors} = props;
    const {
        subject,
        description,
        emailPreviewContent,
        attachImageCheck,
        attachedImages,
        attachFileCheck,
        attachedFile,
        attachedFileName,
        recipientMethodBasicCheck,
        basicRecipientMethodList,
        nonSmallGroupCheck,
        query,
        recipientsGender,
        recipientsMaritalStatus,
        schedule,
        churchId,
        recipients
    } = values;

    const [roleOption, setRoleOption] = useState([]);
    const [listMaritalStatus, setListMaritalStatus] = useState([]);
    const [totalRecipient, setTotalRecipient] = useState(0);

    const [isFirstRequest, setIsFirstRequest] = useState(false);

    const [recipientMethodValid, setRecipientMethodValid] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isLoadingRoles, setIsLoadingRoles] = useState(false);
    const [isMaritalStatusLoading, setIsMaritalStatusLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isSubjectValid, setIsSubjectValid] = useState(undefined);
    const [isDescriptionValid, setIsDescriptionValid] = useState(undefined);
    const [isGenderValid, setIsGenderValid] = useState(undefined);
    const [isMaritalValid, setIsMaritalValid] = useState(undefined);
    // const [isScheduleValid, setIsScheduleValid] = useState(undefined);

    const campaignId = props.match.params.id;

    const [isLoadingDetail, setIsLoadingDetail] = useState(false);

    useEffect(() => {
        setFieldValue('campaignId', campaignId);
        fetchEmailCampaignDetail();
        // fetchEmailCampaignRecipients();
    }, []);

    const fetchEmailCampaignDetail = async () => {
        setIsLoadingDetail(true);
        try {
            let response = await getCampaignDetail(campaignId, 'Email');
            let emailData = response.data.data.campaign.data[0];
            console.log('response: ', response);
            setFieldValue('subject', emailData.title);
            setFieldValue('description', emailData.description);
            setFieldValue('emailPreviewContent', emailData.description);
            // setFieldValue('schedule', emailData.sendstart_at);
            setFieldValue('schedule', emailData.sendstart_at);
            console.log('emailData.sendstart_at: ', emailData.sendstart_at);
            setIsLoadingDetail(false);
        } catch (error) {
            setIsLoadingDetail(false);
            setErrorMessage(error.message);
        }
    };

    const fetchEmailCampaignRecipients = () => {
        const responseGender = 'Female';
        const responseMartialStatus = [3];
        const responseSgmRoles = [3];
        const responseMmRoles = [];
        const responseMinistryGroup = null;
        const responseUserRoles = [];
        const responseNsmRoles = false;
        // const responseQuery = '';
        const responseQuery = "SELECT id as user_id, fullname as name, email from users where gender= 'Female' and maritalstatus_id = 3";

        if (responseQuery === '') {
            let updatedBasicRecipients = [];

            for (const role of responseSgmRoles) {
                updatedBasicRecipients.push('smallgroupmemberrole_id.'.concat(role))
            }

            for (const role of responseUserRoles) {
                updatedBasicRecipients.push('userrole_id.'.concat(role))
            }

            for (const role of responseMmRoles) {
                updatedBasicRecipients.push('ministrymemberrole_id.'.concat(role))
            }

            setFieldValue('basicRecipientMethodList', updatedBasicRecipients);
            setFieldValue('nonSmallGroupCheck', responseNsmRoles);
            setFieldValue('recipientsGender', responseGender);
            setFieldValue('recipientsMaritalStatus', responseMartialStatus);

        } else {
            setFieldValue('recipientMethodBasicCheck', false);
            setFieldValue('query', responseQuery)
        }
    };

    useEffect(() => {
        console.log('schedule', schedule);
        const church = JSON.parse(localStorage.getItem('church'));
        const user = JSON.parse(localStorage.getItem('user'));
        setFieldValue('churchId', church.church_id);
        setFieldValue('documentGroupId', user.document.documentgroup_id);
        setFieldValue('documentTypeId', user.document.documenttype_id);
        fetchRoles();
        fetchMaritalStatus();
    }, []);

    useEffect(() => {
        fetchRecipients();
    }, [basicRecipientMethodList, recipientsGender, recipientsMaritalStatus, nonSmallGroupCheck, recipientMethodBasicCheck]);

    const fetchRecipients = async () => {
        // setIsFirstRequest(true);
        // if(isFirstRequest === true) {
            let updatedRecipientsGender = recipientsGender;
            if (recipientsGender === 'Both') {
                updatedRecipientsGender = null;
            }
            let smallgroupmemberrole_id = [];
            let userrole_id = [];
            let ministrymemberrole_id = [];
            if (basicRecipientMethodList.length > 0) {
                for (const recipient of basicRecipientMethodList) {
                    const splittedId = recipient.split('.');
                    if (recipient.includes('smallgroupmemberrole_id')) {
                        smallgroupmemberrole_id.push(splittedId[1])
                    } else if (recipient.includes('userrole_id')) {
                        userrole_id.push(splittedId[1])
                    } else if (recipient.includes('ministrymemberrole_id')) {
                        ministrymemberrole_id.push(splittedId[1])
                    }
                }
            }
            console.log('updatedRecipientsGender: ', updatedRecipientsGender);
            console.log('recipientsMaritalStatus: ', recipientsMaritalStatus);
            console.log('basicRecipientMethodList: ', basicRecipientMethodList);
            if ((updatedRecipientsGender !== '' || recipientsMaritalStatus !== '' || basicRecipientMethodList.length >= 0) && recipientMethodBasicCheck) {
                console.log('recipientsMaritalStatus: ', recipientsMaritalStatus);
                let updatedMaritalStatus = recipientsMaritalStatus;
                if (updatedMaritalStatus === 'all') {
                    updatedMaritalStatus = null;
                }
                try {
                    setIsLoadingFilter(true);
                    let responseTotal = await getRecipientsBasic(updatedRecipientsGender, updatedMaritalStatus ? updatedMaritalStatus.toString() : null,
                        smallgroupmemberrole_id.toString(), ministrymemberrole_id.toString(),
                        userrole_id.toString(), nonSmallGroupCheck, 'GetRecipients');
                    let totalRecipient = responseTotal.data.data.recipient;
                    console.log('responseTotal: ', responseTotal);
                    setTotalRecipient(totalRecipient);
                    setFieldValue('recipientParams',
                        {
                            gender: updatedRecipientsGender,
                            maritalStatus: updatedMaritalStatus ? updatedMaritalStatus.toString() : null,
                            smallGroupMemberRole: smallgroupmemberrole_id.toString(),
                            ministryMemberRole: ministrymemberrole_id.toString(),
                            userRole: userrole_id.toString(),
                            nonSmallGroup: nonSmallGroupCheck,
                        }
                    );
                    setIsLoadingFilter(false);
                } catch (error) {
                    setIsLoadingFilter(false);
                    await failedMessage(error.message);
                }
            } else if (query !== '') {
                try {
                    setIsLoadingFilter(true);
                    let responseTotal = await getRecipientsQuery(query, 'GetRecipients');
                    let totalRecipient = responseTotal.data.data.recipient;
                    setTotalRecipient(totalRecipient);
                    // setFieldValue('recipients', dummyRecipients);
                    setIsLoadingFilter(false);
                } catch (error) {
                    setTotalRecipient(0);
                    setIsLoadingFilter(false);
                    await failedMessage(error.message);
                }
            } else if (query === '' && !recipientMethodBasicCheck) {
                setTotalRecipient(0);
            }
        // }
    };

    const fetchQueryRecipients = async () => {
        try {
            setIsLoadingFilter(true);
            let responseTotal = await getRecipientsQuery(query, 'GetRecipients');
            let totalRecipient = responseTotal.data.data.recipient;
            setTotalRecipient(totalRecipient);
            setIsLoadingFilter(false);
        } catch (error) {
            setTotalRecipient(0);
            setIsLoadingFilter(false);
            await failedMessage(error.message);
        }
    };

    const [genderOption] = useState(constantCampaignGenderOption);

    const handleSubjectChange = (event) => {
        setFieldValue('subject', event.target.value)
    };

    const handleDescriptionChange = (value) => {
        const decodedHtml = value.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>');
        setFieldValue('description', value);
        const markdownPattern = /(?:__|[*#])|\[(.*?)\]\(.*\)/g;
        const markDown = decodedHtml.match(markdownPattern);
        if (markDown) {
            const marked = require('marked');
            const tokens = marked.lexer(markDown[0]);
            const html = marked.parser(tokens);
            const forUnformattedPattern = /<img.*?src="(.*?)"[^\>]+>/g;
            const forFormattedPattern = /<img.*?src="(.*?)"[^\>]+/g;
            const unformattedImgHtml = html.match(forUnformattedPattern);
            const formattedImgHtml = html.match(forFormattedPattern) + '/>';
            const markDownHtml = html.replace(unformattedImgHtml, formattedImgHtml);
            const blankAttributeAddedMarkDownHtml = markDownHtml.replaceAll('<a ', '<a target="_blank" ');
            const splittedString = decodedHtml.split(markDown[0]);
            const mergedString = splittedString[0].concat(blankAttributeAddedMarkDownHtml).concat(splittedString[1]);
            console.log('mergedString: ', mergedString);
            setFieldValue('emailPreviewContent', mergedString);
        } else {
            setFieldValue('emailPreviewContent', decodedHtml);
        }
    };

    const handleAttachImageCheck = () => {
        setFieldValue('attachImageCheck', !attachImageCheck);
    };

    const handleAttachFileCheck = () => {
        setFieldValue('attachFileCheck', !attachFileCheck);
    };

    useEffect(() => {
        const attachedFileName = attachedFile ? attachedFile.name || attachedFile.substring(attachedFile.lastIndexOf('/') + 1) : 'Click to upload your file here';
        setFieldValue('attachedFileName', attachedFileName)
    }, [attachedFile]);

    const handleAttachFileChange = (event) => {
        console.log('event: ', event.target.files);
        setFieldValue('attachedFile', event.target.files[0])
    };

    const handleRecipientMethodBasicCheck = () => {
        setFieldValue('recipientMethodBasicCheck', !recipientMethodBasicCheck);
    };

    const handleBasicRecipientMethodChange = (recipient) => {
        const cloneBasicRecipientMethodList = [...basicRecipientMethodList];
        const toDeleteIndex = cloneBasicRecipientMethodList.findIndex((itrRecipient) => itrRecipient === recipient);

        if (toDeleteIndex < 0) {
            cloneBasicRecipientMethodList.push(recipient);
            console.log('cloneBasicRecipientMethodList: ', cloneBasicRecipientMethodList);
            setFieldValue('basicRecipientMethodList', cloneBasicRecipientMethodList)
        } else {
            cloneBasicRecipientMethodList.splice(toDeleteIndex, 1);
            console.log('cloneBasicRecipientMethodList: ', cloneBasicRecipientMethodList);
            setFieldValue('basicRecipientMethodList', cloneBasicRecipientMethodList)
        }
    };

    const handleNonSmallGroupChange = () => {
        setFieldValue('nonSmallGroupCheck', !nonSmallGroupCheck);
    };

    const fetchRoles = async () => {
        setIsLoadingRoles(true);
        try {
            let response = await getRoleList();
            const roles = response.data.data;
            const regularRole = roles.role;
            const smallGroupMemberRole = roles.smallgroupmemberrole;
            const ministryMemberRole = roles.ministrymemberrole;
            let listRoles = [];
            if (regularRole) {
                for (const role of regularRole) {
                    listRoles.push(
                        {
                            id: 'userrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'userrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (smallGroupMemberRole) {
                for (const role of smallGroupMemberRole) {
                    listRoles.push(
                        {
                            id: 'smallgroupmemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'smallgroupmemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (ministryMemberRole) {
                for (const role of ministryMemberRole) {
                    listRoles.push(
                        {
                            id: 'ministrymemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'ministrymemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            console.log('listRoles: ', listRoles);

            setRoleOption(listRoles);
            // setFieldValue('listRoles', listRoles);
            setIsLoadingRoles(false);
        } catch (error) {
            setIsLoadingRoles(false);
            setErrorMessage(error.message);
        }
    };

    const renderBasicRecipientsMethod = () => {
        let basicRecipientsMethodEl = [];

        for (const role of roleOption) {
            basicRecipientsMethodEl.push(
                <div className="w-1/2" key={role.id}>
                    <InputCheckBox value={role.value}
                                   checked={basicRecipientMethodList.includes(role.value)}
                                   onChange={() => handleBasicRecipientMethodChange(role.value)}
                                   label={role.label}/>
                </div>
            )
        }

        basicRecipientsMethodEl.push(
            <div className="w-1/2" key={'partner'}>
                <InputCheckBox value={false}
                               checked={nonSmallGroupCheck}
                               onChange={handleNonSmallGroupChange}
                               label={'Partner'}/>
            </div>
        );

        return basicRecipientsMethodEl;
    };

    const renderQueryRecipientMethod = () => {
        return (
            <div>
                <div className="flex flex-col">
                    {/*<span className="text-xs">Use <b>SELECT COUNT(*) as total FROM users</b> to get Total Recipients*/}
                    {/* and use <b>WHERE</b> for filter.*/}
                    {/*</span>*/}
                    {/*<span className="text-blue-500 text-xs italic">*/}
                    {/*Example: <b>SELECT COUNT(*) as total FROM users</b> WHERE gender = 'Female'*/}
                    <span className="text-blue-500 text-xs italic">
                        Example: SELECT id as user_id, fullname as name, email from users where gender= 'Female' and maritalstatus_id = 3
                    </span>
                </div>
                <TextArea name="query"
                          isBottomNeeded={false}
                          value={query}
                          handleOnChange={handleQueryChange}
                />
                <div className="mb-6 mt-2 text-right">
                    <Button handleClick={fetchQueryRecipients} type="secondary" disabled={isLoadingFilter}>
                        {isLoadingFilter ? <Loading primary/> : 'Get Recipients'}
                    </Button>
                </div>
            </div>
        );
    };

    const handleQueryChange = (event) => {
        setFieldValue('query', event.target.value)
    };

    const handleRecipientsGenderChange = (event) => {
        console.log('event.target.value: ', event.target.value);
        setFieldValue('recipientsGender', event.target.value)
    };

    const handleMaritalStatusChange = (event) => {
        // console.log('event.target.value: ', event.target.value);
        setFieldValue('recipientsMaritalStatus', event.target.value)
    };

    const handleScheduleChange = (event) => {
        console.log('event: ', event);
        setFieldValue('schedule', moment(event[0], 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
    };

    const fetchMaritalStatus = async () => {
        setIsMaritalStatusLoading(true);
        try {
            let response = await getRequirements("Event");
            const maritalStatusData = response.data.data.maritalstatuses;

            if (maritalStatusData) {
                let listMaritalStatus = [{
                    id: 'all',
                    label: 'All marriage status',
                    value: 'all'
                }];
                for (const maritalStatus of maritalStatusData) {
                    listMaritalStatus.push(
                        {
                            id: maritalStatus.id,
                            label: maritalStatus.name,
                            value: maritalStatus.id
                        }
                    )
                }
                setListMaritalStatus(listMaritalStatus);
            }
            setIsMaritalStatusLoading(false);
        } catch (error) {
            setIsMaritalStatusLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleCancel = async () => {
        const res = await cancelConfirmation();
        if (res.value) {
            props.history.push('/campaign/list-email')
        }
    };

    const checkValidation = useCallback(
        () => {
            if (!subject) {
                setIsSubjectValid(false);
            } else {
                setIsSubjectValid(true);
            }

            if (!description) {
                setIsDescriptionValid(false);
            } else {
                setIsDescriptionValid(true);
            }

            if ((basicRecipientMethodList.length === 0 && recipientMethodBasicCheck && !nonSmallGroupCheck) ||
                totalRecipient === 0 && !recipientMethodBasicCheck) {
                setRecipientMethodValid(false);
            } else {
                setRecipientMethodValid(true);
            }

            if (!recipientsGender) {
                setIsGenderValid(false)
            } else {
                setIsGenderValid(true)
            }

            if (!recipientsMaritalStatus) {
                setIsMaritalValid(false)
            } else {
                setIsMaritalValid(true)
            }
            //
            // if (!schedule) {
            //     setIsScheduleValid(false);
            // } else {
            //     setIsScheduleValid(true);
            // }

            if (subject && description && (basicRecipientMethodList.length !== 0 && recipientMethodBasicCheck ||
                basicRecipientMethodList.length === 0 && recipientMethodBasicCheck && nonSmallGroupCheck ||
                totalRecipient !== 0 && !recipientMethodBasicCheck)) {
                if (recipientMethodBasicCheck && recipientsGender && recipientsMaritalStatus && totalRecipient !== 0) {
                    handleSubmit();
                } else if (!recipientMethodBasicCheck && totalRecipient !== 0) {
                    handleSubmit();
                }
            }
        }, [
            subject, description, basicRecipientMethodList, recipientMethodBasicCheck, recipientsGender, recipientsMaritalStatus, totalRecipient
        ]
    );

    const handleOnDraft = async () => {
        const res = await saveDraftConfirmation();
        if (res.value) {
            setFieldValue('isDraft', true);
            checkValidation();
        }
    };

    const handleOnSubmit = async () => {
        const res = await publishConfirmation('Email Campaign');
        if (res.value) {
            setFieldValue('isDraft', false);
            checkValidation();
        }
    };

    return (
        <div>
            {
                isLoadingDetail ?
                    <Loading/>
                    :
                    <div className="flex md:-mx-2 flex-wrap">
                        <div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
                            <div className="bg-white p-6 shadow-md">
                                <div className="flex justify-between">
                                    <Label>
                                        SUBJECT
                                    </Label>
                                    <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer"
                                       href="http://embedyoutube.org/">Generate
                                        Youtube Inline here</a>
                                </div>
                                <TextInput styles="pr-4 mb-4" value={subject} name="subject"
                                           marginBottom={false}
                                           onChange={handleSubjectChange}
                                           errors={isSubjectValid === false && isSubjectValid !== undefined ? 'Required' : undefined}
                                />

                                <div className="mb-6">
                                    <DescriptionBox
                                        label={'DESCRIPTION'}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        name="description"
                                        errors={isDescriptionValid === false && isDescriptionValid !== undefined ? 'Required' : undefined}
                                    />
                                </div>

                                <div className="mb-6 flex-col">
                                    <Label>
                                        ATTACH IMAGE
                                    </Label>
                                    <div className="flex ml-2">
                                        <div className="mb-5 mt-3">
                                            <InputRadioButton
                                                checked={attachImageCheck}
                                                name={'checkimg'}
                                                label={'YES'}
                                                onChange={handleAttachImageCheck}
                                            />
                                        </div>

                                        <div className="mb-5 mt-3 ml-48">
                                            <InputRadioButton
                                                checked={!attachImageCheck}
                                                name={'checkimg'}
                                                label={'NO'}
                                                onChange={handleAttachImageCheck}
                                            />
                                        </div>
                                    </div>
                                    {attachImageCheck ? (
                                            <div>
                                                <div className="mb-6">
                                                    <Label text={'UPLOAD IMAGE'}/>
                                                    <Field name="imagesUrl" component={ImageUploadCampaign}/>
                                                </div>
                                            </div>
                                        ) :
                                        <div/>}
                                </div>

                                <div className="mb-6 flex-col">
                                    <Label>
                                        ATTACH FILE
                                    </Label>

                                    <div className="flex flex-row">
                                        <div className="flex ml-2">
                                            <div className="mb-5 mt-3">
                                                <InputRadioButton
                                                    checked={attachFileCheck}
                                                    label={'YES'}
                                                    onChange={handleAttachFileCheck}
                                                />
                                            </div>

                                            <div className="mb-5 mt-3 ml-48">
                                                <InputRadioButton
                                                    checked={!attachFileCheck}
                                                    label={'NO'}
                                                    onChange={handleAttachFileCheck}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        attachFileCheck &&
                                        <ImageInput onChange={handleAttachFileChange} imageName={attachedFileName}
                                                    isImage={false}
                                                    errors={errors.eventBannerImage}/>
                                    }

                                </div>

                                <div>
                                    <Label>RECIPIENTS METHOD</Label>
                                    <div className="flex w-1/4 flex-row justify-between">
                                        <InputRadioButton value={recipientMethodBasicCheck}
                                                          checked={recipientMethodBasicCheck}
                                                          name="recipientMethodBasicCheck"
                                                          label="Basic"
                                                          onChange={handleRecipientMethodBasicCheck}/>
                                        <InputRadioButton value={recipientMethodBasicCheck}
                                                          checked={!recipientMethodBasicCheck}
                                                          name="recipientMethodBasicCheck"
                                                          label="Query"
                                                          onChange={handleRecipientMethodBasicCheck}/>
                                    </div>
                                </div>
                                {
                                    recipientMethodBasicCheck ?
                                        <div className="mb-4 flex flex-row flex-wrap w-full">
                                            {
                                                isLoadingRoles || isLoadingFilter ?
                                                    <Loading/> :
                                                    renderBasicRecipientsMethod()
                                            }
                                        </div>
                                        :
                                        <div>
                                            {renderQueryRecipientMethod()}
                                        </div>
                                }
                                {
                                    !recipientMethodValid ?
                                        <div className="text-red-500 text-xs italic mb-4">Required</div> : <div/>
                                }

                                {
                                    recipientMethodBasicCheck ?
                                        <div className="flex">
                                            <div className="w-full pr-2">
                                                {
                                                    isLoadingFilter ?
                                                        <Loading/>
                                                        :
                                                        <SelectOptions label="RECIPIENTS GENDER"
                                                                       value={recipientsGender}
                                                                       options={genderOption}
                                                                       onChange={handleRecipientsGenderChange}
                                                                       errors={isGenderValid === false && isGenderValid !== undefined ? 'Required' : undefined}
                                                        />
                                                }
                                            </div>


                                            <div className="w-full pr-2">
                                                {
                                                    isMaritalStatusLoading || isLoadingFilter ?
                                                        <Loading/>
                                                        :
                                                        <SelectOptions label="RECIPIENTS MARIAGE STATUS"
                                                                       value={recipientsMaritalStatus}
                                                                       options={listMaritalStatus}
                                                                       onChange={handleMaritalStatusChange}
                                                                       errors={isMaritalValid === false && isMaritalValid !== undefined ? 'Required' : undefined}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div/>
                                }

                                <div className="text-blue-500 text-xs italic mb-6">Total
                                    Recipient: {totalRecipient}</div>

                                <div className="w-3/6 mb-4 pr-2">
                                    <Label>SCHEDULE</Label>
                                    {/*<Field name="schedule" component={DateTimeSelect}/>*/}
                                    <InputDate handleChange={handleScheduleChange}
                                               withSec={true}
                                               value={schedule}
                                               isEdit={true}
                                    />
                                </div>

                                <div className="flex">
                                    <div className="w-1/2">
                                        <Button type="cancel" handleClick={handleCancel}>
                                            {isSubmitting ? <Loading secondary/> : 'Cancel'}
                                        </Button>
                                    </div>
                                    <div className="w-1/2">
                                        <div className="flex justify-end">
                                            <Button handleClick={handleOnDraft} type="secondary"
                                                    disabled={isSubmitting}>
                                                {isSubmitting ? <Loading primary/> : 'Save As Draft'}
                                            </Button>

                                            <Button handleClick={handleOnSubmit} type="primary" size="ml-4"
                                                    disabled={isSubmitting}>
                                                {isSubmitting ? <Loading secondary/> : 'Publish'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 w-full px-2">
                            <CampaignEmailPreview {...props} />
                        </div>
                    </div>
            }
        </div>
    )
};

export default EditEmailCampaignForm;
