import moment from 'moment'

const optionsYear = () => {
  let years = []
  let year = parseInt(moment().format('YYYY'))
  for (year; year >= 2019; year--) {
    years.push({ id: year, label: year })
  }

  return years
}

export default optionsYear
