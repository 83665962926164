import http from "./httpServices";

export const totalActiveAndLastSundayCount = async () => {
  return await http.get("/report", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const totalActiveAndLastSundayCount = async () =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });*/

export const monthlySundayAverage = async (year, month) => {
  return await http.get(`/report/sundayserviceaverage/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const monthlySundayAverage = async (year, month) =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/sundayserviceaverage`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    params: { year, month }
  });*/

export const avgGroupApplicant = async (year, month) => {
  return await http.get(`/report/smallgroupapplicant/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const avgGroupApplicant = async (year, month) => await axios({
  method: 'get',
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  url: `${process.env.REACT_APP_ENDPOINT}/report/smallgroupapplicant`,
  params: {year, month}

})*/

export const avgGroupProbation = async (year, month) => {
  return await http.get(`/report/smallgroupprobation/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const avgGroupProbation = async (year, month) =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/smallgroupprobation`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    params: { year, month }
  });*/

export const avgGroupConversion = async (year, month) => {
  return await http.get(`/report/smallgroupconversion/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const avgGroupConversion = async (year, month) =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/smallgroupconversion`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    params: { year, month }
  });*/

export const serviceAttendance = async id => {
  return await http.get(`/report/campus/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const serviceAttendance = async id => await axios({
  method: 'get',
  url: `${process.env.REACT_APP_ENDPOINT}/report/campus`,
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }

  params: {id}
})*/

export const activeUsers = async (year, month) => {
  return await http.get(`/report/activeusers/${year}/${month}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const activeUsers = async (year, month) =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/activeusers`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    params: { year, month }
  });*/

export const marriageData = async () => {
  return await http.get("/report/marriage", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const marriageData = async () =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/marriage`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }

  });*/

export const genderData = async () => {
  return await http.get("/report/gender", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const genderData = async () =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/gender`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }

  });*/

export const ageGroupData = async () => {
  return await http.get("/report/age", {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};

/*export const ageGroupData = async () =>
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_ENDPOINT}/report/age`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });*/

export const getDataServiceAttendance = async (campusId, year, month) => {
  return await http.get(
    `/report/attendance/export/${campusId}/${year}/${month}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }
  );
};

export const getDataFirstTimer = async (campusId, year, month) => {
  return await http.get(
    `/report/ftattendance/export/${campusId}/${year}/${month}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }
  );
};

export const getSmallGroupReport = async (
  month_from,
  year_from,
  month_to,
  year_to
) => {
  return await http.get(`/report/smallgroup`, {
    params: {
      month_from,
      year_from,
      month_to,
      year_to
    },
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  });
};
