import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { getRoles } from 'libs/apis/usermanagement'
import { Button, ButtonContainer, ButtonFilter, X, ButtonX, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'
import FilterItem from 'components/commons/FilterItem'

export default function UserManagementFilter({ handleClearFilter, handleApplyFilter, handleSelectFilter, roleId, campusId, gender }) {
	const [listRoles, setListRoles] = useState([])
	const [tab, setTab] = useState('gender')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const customStyles = {
		content: {
			top: '10%',
			left: '17%',
			right: '25%',
			width: '1101px',
			height: tab === 'role' ? '420px' : '230px',
			backgroundColor: 'white',
			paddingLeft: '2px',
			paddingRight: '0px',
			overflow: 'hidden',
			borderRadius: '12px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getRoles()
				setListRoles(res.data.data.role)
			} catch (error) {
				setListRoles([])
			}
		}
		fetchRoles()
	}, [])

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('gender')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const handleApply = () => {
		setModalIsOpen(!modalIsOpen)
		handleApplyFilter()
	}

	const genders = [
		{ value: 'Female', title: 'Female' },
		{ value: 'Male', title: 'Male' },
	]

	const roleTab = tab === 'role'
	const genderTab = tab === 'gender'

	const length = roleId.length !== 0 || gender.length !== 0

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal}>Filter</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Newsfeed Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<div>
						<Input tab={tab === 'gender'} type="button" value={'gender'} onClick={handleOnTab} />
						<Input tab={tab === 'role'} type="button" value={'role'} onClick={handleOnTab} />
						{/* <Input tab={tab === 'campus'} type="button" value={'campus'} onClick={handleOnTab} /> */}
					</div>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{genderTab &&
								genders.map(({ title, value }) => (
									<div className="pr-2" key={title}>
										<FilterItem
											classname={`${gender.includes(value.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="gender"
											title={title}
											value={value}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{roleTab &&
								listRoles.map((role) => (
									<div key={role.showname} className="pr-2">
										<FilterItem
											classname={`${roleId.includes(role.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="roleId"
											title={role.showname}
											value={role.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{/* {campusTab &&
								listCampus.map((campus) => (
									<div key={campus.name} className="pr-2">
										<FilterItem
											classname={`${campusId.includes(campus.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="campusId"
											title={campus.name}
											value={campus.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))} */}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
