import React from 'react'
import InvalidFeedBack from 'components/commons/InvalidFeedback'

function InputNumber({ label, name, value, handleChange, min, errors }) {
	return (
		<div>
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<input className="appearance-none border-2 rounded-lg w-full p-2 leading-tight focus:outline-none" name={name} type="number" value={value} onChange={handleChange} min={min} />
			{errors && <InvalidFeedBack message={errors} />}
		</div>
	)
}

export default InputNumber
