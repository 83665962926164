import styled, { css } from 'styled-components'

/* filter dan export */
export const ButtonX = styled.div`
	background-color: #fff;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	position: absolute;
	top: 0;
	left: 70px;
	padding-left: 30px;

	:focus {
		outline: none;
	}
`

export const ButtonFilter = styled.div`
	background-color: #fff;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	color: #48a4df;
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	font-weight: bold;
	border-color: #fff;
	border-width: 2px;
	z-index: 10;
	:focus {
		outline: none;
	}
	${(props) =>
		props.empty &&
		css`
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		`}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: white;
	height: 44px;
`

export const Title = styled.div`
	display: flex;
	justify-content: center;
	color: #48a4df;
	padding-left: 30.4px;
	font-weight: bold;
	font-size: 18px;
`

export const X = styled.div`
	display: flex;
	justify-content: center;
	color: #48a4df;
	font-weight: bold;
	cursor: pointer;
	padding-right: 30.4px;
	font-size: 18px;
`

export const Body = styled.div`
	display: flex;
	background-color: #edf2f7;
	padding-bottom: 10px;
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const Input = styled.input`
	width: 135px;
	height: 44px;
	display: flex;
	justify-content: flex-start;
	border-top-right-radius: 9999px;
	border-bottom-right-radius: 9999px;
	font-weight: bold;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	margin: 0.5rem;
	text-transform: capitalize;
	border-color: ${(props) => (props.tab ? '#fff' : '#e2e2e2')};
	border-width: ${(props) => (props.tab ? 'none' : '2px')};
	box-shadow: ${(props) => (props.tab ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none')};
	background-color: ${(props) => (props.tab ? '#fff' : '#edf2f7')};
`

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

export const Button = styled.div`
	display: flex;
	cursor: pointer;
	text-align: center;
	justify-content: center;
	width: 50%;
	color: red;
	height: 44px;
	background-color: ${(props) => (props.clear ? 'gray' : '#48a4df')};
`

export const Span = styled.span`
	margin: auto 0;
	text-align: center;
	font-weight: bold;
	color: #fff;
`

export const Export = styled.div`
	background-color: #fff;
	border: solid 2px #fff;
	color: #48a4df;
	font-weight: bold;
	width: 125px;
	height: 44px;
	border-radius: 10px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
`

/* TABLE */
export const TableContainer = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 0.25rem;
`

export const Table = styled.table`
	width: 100%;
`

export const THead = styled.thead`
	border-top: 2px solid #f2f6f9;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-transform: uppercase;
	color: gray;
`

export const TBody = styled.tbody`
	border: 2px solid #f2f6f9;
	color: #3e3e3e;
`

export const THeader = styled.th`
	height: 60px;
`

export const TRow = styled.tr`
	border-top: 2px solid #f2f6f9;
	text-align: center;
	height: 50px;
`
