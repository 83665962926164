import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'

import { getRegencies, getSmallGroupCategory, getSmallGroupLevel, getUpperByLevel, getLeaderByLevel } from 'libs/apis/smallGroup'

import TextInput from 'components/commons/TextInput'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import SelectOptions from 'components/commons/SelectOptions'
import InputTime from 'components/commons/InputTime'
import InputDate from 'components/commons/InputDate'
import TextArea from 'components/commons/TextArea'
import InputHTML from 'components/commons/InputHTML'

import { cancelConfirmation } from 'libs/utils/confirmation'
import { constants } from 'constants/constant'
import DropdownItem from 'components/commons/DropdownItem'

function EditNewGroupForm(props) {
	const [categories, setCategories] = useState([])
	const [regions, setRegions] = useState([])
	const [allLevel, setAllLevel] = useState([])
	const [allLeader, setAllLeader] = useState([])
	const [allSGL, setAllSGL] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const { setFieldValue, isSubmitting, handleSubmit, campuses } = props // props dari Multiplication Formik
	const errors = props.errors // error message dari Multiplication Formik
	let { church_id } = JSON.parse(localStorage.getItem('church'))

	/* props.values dari Multiplication Formik */
	const {
		ageFrom,
		ageTo,
		category,
		day,
		description,
		established_date,
		fullAddress,
		name,
		postalCode,
		region,
		shortAddress,
		smallGroupCampus,
		parentCampus,
		smallGroupLeader,
		smallGroupLevel,
		smallGroupUpperLevelLeaderName,
		status,
		time,
	} = props.values

	/* fetch smallgroup level */
	useEffect(() => {
		let isCancel = false
		const fetchSmallGroupLevel = async () => {
			try {
				const res = await getSmallGroupLevel()
				if (!isCancel) {
					let resData = res.data.data.smallgroup_level
					let levelData = resData.map((data) => {
						let obj = {}
						obj.id = data.level
						obj.name = data.name
						return obj
					})

					setFieldValue('smallGroupLevel', levelData[0].id)
					setAllLevel(levelData)
				}
			} catch (error) {
				if (!isCancel) {
				}
			}
		}
		fetchSmallGroupLevel()

		return () => {
			isCancel = true
		}
	}, [])

	/* fetch small group leader */
	useEffect(() => {
		let isCancel = false
		setIsLoading(true)
		const fetchSmallGroupLeader = async () => {
			try {
				let res = await getLeaderByLevel(parseInt(smallGroupLevel) - 1, 1) // harusnya di kurang 1 tapi masih errror

				if (!isCancel) {
					let data = res.data.data.smallgroupmembers

					if (data.length) {
						let leaderData = data.map((d) => {
							let obj = {}
							obj.id = d.id
							if (d.user2) {
								obj.name = `${d.user.fullname} & ${d.user2.fullname}`
							} else {
								obj.name = `${d.user.fullname}`
							}
							return obj
						})

						setAllLeader(leaderData)
						setIsLoading(false)
					} else {
						setAllLeader(data)
						setIsLoading(false)
					}
				}
			} catch (error) {
				if (!isCancel) {
					setIsLoading(false)
				}
			}
		}
		fetchSmallGroupLeader()

		return () => {
			isCancel = true
		}
	}, [smallGroupLevel])

	/* fetch sg upper lvl sgl name */
	useEffect(() => {
		let isCancel = false
		setIsLoading(true)

		const fetchSgUpperLeader = async () => {
			try {
				if (!isCancel) {
					let res = await getUpperByLevel(parseInt(smallGroupLevel) + 1) //  bisa pake smallGroupLevel + 1
					if (res.status === 200) {
						let listData = res.data.data.smallgroupmembers
						if (listData.length) {
							let upper_leader_data = listData
								.filter((val) => !val.iscoreteam)
								.map((data) => {
									let obj = {}
									obj.id = data.id

									if (data.user2 !== null) {
										obj.id = data.id
										obj.name = `${data.user.fullname} & ${data.user2.fullname}`
									} else {
										obj.name = `${data.user.fullname}`
									}

									return obj
								})

							setAllSGL(upper_leader_data)
							setIsLoading(false)
						} else {
							setAllSGL(listData)
							setIsLoading(false)
						}
					}
				}
			} catch (error) {
				if (!isCancel) {
					setIsLoading(false)
				}
			}
		}
		fetchSgUpperLeader()

		return () => {
			isCancel = false
		}
	}, [smallGroupLevel])

	/* fetch smallgroup categories */
	useEffect(() => {
		const fetchSmallGroupCategories = async () => {
			try {
				const res = await getSmallGroupCategory(parseInt(church_id))

				let listCategory = res.data.data.categories

				let categoryData = listCategory.map((cat) => {
					let obj = {}
					obj.id = cat.id
					obj.name = cat.name
					return obj
				})

				setFieldValue('category', categoryData[0].id) // set default value category
				setCategories(categoryData)
			} catch (error) {
				console.log(error)
				return
			}
		}

		fetchSmallGroupCategories()
	}, [church_id])

	/* fetch smallgroup regions */
	useEffect(() => {
		const fetchRegencies = async () => {
			try {
				let res = await getRegencies(church_id)
				setRegions(res.data.data.regencies)
			} catch (error) {
				console.log(error)
			}
		}
		fetchRegencies()
	}, [])

	const handleChange = (event) => {
		setFieldValue(event.target.name, event.target.value)
	}

	const handleSmallGroupLeader = (e) => {
		if (e !== null) {
			setFieldValue('smallGroupLeader', { value: e.value, label: e.label })
		} else {
			setFieldValue('smallGroupLeader', { value: '', label: '' })
		}
	}

	const handleRegion = (e) => {
		if (e !== null) {
			setFieldValue('region', { value: e.value, label: e.label })
		} else {
			setFieldValue('region', { value: '', label: '' })
		}
	}

	const handleUpperLeader = (e) => {
		if (e !== null) {
			setFieldValue('smallGroupUpperLevelLeaderName', { value: e.value, label: e.label })
		} else {
			setFieldValue('smallGroupUpperLevelLeaderName', { value: '', label: '' })
		}
	}

	const handleTime = (date) => {
		setFieldValue('time', moment(date[0]).format('HH:mm'))
	}

	const handleEstabilishDate = (date) => {
		setFieldValue('established_date', date[0])
	}

	const handleDescription = (desc) => {
		setFieldValue('description', desc)
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			props.history.push('/smallgroup/all/filtered')
		}
	}

	if (props.isLoading) {
		return <Loading />
	}

	return (
		<div className="bg-white shadow-md p-4">
			<div className="w-1/2 mb-6">
				<SelectOptions
					name="smallGroupCampus"
					label="SMALL GROUP CAMPUS"
					value={smallGroupCampus === null || smallGroupCampus === 0 ? '0' : smallGroupCampus}
					onChange={handleChange}
					options={campuses}
					disabled={isLoading}
					errors={errors.smallGroupCampus}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions
					name="smallGroupLevel"
					label="SMALL GROUP LEVEL"
					value={smallGroupLevel}
					onChange={handleChange}
					options={allLevel}
					disabled={true}
					errors={errors.smallGroupLevel}
				/>
			</div>

			<div className="flex">
				<div className="w-1/2 mb-6">
					<SelectOptions
						name="parentCampus"
						label="PARENT CAMPUS"
						value={parentCampus}
						onChange={handleChange}
						options={campuses}
						disabled={isLoading}
						errors={errors.parentCampus}
					/>
				</div>
			</div>

			<div className="w-1/2 mb-6">
				<TextInput name="name" label="SMALL GROUP NAME" value={name} onChange={handleChange} placeholder="ex: Alam Sutera 1 " disabled={isLoading} errors={errors.name} />
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions name="category" label="SMALL GROUP CATEGORY" value={category} onChange={handleChange} options={categories} disabled={isLoading} errors={errors.category} />
			</div>

			<div className="w-1/2 mb-6">
				<DropdownItem
					name="smallGroupLeader"
					label="SMALL GROUP LEADER"
					value={smallGroupLeader}
					handleChange={handleSmallGroupLeader}
					options={allLeader}
					isLoading={isLoading}
					disabled={true}
					errors={errors.smallGroupLeader}
				/>
			</div>

			<div className="w-1/2 mb-6">
				<DropdownItem
					name="smallGroupUpperLevelLeaderName"
					label="UPPER LEVEL SGL NAME"
					value={smallGroupUpperLevelLeaderName}
					handleChange={handleUpperLeader}
					options={allSGL}
					isLoading={isLoading}
					errors={errors.smallGroupUpperLevelLeaderName}
				/>
			</div>

			<div className="flex">
				<div className="w-1/2 h-full">
					<TextArea label="FULL ADDRESS" name="fullAddress" value={fullAddress ? fullAddress : ''} handleOnChange={handleChange} disabled={isLoading} errors={errors.fullAddress} />
				</div>

				<div className="w-1/2">
					<div className="mb-6 ml-2">
						<TextInput
							name="shortAddress"
							label="SHORT ADDRESS"
							value={shortAddress ? shortAddress : ''}
							onChange={handleChange}
							placeholder="Type short address"
							disabled={isLoading}
							errors={errors.shortAddress}
						/>
					</div>
					<div className="flex">
						<div className="w-full ml-2 mr-2">
							<DropdownItem name="region" label="REGION" value={region} options={regions} handleChange={handleRegion} isLoading={isLoading} errors={errors.region} />
						</div>

						<div className="w-full">
							<TextInput
								name="postalCode"
								label="POSTAL CODE"
								value={postalCode ? postalCode : ''}
								onChange={handleChange}
								placeholder="ex: 123456"
								disabled={isLoading}
								errors={errors.postalCode}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="flex">
				<div className="w-1/2 mb-6">
					<SelectOptions name="day" label="SCHEDULE: DAY" value={day} onChange={handleChange} options={constants.days} disabled={isLoading} errors={errors.day} />
				</div>
				<div className="w-1/2 mb-6 ml-2">
					<InputTime name="time" label="SCHEDULE: TIME" value={time} handleChange={handleTime} errors={errors.time} />
				</div>
			</div>

			<div className="w-1/2 mb-6">
				<SelectOptions name="status" label="SMALL GROUP STATUS" value={status} onChange={handleChange} options={constants.statuses} disabled={isLoading} errors={errors.status} />
			</div>

			<div className="mb-6 w-3/6">
				<InputDate name="established_date" label="ESTABLISHED DATE" value={established_date} handleChange={handleEstabilishDate} errors={errors.established_date} />
			</div>

			<div className="flex w-1/2 mb-6">
				<div className="w-full">
					<TextInput name="ageFrom" label="AGE FROM" value={ageFrom} onChange={handleChange} placeholder="Choose Age" disabled={isLoading} errors={errors.ageFrom} />
				</div>

				<div className="w-full ml-2">
					<TextInput name="ageTo" label="TO" value={ageTo} onChange={handleChange} placeholder="Choose Age" disabled={isLoading} errors={errors.ageTo} />
				</div>
			</div>

			<div className="w-1/2 mb-6">
				<InputHTML label="SMALL GROUP DESCRIPTION" name="description" value={description} onChange={handleDescription} errors={errors.description} />
			</div>

			<div className="flex justify-between">
				<Button type="cancel" handleClick={handleCancel}>
					{isSubmitting ? <Loading secondary /> : 'Cancel'}
				</Button>
				<Button type="primary" handleClick={handleSubmit}>
					{isSubmitting ? <Loading secondary /> : 'Save'}
				</Button>
			</div>
		</div>
	)
}

export default EditNewGroupForm
