import { withFormik } from 'formik'
import { CreateClassValidationSchema } from './ClassValidationSchema'
import EditClassForm from 'screens/courses/EditClassForm'
import { editClass } from 'libs/apis/courses'
import { failedMessage } from 'libs/utils/confirmation'
import alert from 'services/alert'
import moment from 'moment'

const CreateClassFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		classTitle: props.classTitle,
		classCode: props.classCode,
		classDescription: props.classDescription,
		classStartDate: props.classStartDate,
		classEndDate: props.classEndDate,
		classDay: props.classDay,
		classStartTime: props.classStartTime,
		classEndTime: props.classEndTime,
		classVenue: props.classVenue,
		classVenueName: props.classVenueName,
		classVenueAddress: props.classVenueAddress,
		classCheckRegis: true,
		classStartRegis: props.classStartRegis,
		classEndRegis: props.classEndRegis,
		classParticipantQuota: props.classParticipantQuota,
		startDateShare: props.startDateShare,
		endDateShare: props.endDateShare,
		isEndDateShare: true,
		classSpeaker: props.classSpeaker,
		classMinSession: props.classMinSession,
		classCampaigns: props.classCampaigns,
		isClassPayment: false,
		classPaymentValue: props.classPaymentValue,
		classPriceAll: props.classPriceAll,
		classPaymentPerRole: props.classPaymentPerRole,
		classParticipants: props.classParticipants,
		classAditionnalInformation: props.classAditionnalInformation,
		classType: props.classType,
		classVolunteers: props.classVolunteers,
		dateList: props.dateList,
		priceList: props.priceList,
		allRoles: props.allRolesFormik,
		ishighlights: props.ishighlights,
		isDraft: false,
		campaignByMail: false,
		campaignByNotif: false,
	}),
	// validationSchema: CreateClassValidationSchema,
	async handleSubmit(values, { props }) {
		try {
			let formdata = new FormData()

			let a = values.classVolunteers.map((val) => val.value).join()

			if (values.priceList.length === 0 && values.classPaymentPerRole === false) {
				let a = {}
				a.smallgroupmemberrole_id = null
				a.userrole_id = null
				a.ministrymemberrole_id = null
				a.price = values.classPriceAll
				formdata.append('priceList', JSON.stringify([a]))
			}

			if (values.priceList.length && values.classPaymentPerRole === false) {
				const temp = values.priceList.map((v) => {
					const b = {}

					if (v.id.includes('ministrymemberrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = c[1]
						b.userrole_id = null
						b.smallgroupmemberrole_id = null
						b.price = values.classPriceAll
					}
					if (v.id.includes('userrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = null
						b.userrole_id = c[1]
						b.smallgroupmemberrole_id = null
						b.price = values.classPriceAll
					}
					if (v.id.includes('smallgroupmemberrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = null
						b.userrole_id = null
						b.smallgroupmemberrole_id = c[1]
						b.price = values.classPriceAll
					}
					return b
				})

				formdata.append('priceList', JSON.stringify(temp))
			}

			if (values.priceList.length && values.classPaymentPerRole === true) {
				const temp = values.priceList.map((v) => {
					const b = {}

					if (v.id.includes('ministrymemberrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = c[1]
						b.userrole_id = null
						b.smallgroupmemberrole_id = null
						b.price = v.price
					}
					if (v.id.includes('userrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = null
						b.userrole_id = c[1]
						b.smallgroupmemberrole_id = null
						b.price = v.price
					}
					if (v.id.includes('smallgroupmemberrole_id')) {
						let c = v.id.split('.')
						b.ministrymemberrole_id = null
						b.userrole_id = null
						b.smallgroupmemberrole_id = c[1]
						b.price = v.price
					}
					return b
				})

				formdata.append('priceList', JSON.stringify(temp))
			}

			if (values.classVenue == 'OTHERS') {
				formdata.append('campusRoomId', '')
				formdata.append('location', values.classVenueName)
				formdata.append('locationAddress', values.classVenueAddress)
			} else {
				formdata.append('campusRoomId', values.classVenue)
				formdata.append('location', '')
				formdata.append('locationAddress', '')
			}

			formdata.append('id', props.id)
			formdata.append('title', values.classTitle)
			formdata.append('programCodeId', values.classCode.value)
			formdata.append('description', values.classDescription)
			formdata.append('programStartAt', moment(new Date(values.classStartDate)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('programEndAt', moment(new Date(values.classEndDate)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('registerStartAt', moment(new Date(values.classStartRegis)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('registerEndAt', moment(new Date(values.classEndRegis)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('minimumSession', values.classMinSession)
			formdata.append('startSharingAt', moment(new Date(values.startDateShare)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('endSharingAt', moment(new Date(values.endDateShare)).format('YYYY/MM/DD HH:mm:ss'))
			formdata.append('additionalInformation', values.classAditionnalInformation)
			formdata.append('maxSeating', values.classParticipantQuota)
			formdata.append('speaker', values.classSpeaker)
			formdata.append('programTypeId', values.classType)
			formdata.append('dateList', JSON.stringify(values.dateList))
			formdata.append('volunteerList', a)

			values.ishighlights ? formdata.append('isHighlight', 1) : formdata.append('isHighlight', 0)
			values.isDraft ? formdata.append('status', 'Draft') : formdata.append('status', 'Published')
			values.campaignByMail ? formdata.append('campaignByMail', 1) : formdata.append('campaignByMail', 0)
			values.campaignByNotif ? formdata.append('campaignByNotif', 1) : formdata.append('campaignByNotif', 0)

			try {
				const res = await editClass(formdata)
				if (res.data.api_status) {
					alert.succeed('Success')
					props.history.push('/classes')
				} else {
					failedMessage(res.data.message)
				}
			} catch (error) {
				console.log(error)
				alert.failed('Failed', 'Cannot edit this class at this moment')
			}
		} catch (error) {
			console.log(error)
		}
	},
})(EditClassForm)

export default CreateClassFormik
