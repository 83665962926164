import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import Loading from 'components/commons/Loading'
import { saveCampusCustomServiceList } from 'libs/apis/campus'
import { successMessage, failedMessage } from 'libs/utils/confirmation'

class SpecialServiceForm extends Component {
	state = {
		name: '',
		nameError: '',
		date: '',
		dateError: '',
		dataServices: this.props.dataServices,
		campusId: this.props.campusId,
		isSubmit: false,
		errorSubmit: '',
		isRedirect: false,
		isNoAuthorize: false,
	}

	handleOnChangeName = event => {
		this.setState({ name: event.target.value })
	}

	handleOnDate = date => {
		this.setState({ date: moment(date[0]).format('DD/MM/YYYY') })
	}

	handleOnCounter = event => {
		const { dataServices } = this.state
		if (event.target.value) {
			event.target.value = parseInt(event.target.value)
		} else {
			event.target.value = 0
		}
		const service = dataServices.find(item => item.service_type === parseInt(event.target.id))
		const updated = { ...service, counter: parseInt(event.target.value) }
		const updatedIndex = dataServices.findIndex(
			item => item.service_type === updated.service_type
		)
		const updatedDataServices = [
			...dataServices.slice(0, updatedIndex),
			updated,
			...dataServices.slice(updatedIndex + 1),
		]
		this.setState({ dataServices: updatedDataServices })
	}

	handleOnSave = () => {
		const { name, date, campusId, dataServices } = this.state
		if (!name) {
			this.setState({ nameError: 'Required' })
		} else {
			this.setState({ nameError: '' })
		}

		if (!date) {
			this.setState({ dateError: 'Required' })
		} else {
			this.setState({ dateError: '' })
		}

		if (name && date) {
			this.setState({ isSubmit: true }, async () => {
				try {
					const response = await saveCampusCustomServiceList(
						parseInt(campusId),
						name,
						date,
						dataServices
					)
					if (response.data.api_status) {
						await successMessage()
						this.setState({ isSubmit: false, isRedirect: true })
					} else {
						await failedMessage(response.data.message)
						this.setState({ isSubmit: false })
					}
				} catch (error) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							this.setState({ isSubmit: false, isNoAuthorize: true })
							await failedMessage(error.response.data.message)
						} else {
							this.setState({ isSubmit: false, isNoAuthorize: true })
						}
					}
				}
			})
		}
	}

	render() {
		const { name, date, dataServices, isSubmit, isRedirect, isNoAuthorize } = this.state

		if (isRedirect) {
			return <Redirect to="/input-data" />
		}

		if (isNoAuthorize) {
			return <Redirect to="/login" />
		}

		const styleInputName = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
		const styleInputDate = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`

		return (
			<div className="bg-white p-4 shadow-md rounded-lg">
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
					<input
						className={styleInputName}
						value={name}
						onChange={this.handleOnChangeName}
					/>
				</div>
				<div className="mb-6">
					<label className="block text-gray-700 text-sm font-bold mb-2">Date</label>
					<Flatpickr
						className={styleInputDate}
						placeholder="Choose date"
						value={date}
						onChange={this.handleOnDate}
						options={{
							dateFormat: 'd/m/Y',
							altInput: true,
							altFormat: 'd M Y',
							enableTime: false,
						}}
					/>
				</div>
				{dataServices.map(item => {
					const styleInput = `appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary`
					return (
						<div className="mb-6" key={item.service_type}>
							<label className="block text-gray-700 text-sm font-bold mb-2">
								{item.title}
							</label>
							<input
								className={styleInput}
								id={item.service_type}
								value={item.counter}
								type="number"
								onChange={this.handleOnCounter}
							/>
						</div>
					)
				})}
				<button
					className="bg-primary rounded-lg py-2 px-6 text-white focus:outline-none"
					disabled={isSubmit}
					onClick={this.handleOnSave}
				>
					{isSubmit ? <Loading secondary /> : 'Save'}
				</button>
			</div>
		)
	}
}

export default SpecialServiceForm
