import axios from 'axios'
import search from './search'

const ENDPOINT = process.env.REACT_APP_ENDPOINT
const ADMIN_PASS = process.env.REACT_APP_PASSWORD

export const getPrayerList = async (paginate, page, userName, campusId, prayerCategoryId, startAt, endAt, sortBy, sortDirection) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayer/all/filtered`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: {
      paginate,
      page,
      prayertypeIds: 1,
      campusserviceIds: campusId,
      prayercategoryIds: prayerCategoryId,
      userName: userName,
      startat: startAt,
      endat: endAt,
      sortBy: sortBy,
      sortDir: sortDirection,
      adminPass: ADMIN_PASS
    },
  })

export const getPraiseList = async (paginate, page, userName, campusId, prayerCategoryId, startAt, endAt, sortBy, sortDirection) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayer/all/filtered`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: {
      paginate,
      page,
      prayertypeIds: 2,
      campusserviceIds: campusId,
      prayercategoryIds: prayerCategoryId,
      userName: userName,
      startat: startAt,
      endat: endAt,
      sortBy: sortBy,
      sortDir: sortDirection,
      adminPass: ADMIN_PASS
    },
  })

export const getPrayerCategories = async () =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayercategory/all`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })

export const getRequestHistory = async (params) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayer/all/userid`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: {
      ...params,
      adminPass: ADMIN_PASS
    },
  })

export const prayerRequest = async (page, filter_date_from, filter_date_to, filter_category, order_by, order_dir) => {
  return await search(
    `${
    ENDPOINT
    }/prayer?page=${page}&filter_date_from=${filter_date_from}&filter_date_to=${filter_date_to}&filter_category=${filter_category}&order_by=${order_by}&order_dir=${order_dir}`
  )
}

export const praiseReport = async (page, filter_date_from, filter_date_to, filter_category, order_by, order_dir) => {
  return await search(
    `${
    ENDPOINT
    }/praise?page=${page}&filter_date_from=${filter_date_from}&filter_date_to=${filter_date_to}&filter_category=${filter_category}&order_by=${order_by}&order_dir=${order_dir}`
  )
}

export const exportPrayerPraise = async (prayertypeIds, campusserviceIds, prayercategoryIds, startat, endat) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayer/export/filtered`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { prayertypeIds, campusserviceIds, prayercategoryIds, startat, endat }
  })

export const prayerListUser = async (page, id) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/prayer/user/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { page }
  })

export const praiseListUser = async (page, id) =>
  await axios({
    method: 'get',
    url: `${ENDPOINT}/praise/user/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    params: { page }
  })
