import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import Select from 'react-select'

import TextInput from 'components/commons/TextInput'
import Button from 'components/commons/Button'
import DropdownItem from 'components/commons/DropdownItem'
import UploadImage from 'components/commons/UploadImage'
import Loading from 'components/commons/Loading'
import { ContainerWrapper, ButtonWrapper } from 'screens/ministry/Styles.js'
import { cancelConfirmation } from 'libs/utils/confirmation'

function EditMinistryForm(props) {
	const [imagePreview, setImagePreview] = useState('')

	const { handleChange, handleSubmit, isSubmitting, setFieldValue, errors } = props
	const { ministryName, ministryLevel, ministryDescription, ministryStatus, ministryGender, ministryUpperLevel, ministryHeads, keyVolunteers, ministryImageURL } = props.values

	const handleDescription = (value) => {
		setFieldValue('ministryDescription', value)
	}

	const handleSelectMinistryLevel = (e) => {
		setFieldValue('ministryLevel', e)
	}

	const handleSelectMinistryUpperLevel = (e) => {
		let a = e.value.split('.')
		setFieldValue('ministryUpperLevel', { value: a[1], label: e.label })
	}

	const handleSelectMinistryHeads = (e) => {
		setFieldValue('ministryHeads', e)
	}

	const handleSelectDeputy = (e) => {
		setFieldValue('volunteers', e)
	}

	const handleSelectGender = (e) => {
		setFieldValue('ministryGender', e)
	}

	const handleSelectStatus = (e) => {
		setFieldValue('ministryStatus', e)
	}

	const handleFileChange = (e) => {
		setImagePreview(URL.createObjectURL(e.target.files[0]))
		setFieldValue('ministryImageURL', e.target.files[0])
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			props.history.push('/ministry')
		}
	}

	function UpperLevels() {
		if (ministryLevel.value == 'Ministry') {
			const a = props.ministryGroups.concat(props.ministrySubGroups)
			return a
		}

		return []
	}

	const genders = [
		{ id: 'Female', name: 'Female' },
		{ id: 'Male', name: 'Male' },
		{ id: '', name: 'Both' },
	]

	const listLevels = [{ id: 'Ministry', name: 'Ministry' }]

	const statuses = [
		{ id: 'Open', name: 'Open' },
		{ id: 'Full', name: 'Full' },
		{ id: 'Closed', name: 'Closed' },
	]

	return (
		<ContainerWrapper>
			<div className="flex -mb-4">
				<div className="mb-6 w-1/2">
					<TextInput name="ministryName" label="NAME" value={ministryName} onChange={handleChange} errors={errors.ministryName} />
				</div>

				<div className="mb-6 w-1/2">
					<DropdownItem name="ministryLevel" label="LEVEL" value={ministryLevel} handleChange={handleSelectMinistryLevel} options={listLevels} isMulti={false} styles="ml-4" />
				</div>
			</div>

			<div className="mb-6 w-1/2">
				<DropdownItem name="ministryUpperLevel" label="UPPER LEVEL" value={ministryUpperLevel} handleChange={handleSelectMinistryUpperLevel} options={UpperLevels()} isMulti={false} />
			</div>

			<div className="mb-6 w-1/2">
				<DropdownItem
					label="HEAD(S)"
					name="ministryHeads"
					value={ministryHeads}
					options={ministryHeads}
					isMulti={true}
					handleChange={handleSelectMinistryHeads}
					disabled={true}
					errors={errors.ministryHeads}
				/>
			</div>

			<div className="mb-6 w-1/2">
				<DropdownItem
					label="DEPUTY / KEY VOLUNTEER(S)"
					value={keyVolunteers}
					name="keyVolunteers"
					options={keyVolunteers}
					isMulti={true}
					disabled={true}
					handleChange={handleSelectDeputy}
				/>
			</div>

			<div className="mb-6 w-1/2">
				<DropdownItem name="ministryGender" label="GENDER" value={ministryGender} options={genders} handleChange={handleSelectGender} isMulti={false} />
			</div>

			<div className="mb-6 w-1/2">
				<label className="ml-1 block text-gray-700 text-sm font-bold mb-2">DESCRIPTION</label>
				<ReactQuill value={ministryDescription} onChange={handleDescription} />
			</div>

			<div className="mb-6 w-1/2">
				<DropdownItem
					label="RECRUITMENT STATUS"
					name="ministryStatus"
					options={statuses}
					value={ministryStatus}
					handleChange={handleSelectStatus}
					isMulti={false}
					errors={errors.ministryStatus}
				/>
			</div>

			<UploadImage imageUrl={imagePreview ? imagePreview : ministryImageURL} handleFileChange={handleFileChange} />
			<ButtonWrapper>
				<Button type="cancel" handleClick={handleCancel}>
					{isSubmitting ? <Loading secondary /> : 'Cancel'}
				</Button>
				<Button type="primary" handleClick={handleSubmit}>
					{isSubmitting ? <Loading secondary /> : 'Publish'}
				</Button>
			</ButtonWrapper>
		</ContainerWrapper>
	)
}

export default EditMinistryForm
