import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
// import ChangeRole from './ChangeRole'
import { partial } from 'libs/utils/utils'

const TableContainer = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 0.25rem;
`

const Table = styled.table`
	width: 100%;
`

function UsersTable(props) {
	const { list, handleSort } = props
	const handleSortName = partial(handleSort, 'fullname')
	const handleSortEmail = partial(handleSort, 'email')
	const handleSortPhone = partial(handleSort, 'phone')
	const handleSortGender = partial(handleSort, 'gender')
	const handleSortRole = partial(handleSort, 'role')

	return (
		<TableContainer>
			<Table>
				<UserTableHead
					handleSortName={handleSortName}
					handleSortEmail={handleSortEmail}
					handleSortPhone={handleSortPhone}
					handleSortGender={handleSortGender}
					handleSortRole={handleSortRole}
				/>

				<UserTableBody list={list} {...props} />
			</Table>
		</TableContainer>
	)
}

export default UsersTable

const UserTableHead = ({ handleSortName, handleSortEmail, handleSortPhone, handleSortGender, handleSortRole }) => {
	return (
		<thead className="bt-blueSecondary border-2 border-solid">
			<tr className="text-gray">
				<th className="text-left pl-8 py-3">
					NAME
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortName} />
				</th>
				<th className="text-left">
					EMAIL
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortEmail} />
				</th>
				<th className="text-left">
					PHONE
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortPhone} />
				</th>
				<th className="text-left">
					GENDER
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortGender} />
				</th>
				<th className="text-left">
					ROLE
					<i className="ml-1 text-center fas fa-sort sortby cursor-pointer" onClick={handleSortRole} />
				</th>

				<th>STATUS</th>
				<th></th>
			</tr>
		</thead>
	)
}

const UserTableBody = ({ list, handleDeactiveUser }) => {
	return (
		<tbody className="border-2 border-solid">
			{list.map((value, idx) => (
				<UsersItem key={idx} {...value} handleDeactiveUser={handleDeactiveUser} />
			))}
		</tbody>
	)
}

function UsersItem(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleClickOutside = (e) => {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const { id, name, email, phone, gender, role, status, lastactive, handleDeactiveUser } = props
	const lastActive = lastactive ? moment(lastactive).format('DD MMM YYYY') : ''

	let json = JSON.parse(localStorage.getItem('church'))
	let churchCode = json.church_code

	const styleDropDownOpen = `z-20 shadow-md absolute bg-white right-20px ${dropDownOpen ? 'block' : 'hidden'}`
	const styleStatus = `fas fa-circle ${status ? 'text-red-500' : 'text-green-500'}`

	return (
		<tr className="border border-solid bt-blueSecondary" key={id}>
			<td className="pl-4 py-4 px-2">
				<Link to={`/user-management/users/${id}`}>{name}</Link>
			</td>
			<td className="py-4 px-2">{churchCode === 'skybr.id' ? '' : email}</td>
			<td className="py-4 px-2">{churchCode === 'skybr.id' ? '+62********' + phone.slice(11, 14) : phone}</td>
			<td className="py-4 px-2">{gender}</td>
			<td className="py-4 px-2">{role}</td>

			<td className="py-4 px-2">
				<span className={styleStatus} /> {status ? 'Inactive' : 'Active'}
			</td>

			<td className="py-4 pl-4 px-2">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<ul ref={node} className={styleDropDownOpen}>
						<li>
							<Link className="px-4 py-2 block hover:bg-blueSecondary" to={`/user-management/users/${id}`}>
								View User
							</Link>
						</li>
						{status === null && (
							<li className="px-4 py-2 block hover:bg-blueSecondary" onClick={() => handleDeactiveUser(id)}>
								Deactivate User
							</li>
						)}
					</ul>
				</div>
			</td>
		</tr>
	)
}
