import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Button, ButtonContainer, ButtonFilter, X, ButtonX, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'
import InputDateFilterExport from 'components/commons/InputDateFilterExport'
import FilterItem from 'components/commons/FilterItem'
import Loading from 'components/commons/Loading'
import alert from 'services/alert'
import Label from 'components/commons/Label'
import InputDate from 'components/commons/InputDate'

import { getPrayerCategories } from 'libs/apis/prayerpraise'
import { getCampusServices } from 'libs/apis/campusServices'

const customModalStyles = {
	content: {
		position: 'relative',
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		paddingBottom: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

const customInputStyles = {
	width: 'auto',
	paddingRight: '15px'
}

const customModalContentStyles = {
	justifyContent: 'normal'
}

export default function PrayerFilter({ handleClearFilter, handleApplyFilter, handleSelectFilter, campusId, categoryId, dateFrom, dateTo, handleDateFrom, handleDateTo }) {
	const [tab, setTab] = useState('campus service')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const [isLoadingData, setIsLoadingData] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [listCampus, setListCampus] = useState([])
	const [prayerCategories, setPrayerCategories] = useState([])

	const fetchPrayerCategories = async () => {
		setIsLoadingData(true)
		try {
			const res = await getPrayerCategories()
			setPrayerCategories(res.data.data.prayercategories)
		} catch (error) {
			console.error(error)
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
				} else if (error.response.status >= 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setIsLoadingData(false)
				}
			}
		} finally {
			setIsLoadingData(false)
		}
	}

	const fetchCampusServices = async () => {
		setIsLoadingData(true)
		try {
			const res = await getCampusServices()
			setListCampus(res.data.data.campusservices)
			
		} catch (error) {
			console.error(error)
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
				} else if (error.response.status >= 500) {
					setErrorMessage('Cannot retrieve any data at this moment')
					setIsLoadingData(false)
				}
			}
		} finally {
			setIsLoadingData(false)
		}
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('campus service')
	}

	const handleApply = () => {
		setModalIsOpen(!modalIsOpen)
		handleApplyFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	useEffect(() => {
		fetchPrayerCategories()
		fetchCampusServices()	
	}, [])

	const campusTab = tab === 'campus service'
	const categoryTab = tab === 'category'
	const dateSubmittedTab = tab === 'date'
	const length = (campusId.length !== 0) || (categoryId.length !== 0)

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Filter
				</ButtonFilter>
			</div>

			<Modal
				isOpen={modalIsOpen}
				style={customModalStyles}
				contentLabel="Prayer Filter Modal"
				ariaHideApp={false}
			>
				<Header>
					<Title>Prayer Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content style={customModalContentStyles}>
						<Input
							style={customInputStyles}
							className={`${
								tab === 'campus service'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
							}`}
							type="button"
							value="campus service"
							onClick={handleOnTab}
						/>
						<Input
							style={customInputStyles}
							className={`${
								tab === 'category'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
							}`}
							type="button"
							value="category"
							onClick={handleOnTab}
						/>
						<Input
							style={customInputStyles}
							className={`${
								tab === 'date'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
							}`}
							type="button"
							value="date"
							onClick={handleOnTab}
						/>
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						{isLoadingData ?
							<Loading />
							:
							<div className="flex flex-wrap justify-arround">
								{campusTab && listCampus &&
									listCampus.map((item, index) => (
										<div key={index} className="pr-2">
											<FilterItem
												classname={`${campusId.includes(item.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
												name="campusId"
												title={item.campus.name}
												subtitle={item.servicetime}
												value={item.id}
												onClick={handleSelectFilter}
											/>
										</div>
									))}
								{categoryTab && prayerCategories &&
									prayerCategories.map((category, index) => (
										<div key={index} className="pr-2">
											<FilterItem
												classname={`${categoryId.includes(category.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
												name="categoryId"
												title={category.name}
												value={category.id}
												onClick={handleSelectFilter}
											/>
										</div>
									))}
								{dateSubmittedTab && (
									<div className="flex mt-2">
										<div className="pt-2 flex flex-row ">
											<InputDateFilterExport label="DATE FROM" value={dateFrom} name="dateFrom" handleChange={handleDateFrom} />
											<InputDateFilterExport label="TO" name="dateTo" value={dateTo} startDate={dateFrom} handleChange={handleDateTo} />
										</div>
									</div>
								)}
							</div>
						}
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
