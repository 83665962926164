import {withFormik} from 'formik'
import CampaignEmailForm from '../forms/CampaignEmailForm'
import {failedMessage, successMessage} from "../../libs/utils/confirmation";
import {createCampaign, getRecipientsBasic, getRecipientsQuery, uploadAttachment} from "../../libs/apis/emailCampaign";
import moment from "moment";

const CampaignEmailFormik = withFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: (props) => ({
        subject: '',
        description: '',
        emailPreviewContent: '',
        attachImageCheck: false,
        attachedImages: [],
        attachFileCheck: false,
        attachedFile: '',
        attachedFileName: '',
        recipientMethodBasicCheck: true,
        basicRecipientMethodList: [],
        nonSmallGroupCheck: false,
        query: '',
        recipientsGender: '',
        recipientsMaritalStatus: '',
        schedule: '',
        churchId: '',
        documentGroupId: '',
        documentTypeId: '',
        isDraft: false,
        recipients: [],
        recipientParams: {}
    }),
    // validationSchema: EmailCampaignValidationSchema,
    handleSubmit: async (values, {props, setSubmitting, setFieldValue}) => {
        const {
            subject,
            description,
            recipients,
            schedule,
            isDraft,
            attachFileCheck,
            attachedFile,
            attachedFileName,
            churchId,
            documentGroupId,
            documentTypeId,
            recipientParams,
            query,
            recipientMethodBasicCheck
        } = values;

        // let updatedSchedule = schedule;
        // if (!schedule) {
        //     updatedSchedule = moment(new Date(), 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        // }

        let formdata = new FormData();
        formdata.append('title', subject);
        formdata.append('description', description);
        formdata.append('type', 'email');
        formdata.append('sendstart_at', schedule);

        let campaignId = '';

        try {
            let res = await createCampaign(formdata);
            campaignId = res.data.data.createdData.campaign.id;

            if (res.data.api_status === true) {
                try {
                    let resDraft = '';
                    let resPublish = '';
                    if (isDraft) {
                        if (recipientMethodBasicCheck) {
                            resDraft = await getRecipientsBasic(recipientParams.gender, recipientParams.maritalStatus,
                                recipientParams.smallGroupMemberRole, recipientParams.ministryMemberRole,
                                recipientParams.userRole, recipientParams.nonSmallGroup, 'Draft', campaignId);
                        } else {
                            resDraft = await getRecipientsQuery(query, 'Draft', campaignId);
                        }
                        if (resDraft.data.api_status === true) {
                            try {
                                if (attachedFile && attachFileCheck) {
                                    let formdataAttachment = new FormData();
                                    formdataAttachment.append('file', attachedFile);
                                    formdataAttachment.append('campaignId', campaignId);
                                    formdataAttachment.append('documentGroupId', documentGroupId);
                                    formdataAttachment.append('churchId', churchId);
                                    formdataAttachment.append('documentTypeId', documentTypeId);
                                    formdataAttachment.append('fileName', attachedFileName);
                                    let responseAttachment = await uploadAttachment(formdataAttachment);
                                    if (responseAttachment.data.api_status === true) {
                                        await successMessage();
                                        props.history.push('/campaign/list-email')
                                    } else {
                                        await failedMessage(responseAttachment.data.message)
                                    }
                                } else {
                                    await successMessage();
                                    props.history.push('/campaign/list-email')
                                }
                            } catch (e) {
                                await failedMessage(e.message);
                            }
                        } else {
                            await failedMessage(res.data.message)
                        }
                    } else {
                        if (recipientMethodBasicCheck) {
                            resPublish = await getRecipientsBasic(recipientParams.gender, recipientParams.maritalStatus,
                                recipientParams.smallGroupMemberRole, recipientParams.ministryMemberRole,
                                recipientParams.userRole, recipientParams.nonSmallGroup, 'Published', campaignId);
                        } else {
                            resPublish = await getRecipientsQuery(query, 'Published', campaignId);
                        }

                        if (resPublish.data.api_status === true) {
                            try {
                                if (attachedFile && attachFileCheck) {
                                    let formdataAttachment = new FormData();
                                    formdataAttachment.append('file', attachedFile);
                                    formdataAttachment.append('campaignId', campaignId);
                                    formdataAttachment.append('documentGroupId', documentGroupId);
                                    formdataAttachment.append('churchId', churchId);
                                    formdataAttachment.append('documentTypeId', documentTypeId);
                                    formdataAttachment.append('fileName', attachedFileName);
                                    let responseAttachment = await uploadAttachment(formdataAttachment);
                                    if (responseAttachment.data.api_status === true) {
                                        await successMessage();
                                        props.history.push('/campaign/list-email')
                                    } else {
                                        await failedMessage(responseAttachment.data.message)
                                    }
                                } else {
                                    await successMessage();
                                    props.history.push('/campaign/list-email')
                                }
                            } catch (e) {
                                await failedMessage(e.message);
                            }
                        } else {
                            await failedMessage(res.data.message)
                        }
                    }
                } catch (e) {
                    await failedMessage(e.message);
                }
            } else {
                await failedMessage(res.data.message)
            }
        } catch (e) {
            await failedMessage(e.message);
        }
    }
    ,
})(CampaignEmailForm);

export default CampaignEmailFormik
