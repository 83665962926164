import React from 'react'

function FilterItem({ name, value, onClick, classname, title, subtitle }) {
	return (
		<div>
			<button
				className={`${classname} py-2 m-2 p-4 text-left border-grey-600 border-2 rounded-full focus:outline-none`}
				style={{ width: '230px' }}
				name={name}
				value={value}
				onClick={onClick}
			>
					{title}
					<br></br>
					{subtitle}
			</button>
		</div>
	)
}

export default FilterItem
