import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import CampusItem from './CampusItem'
import { CampusListContainer, CampusesContainer } from './styles'

export default class CampusList extends Component {
	renderData = () => {
		const { data, handleUpdateList, handleOnDeleteCampus, isSubmit } = this.props
		return (
			<Fragment>
				{data.map((item) => (
					<CampusItem
						key={item.id}
						id={item.id}
						name={item.campus_name}
						address={item.address}
						handleUpdateList={handleUpdateList}
						handleOnDeleteCampus={handleOnDeleteCampus}
						isSubmit={isSubmit}
					/>
				))}
			</Fragment>
		)
	}

	render() {
		const { isSubmit, data, errorMessage, isRedirect } = this.props
		const displayNoData = !isSubmit && !errorMessage && data.length === 0
		const displayError = !isSubmit && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<CampusListContainer>
				<CampusesContainer>
					{this.renderData()}
					{displayNoData && <div className="align-center">No data available</div>}
					{displayError && <div className="align-center">{errorMessage}</div>}
				</CampusesContainer>
			</CampusListContainer>
		)
	}
}
