import React, {useEffect, useState} from 'react'
import EventPreview from '../events/EventPreview'
import TextInput from 'components/commons/TextInput'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import InputDate from 'components/commons/InputDate'
import InputTime from 'components/commons/InputTime'
import InputNumber from 'components/commons/InputNumber'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import SelectOptions from 'components/commons/SelectOptions'
import ImageInput from 'components/commons/ImageInput'
import InputHTML from 'components/commons/InputHTML'
import Label from "../commons/Label";
import {dummyCampaignOption} from "../events/EventDummyObject";
import {cancelConfirmation, saveConfirmation, saveDraftConfirmation} from "../../libs/utils/confirmation";
import TextArea from "../commons/TextArea";
import InputRadioButton from "../commons/InputRadioButton";
import {getAllCampus} from "../../libs/apis/church";
import moment from "moment";
import {getEventDetail, getProgramCodeList, getProgramTypeList, getRoleList} from "../../libs/apis/events";
import {getHeadsAndDeputy, getMinistryHeadsAndDeputy, getUsers} from "../../libs/apis/ministry";
import DropdownAsync from "../commons/DropdownAsync";

function EditEventForm(props) {
    const [programCodeOption, setProgramCodeOption] = useState([]);
    const [programTypeOption, setProgramTypeOption] = useState([]);
    const [venueOption, setVenueOption] = useState([]);
    const [campaignOption, setCampaignOption] = useState([]);
    const [roleOption, setRoleOption] = useState([]);
    const [volunteerOption, setVolunteerOption] = useState([]);
    const [isStartEndDateInvalid, setIsStartEndDateInvalid] = useState(false);
    const [isStartEndSharingDateInvalid, setIsStartEndSharingDateInvalid] = useState(false);
    const [isStartEndRegistrationDateInvalid, setIsStartEndRegistrationDateInvalid] = useState(false);
    const [invalidDateSessionList, setInvalidDateSessionList] = useState([]);
    const [invalidTimeIndexList, setInvalidTimeIndexList] = useState([]);

    const [inputVolunteer, setInputVolunteer] = useState([]);

    const [isLoadingEventDetail, setIsLoadingEventDetail] = useState(false);
    const [isLoadingProgramCode, setIsLoadingProgramCode] = useState(false);
    const [isLoadingProgramType, setIsLoadingProgramType] = useState(false);
    const [isLoadingVenue, setIsLoadingVenue] = useState(false);
    const [isLoadingRoles, setIsLoadingRoles] = useState(false);
    const [isLoadingVolunteers, setIsLoadingVolunteers] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const displayLoading = isLoadingEventDetail && !errorMessage;
    const displayError = !isLoadingEventDetail && errorMessage;

    const eventId = props.match.params.id;

    useEffect(() => {
        setFieldValue('eventId', eventId);
    }, []);

    const {setFieldValue, isSubmitting, handleSubmit, errors} = props;
    const {
        eventTitle,
        eventDescription,
        speaker,
        termConditionCheck,
        eventTermCondition,
        eventStartDate,
        eventEndDate,
        eventSessionDetails,
        programCodeId,
        programTypeId,
        venue,
        venueName,
        venueAddressLine,
        eventBannerCheck,
        eventBannerImage,
        registrationCheck,
        registrationStartDate,
        registrationEndDate,
        quota,
        checkInCheck,
        startSharingDate,
        endSharingCheck,
        endSharingDate,
        sendCampaignCheck,
        campaignType,
        highlightCheck,
        eventPaymentCheck,
        eventPayment,
        pricePerRoleCheck,
        participants,
        priceAllRoles,
        volunteers,
        additionalInformation
    } = props.values;

    const fileName = eventBannerImage ? eventBannerImage.name || eventBannerImage.substring(eventBannerImage.lastIndexOf('/') + 1) : 'Click to upload your image here';

    useEffect(() => {
        fetchEventDetail();
        setCampaignOption(dummyCampaignOption);
        fetchVenueList();
        fetchProgramCodeList();
        fetchProgramTypeList();
        fetchRoles();
    }, []);

    useEffect(() => {
        fetchVolunteers();
    }, [volunteers]);

    const extractingEventSessionDetails = (unformattedSessionDetails) => {
        let formattedSessionDetails = [];

        for (const detail of unformattedSessionDetails) {
            formattedSessionDetails.push(
                {
                    date: moment(detail.timestart_at).format('YYYY/MM/DD'),
                    timeStartAt: moment(detail.timestart_at).format('HH:mm:ss'),
                    timeEndAt: moment(detail.timeend_at).format('HH:mm:ss'),
                    isAllDay: detail.isallday ? 1 : 0
                }
            )
        }

        return formattedSessionDetails;
    };

    const extractingParticipants = (unformattedParticipants) => {
        let formattedParticipants = [];

        if (unformattedParticipants.length === 1) {
            if (unformattedParticipants[0].smallgroupmemberrole_id === null ||
                unformattedParticipants[0].userrole_id === null ||
                unformattedParticipants[0].ministrymemberrole_id === null) {
                return [];
            }
        }

        for (const participant of unformattedParticipants) {
            let formattedId = null;
            if (participant.smallgroupmemberrole_id) {
                formattedId = 'smallgroupmemberrole_id.'.concat(participant.smallgroupmemberrole_id)
            } else if (participant.userrole_id) {
                formattedId = 'userrole_id.'.concat(participant.userrole_id)
            } else if (participant.ministrymemberrole_id) {
                formattedId = 'ministrymemberrole_id.'.concat(participant.ministrymemberrole_id)
            }

            formattedParticipants.push(
                {
                    id: formattedId,
                    price: participant.price
                }
            )
        }


        return formattedParticipants;
    };

    const checkPricePerRole = (unformattedParticipants) => {
        return unformattedParticipants.every(participant => participant.price === unformattedParticipants[0].price)
    };

    const gettingPriceAllRoles = (unformattedParticipants, pricePerRoleCheck) => {
        if (!pricePerRoleCheck) {
            return unformattedParticipants[0].price
        } else return 0;
    };

    const formattingVolunteers = (unformattedVolunteers) => {
        let formattedVolunteers = [];
        for (const volunteer of unformattedVolunteers) {
            if (volunteer.user !== null) {
                formattedVolunteers.push(
                    {
                        id: volunteer.user.id,
                        value: volunteer.user.id,
                        label: volunteer.user.fullname,
                    }
                );
            }
        }

        return formattedVolunteers;
    };

    const formattingEventDetailData = (eventDetailData) => {
        return {
            eventTitle: eventDetailData.name,
            eventDescription: eventDetailData.description,
            speaker: eventDetailData.programbreakouts[0].speaker ? eventDetailData.programbreakouts[0].speaker : '',
            termConditionCheck: !!eventDetailData.terms,
            eventTermCondition: eventDetailData.terms ? eventDetailData.terms : '',
            eventStartDate: moment(eventDetailData.programstart_at).format('YYYY/MM/DD hh:mm:ss'),
            eventEndDate: moment(eventDetailData.programend_at).format('YYYY/MM/DD hh:mm:ss'),
            eventSessionDetails: extractingEventSessionDetails(eventDetailData.programbreakouts[0].programbreakoutdates),
            programCodeId: eventDetailData.programcode_id,
            programTypeId: eventDetailData.programtype_id,
            venue: eventDetailData.programbreakouts[0].campusroom_id,
            venueName: eventDetailData.location ?? '',
            venueAddress: eventDetailData.locationaddress ?? '',
            eventBannerCheck: !!eventDetailData.bannerurl,
            eventBannerImage: eventDetailData.bannerurl ?? '',
            registrationCheck: !!eventDetailData.registerstart_at,
            registrationStartDate: moment(eventDetailData.registerstart_at).format('YYYY/MM/DD hh:mm:ss'),
            registrationEndDate: moment(eventDetailData.registerend_at).format('YYYY/MM/DD hh:mm:ss'),
            quota: eventDetailData.quotaPrograms,
            checkInCheck: eventDetailData.programbreakouts[0].isreqcheckin,
            startSharingDate: moment(eventDetailData.publishstart_at).format('YYYY/MM/DD hh:mm:ss'),
            endSharingCheck: !!eventDetailData.publishend_at,
            endSharingDate: moment(eventDetailData.publishend_at).format('YYYY/MM/DD hh:mm:ss'),
            highlightCheck: eventDetailData.ishighlight,
            participants: extractingParticipants(eventDetailData.programprices),
            pricePerRoleCheck: !checkPricePerRole(eventDetailData.programprices),
            priceAllRoles: gettingPriceAllRoles(eventDetailData.programprices, !checkPricePerRole(eventDetailData.programprices)),
            additionalInformation: eventDetailData.description2,
            volunteers: formattingVolunteers(eventDetailData.programvolunteers),


            // startTime: moment(eventDetailData.programstart_at, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            // endTime: moment(eventDetailData.programend_at, 'YYYY-MM-DD HH:mm').format('HH:mm'),
        }
    };

    const fetchEventDetail = async () => {
        setIsLoadingEventDetail(true);
        let eventDetail = {};
        try {
            let response = await getEventDetail(eventId, "Web", 1);

            let eventDetailData = response.data.data.program;

            if (eventDetailData) {
                eventDetail = formattingEventDetailData(eventDetailData);

                setFieldValue('eventTitle', eventDetail.eventTitle);
                setFieldValue('eventDescription', eventDetail.eventDescription);
                setFieldValue('speaker', eventDetail.speaker);
                setFieldValue('termConditionCheck', eventDetail.termConditionCheck);
                setFieldValue('eventTermCondition', eventDetail.eventTermCondition);
                setFieldValue('eventStartDate', eventDetail.eventStartDate);
                setFieldValue('eventEndDate', eventDetail.eventEndDate);
                setFieldValue('eventSessionDetails', eventDetail.eventSessionDetails);
                setFieldValue('programCodeId', eventDetail.programCodeId);
                setFieldValue('programTypeId', eventDetail.programTypeId);
                setFieldValue('venue', eventDetail.venue);
                setFieldValue('venueName', eventDetail.venueName);
                setFieldValue('venueAddressLine', eventDetail.venueAddress);
                setFieldValue('eventBannerCheck', eventDetail.eventBannerCheck);
                setFieldValue('eventBannerImage', eventDetail.eventBannerImage);
                setFieldValue('registrationCheck', eventDetail.registrationCheck);
                setFieldValue('registrationStartDate', eventDetail.registrationStartDate);
                setFieldValue('registrationEndDate', eventDetail.registrationEndDate);
                setFieldValue('highlightCheck', eventDetail.highlightCheck);
                setFieldValue('participants', eventDetail.participants);
                setFieldValue('pricePerRoleCheck', eventDetail.pricePerRoleCheck);
                setFieldValue('priceAllRoles', eventDetail.priceAllRoles);
                setFieldValue('quota', eventDetail.quota);
                setFieldValue('checkInCheck', eventDetail.checkInCheck);
                setFieldValue('startSharingDate', eventDetail.startSharingDate);
                setFieldValue('endSharingCheck', eventDetail.endSharingCheck);
                setFieldValue('endSharingDate', eventDetail.endSharingDate);
                setFieldValue('additionalInformation', eventDetail.additionalInformation);
                setFieldValue('volunteers', eventDetail.volunteers);
            }
            setIsLoadingEventDetail(false);
        } catch (error) {
            setIsLoadingEventDetail(false);
            setErrorMessage(error.message);
        }
    };

    const formattingCampusRoom = (campusroom) => {
        return {
            id: campusroom.campus_id,
            label: campusroom.name,
            value: campusroom.campus_id,
        }
    };

    const formattingCampusList = (unformattedCampusList) => {
        let venueList = [];

        for (const rooms of unformattedCampusList.campusrooms) {
            venueList.push(formattingCampusRoom(rooms))
        }

        return venueList;
    };

    const fetchVenueList = async () => {
        setIsLoadingVenue(true);
        try {
            let response = await getAllCampus();
            const campuslist = response.data.data.campus_list;

            if (campuslist) {
                let listVenue = [];
                for (const campus of campuslist) {
                    if (campus.campusrooms.length > 0) {
                        for (const venue of formattingCampusList(campus)) {
                            listVenue.push(venue)
                        }
                    }
                }
                setVenueOption(listVenue);
                setIsLoadingVenue(false);
            }
        } catch (error) {
            setIsLoadingVenue(false);
            setErrorMessage(error.message);
        }
    };

    const formattingProgramCodes = (code) => {
        return {
            id: code.id,
            label: code.name,
            value: code.id,
        }
    };

    const fetchProgramCodeList = async () => {
        setIsLoadingProgramCode(true);
        try {
            let response = await getProgramCodeList('Event');
            const programCodes = response.data.data.programcodes;

            if (programCodes) {
                let listProgramCodes = [];
                for (const code of programCodes) {
                    listProgramCodes.push(formattingProgramCodes(code));
                }
                setProgramCodeOption(listProgramCodes);
                setIsLoadingProgramCode(false);
            }
        } catch (error) {
            setIsLoadingProgramCode(false);
            setErrorMessage(error.message);
        }
    };

    const formattingProgramTypes = (type) => {
        return {
            id: type.id,
            label: type.name,
            value: type.id,
        }
    };

    const fetchProgramTypeList = async () => {
        setIsLoadingProgramType(true);
        try {
            let response = await getProgramTypeList('Event');
            const programTypes = response.data.data.programtype;

            if (programTypes) {
                let listProgramTypes = [];
                for (const type of programTypes) {
                    listProgramTypes.push(formattingProgramTypes(type));
                }
                setProgramTypeOption(listProgramTypes);
                setIsLoadingProgramType(false);
            }
        } catch (error) {
            setIsLoadingProgramType(false);
            setErrorMessage(error.message);
        }
    };

    const fetchRoles = async () => {
        setIsLoadingRoles(true);
        try {
            let response = await getRoleList();
            const roles = response.data.data;
            const regularRole = roles.role;
            const smallGroupMemberRole = roles.smallgroupmemberrole;
            const ministryMemberRole = roles.ministrymemberrole;
            let listRoles = [];
            if (regularRole) {
                for (const role of regularRole) {
                    listRoles.push(
                        {
                            id: 'userrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'userrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (smallGroupMemberRole) {
                for (const role of smallGroupMemberRole) {
                    listRoles.push(
                        {
                            id: 'smallgroupmemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'smallgroupmemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            if (ministryMemberRole) {
                for (const role of ministryMemberRole) {
                    listRoles.push(
                        {
                            id: 'ministrymemberrole_id.'.concat(role.id),
                            label: role.name,
                            value: 'ministrymemberrole_id.'.concat(role.id),
                        }
                    )
                }
            }

            setRoleOption(listRoles);
            // setFieldValue('listRoles', listRoles);
            setIsLoadingRoles(false);
        } catch (error) {
            setIsLoadingRoles(false);
            setErrorMessage(error.message);
        }
    };

    const fetchVolunteers = async () => {
        setIsLoadingVolunteers(true);
        try {
            let response = await getHeadsAndDeputy(100, 1);
            const volunteerData = response.data.data.user.data;

            let listVolunteer = [];

            for (const volunteer of volunteerData) {
                listVolunteer.push(
                    {
                        id: volunteer.id,
                        value: volunteer.id,
                        label: volunteer.fullname,
                    }
                )
            }

            // let filteredVolunteers = dummyVolunteers;
            let filteredVolunteers = listVolunteer;
            for (const selectedVolunteer of volunteers) {
                filteredVolunteers = filteredVolunteers.filter((volunteer) => volunteer.id !== selectedVolunteer.id);
            }
            setVolunteerOption(filteredVolunteers);
            // setVolunteerOption(listVolunteer);
            setIsLoadingVolunteers(false);
        } catch (error) {
            setIsLoadingVolunteers(false);
            setErrorMessage(error.message);
        }
    };

    useEffect(() => {
        eventStartDate > eventEndDate ? setIsStartEndDateInvalid(true) : setIsStartEndDateInvalid(false);
        startSharingDate > endSharingDate ? setIsStartEndSharingDateInvalid(true) : setIsStartEndSharingDateInvalid(false);
        registrationStartDate > registrationEndDate ? setIsStartEndRegistrationDateInvalid(true) : setIsStartEndRegistrationDateInvalid(false);

    }, [eventStartDate, eventEndDate, startSharingDate, endSharingDate, registrationStartDate, registrationEndDate]);

    useEffect(() => {
        if (!eventBannerCheck) {
            setFieldValue('eventBannerImage', '')
        }
    }, [eventBannerCheck]);

    useEffect(() => {
        const cloneEventessionDetails = [...eventSessionDetails];
        const cloneInvalidTimeIndexList = [...invalidTimeIndexList];
        cloneEventessionDetails.forEach((detail, index) => {
            if (detail.timeStartAt >= detail.timeEndAt && !detail.isAllDay) {
                if (!invalidTimeIndexList.includes(index)) {
                    cloneInvalidTimeIndexList.push(index);
                    setInvalidTimeIndexList(cloneInvalidTimeIndexList)
                }
            } else {
                const deleteIndex = invalidTimeIndexList.findIndex((itrIdx) => itrIdx === index);
                if (deleteIndex !== -1) {
                    cloneInvalidTimeIndexList.splice(deleteIndex, 1);
                    setInvalidTimeIndexList(cloneInvalidTimeIndexList)
                }
            }

            const cloneInvalidDateSessionList = [...invalidDateSessionList];
            if (detail.date === '') {
                if (!invalidDateSessionList.includes(index)) {
                    cloneInvalidDateSessionList.push(index);
                    setInvalidDateSessionList(cloneInvalidDateSessionList)
                }
            } else {
                const deleteIndex = invalidDateSessionList.findIndex((itrIdx) => itrIdx === index);
                if (deleteIndex !== -1) {
                    cloneInvalidDateSessionList.splice(deleteIndex, 1);
                    setInvalidDateSessionList(cloneInvalidDateSessionList)
                }
            }
        });
    }, [eventSessionDetails]);

    const handleEventTitleChange = (event) => {
        setFieldValue('eventTitle', event.target.value)
    };

    const handleEventDescriptionChange = (event) => {
        setFieldValue('eventDescription', event.target.value)
    };

    const handleSpeakerChange = (event) => {
        setFieldValue('speaker', event.target.value)
    };

    const handleEventTermConditionCheck = (event) => {
        if (termConditionCheck) {
            setFieldValue('eventTermCondition', '')
        }
        setFieldValue('termConditionCheck', !termConditionCheck)
    };

    const handleEventTermConditionChange = (value) => {
        setFieldValue('eventTermCondition', value)
    };

    const handleStartDateChange = (event) => {
        setFieldValue('eventStartDate', moment(event[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const handleEndDateChange = (event) => {
        setFieldValue('eventEndDate', moment(event[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const renderEventSessionDetails = () => {
        let eventSessionDetailsEl = [];
        let isTimeValid = false;

        eventSessionDetails.forEach((sessionDetail, index) => {

            eventSessionDetailsEl.push(
                <div key={index}>
                    <div className="flex items-center mb-2" key={index}>
                        <div className="w-1/2 flex items-center">
                            <div>
                                <InputDate
                                    name="eventStartDate"
                                    value={sessionDetail.date}
                                    handleChange={(event) => {
                                        handleEventSessionDateChange(index, event)
                                    }}
                                    isEdit={true}
                                    withSec={false}
                                />
                            </div>
                            <div className="w-1/2 ml-3">
                                <InputCheckBox checked={sessionDetail.isAllDay === 1} value={sessionDetail.isAllDay}
                                               onChange={() => handleOnCheckAllDay(index)} label="ALL DAYS"/>
                            </div>
                        </div>

                        <div className={'flex '.concat(sessionDetail.isAllDay ? 'invisible' : '')}>
                            <div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
                                <InputTime name="startTime" placeholder="Start" value={sessionDetail.timeStartAt}
                                           handleChange={(event) => {
                                               handleStartTimeChange(index, event)
                                           }}
                                           errors={errors.startTime}/>
                            </div>
                            <div className="md:w-1/2 w-full md:px-2">
                                <InputTime name="endTime" placeholder="End" value={sessionDetail.timeEndAt}
                                           handleChange={(event) => {
                                               handleEndTimeChange(index, event)
                                           }}
                                           errors={errors.endTime}/>
                            </div>
                        </div>

                        <i className="fas fa-times text-red-400 cursor-pointer flex items-center"
                           onClick={() => handleButtonDeleteSessionClick(index)}/>
                    </div>
                    <div className="mb-4 flex justify-between">
                        {
                            invalidDateSessionList.includes(index) ?
                                <span
                                    className="text-red-500 text-xs italic">Required</span>
                                :
                                <div/>
                        }
                        {
                            invalidTimeIndexList.includes(index) && !sessionDetail.isAllDay ?
                                <span
                                    className="text-red-500 text-xs italic">End Time should be greater than Start Time</span>
                                :
                                <div/>
                        }
                    </div>
                </div>
            )
        });

        return eventSessionDetailsEl;
    };

    const handleEventSessionDateChange = (index, event) => {
        const cloneEventSessionDetails = [...eventSessionDetails];
        // const toUpdateIndex = cloneEventSessionDetails.findIndex((detail) => detail.id === sessionDetailId);
        const toUpdatedObject = cloneEventSessionDetails[index];

        const updatedObject = Object.assign({}, toUpdatedObject, {
            date: moment(event[0]).format('YYYY/MM/DD')
        });

        const updatedArray = [
            ...eventSessionDetails.slice(0, index),
            updatedObject,
            ...eventSessionDetails.slice(index + 1)
        ];

        setFieldValue("eventSessionDetails", updatedArray);
    };

    const handleOnCheckAllDay = (index) => {
        const cloneEventSessionDetails = [...eventSessionDetails];
        // const toUpdateIndex = cloneEventSessionDetails.findIndex((detail) => detail.id === sessionDetailId);
        const toUpdatedObject = cloneEventSessionDetails[index];

        const updatedObject = Object.assign({}, toUpdatedObject, {
            isAllDay: +!toUpdatedObject.isAllDay
        });

        const updatedArray = [
            ...eventSessionDetails.slice(0, index),
            updatedObject,
            ...eventSessionDetails.slice(index + 1)
        ];

        setFieldValue("eventSessionDetails", updatedArray);
    };

    const handleStartTimeChange = (index, event) => {
        const cloneEventSessionDetails = [...eventSessionDetails];
        // const toUpdateIndex = cloneEventSessionDetails.findIndex((detail) => detail.id === sessionDetailId);
        const toUpdatedObject = cloneEventSessionDetails[index];

        // if (event[0] < toUpdatedObject.endTime ) {
        const updatedObject = Object.assign({}, toUpdatedObject, {
            timeStartAt: moment(event[0]).format('HH:mm:ss')
        });

        const updatedArray = [
            ...eventSessionDetails.slice(0, index),
            updatedObject,
            ...eventSessionDetails.slice(index + 1)
        ];

        setFieldValue("eventSessionDetails", updatedArray);
    };

    const handleEndTimeChange = (index, event) => {
        const cloneEventSessionDetails = [...eventSessionDetails];
        // const toUpdateIndex = cloneEventSessionDetails.findIndex((detail) => detail.id === sessionDetailId);
        const toUpdatedObject = cloneEventSessionDetails[index];

        // if (event[0] > toUpdatedObject.startTime) {
        const updatedObject = Object.assign({}, toUpdatedObject, {
            timeEndAt: moment(event[0]).format('HH:mm:ss')
        });

        const updatedArray = [
            ...eventSessionDetails.slice(0, index),
            updatedObject,
            ...eventSessionDetails.slice(index + 1)
        ];

        setFieldValue("eventSessionDetails", updatedArray);
        //     return true;
        // } else {
        //     return false;
        // }
    };

    const handleButtonAddSessionClick = () => {
        setFieldValue("eventSessionDetails", [...eventSessionDetails,
            {
                isAllDay: 0,
                date: eventStartDate ? moment(eventStartDate).format('YYYY/MM/DD') : '',
                timeStartAt: null,
                timeEndAt: null
            }
        ])
    };

    const handleButtonDeleteSessionClick = (index) => {
        const cloneEventSessionDetails = [...eventSessionDetails];
        // const toDeleteIndex = cloneEventSessionDetails.findIndex((detail) => detail.id === sessionDetailId);
        cloneEventSessionDetails.splice(index, 1);

        setFieldValue("eventSessionDetails", cloneEventSessionDetails);
    };

    const handleProgramCodeChange = (event) => {
        setFieldValue('programCodeId', event.target.value)
    };

    const handleProgramTypeChange = (event) => {
        setFieldValue('programTypeId', event.target.value)
    };

    const handleVenueChange = (event) => {
        setFieldValue('venue', event.target.value)
    };

    const handleVenueNameChange = (event) => {
        setFieldValue('venueName', event.target.value)
    };

    const handleVenueAddressLineChange = (event) => {
        setFieldValue('venueAddressLine', event.target.value)
    };

    const handleEventBannerCheck = () => {
        setFieldValue('eventBannerCheck', !eventBannerCheck)
    };

    const handleEventBannerImageChange = (event) => {
        setFieldValue('eventBannerImage', event.target.files[0])
    };

    const handleCheckRegistration = () => {
        setFieldValue('registrationCheck', !registrationCheck);
        if (registrationCheck) {
            setFieldValue('registrationStartDate', '');
            setFieldValue('registrationEndDate', '')
        }
    };

    const handleRegistrationStartDate = (date) => {
        setFieldValue('registrationStartDate', moment(date[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const handleRegistrationEndDate = (date) => {
        setFieldValue('registrationEndDate', moment(date[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const handleQuotaChange = (event) => {
        if (event.target.value) {
            event.target.value = parseInt(event.target.value);
            setFieldValue('quota', event.target.value)
        } else {
            setFieldValue('quota', 0)
        }
    };

    const handleCheckInCheck = () => {
        setFieldValue('checkInCheck', !checkInCheck);
    };

    const handleStartSharingDate = (event) => {
        setFieldValue('startSharingDate', moment(event[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const handleEndSharingCheck = () => {
        if (endSharingCheck) {
            setFieldValue('endSharingDate', '');
        }
        setFieldValue('endSharingCheck', !endSharingCheck)
    };

    const handleEndSharingDate = (event) => {
        setFieldValue('endSharingDate', moment(event[0]).format('YYYY/MM/DD hh:mm:ss'))
    };

    const handleSendCampaignCheck = () => {
        setFieldValue('sendCampaignCheck', !sendCampaignCheck);
    };

    const handleCampaignTypeChange = (event) => {
        setFieldValue('campaignType', event.target.value);
    };

    const handleHighlightCheck = () => {
        setFieldValue('highlightCheck', !highlightCheck);
    };

    const handleEventPaymentCheck = () => {
        setFieldValue('eventPaymentCheck', !eventPaymentCheck);
    };

    const handleEventPaymentChange = (event) => {
        setFieldValue('eventPayment', event.target.value);
    };

    useEffect(() => {
        if (!pricePerRoleCheck) {
            const cloneParticipants = [...participants];
            let updatedParticipants = [];
            for (const participant of cloneParticipants) {
                updatedParticipants.push(
                    Object.assign({}, participant, {
                        price: priceAllRoles
                    })
                );
            }
            setFieldValue('participants', updatedParticipants)
        }
    }, [pricePerRoleCheck, priceAllRoles]);

    const handlePricePerRoleCheck = () => {
        setFieldValue('pricePerRoleCheck', !pricePerRoleCheck);

        const cloneParticipants = [...participants];
        let updatedParticipants = [];
        for (const participant of cloneParticipants) {
            updatedParticipants.push(
                Object.assign({}, participant, {
                    price: 0
                })
            );
        }
        setFieldValue('participants', updatedParticipants)
    };

    const renderParticipants = () => {
        const participantEl = [];

        participants.forEach((participant, index) => {
            participantEl.push(
                <div className="flex" key={index}>
                    <div className="w-full">
                        <div className="mb-2"/>
                        <SelectOptions options={roleOption} value={participant.id}
                                       onChange={(event) => handleParticipantRoleChange(index, event)}/>
                    </div>
                    <div className={pricePerRoleCheck ? "w-1/2 ml-2" : "w-1/2 ml-2 hidden"}>
                        <InputNumber size="large" handleChange={(event) => handleParticipantPriceChange(index, event)}
                                     value={participant.price} min={0}/>
                    </div>
                    <i className="fas fa-times text-red-400 cursor-pointer flex items-center ml-2"
                       onClick={() => handleButtonDeleteParticipantClick(index)}/>
                </div>
            )
        });

        return participantEl;
    };

    const handleParticipantRoleChange = (index, event) => {
        const cloneParticipants = [...participants];
        // const toUpdateIndex = cloneParticipants.findIndex((participant) => participant.id === participantId);
        const toUpdatedObject = cloneParticipants[index];
        const updatedObject = Object.assign({}, toUpdatedObject, {
            id: event.target.value,
        });

        const updatedArray = [
            ...participants.slice(0, index),
            updatedObject,
            ...participants.slice(index + 1)
        ];
        setFieldValue("participants", updatedArray);
    };

    const handleParticipantPriceChange = (index, event) => {
        const cloneParticipants = [...participants];
        // const toUpdateIndex = cloneParticipants.findIndex((participant) => participant.id === participantId);
        const toUpdatedObject = cloneParticipants[index];
        const updatedObject = Object.assign({}, toUpdatedObject, {
            price: Number(event.target.value)
        });

        const updatedArray = [
            ...participants.slice(0, index),
            updatedObject,
            ...participants.slice(index + 1)
        ];
        setFieldValue("participants", updatedArray);
    };

    const handleButtonAddParticipantClick = () => {
        setFieldValue("participants",
            [...participants,
                {
                    id: roleOption[0].id,
                    price: 0
                }
            ]);
    };

    const handleButtonDeleteParticipantClick = (index) => {
        const cloneParticipants = [...participants];
        // const toDeleteIndex = cloneParticipants.findIndex((participant) => participant.id === participantId);
        cloneParticipants.splice(index, 1);
        setFieldValue("participants", cloneParticipants);
    };

    const handlePriceAllRolesChange = (event) => {
        if (event.target.value) {
            event.target.value = parseInt(event.target.value);
            setFieldValue('priceAllRoles', event.target.value)
        } else {
            setFieldValue('priceAllRoles', 0)
        }
    };

    const loadVolunteer = async () => {
        if (inputVolunteer.length >= 3) {
            const res = await getUsers(inputVolunteer);
            const data = res.data.data.user;

            if (res.data.api_status) {
                return data.map((item) => ({
                    id: item.id,
                    value: item.id,
                    label: item.fullname,
                }))
            }
        }
    };

    const handleInputVolunteer = (value) => {
        setInputVolunteer(value)
    };

    const handleSelectVolunteer = (event) => {
        setFieldValue('volunteers', event)
    };

    const handleAdditionalInformationChange = (event) => {
        setFieldValue('additionalInformation', event.target.value)
    };

    const handleOnCancel = async () => {
        const res = await cancelConfirmation();
        if (res.value) {
            props.history.push('/events/list')
        }
    };

    useEffect(() => {
        if (isStartEndDateInvalid || invalidTimeIndexList.length > 0 || invalidDateSessionList.length > 0 || (isStartEndSharingDateInvalid && endSharingCheck) || (isStartEndRegistrationDateInvalid && registrationCheck)) {
            setFieldValue('isDateValid', false);
        } else {
            setFieldValue('isDateValid', true);
        }
    }, [isStartEndDateInvalid, invalidTimeIndexList, invalidDateSessionList, isStartEndSharingDateInvalid, endSharingCheck, eventSessionDetails, isStartEndRegistrationDateInvalid, registrationCheck]);

    const handleOnDraft = async () => {
        const res = await saveDraftConfirmation();
        if (res.value) {
            setFieldValue('status', 'Draft');
            handleSubmit();
        }
    };

    const handleOnSubmit = async () => {
        const res = await saveConfirmation('Event Form');
        if (res.value) {
            setFieldValue('status', 'Published');
            handleSubmit();
        }
    };

    return (
        <div>
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayLoading && <Loading/>}
            {
                !displayLoading &&
                <div className="flex md:-mx-2 flex-wrap">
                    <div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
                        <div className="bg-white shadow-md p-6">
                            <div>
                                <TextInput label="EVENT TITLE" size="large" onChange={handleEventTitleChange}
                                           value={eventTitle}
                                           name="eventTitle" errors={errors.eventTitle}/>
                            </div>

                            <TextArea label="DESCRIPTION" name="eventDescription" value={eventDescription}
                                      handleOnChange={handleEventDescriptionChange}
                                      errors={errors.eventDescription}/>

                            <TextInput label="SPEAKER" size="large" onChange={handleSpeakerChange} value={speaker}
                            />

                            <div className="mb-6">
                                <InputCheckBox name="isEventTermCondition" value={termConditionCheck}
                                               checked={termConditionCheck} onChange={handleEventTermConditionCheck}
                                               label="TERMS AND CONDITIONS"/>
                                {termConditionCheck &&
                                <InputHTML name="eventTermCondition" value={eventTermCondition}
                                           onChange={handleEventTermConditionChange}
                                />}
                            </div>
                            <div className="mb-6">
                                <div className="flex flex-wrap md:-mx-2">
                                    <div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
                                        <InputDate
                                            isEdit={true}
                                            name="eventStartDate"
                                            label="START DATE"
                                            value={eventStartDate}
                                            handleChange={handleStartDateChange}
                                            withSec={false}
                                            errors={errors.eventStartDate}
                                        />
                                    </div>
                                    <div className="md:w-1/2 w-full md:px-2">
                                        <InputDate isEdit={true} name="eventEndDate" label="END DATE"
                                                   value={eventEndDate}
                                                   handleChange={handleEndDateChange} withSec={false}
                                                   errors={errors.eventEndDate}/>
                                        {
                                            isStartEndDateInvalid && (
                                                <span className="text-red-500 text-xs italic">End Date should be greater than or equal to Start Date
                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-6">
                                <div className="flex flex-col">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        EVENT SESSION DETAILS
                                    </label>
                                    {renderEventSessionDetails()}
                                </div>
                                <div className="flex justify-end">
                                    <Button handleClick={handleButtonAddSessionClick} type="primary" size="ml-4">
                                        Add Session
                                    </Button>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        {
                                            isLoadingProgramCode ?
                                                <Loading/>
                                                :
                                                <SelectOptions label="PROGRAM CODE" value={programCodeId}
                                                               options={programCodeOption}
                                                               onChange={handleProgramCodeChange}
                                                               errors={errors.programCodeId}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        {
                                            isLoadingProgramType ?
                                                <Loading/>
                                                :
                                                <SelectOptions label="PROGRAM TYPE" value={programTypeId}
                                                               options={programTypeOption}
                                                               onChange={handleProgramTypeChange}
                                                               errors={errors.programTypeId}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        {
                                            isLoadingVenue ?
                                                <Loading/>
                                                :
                                                <SelectOptions name="venue" label="VENUE" value={venue}
                                                               options={venueOption}
                                                               onChange={handleVenueChange} errors={errors.venue}/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <TextInput label="VENUE NAME" size="large" onChange={handleVenueNameChange}
                                       value={venueName}
                                       name="vanueName" errors={errors.venueName}/>
                            <TextArea label="VENUE ADDRESS LINE 2" name="vanueAddressLine2" value={venueAddressLine}
                                      handleOnChange={handleVenueAddressLineChange}
                                      errors={errors.venueAddressLine}/>

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">USE EVENT
                                            BANNER</label>
                                        <div className="flex">
                                            <div className="w-1/2">
                                                <InputRadioButton checked={eventBannerCheck} value={eventBannerCheck}
                                                                  onChange={handleEventBannerCheck} label="YES"/>
                                            </div>
                                            <div className="w-1/2">
                                                <InputRadioButton checked={!eventBannerCheck} value={eventBannerCheck}
                                                                  onChange={handleEventBannerCheck} label="NO"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                eventBannerCheck &&
                                <ImageInput onChange={handleEventBannerImageChange} imageName={fileName}
                                            label={"UPLOAD IMAGE"}
                                            isImage={true}
                                            errors={errors.eventBannerImage}/>
                            }

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">OPEN FOR
                                            REGISTRATION</label>
                                        <div className="flex">
                                            <div className="w-1/2">
                                                <InputRadioButton checked={registrationCheck} value={registrationCheck}
                                                                  onChange={handleCheckRegistration} label="YES"/>
                                            </div>
                                            <div className="w-1/2">
                                                <InputRadioButton checked={!registrationCheck} value={registrationCheck}
                                                                  onChange={handleCheckRegistration} label="NO"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {registrationCheck && (
                                <div className="mb-6">
                                    <div className="flex flex-wrap md:-mx-2">
                                        <div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
                                            <InputDate name="eventStartRegis" value={registrationStartDate}
                                                       handleChange={handleRegistrationStartDate} withSec={true}
                                                       isEdit={true}
                                                       errors={errors.eventStartRegis}/>
                                        </div>
                                        <div className="md:w-1/2 w-full md:px-2">
                                            <InputDate name="eventEndRegis" value={registrationEndDate}
                                                       handleChange={handleRegistrationEndDate} withSec={true}
                                                       isEdit={true}
                                                       errors={errors.eventEndRegis}/>
                                            {
                                                isStartEndRegistrationDateInvalid && registrationCheck ?
                                                    <span className="text-red-500 text-xs italic">End Date should be greater than or equal to Start Date
                                            </span>
                                                    : <div/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="mb-6">
                                <div className="flex md:-mx-2">
                                    <div className="md:w-1/2 w-full md:px-2">
                                        <InputNumber label="EVENT PARTICIPANT QUOTA" value={quota}
                                                     handleChange={handleQuotaChange} min={0} errors={errors.quota}/>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            CHECK IN REQUIRED
                                        </label>
                                        <div className="flex">
                                            <div className="w-1/2">
                                                <InputRadioButton checked={checkInCheck} value={checkInCheck}
                                                                  onChange={handleCheckInCheck} label="YES"/>
                                            </div>
                                            <div className="w-1/2">
                                                <InputRadioButton checked={!checkInCheck} value={checkInCheck}
                                                                  onChange={handleCheckInCheck} label="NO"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                checkInCheck ?
                                    <div className="mb-6 w-1/2">
                                        <DropdownAsync
                                            label="VOLUNTEER"
                                            value={volunteers}
                                            loadOptions={loadVolunteer}
                                            handleInputChange={handleInputVolunteer}
                                            onChange={handleSelectVolunteer}
                                            errors={errors.keyVolunteers}
                                            defaultOptions={volunteerOption}
                                            isLoading={isLoadingVolunteers}
                                            isMulti={true}
                                        />
                                    </div>
                                    :
                                    <div/>
                            }

                            <div className="mb-6">
                                <div className="flex flex-wrap md:-mx-2">
                                    <div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
                                        <InputDate
                                            label="START SHARING ON"
                                            name="startDateShare"
                                            value={startSharingDate}
                                            handleChange={handleStartSharingDate}
                                            withSec={false}
                                            isEdit={true}
                                            errors={errors.startSharingDate}
                                        />
                                    </div>
                                    <div className="md:w-1/2 w-full md:px-2">
                                        <div className="mb-1">
                                            <InputCheckBox name="isEndDateShare" value={endSharingDate}
                                                           checked={endSharingCheck}
                                                           onChange={handleEndSharingCheck} label="END SHARING ON"/>
                                        </div>
                                        {endSharingCheck &&
                                        <InputDate name="endDateShare" value={endSharingDate}
                                                   handleChange={handleEndSharingDate}
                                                   isEdit={true}
                                                   withSec={false}/>}
                                    </div>
                                </div>
                                {
                                    isStartEndSharingDateInvalid && endSharingCheck ?
                                        <span className="text-red-500 text-xs italic">End Date should be greater than or equal to Start Date
                                </span>
                                        : <div/>
                                }
                            </div>

                            {/*<div className="mt-3 mb-6">*/}
                            {/*    <label className="items-center">*/}
                            {/*        <InputCheckBox name="isSendCampaign" label="SEND AS CAMPAIGN"*/}
                            {/*                       onChange={handleSendCampaignCheck}*/}
                            {/*                       value={sendCampaignCheck} checked={sendCampaignCheck}/>*/}
                            {/*        {sendCampaignCheck && (*/}
                            {/*            <div className="flex mt-2">*/}
                            {/*                <div className="w-1/2">*/}
                            {/*                    <SelectOptions name="campaignType" options={campaignOption}*/}
                            {/*                                   value={campaignType}*/}
                            {/*                                   onChange={handleCampaignTypeChange}/>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*    </label>*/}
                            {/*</div>*/}

                            <div className="mb-6">
                                <div className="flex">
                                    <div className="w-1/2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            IS HIGHLIGHT
                                        </label>
                                        <div className="flex">
                                            <div className="w-1/2">
                                                <InputRadioButton checked={highlightCheck} value={highlightCheck}
                                                                  onChange={handleHighlightCheck} label="YES"/>
                                            </div>
                                            <div className="w-1/2">
                                                <InputRadioButton checked={!highlightCheck} value={highlightCheck}
                                                                  onChange={handleHighlightCheck} label="NO"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-3">
                                <label className="items-center">
                                    <InputCheckBox name="isPricePerRole" label="PRICE PER ROLE"
                                                   onChange={handlePricePerRoleCheck}
                                                   value={pricePerRoleCheck} checked={pricePerRoleCheck}/>
                                </label>
                            </div>

                            {
                                isLoadingRoles ?
                                    <Loading/>
                                    :
                                    <div className="mb-6">
                                        <Label>PARTICIPANTS</Label>
                                        {
                                            participants.length > 0 ?
                                                renderParticipants()
                                                :
                                                <div className="text-center text-grey-300">This is available for all
                                                    roles.</div>
                                        }
                                        <div className="flex justify-end mt-6">
                                            <Button handleClick={handleButtonAddParticipantClick} type="primary"
                                                    size="ml-4">
                                                Add Participant
                                            </Button>
                                        </div>
                                    </div>
                            }

                            {
                                !pricePerRoleCheck && (
                                    <div className="mb-6">
                                        <label className="items-center">
                                            <InputNumber size="large" handleChange={handlePriceAllRolesChange}
                                                         label="PRICE FOR ALL ROLES"
                                                         value={priceAllRoles}
                                                         min={0}
                                                         name="price all roles"/>
                                        </label>
                                    </div>
                                )
                            }

                            <TextArea label="ADDITIONAL INFORMATION"
                                      additionalLabel="(Sent along with confirmation email)"
                                      name="additionalInformation" value={additionalInformation}
                                      handleOnChange={handleAdditionalInformationChange}
                                      errors={errors.additionalInformation}
                            />

                            <div className="flex">
                                <div className="w-1/2">
                                    <Button type="cancel" handleClick={handleOnCancel}>
                                        {isSubmitting ? <Loading secondary/> : 'Cancel'}
                                    </Button>
                                </div>
                                <div className="w-1/2">
                                    <div className="flex justify-end">
                                        <Button handleClick={handleOnDraft} type="secondary">
                                            {isSubmitting ? <Loading primary/> : 'Save As Draft'}
                                        </Button>

                                        <Button type="primary" handleClick={handleOnSubmit} size="ml-4">
                                            {isSubmitting ? <Loading secondary/> : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:w-1/3 w-full px-2">
                        <EventPreview
                            eventBanner={eventBannerImage}
                            eventTitle={eventTitle}
                            startDate={eventStartDate}
                            endDate={eventEndDate}
                            eventSessionDetails={eventSessionDetails}
                            venueName={venueName}
                            venueAddressLine={venueAddressLine}
                            eventDescription={eventDescription}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default EditEventForm;
