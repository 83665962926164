import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loading from 'components/commons/Loading'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import SpecialServiceForm from 'components/forms/SpecialServiceForm'
import { campusList, getServiceTypes } from 'libs/apis/campus'

class SpecialService extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		campuses: [],
		campusSelected: '',
		errorForm: '',
		dataServices: [],
	}

	async componentDidMount() {
		try {
			const response = await campusList()
			this.setState({ campuses: response.data.data })
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.props.history.push('/login')
					this.setState({ isRedirect: true })
				}
			}
		}
	}

	handleOnSelected = event => {
		this.setState({ campusSelected: event.target.value, isLoading: true }, async () => {
			try {
				const response = await getServiceTypes(this.state.campusSelected)
				if (response.data.api_status) {
					const services = response.data.data
						.filter(filtered => filtered.is_special === false)
						.map(item => ({
							service_type: item.id,
							counter: 0,
							title: item.title,
						}))
					this.setState({ isLoading: false, dataServices: services })
				} else {
					this.setState({ isLoading: false, errorForm: response.data.message })
				}
			} catch (error) {
				if (error.response) {
					if (error.response.status === 401) {
						await localStorage.removeItem('token')
						this.setState({ isRedirect: true })
					} else {
						const message = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'
						this.setState({ isLoading: false, errorForm: message })
					}
				}
			}
		})
	}

	render() {
		const { isLoading, isRedirect, errorForm, campuses, campusSelected, dataServices } = this.state

		const displayLoading = isLoading && !errorForm
		const displayForm = !isLoading && !errorForm && dataServices.length > 0

		const breads = [{ url: '', title: 'Input Data' }]

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content flex-1 bg-gray-100 pb-24 md:p-6">
				<PageBreadcrumbs icon={HomeIcon} title="Input Data" breads={breads} />
				<div className="mb-6">
					<div className="inline-block">
						<select className="form-select border-0 rounded-lg shadow-md mr-6" value={campusSelected} onChange={this.handleOnSelected}>
							<option value="" disabled>
								Choose
							</option>
							{campuses.map(item => (
								<option key={item.id} value={item.id}>
									{item.title}
								</option>
							))}
						</select>
					</div>
				</div>
				{displayLoading && <Loading />}
				{displayForm && <SpecialServiceForm campusId={campusSelected} dataServices={dataServices} />}
			</div>
		)
	}
}

export default SpecialService
