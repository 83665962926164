import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getClassRole, getVenues, getListCode, getClassType } from 'libs/apis/courses'
import ReactQuill from 'react-quill'

import TextInput from 'components/commons/TextInput'
import TextArea from 'components/commons/TextArea'
import Label from 'components/commons/Label'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import SelectOptions from 'components/commons/SelectOptions'
import Button from 'components/commons/Button'
import DaySession from 'components/courses/DaySession'
import ParticipantSession from 'components/courses/ParticipantSession'
import EditClassPreview from './EditClassPreview'
import StartDate from 'components/commons/StartDate'
import EndDate from 'components/commons/EndDate'
import Loading from 'components/commons/Loading'
import InputNumber from 'components/commons/InputNumber'
import DropdownItem from 'components/commons/DropdownItem'
import { saveDraftConfirmation, cancelConfirmation } from 'libs/utils/confirmation'

function EditClassForm(props) {
	const [listVenue, setListVenue] = useState([])
	const [roles, setRoles] = useState([])
	const [listCode, setListCode] = useState([])
	const [listType, setListType] = useState([])

	const { setFieldValue, errors, handleChange, isSubmitting, handleSubmit, allVolunteer } = props
	const {
		classTitle,
		classCode,
		classDescription,
		classStartDate,
		classEndDate,
		classVenue,
		classVenueName,
		classVenueAddress,
		classCheckRegis,
		classStartRegis,
		classEndRegis,
		startDateShare,
		endDateShare,
		isEndDateShare,
		classPaymentPerRole,
		classParticipantQuota,
		classSpeaker,
		classMinSession,
		classPriceAll,
		classAditionnalInformation,
		classType,
		ishighlights,
		dateList,
		classVolunteers,
		campaignByMail,
		campaignByNotif,
	} = props.values

	useEffect(() => {
		const fetchRole = async () => {
			try {
				const res = await getClassRole()
				const ministrymemberrole = res.data.data.ministrymemberrole.map((v) => {
					const temp = {}
					temp.id = `ministrymemberrole_id.${v.id}`
					temp.name = v.name
					return temp
				})
				const role = res.data.data.role.map((v) => {
					const temp = {}
					temp.id = `userrole_id.${v.id}`
					temp.name = v.name
					return temp
				})
				const smallgroupmemberrole = res.data.data.smallgroupmemberrole.map((v) => {
					const temp = {}
					temp.id = `smallgroupmemberrole_id.${v.id}`
					temp.name = v.name

					return temp
				})

				setRoles(ministrymemberrole.concat(role, smallgroupmemberrole))
				setFieldValue('allRoles', ministrymemberrole.concat(role, smallgroupmemberrole))
			} catch (error) {
				console.log(error)
			}
		}
		fetchRole()
	}, [])

	useEffect(() => {
		const fetchVenues = async () => {
			try {
				const res = await getVenues()
				const venues = res.data.data.campus_list.map((v) => v.campusrooms)
				const a = venues
					.map((v) => {
						let element
						for (let i = 0; i < v.length; i++) {
							element = v[i]
						}
						return element
					})
					.filter((v) => v !== undefined)
				setListVenue([...a, { id: 'OTHERS', name: 'OTHERS' }])
			} catch (error) {
				console.log(error)
			}
		}

		fetchVenues()
	}, [])

	useEffect(() => {
		const fetchClassType = async () => {
			try {
				const res = await getClassType()
				setListType(res.data.data.programtype)
			} catch (error) {
				console.log(error)
			}
		}

		fetchClassType()
	}, [])

	useEffect(() => {
		const fetchListCode = async () => {
			try {
				const res = await getListCode()
				const codes = res.data.data.programcodes.map((v) => {
					const temp = {}
					temp.id = v.id
					temp.name = v.name
					return temp
				})

				setListCode(codes)
			} catch (error) {
				console.log(error)
			}
		}

		fetchListCode()
	}, [])

	const handleOnChange = (e) => {
		setFieldValue(e.target.name, e.target.value)
	}

	const handleChangeClassCode = (e) => {
		setFieldValue('classCode', e)
	}

	const handleStartEndDate = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD HH:mm:ss'))
	}

	const handleCheckRegistration = () => {
		setFieldValue('classCheckRegis', !classCheckRegis)
		if (classCheckRegis) {
			setFieldValue('classStartRegis', '')
			setFieldValue('classEndRegis', '')
		}
	}

	const handleCheckPaymentRole = () => {
		setFieldValue('classPaymentPerRole', !classPaymentPerRole)
		if (!classPaymentPerRole) {
			setFieldValue('classPriceAll', '')
		}
	}

	const handleStartEndWithSec = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD HH:mm:ss'))
	}

	const handleStartEndRegis = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY HH:mm:ss').format('YYYY/MM/DD HH:mm:ss'))
	}

	const handleOnCheckShare = (event) => {
		const value = event.target.value === 'false'

		setFieldValue('isEndDateShare', value)
		if (!value) {
			setFieldValue('endDateShare', '')
		}
	}

	const handleTime = (e, index, name) => {
		if (name === 'date') {
			const value = moment(e[0]).format('YYYY/MM/DD')
			const list = [...props.values.dateList]
			list[index][name] = value
			setFieldValue('dateList', list)
		} else {
			const value = moment(e[0]).format('HH:mm')
			const list = [...props.values.dateList]
			list[index][name] = value
			setFieldValue('dateList', list)
		}
	}

	const handleRemoveDay = (e, index) => {
		const list = [...props.values.dateList]
		list.splice(index, 1)
		setFieldValue('dateList', list)
	}

	const handleAddDay = () => {
		const list = [...props.values.dateList]
		list.splice(list.length, 0, {
			date: '',
			timeStartAt: '',
			timeEndAt: '',
			isAllDay: 0,
		})
		setFieldValue('dateList', list)
	}

	const handleAddParticipant = () => {
		const list = [...props.values.priceList]
		list.splice(list.length, 0, { id: '', price: '' })
		setFieldValue('priceList', list)
	}

	const handleRemoveParticipant = (index) => {
		const list = [...props.values.priceList]
		list.splice(index, 1)
		console.log(list, 'remove')
		setFieldValue('priceList', list)
	}

	const handleParticipant = (e, index) => {
		const { value, name } = e.target
		const list = [...props.values.priceList]
		list[index][name] = value
		setFieldValue('priceList', list)
	}

	const handleDescription = (e) => {
		setFieldValue('classDescription', e)
	}

	const handleHighlight = () => {
		setFieldValue('ishighlights', !ishighlights)
	}

	const handleSelectVolunteers = (e) => {
		setFieldValue('classVolunteers', e)
	}

	const handleCampaignByEmail = () => {
		setFieldValue('campaignByMail', !campaignByMail)
	}

	const handleCampaignByNotif = () => {
		setFieldValue('campaignByNotif', !campaignByNotif)
	}

	const handleCancel = async (event) => {
		const response = await cancelConfirmation()
		if (response.value) {
			props.history('/classes')
		}
	}

	const handleOnDraft = async (e) => {
		const confirm = await saveDraftConfirmation()

		if (confirm.value) {
			setFieldValue('isDraft', true)
			handleSubmit(e)
		}
	}

	return (
		<div className="flex w-full flex-wrap">
			<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
				<div className="bg-white shadow-md p-6">
					<div className="mb-4">
						<TextInput name="classTitle" value={classTitle} label="CLASS TITLE" onChange={handleOnChange} errors={errors.classTitle} />
					</div>
					<div className="mb-6">
						<DropdownItem
							name="classCode"
							label="CLASS CODE"
							value={classCode}
							handleChange={handleChangeClassCode}
							options={listCode}
							isMulti={false}
							errors={errors.classCode}
						/>
					</div>
					<div className="mb-6">
						<SelectOptions name="classType" value={classType} label="CLASS TYPE" options={listType} onChange={handleOnChange} errors={errors.classType} />
					</div>
					<div className="mb-4">
						<label className="ml-1 block text-gray-700 text-sm font-bold mb-2">DESCRIPTION</label>
						<ReactQuill value={classDescription} onChange={handleDescription} />
					</div>
					<div className="flex justify-between mb-4">
						<StartDate
							styles="mr-4"
							name="classStartDate"
							label="START DATE"
							value={classStartDate}
							handleChange={handleStartEndDate}
							withSec={true}
							errors={errors.classStartDate}
						/>
						<EndDate
							name="classEndDate"
							label="END DATE"
							startDate={classStartDate}
							value={classEndDate}
							handleChange={handleStartEndDate}
							withSec={true}
							errors={errors.classEndDate}
						/>
					</div>
					<div className="mb-4">
						<Label>DAYS</Label>
						{dateList.map((v, idx) => (
							<DaySession
								idx={idx}
								handleRemoveDay={handleRemoveDay}
								length={dateList.length}
								date={v.date}
								startTimeDay={v.timeStartAt}
								endTimeDay={v.timeEndAt}
								handleTime={handleTime}
							/>
						))}
						<div className="flex justify-end mt-2">
							<button className="bg-primary border-primary border-2 text-white font-bold shadow-md rounded-lg text-sm p-1 focus:outline-none mr-4" onClick={handleAddDay}>
								Add Session
							</button>
						</div>
					</div>

					<div className="mb-4">
						<SelectOptions name="classVenue" value={classVenue} label="VENUE" options={listVenue} size="md" onChange={handleChange} />
					</div>
					{classVenue == 'OTHERS' && (
						<>
							<div className="mb-4">
								<TextInput name="classVenueName" value={classVenueName} label="VENUE NAME" onChange={handleChange} />
							</div>
							<div className="mb-4">
								<TextArea name="classVenueAddress" value={classVenueAddress} label="VENUE ADDRESS" handleOnChange={handleChange} />
							</div>
						</>
					)}

					<div className="mb-4">
						<div className="flex">
							<div className="w-1/2">
								<label className="block text-gray-700 text-sm font-bold mb-2">OPEN FOR REGISTRATION</label>
								<div className="flex">
									<div className="w-1/2">
										<InputCheckBox checked={classCheckRegis} value={classCheckRegis} onChange={handleCheckRegistration} label="YES" />
									</div>
									<div className="w-1/2">
										<InputCheckBox checked={!classCheckRegis} value={!classCheckRegis} onChange={handleCheckRegistration} label="NO" />
									</div>
								</div>
							</div>
						</div>
						{classCheckRegis && (
							<div className="mb-6 mt-4">
								<div className="flex flex-wrap md:-mx-2">
									<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
										<StartDate
											styles="mr-4"
											name="classStartRegis"
											value={classStartRegis}
											handleChange={handleStartEndRegis}
											withSec={true}
											errors={errors.classStartRegis}
										/>
									</div>

									<div className="md:w-1/2 w-full md:px-2">
										<EndDate
											name="classEndRegis"
											startDate={classStartRegis}
											value={classEndRegis}
											handleChange={handleStartEndRegis}
											withSec={true}
											errors={errors.classEndRegis}
										/>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="mb-4">
						<TextInput name="classSpeaker" label="SPEAKER" value={classSpeaker} onChange={handleChange} />
					</div>

					<div className="flex justify-between mb-4">
						<TextInput name="classParticipantQuota" label="CLASS PARTICIPANT QUOTA" value={classParticipantQuota} onChange={handleChange} styles="w-full" />
						<TextInput name="classMinSession" label="MINIMUM SESSION COMPLETION" value={classMinSession} onChange={handleChange} styles="w-full ml-4" />
					</div>

					<div className="mb-6">
						<div className="flex flex-wrap md:-mx-2">
							<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
								<StartDate
									label="START SHARING ON"
									name="startDateShare"
									value={startDateShare}
									handleChange={handleStartEndWithSec}
									withSec={true}
									errors={errors.startDateShare}
								/>
							</div>
							<div className="md:w-1/2 w-full md:px-2">
								<InputCheckBox name="isEndDateShare" value={isEndDateShare} checked={isEndDateShare} onChange={handleOnCheckShare} label="END SHARING ON" />

								{isEndDateShare && (
									<div className="mt-1">
										<EndDate
											name="endDateShare"
											startDate={startDateShare}
											value={endDateShare}
											handleChange={handleStartEndWithSec}
											withSec={true}
											errors={errors.endDateShare}
										/>
									</div>
								)}
							</div>
						</div>
					</div>

					<InputCheckBox name="classPaymentPerRole" label="PRICE PER ROLE" value={classPaymentPerRole} checked={classPaymentPerRole} onChange={handleCheckPaymentRole} />
					<div className="mt-4">
						<Label>PARTICIPANTS</Label>
						{props.values.priceList.length ? (
							props.values.priceList.map((v, idx) => (
								<ParticipantSession
									idx={idx}
									role={v.id}
									price={v.price}
									classPaymentPerRole={classPaymentPerRole}
									handleRemoveParticipant={handleRemoveParticipant}
									handleChange={handleParticipant}
									roles={roles}
								/>
							))
						) : (
							<div className="text-center text-grey-500 text-bold">This is available for all roles</div>
						)}
						<div className="flex justify-end">
							<button
								className="bg-primary mb-2 mt-2 border-primary border-2 text-sm font-bold shadow-md p-1 rounded-lg text-white focus:outline-none"
								onClick={handleAddParticipant}
							>
								Add Participant
							</button>
						</div>
						{!classPaymentPerRole && (
							<div className="mt-4 mb-4">
								<InputNumber size="large" handleChange={handleChange} label="PRICE FOR ALL ROLES" value={classPriceAll} min={0} name="classPriceAll" />
							</div>
						)}
					</div>

					<TextArea name="classAditionnalInformation" label="ADITIONAL INFORMATION" value={classAditionnalInformation} handleOnChange={handleChange} />

					<div className="mb-6">
						<DropdownItem label="VOLUNTEERS" name="classVolunteers" options={allVolunteer} value={classVolunteers} isMulti={true} handleChange={handleSelectVolunteers} />
					</div>

					<div className="mb-4">
						<InputCheckBox name="ishighlights" value={ishighlights} checked={ishighlights} onChange={handleHighlight} label="HIGHLIGHT" />
					</div>

					<div className="mb-4">
						<InputCheckBox name="campaignByMail" value={campaignByMail} checked={campaignByMail} onChange={handleCampaignByEmail} label="CAMPAIGN BY EMAIL" />
					</div>

					<div className="mb-4">
						<InputCheckBox name="campaignByNotif" value={campaignByNotif} checked={campaignByNotif} onChange={handleCampaignByNotif} label="CAMPAIGN BY NOTIFICATION" />
					</div>

					<div className="flex justify-between">
						<div className="w-1/2">
							<Button type="cancel" handleClick={handleCancel}>
								{isSubmitting ? <Loading secondary /> : 'Cancel'}
							</Button>
						</div>
						<div className="w-1/2">
							<div className="flex justify-end">
								<Button type="secondary" handleClick={handleOnDraft}>
									{isSubmitting ? <Loading primary /> : 'Save as Draft'}
								</Button>
								<Button type="primary" style="ml-4" handleClick={handleSubmit}>
									{isSubmitting ? <Loading secondary /> : 'Submit'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="md:w-1/3 w-full px-2">
				<EditClassPreview
					classTitle={classTitle}
					classCode={classCode.label}
					classSessions={dateList}
					classDescription={classDescription}
					classStartDate={classStartDate}
					classEndDate={classEndDate}
					classStartRegis={classStartRegis}
					classEndRegis={classEndRegis}
					classVenueName={classVenueName}
					classVenueAddress={classVenueAddress}
				/>
			</div>
		</div>
	)
}

export default EditClassForm
