import React from 'react'
import TableBody from '../commons/table/TableBody'
import ActionsRow from '../commons/table/ActionsRow'
import TableHeader from '../commons/table/TableHeader'

const AttendanceTable = (props) => {
	const sessions = [...Array(props.session)].map((session, index) => {
		return {
			key: index + 1,
			label: index + 1,
			content: (registrant, index) => {
				if (registrant.attendance[index] === true) {
					return (
						<td className="px-2 py-4 text-center">
							<div onClick={() => props.handleUnCheck(registrant, index + 1)} className="btn-check-attendance check-green cursor-pointer">
								<i className="far fa-check-square" />
							</div>
						</td>
					)
				}
				return (
					<td className="px-2 py-4 text-center">
						<div onClick={() => props.handleCheck(registrant, index + 1)} className="btn-check-attendance check-white cursor-pointer">
							<i className="far fa-square" />
						</div>
					</td>
				)
			},
		}
	})

	const columns = [
		{ label: 'Name', path: 'fullname', key: 'fullname' },
		{
			label: 'GROUP NAME',
			key: 'groupName',
			content: (registrant) => {
				return <td className="px-2 py-4">{registrant.groupName ? registrant.groupName : '-'}</td>
			},
		},
		sessions,
		{},
	]

	return (
		<div className="bg-white shadow-md overflow-x-auto mx-2 md:mx-0 whitespace-no-wrap">
			<table className="min-w-full">
				<TableHeader columns={columns} />
				<TableBody data={props.attendances} columns={columns} />
			</table>
		</div>
	)
}

export default AttendanceTable
