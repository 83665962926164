import { withFormik } from 'formik'
import EditClassCodeForm from 'components/forms/EditClassCodeForm'
import { failedMessage, somethingWrong, successMessage } from '../../libs/utils/confirmation'
import { editClassCode } from 'libs/apis/courses'

const EditClassCodeFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		id: '',
		classCode: '',
		className: '',
		minimumOfSession: '',
		numberOfSession: '',
		reqLeaderApproval: false,
		reqAdminApproval: false,
		prerequisiteClass: [],
		genderList: [],
		maritalStatusList: [],
		roleList: [],
		ministryList: [],
		typeList: [],
		basicRecipientMethodList: [],
	}),
	// validationSchema: ,
	handleSubmit: async (values, { props }) => {
		const {
			id,
			classCode,
			className,
			reqLeaderApproval,
			reqAdminApproval,
			genderList,
			maritalStatusList,
			roleList,
			ministryList,
			typeList,
			minimumOfSession,
			numberOfSession,
			prerequisiteClass,
		} = values

		let formattedReqfamily = 0
		let formattedReqchildren = 0
		let formattedIsCouple = 0

		let formattedGender = ''
		if (genderList.length === 1) {
			formattedGender = genderList[0]
		}
		if (genderList.length > 1 || genderList.length === 0) {
			formattedGender = ''
		}

		const prerequisite = prerequisiteClass.map((v) => v.id)

		if (typeList.length >= 1) {
			formattedIsCouple = 1
			if (typeList.includes('type0')) {
				formattedIsCouple = 1
			}
			if (typeList.includes('type2')) {
				formattedReqfamily = 1
				formattedReqchildren = 1
			} else if (typeList.includes('type1') && !typeList.includes('type1')) {
				formattedReqfamily = 1
				formattedReqchildren = 0
			}
		}

		let formdata = new FormData()
		formdata.append('id', id)
		formdata.append('type', 'Class')
		formdata.append('classCode', classCode)
		formdata.append('className', className)
		formdata.append('numberSession', numberOfSession)
		formdata.append('minimumSession', minimumOfSession)
		formdata.append('gender', formattedGender)
		formdata.append('reqfamily', formattedReqfamily)
		formdata.append('reqchildren', formattedReqchildren)
		formdata.append('leaderapproval', reqLeaderApproval ? 1 : 0)
		formdata.append('adminapproval', reqAdminApproval ? 1 : 0)
		formdata.append('roleId', roleList)
		formdata.append('ministryId', ministryList)
		formdata.append('classPrerequisiteId', prerequisite.toString())
		formdata.append('maritalStatusId', maritalStatusList)
		formdata.append('iscouple', formattedIsCouple)

		for (const pair of formdata.entries()) {
		}

		try {
			let res = await editClassCode(formdata)
			if (res.data.api_status === true) {
				successMessage()
				props.history.push('/classes/list-code')
			} else {
				failedMessage(res.data.message)
			}
		} catch (error) {
			somethingWrong()
		}
	},
})(EditClassCodeForm)

export default EditClassCodeFormik
