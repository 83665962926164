import React, { useState, useEffect } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { Helmet } from 'react-helmet'
import { ReactComponent as MinistryIcon } from 'assets/icons/ministry.svg'
import MinistryTable from 'components/ministry/MinistryTable'
import { getMinistryList, closeMinistry, getGroups } from 'libs/apis/ministry'
import Loading from 'components/commons/Loading'
import { Redirect } from 'react-router-dom'
import alert from 'services/alert'
import queryString from 'query-string'
import PaginationPage from 'components/commons/PaginationPage'
import Searchbar from 'components/commons/Searchbar'
import ButtonExport from 'components/commons/ButtonDropdown'
import MinistryFilter from 'components/ministry/MinistryFilter'
import MinistriesExport from 'components/ministry/MinistriesExport'
import MinistryMemberExport from 'components/ministry/MinistryMemberExport'
import { helperSelectFilter } from 'libs/utils/utils'
import { SmallGroupHeader } from 'screens/smallGroup/styles'

export const MinistryContext = React.createContext()

function ListMinistry(props) {
	const [ministryData, setMinistryData] = useState([])
	const [levels, setLevels] = useState('Ministry')
	const [subGroups, setSubGroups] = useState([])
	const [groups, setGroups] = useState([])
	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [ministryGroups, setMinistryGroups] = useState([])
	const [ministrySubGroups, setMinistrySubGroups] = useState([])

	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [sortBy, setSortBy] = useState('id')
	const [sortDir, setSortDir] = useState('DESC')
	const [error, setError] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List of Ministry' }]

	const { location } = props
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`

	useEffect(() => {
		handleLoadData()
	}, [location.search, searchValue, groups, subGroups, sortBy, sortDir])

	useEffect(() => {
		const fetchGroupsSubGroups = async () => {
			try {
				const res = await getGroups()
				setMinistryGroups(res.data.data.ministrygroups)
				setMinistrySubGroups(res.data.data.ministrysubgroups)
			} catch (error) {
				console.log(error)
			}
		}
		fetchGroupsSubGroups()
	}, [])

	const handleLoadData = async () => {
		const { search } = props.location
		const page = search ? queryString.parse(search).page : 1

		setIsLoading(true)
		try {
			const res = await getMinistryList(levels, 10, subGroups.toString(), groups.toString(), sortBy, sortDir, searchValue, page)

			setMinistryData(res.data.data.ministryList.data)
			setCurrentPage(res.data.data.ministryList.current_page)
			setLastPage(res.data.data.ministryList.last_page)
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setError('We currently are investigating this issues, Please try again later')
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleCloseGroup = async (ministry_id) => {
		let res = await alert.confirmCloseMinistry()

		if (res.value) {
			let formdata = new FormData()

			formdata.set('ministry_id', ministry_id)

			let res = await closeMinistry(formdata)

			if (res.data.api_status) {
				alert.succeed('Success', 'Ministry has been closed')
				handleLoadData()
			}
		}
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDir('ASC')
		} else {
			if (sortDir === 'ASC') {
				setSortBy(sort)
				setSortDir('DESC')
			} else if (sortDir === 'DESC') {
				setSortBy(sort)
				setSortDir('ASC')
			}
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'level') {
			setLevels(event.target.value)
		}
		if (event.target.name === 'subGroups') {
			helperSelectFilter(subGroups, event.target.value, setSubGroups)
		}
		if (event.target.name === 'groups') {
			helperSelectFilter(groups, event.target.value, setGroups)
		}
	}

	const handleApplyFilter = () => {
		handleLoadData()
	}

	const handleClearFilter = () => {
		setLevels('Ministry')
		setSubGroups('')
		setGroups('')
	}

	const shouldDisplayLoad = isLoading && !error
	const shouldDisplayNoData = !isLoading && !error && !ministryData.length
	const shouldDisplayData = !isLoading && !error && ministryData.length > 0
	const shouldDisplayErr = !isLoading && error

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<MinistryContext.Provider value={ministryData}>
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<title>
						{name} - List of {levels}
					</title>
				</Helmet>
				<PageBreadcrumbs icon={MinistryIcon} title={`List of ${levels}`} breads={breads} />

				<SmallGroupHeader>
					<MinistryFilter
						levels={levels}
						subGroups={subGroups}
						groups={groups}
						ministryGroups={ministryGroups}
						ministrySubGroups={ministrySubGroups}
						handleSelectFilter={handleSelectFilter}
						handleClearFilter={handleClearFilter}
						handleApplyFilter={handleApplyFilter}
					/>
					{levels == 'Ministry' ? <MinistriesExport levels={levels} ministryGroups={ministryGroups} ministrySubGroups={ministrySubGroups} /> : <> </>}
					<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
				</SmallGroupHeader>
				<div>
					{shouldDisplayLoad && <Loading />}
					{shouldDisplayNoData && <div className="text-center">No data available</div>}
					{shouldDisplayErr && <div className="text-center">{error}</div>}
					{shouldDisplayData && (
						<div>
							<MinistryTable levels={levels} data={ministryData} handleSort={handleSort} handleCloseGroup={handleCloseGroup} />
							<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={ministryData.length} url={props.location.pathname} />
						</div>
					)}
				</div>
			</div>
		</MinistryContext.Provider>
	)
}

export default ListMinistry
