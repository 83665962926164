import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ReactQuill from 'react-quill'

import TextInput from 'components/commons/TextInput'
import TextArea from 'components/commons/TextArea'
import Label from 'components/commons/Label'
import CheckBox from 'components/commons/Checkbox'
import SelectOptions from 'components/commons/SelectOptions'
import Button from 'components/commons/Button'
import DaySession from 'components/courses/DaySession'
import ParticipantSession from 'components/courses/ParticipantSession'
import ClassPreview from './ClassPreview'
import StartDate from 'components/commons/StartDate'
import EndDate from 'components/commons/EndDate'
import DropdownAsync from 'components/commons/DropdownAsync'
import DropdownItem from 'components/commons/DropdownItem'
import Loading from 'components/commons/Loading'
import InputRadioButton from "components/commons/InputRadioButton";
import { saveDraftConfirmation, cancelConfirmation } from 'libs/utils/confirmation'
import { getMinistryHeadsAndDeputy } from 'libs/apis/ministry'

function ClassForm(props) {
	const [dateList, setDateList] = useState([
		{
			date: '',
			timeStartAt: '',
			timeEndAt: '',
			isAllDays: false,
		},
	])
	const [inputValueVolunteer, setInputValueVolunteer] = useState('')
	const [priceList, setPriceList] = useState([])
	const [priceAllRoles, setPriceAllRoles] = useState(0)
	const [codeClass, setCodeClass] = useState('')

	const { setFieldValue, errors, handleChange, handleSubmit, listCode, listType, listVenue, roles, isSubmitting, allVolunteer, isLoading } = props
	const {
		classTitle,
		classDescription,
		classStartDate,
		classEndDate,
		classVenue,
		classVenueName,
		classVenueAddress,
		classCheckRegis,
		classStartRegis,
		classEndRegis,
		startDateShare,
		endDateShare,
		isEndDateShare,
		classPaymentPerRole,
		classParticipantQuota,
		classSpeaker,
		classMinSession,
		classPriceAll,
		classAditionnalInformation,
		classType,
		classVolunteers,
		ishighlights,
		campaignByMail,
		campaignByNotif,
		isReqCheckIn,
		isCheckForAll
	} = props.values

	const handleOnChange = (e) => {
		setFieldValue(e.target.name, e.target.value)
	}

	const handleStartEndDate = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY').format('YYYY-MM-DD'))
	}

	const handleCheckRegistration = () => {
		setFieldValue('classCheckRegis', !classCheckRegis)
		if (classCheckRegis) {
			setFieldValue('classStartRegis', '')
			setFieldValue('classEndRegis', '')
		}
	}

	const handleCheckPaymentRole = () => {
		const list = priceList.map(item => {
			return {
				id: item.id,
				price: item.price = 0
			}
		})
		setPriceList(list)
		setFieldValue('classPaymentPerRole', !classPaymentPerRole)
		if (!classPaymentPerRole) { // uncheck
			setFieldValue('classPriceAll', classPriceAll)
		} else { //check
			setFieldValue('classPriceAll', '')
		}
	}

	const handleReqCheckIn = () => {
		setFieldValue('classVolunteers', null)
		setFieldValue('isCheckForAll', 0)
		setFieldValue('isReqCheckIn',!isReqCheckIn)
	}

	const handleCheckForAll = () => {
		setFieldValue('isCheckForAll',!isCheckForAll)
	}

	const handleStartEndWithSec = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
	}

	const handleStartEndRegis = (_, value, attribute) => {
		setFieldValue(attribute.input.name, moment(new Date(_[0]), 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
	}

	const handleOnCheckShare = () => {
		setFieldValue('isEndDateShare', !isEndDateShare)
		if (!isEndDateShare) {
			setFieldValue('endDateShare', '')
		}
	}

	const handleTime = (e, index, name) => {
		if (name === 'date') {
			const value = moment(e[0]).format('YYYY-MM-DD HH:mm:ss')
			const list = [...dateList]
			list[index][name] = value
			setDateList(list)
			setFieldValue('dateList', list)
		} else {
			const value = moment(e[0]).format('HH:mm')
			const list = [...dateList]

			list[index][name] = value
			
			setDateList([...list])
			setFieldValue('dateList', list)
		}
	}

	const handleRemoveDay = (e, index) => {
		const list = [...dateList]
		list.splice(index, 1)
		setDateList(list)
		setFieldValue('dateList', list)
	}

	const handleAddDay = () => {
		setDateList([
			...dateList,
			{
				date: '',
				timeStartAt: '',
				timeEndAt: '',
				isAllDays: false,
			},
		])
	}

	const handleOnCheckAllDays = (index) => {
		const classDateList = [...dateList];
		const toUpdatedObject = classDateList[index];

		const updatedObject = {
			...toUpdatedObject,
			isAllDay: !toUpdatedObject.isAllDay
		}

		const updatedArray = [
			...dateList.slice(0, index),
			updatedObject,
			...dateList.slice(index + 1)
		];

		setDateList(updatedArray)
		setFieldValue("dateList", updatedArray);
	};

	const handleAddParticipant = () => {
		const list = [...priceList]
		list.splice(list.length, 0, { id: '', price: '' })
		setPriceList(list)
		setFieldValue('priceList', list)
	}

	const handleRemoveParticipant = (index) => {
		const list = [...priceList]
		list.splice(index, 1)
		setPriceList(list)
		setFieldValue('priceList', list)
	}

	const handleParticipant = (e, index) => {
		const { value, name } = e.target
		const list = [...priceList]
		list[index][name] = value
		setPriceList(list)
		setFieldValue('priceList', list)
	}

	const handlePriceAllRoles = (e) => {
		const { value } = e.target
		setFieldValue('classPriceAll', value)
		setPriceAllRoles(value)
		// 
		// const list = [...priceList]
		// list[index][name] = value
		// setPriceList(list)
		// setFieldValue('priceList', list)
	}

	const handleSelectClassCode = (e) => {
		if (e !== null) {
			setFieldValue('codeClass', e)
			setCodeClass({ ...codeClass, value: e.value, label: e.label })
		} else {
			setFieldValue('codeClass', e)
			setCodeClass({ ...codeClass, value: e.value, label: e.label })
		}
	}

	const handleDescription = (e) => {
		setFieldValue('classDescription', e)
	}

	const handleHighlight = () => {
		setFieldValue('ishighlights', !ishighlights)
	}

	const handleSelectVolunteers = (e) => {
		setFieldValue('classVolunteers', e)
	}

	const handleCampaignByEmail = () => {
		setFieldValue('campaignByMail', !campaignByMail)
	}

	const handleCampaignByNotif = () => {
		setFieldValue('campaignByNotif', !campaignByNotif)
	}

	const handleInputVolunteers = (value) => {
		setInputValueVolunteer(value)
	}

	const loadVolunteers = async () => {
		if (inputValueVolunteer.length > 2) {
			const res = await getMinistryHeadsAndDeputy(inputValueVolunteer)
			const data = res.data.data.user

			if (res.data.api_status) {
				const options = data.map((item) => ({
					value: item.id,
					label: item.fullname,
				}))
				return options
			}
		}
	}

	const handleCancel = async (event) => {
		const response = await cancelConfirmation()
		if (response.value) {
			props.history('/classes')
		}
	}

	const handleOnDraft = async (e) => {
		const confirm = await saveDraftConfirmation()

		if (confirm.value) {
			setFieldValue('isDraft', true)
			handleSubmit(e)
		}
	}

	return (
		<div className="flex w-full flex-wrap">
			<div className="md:w-2/3 w-full px-2 mb-4 md:mb-0">
				<div className="bg-white shadow-md p-6">
					<div className="mb-4">
						<TextInput name="classTitle" value={classTitle} label="CLASS TITLE" onChange={handleOnChange} errors={errors.classTitle} />
					</div>
					<div className="mb-6">
						<DropdownItem name="codeClass" label="CLASS CODE" value={codeClass} options={listCode} handleChange={handleSelectClassCode} errors={errors.codeClass} />
					</div>
					<div className="mb-6">
						<SelectOptions name="classType" value={classType} label="CLASS TYPE" options={listType} onChange={handleOnChange} errors={errors.classType} />
					</div>
					<div className="mb-4">
						<label className="ml-1 block text-gray-700 text-sm font-bold mb-2">DESCRIPTION</label>
						<ReactQuill value={classDescription} onChange={handleDescription} />
					</div>
					<div className="flex justify-between mb-4">
						<StartDate
							styles="mr-4"
							name="classStartDate"
							label="START DATE"
							value={classStartDate}
							handleChange={handleStartEndDate}
							withSec={false}
							errors={errors.classStartDate}
						/>
						<EndDate
							name="classEndDate"
							label="END DATE"
							startDate={classStartDate}
							value={classEndDate}
							handleChange={handleStartEndDate}
							withSec={false}
							errors={errors.classEndDate}
						/>
					</div>
					<div className="mb-4">
						<Label>DAYS</Label>
						{dateList.map((v, idx) => (
								<DaySession
									key={idx}
									idx={idx}
									handleRemoveDay={handleRemoveDay}
									length={dateList.length}
									date={v.date}
									startTimeDay={v.timeStartAt}
									endTimeDay={v.timeEndAt}
									handleTime={handleTime}
									isAllDay={v.isAllDay || false}
									handleOnCheckAllDays={handleOnCheckAllDays}
									errorDate={errors.classSessionDate && v.date ? null : 'Required'}
									errorStartTime={errors.classSessionStartTime && v.timeStartAt ? null : 'Required' }
									errorEndTime={errors.classSessionEndTime && v.timeEndAt ? null : 'Required'}
								/>
						))}
						
						<div className="flex justify-end mt-2">
							<button className="bg-primary border-primary border-2 text-white font-bold shadow-md rounded-lg text-sm p-1 focus:outline-none mr-4" onClick={handleAddDay}>
								Add Session
							</button>
						</div>
					</div>

					{/* <div className="flex justify-between mb-4">
						<InputTime name="classStartTime" label="START TIME" value={classStartTime} handleChange={handleStartEndTime} errors={errors.classStartTime} styles="mr-4" />
						<InputTime name="classEndTime" label="END TIME" value={classEndTime} handleChange={handleStartEndTime} errors={errors.classEndTime} />
					</div> */}

					<div className="mb-4">
						<SelectOptions name="classVenue" value={classVenue} label="VENUE" options={listVenue} size="md" onChange={handleChange} errors={errors.classVenue} />
					</div>
					{classVenue == 'OTHERS' && (
						<>
							<div className="mb-4">
								<TextInput name="classVenueName" value={classVenueName} label="VENUE NAME" onChange={handleChange} />
							</div>
							<div className="mb-4">
								<TextArea name="classVenueAddress" value={classVenueAddress} label="VENUE ADDRESS" handleOnChange={handleChange} />
							</div>
						</>
					)}

					<div className="mb-4">
						<div className="flex">
							<div className="w-1/2">
								<label className="block text-gray-700 text-sm font-bold mb-2">OPEN FOR REGISTRATION</label>
								<div className="flex">
									<div className="w-1/2">
										<InputRadioButton checked={classCheckRegis} value={classCheckRegis} onChange={handleCheckRegistration} label="YES" />
									</div>
									<div className="w-1/2">
										<InputRadioButton checked={!classCheckRegis} value={!classCheckRegis} onChange={handleCheckRegistration} label="NO" />
									</div>
								</div>
							</div>
						</div>
						{classCheckRegis && (
							<div className="mb-6 mt-4">
								<div className="flex flex-wrap md:-mx-2">
									<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
										<StartDate
											styles="mr-4"
											name="classStartRegis"
											value={classStartRegis}
											handleChange={handleStartEndRegis}
											withSec={true}
											errors={errors.classStartRegis}
										/>
									</div>
									<div className="md:w-1/2 w-full md:px-2">
										<EndDate
											name="classEndRegis"
											startDate={classStartRegis}
											value={classEndRegis}
											handleChange={handleStartEndRegis}
											withSec={true}
											errors={errors.classEndRegis}
										/>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="mb-4">
						<TextInput name="classSpeaker" label="SPEAKER" value={classSpeaker} onChange={handleChange} errors={errors.classSpeaker} />
					</div>

					<div className="flex justify-between mb-4">
						<TextInput
							name="classParticipantQuota"
							label="CLASS PARTICIPANT QUOTA"
							value={classParticipantQuota}
							onChange={handleChange}
							errors={errors.classParticipantQuota}
							styles="w-full"
						/>
						<TextInput
							name="classMinSession"
							label="MINIMUM SESSION COMPLETION"
							value={classMinSession}
							onChange={handleChange}
							errors={errors.classMinSession}
							styles="w-full ml-4"
						/>
					</div>

					<div className="mb-6">
						<div className="flex flex-wrap md:-mx-2">
							<div className="md:w-1/2 w-full mb-6 md:mb-0 md:px-2">
								<StartDate
									label="START SHARING ON"
									name="startDateShare"
									value={startDateShare}
									handleChange={handleStartEndWithSec}
									withSec={true}
									errors={errors.startDateShare}
								/>
							</div>
							<div className="md:w-1/2 w-full md:px-2">
								<CheckBox label="END SHARING ON" name="isEndDateShare" handleCheck={handleOnCheckShare} checked={isEndDateShare} />

								{isEndDateShare && (
									<div className="mt-1">
										<EndDate
											name="endDateShare"
											startDate={startDateShare}
											value={endDateShare}
											handleChange={handleStartEndWithSec}
											withSec={true}
											errors={errors.endDateShare}
										/>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* <InputCheckBox name="classCampaign" label="SEND AS CAMPAIGN" onChange={handleCheckSendAsCampaign} />
					{sendAsCampaign && (
						<div className="w-1/2 mt-2 mb-4">
							<SelectOptions name="classCampaigns" options={campaigns} value={classCampaigns} onChange={handleChange} />
						</div>
					)} */}

					{/* <InputCheckBox name="classPayment" label="CLASS PAYMENT" onChange={handleCheckPayment} />
					{isClassPayment && (
						<div className="w-1/2 mt-1 mb-4">
							<TextInput name="classPaymentValue" value={classPaymentValue} onChange={handleChange} placeholder="Input payment" />
						</div>
					)} */}

					<CheckBox label="PRICE PER ROLE" name="classPaymentPerRole" handleCheck={handleCheckPaymentRole} checked={classPaymentPerRole} />

					<div className="mt-4">
						<Label>PARTICIPANTS</Label>
						{!priceList.length ? (
							<div className="text-center text-grey-500 text-bold">This is available for all roles</div>
						) : (
							priceList.map((v, idx) => (
								<ParticipantSession
									key={idx}
									idx={idx}
									role={v.id}
									price={v.price}
									classPaymentPerRole={classPaymentPerRole}
									handleRemoveParticipant={handleRemoveParticipant}
									handleChange={handleParticipant}
									roles={roles}
								/>
							))
						)}
						<div className="flex justify-end">
							<button
								className="bg-primary mb-2 mt-2 border-primary border-2 text-sm font-bold shadow-md p-1 rounded-lg text-white focus:outline-none"
								onClick={handleAddParticipant}
							>
								Add Participant
							</button>
						</div>
						{!classPaymentPerRole && (
							<div className="mt-4 mb-4">
								<TextInput name="classPriceAll" label="PRICE FOR ALL ROLES" placeholder="Price" value={classPriceAll} onChange={handlePriceAllRoles} />
								{/* <InputNumber size="large" handleChange={(e) => handleChange(e,idx)} label="PRICE FOR ALL ROLES" value={classPriceAll} min={0} name="classPriceAll" /> */}
							</div>
						)}
					</div>

					<TextArea name="classAditionnalInformation" label="ADITIONAL INFORMATION" value={classAditionnalInformation} handleOnChange={handleChange} />

					<CheckBox label="REQUIRE CHECK IN" name="reqCheckIn" handleCheck={handleReqCheckIn} checked={isReqCheckIn} />
					{
						isReqCheckIn ?
						<>
							<CheckBox label="CHECK FOR ALL" name="checkForAll" handleCheck={handleCheckForAll} checked={isCheckForAll} />
							<div className="mb-6">
								<DropdownAsync
									label="VOLUNTEERS"
									value={classVolunteers}
									loadOptions={loadVolunteers}
									isMulti={true}
									handleInputChange={handleInputVolunteers}
									onChange={handleSelectVolunteers}
									defaultOptions={allVolunteer}
									errors={errors.classVolunteers}
									isLoading={isLoading}
								/>
							</div> 
						</> : null
					}

					<div className="mb-4">
						<CheckBox label="HIGHLIGHT" name="ishighlights" handleCheck={handleHighlight} checked={ishighlights} />
					</div>

					<div className="mb-4">
						<CheckBox label="CAMPAIGN BY EMAIL" name="campaignByMail" handleCheck={handleCampaignByEmail} checked={campaignByMail} />
					</div>

					<div className="mb-4">
						<CheckBox label="CAMPAIGN BY NOTIFICATION" name="campaignByNotif" handleCheck={handleCampaignByNotif} checked={campaignByNotif} />
					</div>

					<div className="flex justify-between">
						<div className="w-1/2">
							<Button type="cancel" handleClick={handleCancel}>
								{isSubmitting ? <Loading secondary /> : 'Cancel'}
							</Button>
						</div>
						<div className="w-1/2">
							<div className="flex justify-end">
								<Button type="secondary" handleClick={handleOnDraft}>
									{isSubmitting ? <Loading primary /> : 'Save as Draft'}
								</Button>
								<Button type="primary" style="ml-4" handleClick={handleSubmit}>
									{isSubmitting ? <Loading secondary /> : 'Submit'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="md:w-1/3 w-full px-2">
				<ClassPreview
					classTitle={classTitle}
					classCode={codeClass.label}
					classSessions={dateList}
					classDescription={classDescription}
					classStartDate={classStartDate}
					classEndDate={classEndDate}
					classStartRegis={classStartRegis}
					classEndRegis={classEndRegis}
					classVenueName={classVenueName}
					classVenueAddress={classVenueAddress}
				/>
			</div>
		</div>
	)
}

export default ClassForm
