import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import InputDate from 'components/commons/InputDate'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Input } from 'components/smallGroup/Styles'
import FilterItem from 'components/commons/FilterItem'
import { constants } from 'constants/constant'
import Label from 'components/commons/Label'

export default function CourseFilter({ startDate, endDate, handleClearFilter, handleStartDate, handleEndDate, handleApplyFilter }) {
	const [tab, setTab] = useState('time')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const customStyles = {
		content: {
			top: '10%',
			left: '17%',
			right: '25%',
			width: '1101px',
			height: '180px',
			backgroundColor: 'white',
			paddingLeft: '2px',
			paddingRight: '0px',
			overflow: 'hidden',
			borderRadius: '12px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.6)',
		},
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('time')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const handleApply = () => {
		setModalIsOpen(!modalIsOpen)
		handleApplyFilter()
	}

	const length = startDate || endDate
	const timeTab = tab === 'time'

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Filter
				</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Ministry Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<div>
						<Input tab={tab === 'time'} type="button" value="time" onClick={handleOnTab} />
					</div>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div className="flex flex-wrap justify-arround">
						{timeTab && (
							<div className="flex mt-2">
								<div className="flex">
									<Label>Start Date</Label>
									<InputDate name="startTime" handleChange={handleStartDate} withSec={false} isEdit={true} />
								</div>
								<div className="flex ml-4">
									<Label>End Date</Label>
									<InputDate name="endTime" handleChange={handleEndDate} withSec={false} isEdit={true} />
								</div>
							</div>
						)}
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
