import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import CreateMultiplicationFormik from 'components/formik/CreateMultiplicationFormik'
import CreateNewGroupFormik from 'components/formik/CreateNewGroupFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import InputRadioButton from 'components/commons/InputRadioButton'

function CreateSmallGroup(props) {
	const [tab, setTab] = useState('Multiplication')
	const [check, setCheck] = useState(true)

	const handleOnTab = (e) => {
		setTab(e.target.value)
		setCheck(!check)
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create Group' }]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Small Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="Create Group" breads={breads} />

			<div className="flex bg-white shadow-md pl-4 pt-4">
				<div className="w-1/2">
					<InputRadioButton value="Multiplication" label="MULTIPLICATION" onChange={handleOnTab} checked={check} />
				</div>
				<div className="w-1/2">
					<InputRadioButton value="New" label="NEW GROUP" onChange={handleOnTab} checked={!check} />
				</div>
			</div>

			<div>
				{tab === 'Multiplication' && <CreateMultiplicationFormik {...props} type={tab} />}
				{tab === 'New' && <CreateNewGroupFormik {...props} type={tab} />}
			</div>
		</div>
	)
}

export default CreateSmallGroup
