import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getClassRole, getVenues, getListCode, getClassType, getClassDetail } from 'libs/apis/courses'
import { getMinistryHeadsAndDeputy } from 'libs/apis/ministry'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import EditClassFormik from 'components/formik/EditClassFormik'

function EditClass(props) {
	const [state, setState] = useState({
		classTitle: '',
		classCode: '',
		classDescription: '',
		classStartDate: '',
		classEndDate: '',
		classStartTime: '',
		classEndTime: '',
		classVenue: '',
		classVenueName: '',
		classVenueAddress: '',
		classCheckRegis: '',
		classStartRegis: '',
		classEndRegis: '',
		startDateShare: '',
		endDateShare: '',
		isEndDateShare: '',
		classPaymentPerRole: '',
		classParticipantQuota: '',
		classSpeaker: '',
		classMinSession: '',
		classPriceAll: '',
		classAditionnalInformation: '',
		classType: '',
		dateList: [],
		priceList: [],
		allRoles: [],
	})
	const [listVenue, setListVenue] = useState([])
	const [roles, setRoles] = useState([])
	const [allRolesFormik, setAllRolesFormik] = useState([])
	const [listCode, setListCode] = useState([])
	const [listType, setListType] = useState([])
	const [allVolunteer, setAllVolunteer] = useState([])

	const { id } = useParams()

	useEffect(() => {
		const fetchDetailClass = async () => {
			try {
				const res = await getClassDetail(id)

				let datelist = []
				let pricelist = []
				let priceall = ''

				if (res.data.data.program.programbreakouts.length) {
					const a = res.data.data.program.programbreakouts[0].programbreakoutdates.map((v) => {
						const t = {}

						t.date = v.timestart_at
						t.timeStartAt = moment(v.timestart_at).format('HH:mm')
						t.timeEndAt = moment(v.timeend_at).format('HH:mm')
						t.isAllDay = 0
						return t
					})
					datelist = a
				}

				if (res.data.data.program.programprices.length) {
					for (const v of res.data.data.program.programprices) {
						let formattedId = null
						let formattedprice = null

						if (v.smallgroupmemberrole_id !== null) {
							formattedId = `smallgroupmemberrole_id.${v.smallgroupmemberrole_id}`
							formattedprice = v.price
							pricelist.push({
								id: formattedId,
								price: formattedprice,
							})
						} else if (v.userrole_id !== null) {
							formattedId = `smallgroupmemberrole_id.${v.userrole_id}`
							formattedprice = v.price

							pricelist.push({
								id: formattedId,
								price: formattedprice,
							})
						} else if (v.ministrymemberrole_id !== null) {
							formattedId = `ministrymemberrole_id.${v.ministrymemberrole_id}`
							formattedprice = v.price
							pricelist.push({
								id: formattedId,
								price: formattedprice,
							})
						} else if (v.ministrymemberrole_id === null && v.userrole_id === null && v.smallgroupmemberrole_id === null) {
							pricelist = []
							priceall = v.price
						}
					}
				}

				let a = res.data.data.program.programvolunteers.map((v) => {
					const t = {}
					t.value = v.id
					t.label = v.user_id ? v.user.fullname : ''
					return t
				})

				setState({
					...state,
					classTitle: res.data.data.program.name,
					classCode: { value: res.data.data.program.programcode_id, label: res.data.data.program.programcode.name },
					classDescription: res.data.data.program.description,
					classType: res.data.data.program.programtype_id,
					classStartDate: res.data.data.program.programstart_at,
					classEndDate: res.data.data.program.programend_at,
					classStartRegis: res.data.data.program.registerstart_at,
					classEndRegis: res.data.data.program.registerend_at,
					classSpeaker: res.data.data.program.programbreakouts.length ? res.data.data.program.programbreakouts[0].speaker : '',
					classVenue: res.data.data.program.campus_id ? res.data.data.program.campus_id : 'OTHERS',
					classVenueName: res.data.data.program.location == 'null' ? '' : res.data.data.program.location,
					classVenueAddress: res.data.data.program.locationaddress != 'null' ? res.data.data.program.locationaddress : '',
					classParticipantQuota: res.data.data.program.quotaPrograms,
					classMinSession: res.data.data.program.minattenddays,
					startDateShare: res.data.data.program.publishstart_at,
					endDateShare: res.data.data.program.publishend_at,
					isEndDateShare: res.data.data.program.publishend_at ? true : false,
					classAditionnalInformation: res.data.data.program.description2,
					classPriceAll: priceall,
					priceList: pricelist,
					dateList: datelist,
					classPaymentPerRole: pricelist.length ? true : false,
					classVolunteers: a,
					ishighlights: res.data.data.program.ishighlight,
				})
			} catch (error) {
			}
		}

		fetchDetailClass()
	}, [])

	useEffect(() => {
		const fetchRole = async () => {
			try {
				const res = await getClassRole()
				const ministrymemberrole = res.data.data.ministrymemberrole.map((v) => {
					const temp = {}
					temp.id = `ministrymemberrole_id.${v.id}`
					temp.name = v.name
					return temp
				})
				const role = res.data.data.role.map((v) => {
					const temp = {}
					temp.id = `userrole_id.${v.id}`
					temp.name = v.name
					return temp
				})
				const smallgroupmemberrole = res.data.data.smallgroupmemberrole.map((v) => {
					const temp = {}
					temp.id = `smallgroupmemberrole_id.${v.id}`
					temp.name = v.name

					return temp
				})

				setRoles(ministrymemberrole.concat(role, smallgroupmemberrole))
				setAllRolesFormik(ministrymemberrole.concat(role, smallgroupmemberrole))
			} catch (error) {
			}
		}
		fetchRole()
	}, [])

	useEffect(() => {
		const fetchVenues = async () => {
			try {
				const res = await getVenues()
				const venues = res.data.data.campus_list.map((v) => v.campusrooms)
				const a = venues
					.map((v) => {
						let element
						for (let i = 0; i < v.length; i++) {
							element = v[i]
						}
						return element
					})
					.filter((v) => v !== undefined)
				setListVenue([...a, { id: 'OTHERS', name: 'OTHERS' }])
			} catch (error) {
			}
		}

		fetchVenues()
	}, [])

	useEffect(() => {
		const fetchClassType = async () => {
			try {
				const res = await getClassType()
				setListType(res.data.data.programtype)
			} catch (error) {
			}
		}

		fetchClassType()
	}, [])

	useEffect(() => {
		const fetchListCode = async () => {
			try {
				const res = await getListCode()
				const codes = res.data.data.programcodes.map((v) => {
					const temp = {}
					temp.id = v.id
					temp.name = v.name
					return temp
				})

				setListCode(codes)
			} catch (error) {
			}
		}

		fetchListCode()
	}, [])

	useEffect(() => {
		const fetchMinistryHeads = async () => {
			try {
				const res = await getMinistryHeadsAndDeputy()
				const data = res.data.data.user.data
				const a = data.map((v) => {
					let temp = {}
					temp.id = v.id
					temp.label = v.fullname
					return temp
				})
				setAllVolunteer(a)
			} catch (error) {
				console.log(error)
			}
		}
		fetchMinistryHeads()
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/classes', title: 'List Class' },
		{ url: '', title: 'Edit Class' },
	]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Class</title>
			</Helmet>

			<PageBreadcrumbs icon={SGIcon} title="Edit Class" breads={breads} />
			<div className="flex md:-mx-2 flex-wrap">
				<EditClassFormik
					{...props}
					{...state}
					id={id}
					listCode={listCode}
					listType={listType}
					listVenue={listVenue}
					roles={roles}
					allRolesFormik={allRolesFormik}
					allVolunteer={allVolunteer}
				/>
			</div>
		</div>
	)
}

export default EditClass
