import React from 'react'
import ContentLoader from 'react-content-loader'

const PieChart = ({ small }) => (
	<ContentLoader className="bg-white mb-6 rounded-lg shadow-md text-center" speed={1} width={385} height={355} viewBox="0 0 400 355" backgroundColor="#ededed" foregroundColor="#ffff">
		{small ? <circle cx="182" cy="140" r="78" /> : <circle cx="187" cy="161" r="112" />}
		<rect x="106" y="16" rx="0" ry="0" width="160" height="8" />
		<rect x="35" y="295" rx="0" ry="0" width="25" height="24" />
		<rect x="206" y="297" rx="0" ry="0" width="24" height="23" />
		<rect x="71" y="303" rx="0" ry="0" width="100" height="8" />
		<rect x="245" y="304" rx="0" ry="0" width="100" height="8" />
	</ContentLoader>
)

export default PieChart
