import { withFormik } from 'formik'
import moment from 'moment'
import EditMultiplicationForm from 'components/forms/EditMultiplicationForm'
import { EditMultiplicationValidationSchema } from 'components/formik/MultiplicationValidationSchema'
import { updateSmallGroup } from 'libs/apis/smallGroup'
import { successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'

const EditMultiplicationFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		smallGroupCampus: props.smallGroupCampus ? props.smallGroupCampus : 0,
		smallGroupLevel: props.smallGroupLevel,
		parentCampus: props.parentCampus,
		parentSmallGroup: props.parentSmallGroup,
		name: props.name,
		category: props.category,
		smallGroupLeader: props.smallGroupLeader,
		smallGroupUpperLevelLeaderName: props.smallGroupUpperLevelLeaderName,
		fullAddress: props.fullAddress,
		shortAddress: props.shortAddress,
		region: props.region,
		postalCode: props.postalCode,
		day: props.day ? props.day : 0,
		time: props.time,
		status: props.status ? props.status : 'Full',
		established_date: props.established_date,
		ageFrom: props.ageFrom,
		ageTo: props.ageTo,
		description: props.description,
	}),
	validate: (values) => {
		const errors = {}
		if (!parseInt(values.ageTo)) {
			errors.ageTo = 'Required'
		} else if (!parseInt(values.ageFrom)) {
			errors.ageFrom = 'Required'
		} else if (parseInt(values.ageFrom) > parseInt(values.ageTo)) {
			errors.ageTo = 'has to be higher than Age From'
		}
		return errors
	},
	validationSchema: EditMultiplicationValidationSchema,
	handleSubmit: async (values, { props }) => {
		const {
			ageFrom,
			ageTo,
			category,
			day,
			description,
			established_date,
			fullAddress,
			name,
			parentSmallGroup,
			postalCode,
			region,
			shortAddress,
			smallGroupCampus,
			smallGroupLeader,
			smallGroupLevel,
			smallGroupUpperLevelLeaderName,
			status,
			time,
		} = values

		let formdata = new FormData()
		let date = moment(established_date).format('YYYY/MM/DD')

		formdata.append('sgCampus', smallGroupCampus ? parseInt(smallGroupCampus) : 0)
		formdata.append('sgLevel', parseInt(smallGroupLevel))
		formdata.append('sgParent', parseInt(parentSmallGroup.value))
		formdata.append('sgName', name)
		formdata.append('sgCategory', parseInt(category))
		formdata.append('sgLeader', parseInt(smallGroupLeader.value))
		formdata.append('sgUpperLeader', parseInt(smallGroupUpperLevelLeaderName.value))
		formdata.append('sgFullAddress', fullAddress)
		formdata.append('sgShortAddress', shortAddress)
		formdata.append('sgRegion', parseInt(region.value))
		formdata.append('sgPostalCode', postalCode)
		formdata.append('sgDay', parseInt(day))
		formdata.append('sgTime', time)
		formdata.append('sgStatus', status)
		formdata.append('sgEstablishedDate', date)
		formdata.append('sgAgeFrom', parseInt(ageFrom))
		formdata.append('sgAgeTo', parseInt(ageTo))
		formdata.append('sgDescription', description)
		formdata.append('sgId', props.sgId)
		formdata.append('type', 'Multiplication')

		try {
			let res = await updateSmallGroup(formdata)
			if (res.data.api_status === true) {
				successMessage()
				props.history.push('/smallgroup/all/filtered')
			} else {
				failedMessage(res.data.message)
			}
		} catch ({ response: { status } }) {
			if (status === 500) {
				somethingWrong()
			}
		}
	},
})(EditMultiplicationForm)

export default EditMultiplicationFormik
