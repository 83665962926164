import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ServiceItemContainer, XIconService } from './styles'
import { deleteServiceTime } from 'libs/apis/campus'
import { deleteItemConfirmation } from 'libs/utils/confirmation'

class TimeItem extends Component {
	state = {
		isDeleteLoading: false,
		errorDeleteMessage: '',
		isRedirect: false,
		isCurrentEdit: false,
	}

	handleOnDelete = async () => {
		const result = await deleteItemConfirmation()

		if (result.value) {
			this.setState({ isDeleteLoading: true }, async () => {
				try {
					const response = await deleteServiceTime(this.props.id)
					if (response.data.api_status) {
						this.props.handleOnDelete(this.props.id)
					} else {
						this.setState({ isDeleteLoading: false, errorDeleteMessage: response.data.message })
					}
				} catch (error) {
					if (error.response) {
						if (error.response.status === 401) {
							await localStorage.removeItem('token')
							this.setState({ isRedirect: true })
						} else if (error.response.status >= 500) {
							this.setState({ isDeleteLoading: false, errorDeleteMessage: 'Error!' })
						}
					}
				}
			})
		}
	}

	handleOnEdit = () => {
		this.setState({ isCurrentEdit: true }, () => {
			const time = this.props.hour + ':' + this.props.minute
			this.props.handleOnEditTime(this.props.id, this.props.day, time)
		})
	}

	handleOnEditCancel = () => {
		this.setState({ isCurrentEdit: false }, () => {
			this.props.handleOnCancelEditTime()
		})
	}

	renderXIcon = () => <XIconService className="fas fa-times" style={{ color: '#4da5dc' }} onClick={this.handleOnDelete} />
	renderXIconGrey = () => <XIconService className="fas fa-times" style={{ color: '#b5b5b5' }} />

	render() {
		const { day, hour, minute, isEditTime } = this.props
		const { isDeleteLoading, errorDeleteMessage, isRedirect, isCurrentEdit } = this.state

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<ServiceItemContainer>
				{day} {hour}:{minute}
				{isCurrentEdit && <EditIcon fill="#4da5dc" stroke="#4da4dc" strokeWidth=".3px" onClick={this.handleOnEditCancel} />}
				{!isCurrentEdit && isEditTime && <EditIcon fill="#b5b5b5" stroke="#b5b5b5" strokeWidth=".3px" />}
				{!isEditTime && <EditIcon fill="#4da5dc" stroke="#4da4dc" strokeWidth=".3px" onClick={this.handleOnEdit} />}
				{isDeleteLoading && 'Loading'}
				{!isDeleteLoading && !isEditTime && this.renderXIcon()}
				{!isDeleteLoading && isEditTime && this.renderXIconGrey()}
				{errorDeleteMessage && <div className="text-invalid">{errorDeleteMessage}</div>}
			</ServiceItemContainer>
		)
	}
}

export default TimeItem
