import React from 'react'
import { Helmet } from 'react-helmet'
import CreateEventCodeFormik from 'components/formik/CreateEventCodeFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import EditEventCodeFormik from "../../components/formik/EditEventCodeFormik";

function EditEventCode(props) {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const breads = [{ url: '', title: 'Edit Event Code' }];
    return (
        <div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - Edit Event Code</title>
            </Helmet>
            <PageBreadcrumbs icon={EventIcon} title="Edit Event Code" breads={breads} />
            <div className="flex bg-white shadow-md pl-4 pt-4">
                <EditEventCodeFormik {...props}/>
            </div>
        </div>
    )
}

export default EditEventCode;
