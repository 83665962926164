import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { deleteFile, getFileRole, getFileDetail, getFileList } from 'libs/apis/file'
import { ReactComponent as CampaignIcon } from 'assets/icons/file.svg'
import { getMinistryRole } from 'libs/apis/ministry'
import Loading from 'components/commons/Loading'
import FileAdd from 'screens/file/FileAdd'
import FolderListTable from 'screens/file/FolderListTable'
import Searchbar from 'components/commons/Searchbar'
import ButtonExport from 'components/commons/ButtonDropdown'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import alert from 'services/alert'

import { SmallGroupHeader } from 'screens/smallGroup/styles'

export default function FolderList(props) {
	const [data, setData] = useState([])
	const [folders, setFolders] = useState([])
	const [filterKeyword, setFilterKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [smallgroupMembersRoles, setSmallgroupMembersRoles] = useState([])
	const [ministryRole, setMinistryRole] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List Folder' }]

	const params = useParams()

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getFileRole()
				setSmallgroupMembersRoles(res.data.data.smallgroupmemberroles)
			} catch (error) {
				console.log(error)
			}
		}
		fetchRoles()
	}, [])

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getMinistryRole()
				setMinistryRole(res.data.data.ministrymemberrole)
			} catch (error) {
				console.log(error)
			}
		}
		fetchRoles()
	}, [])

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const res = await getFileRole()
				setSmallgroupMembersRoles(res.data.data.smallgroupmemberroles)
			} catch (error) {
				console.log(error)
			}
		}
		fetchRoles()
	}, [])

	useEffect(() => {
		handleLoadData()
	}, [])

	useEffect(() => {
		setIsLoading(true)
		const handleLoadFolders = async () => {
			try {
				let res = await getFileList()
				let docGroups = res.data.data.documentgroups.map((val) => {
					let temp = {}
					temp.id = val.id
					temp.created_at = val.created_at
					temp.name = val.name
					return temp
				})
				setFolders(docGroups)
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setIsLoading(false)
			}
		}
		handleLoadFolders()
	}, [])

	async function handleLoadData() {
		try {
			let res = await getFileDetail(params.id)
			setData(res.data.data.documents)
		} catch (error) {
			console.log(error)
		}
	}

	const handleSearch = (event) => {
		setFilterKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			setFilterKeyword(event.target.value)
			setSearchValue(event.target.value)
		}
	}

	const handleDelete = async (id, type) => {
		const { value } = await alert.confirmDelete()
		if (value) {
			try {
				let res = await deleteFile('Delete', type, id)
				if (res.data.api_status) {
					alert.succeed(`Success`, `Your file has been deleted`)
					handleLoadData()
				}
			} catch (error) {
				alert.failed(`Failed`, `Cannot delete file`)
				handleLoadData()
			}
		}
	}

	const displayNoData = data.length === 0 && !isLoading
	const displaydata = data.length !== 0 && !isLoading

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 lg:p-6 md:w-5/6">
			<Helmet>
				<title>{name}</title>
			</Helmet>
			<PageBreadcrumbs icon={CampaignIcon} title={params.name} breads={breads} />

			<SmallGroupHeader>
				<ButtonExport
					classname="mr-6"
					options={[<FileAdd smallgroupMembersRoles={smallgroupMembersRoles} ministryRole={ministryRole} folderId={params.id} {...props} handleLoadData={handleLoadData} />]}
				>
					Add
				</ButtonExport>
				<Searchbar filterKeyword={filterKeyword} handleOnSearch={handleSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
			</SmallGroupHeader>
			{isLoading && <Loading />}
			{displayNoData && <div className="text-center">No data available</div>}
			{displaydata && <FolderListTable search={searchValue} data={data} folders={folders} handleDelete={handleDelete} handleLoadData={handleLoadData} />}
		</div>
	)
}
