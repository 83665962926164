import { withFormik } from 'formik'
import CreateClassCodeForm from 'components/forms/CreateClassCodeForm'
import { createClassCode } from 'libs/apis/courses'
import { failedMessage, somethingWrong, successMessage } from '../../libs/utils/confirmation'

const CreateClassCodeFormik = withFormik({
	
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		classCode: '',
		className: '',
		minimumOfSession: '',
		numberOfSession: '',
		reqLeaderApproval: false,
		reqAdminApproval: false,
		prerequisiteClass: [],
		genderList: [],
		maritalStatusList: [],
		roleList: [],
		ministryList: [],
		typeList: [],
		basicRecipientMethodList: [],
	}),
	// validationSchema: ,
	handleSubmit: async (values, { props }) => {
		const {
			classCode,
			className,
			reqLeaderApproval,
			reqAdminApproval,
			genderList,
			maritalStatusList,
			roleList,
			ministryList,
			typeList,
			minimumOfSession,
			numberOfSession,
			prerequisiteClass,
		} = values

		let formattedReqfamily = 0
		let formattedReqchildren = 0
		let formattedIsCouple = 0

		let formattedGender = ''
		if (genderList.length === 1) {
			formattedGender = genderList[0]
		}
		if (genderList.length > 1 || genderList.length === 0) {
			formattedGender = ''
		}

		const prerequisite = prerequisiteClass.map((v) => v.id)

		if (typeList.length >= 1) {
			formattedIsCouple = 1
			if (typeList.includes('type0')) {
				formattedIsCouple = 1
			}
			if (typeList.includes('type2')) {
				formattedReqfamily = 1
				formattedReqchildren = 1
			} else if (typeList.includes('type1') && !typeList.includes('type1')) {
				formattedReqfamily = 1
				formattedReqchildren = 0
			}
		}

		let formdata = new FormData()
		formdata.append('type', 'Class')
		formdata.append('classCode', classCode)
		formdata.append('className', className)
		formdata.append('numberSession', numberOfSession)
		formdata.append('minimumSession', minimumOfSession)
		formdata.append('gender', formattedGender)
		formdata.append('reqfamily', formattedReqfamily)
		formdata.append('reqchildren', formattedReqchildren)
		formdata.append('leaderapproval', reqLeaderApproval ? 1 : 0)
		formdata.append('adminapproval', reqAdminApproval ? 1 : 0)
		formdata.append('roleId', roleList)
		formdata.append('ministryId', ministryList)
		formdata.append('classPrerequisiteId', prerequisite.toString())
		formdata.append('maritalStatusId', maritalStatusList)
		formdata.append('iscouple', formattedIsCouple)
		formdata.append('isuser', 0)
		formdata.append('agemax', 0)
		formdata.append('agemin', 0)
		try {
			const res = await createClassCode(formdata)
			const status = res.data && res.data.api_status

			if (status) {
				successMessage().then(
					props.history.push('/classes/list-code')
				)
			}
		} catch (error) {
			somethingWrong()
			console.error(error)
		}
	},
})(CreateClassCodeForm)

export default CreateClassCodeFormik
