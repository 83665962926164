import React from "react";
import Plot from "react-plotly.js";

class SmallGroupAttendanceCart extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Plot
        data={data}
        layout={{
          barmode: "stack"
          // margin: { b: 0, t: 80 },
          // legend: { orientation: "h", y: -1, yanchor: "bottom" },
          // showlegend: true,
          // xaxis: {
          //   autorange: data[0].x.length > 1 ? false : true,
          //   range: [
          //     moment()
          //       .subtract(30, "days")
          //       .format("YYYY-MM-DD"),
          //     moment().format("YYYY-MM-DD")
          //   ],
          //   rangeslider: {},
          //   tickangle: 15,
          //   tickformat: "%d %B \n %Y"
          // }
        }}
      />
    );
  }
}

export default SmallGroupAttendanceCart;
