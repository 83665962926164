import React, {useEffect, useRef, useState} from 'react'

const EventAttendanceTable = ({attendanceCount, listEventAttendance, handleCheckUncheckClick}) => {
    const styleTableRow = `border border-solid bt-blueSecondary`;
    const styleDropDown = 'z-20 shadow-md absolute right-20px ';
    const styleNullData = ' text-red-500 text-xs italic';
    const styleData = 'px-2 py-4 text-center';

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/events/edit-code/')) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const renderAttendancesHead = (counts) => {
        let attendancesEl = [];

        for (let idx = 0; idx < counts; idx++) {
            attendancesEl.push(
                <th key={idx} className="px-4 py-4">
                    {idx + 1}
                </th>
            )
        }

        return attendancesEl;
    };

    const renderListEventRegistrantHead = () => {
        return (
            <thead className="bt-blueSecondary py-4  uppercase text-gray">
            <tr>
                <th className="px-4 py-4">
                    NAME
                </th>
                <th className="px-4 py-4">
                    GROUP NAME
                </th>
                {renderAttendancesHead(attendanceCount)}
                {/*<th className="px-4 py-4"/>*/}
            </tr>
            </thead>
        );
    };

    const renderAttendancesBody = (counts, attendanceStatus) => {
        let attendancesEl = [];
        for (let idx = 0; idx < counts; idx++) {
            attendancesEl.push(
                <td key={idx} className="px-4 py-4 text-center">
                    {
                        attendanceStatus[idx] ?
                            attendanceStatus[idx].status === true ?
                                // <i className="fa fa-check-square text-green-400" aria-hidden="true"/>
                                <div className="btn-check-attendance check-green cursor-pointer"
                                     onClick={() => handleCheckUncheckClick(attendanceStatus[idx].id)}>
                                    <i className="far fa-check-square"/>
                                </div>
                                :
                                <div className="btn-check-attendance check-white cursor-pointer"
                                     onClick={() => handleCheckUncheckClick(attendanceStatus[idx].id)}>
                                    <i className="far fa-square"/>
                                </div>
                            :
                            <div className="btn-check-attendance check-red text-center">
                                <i className="fas fa-times text-red-400 items-center"/>
                            </div>
                        // <div className="btn-check-attendance check-red">
                        //     <i className="far fa-square" onClick={handleCheckUncheckClick}/>
                        // </div>
                    }
                </td>
            )
        }

        return attendancesEl;
    };

    const renderListEventAttendanceBody = () => {
        const attendanceBodyEl = [];

        listEventAttendance.forEach((registrant, index) => {
            attendanceBodyEl.push(
                <tr key={index} className={styleTableRow}>
                    <td className="px-2 py-4 text-center">
                        <div>{registrant.name.concat(registrant.name2 ? ', '.concat(registrant.name2) : '')}</div>
                    </td>
                    <td className={styleData}>
                        {
                            registrant.groupName !== '' ?
                                <div>{registrant.groupName}</div>
                                :
                                <div className={styleNullData}>unknown</div>
                        }
                    </td>
                    {renderAttendancesBody(attendanceCount, registrant.attendanceStatus)}

                    {/*<td className="px-2 py-4">*/}
                    {/*    <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>*/}
                    {/*        <i className="dropdown-trigger fas fa-ellipsis-v"/>*/}
                    {/*        <div*/}
                    {/*            className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>*/}
                    {/*            <ul ref={dropdownRef} className="bg-white">*/}
                                    {/*<li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">*/}
                                    {/*    Resend Confirmation Email*/}
                                    {/*</li>*/}
                                    {/*<li className="px-4 py-2 block hover:bg-darkSkyBlue text-left" onClick={() => {*/}
                                    {/*}}>*/}
                                    {/*    Remove Registrant*/}
                                    {/*</li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                </tr>
            )
        });

        return attendanceBodyEl;
    };

    return (
        <div className="bg-white shadow-md rounded overflow-x-auto mx-2 md:mx-0 whitespace-no-wrap">
            <table className="w-full">
                {renderListEventRegistrantHead()}
                <tbody>
                {renderListEventAttendanceBody()}
                </tbody>
            </table>
        </div>
    )
};

export default EventAttendanceTable
