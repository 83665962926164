import React from 'react'
import TextInput from 'components/commons/TextInput'

function ParticipantSession({ handleRemoveParticipant, classPaymentPerRole, role, price, handleChange, idx, roles }) {
	return (
		<div className="flex w-full mt-2 mb-2">
			<div className="flex w-full">
				<div className="w-1/2">
					<select className="form-select h-11 block w-full border-2 rounded-lg focus:border-secondary" name="id" value={role} onChange={(e) => handleChange(e, idx)}>
						<option key={idx} value="">
							Choose
						</option>
						{roles.map((option, idx) => (
							<option key={idx} value={option.id}>
								{option.name}
							</option>
						))}
					</select>
				</div>
				{classPaymentPerRole && (
					<div className="-mt-2 -mb-4 ml-2 w-1/2">
						<TextInput name="price" placeholder="Price" value={price} onChange={(e) => handleChange(e, idx)} />
					</div>
				)}
				<i className="fas fa-times mt-4 text-red-400 cursor-pointer ml-2" onClick={() => handleRemoveParticipant(idx)} />
			</div>
		</div>
	)
}

export default ParticipantSession
