import React, { useState } from 'react'
import Modal from 'react-modal'

import { uploadFile } from 'libs/apis/file'
import alert from 'services/alert'
import FileInput from 'components/commons/FileInput'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import TextInput from 'components/commons/TextInput'
import EligibleTo from 'components/commons/EligibleTo'
import { removeItem } from 'libs/utils/utils'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '30px 39.6px',
		height: '570px',
		width: '550px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.6)',
	},
}
export default function FileAdd(props) {
	const [selectedFile, setSelectedFile] = useState('')
	const [selectedRoles, setSelectedRoles] = useState([])
	const [selectedMinistryRole, setSelectedMinistryRole] = useState([])
	const [fileName, setFileName] = useState('')
	const [newname, setNewname] = useState('')

	const [errorMessage, setErrorMessage] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { smallgroupMembersRoles, folderId, ministryRole } = props

	const handleOnChange = (event) => {
		setErrorMessage('')
		let file = event.target.files[0]
		if (file) {
			const validateFile = vaidateSize(file)
			setSelectedFile(validateFile)
			setFileName(file.name)
			setNewname(file.name)
		}
	}

	const handleUpload = async (selectedFile, selectedRoles, newname, fileName) => {
		try {
			setIsLoading(true)

			const formdata = new FormData()

			formdata.append('action', 'Upload')
			formdata.append('type', 'Document')
			formdata.append('name', newname ? newname : fileName)
			formdata.append('file', selectedFile)
			formdata.append('smallgroupMemberRoleId', selectedRoles)
			formdata.append('ministryMemberRoleId', selectedMinistryRole.join())

			if (folderId) {
				formdata.append('groupId', folderId)
			}

			const res = await uploadFile(formdata)
			if (res.data.api_status) {
				alert.succeed('Success', 'File has been uploaded')
				setModalIsOpen(false)
				setIsLoading(false)
				props.handleLoadData()
			} else {
				alert.failed(`Failed`, `Cannot upload this file`)
				props.handleLoadData()
				setIsLoading(false)
			}
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	const handleSubmit = async () => {
		try {
			if (folderId) {
				if (selectedRoles.length) {
					handleUpload(selectedFile, selectedRoles.toString(), newname, fileName, folderId)
				} else {
					handleUpload(selectedFile, '', newname, fileName, folderId)
				}
			} else {
				if (selectedRoles.length) {
					handleUpload(selectedFile, selectedRoles.toString(), newname, fileName)
				} else {
					handleUpload(selectedFile, '', newname, fileName)
				}
			}
		} catch (error) {
			alert.failed('Failed', 'Cannot upload this file at this moment')
			togleModal()
		}
	}

	const handleChangeName = (e) => {
		setNewname(e.target.value)
	}

	const handleCheckbox = (e) => {
		if (!e.target.checked) {
			setSelectedRoles(removeItem(selectedRoles, parseInt(e.target.value)))
		} else {
			setSelectedRoles([...selectedRoles, parseInt(e.target.value)])
		}
	}

	const handleCheckboxMinistryRole = (e) => {
		if (!e.target.checked) {
			setSelectedMinistryRole(removeItem(selectedMinistryRole, parseInt(e.target.value)))
		} else {
			setSelectedMinistryRole([...selectedMinistryRole, parseInt(e.target.value)])
		}
	}

	/* ------ HELPER -------- */
	const togleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setSelectedFile(null)
		setErrorMessage('')
		setFileName('')
		setNewname('')
		setSelectedRoles([])
	}

	const vaidateSize = (file) => {
		let max = 20971520
		if (file === null) {
			setErrorMessage('File cannot be empty')
			return
		} else {
			if (file.size > max) {
				setErrorMessage('Your file size is too big')
				return
			}
			return file
		}
	}
	/* --------- END --------- */
	console.log(selectedRoles, '----')
	return (
		<div>
			<div className="w-24" onClick={togleModal}>
				Add File
			</div>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Example Modal" appElement={document.getElementById('root')}>
				<h2 className="font-bold mb-2 text-sm">ADD FILE</h2>
				<hr className="border-2 border-blueSecondary" />
				<div className="flex mt-3">
					<FileInput handleOnChange={handleOnChange} fileName={fileName} />
				</div>
				<div className="float-left mb-4">
					<p className="mt-3 text-xs">Maximum file size is 20 MB</p>
				</div>

				<TextInput label="RENAME FILE" name="rename" value={newname} onChange={handleChangeName} />

				<div className="mt-4">
					<EligibleTo label="VISIBLE TO" options={smallgroupMembersRoles} selectedRoles={selectedRoles} handleCheckboxRole={handleCheckbox} />
					<EligibleTo options={ministryRole} selectedRoles={selectedMinistryRole} handleCheckboxRole={handleCheckboxMinistryRole} />
				</div>

				<div className="float-left ">
					<p className="mt-3 text-red-400 text-xs">{errorMessage}</p>
				</div>

				<hr className="border-2 border-blueSecondary mt-10" />

				<div className="flex justify-center mt-4">
					<Button type="cancel" handleClick={togleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" style="ml-6" handleClick={handleSubmit}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
