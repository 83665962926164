import React, {useEffect, useRef, useState} from 'react'
import {partial} from 'libs/utils/utils'
import Tippy from "@tippy.js/react";
import moment from "moment";
import {Link} from "react-router-dom";

const EmailListTable = ({listEmailCampaign, handleOnSort, handleOnDelete}) => {
    const handleSortByCreatedOn = partial(handleOnSort, 'created_at');
    const handleSortBySchedule = partial(handleOnSort, 'sendstart_at');

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const styleTableRow = `border border-solid bt-blueSecondary`;
    const styleDropDown = 'z-20 shadow-md absolute right-20px ';
    const styleNullData = ' text-red-500 text-xs italic';

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/campaign/view-email/') ||
            String(event.target).includes('/campaign/edit-email/') ||
            event.target.innerHTML.includes('Delete')
        ) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const renderListEmailCampaignHead = () => {
        return (
            <thead className="bt-blueSecondary border-2 border-solid">
            <tr>
                <th className="px-2 py-4"/>
                <th className="px-4 py-4">NO.</th>
                <th className="px-4 py-4">SUBJECT</th>
                {/*<th className="px-4 py-4">*/}
                {/*    CREATED BY*/}
                {/*</th>*/}
                <th className="px-4 py-4">
                    CREATED ON
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByCreatedOn}/>
                </th>
                {/*<th className="px-4 py-4">*/}
                {/*    LAST UPDATED*/}
                {/*    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByLastUpdated}/>*/}
                {/*</th>*/}
                <th className="px-4 py-4">
                    SCHEDULE
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortBySchedule}/>
                </th>
                <th className="px-4 py-4">
                    STATUS
                </th>
                <th className="px-4 py-4">
                    TOTAL
                </th>
                <th className="px-2 py-4"/>
            </tr>
            </thead>
        )
    };

    const renderListEmailCampaignBody = () => {
        let listEmailCampaignBodyEl = [];

        listEmailCampaign.forEach((emailCampaign, index) => {
            const styleTdStatus = emailCampaign.isHighlight ? 'relative mr-6' : 'absolute mr-6';
            const styleStatusArchive = `${emailCampaign.status === 'Archived' ? 'opacity-50' : 'opacity-100'}`;

            listEmailCampaignBodyEl.push(
                <tr className={styleTableRow} key={index}>
                    <td className={styleTdStatus}>
                        {emailCampaign.emailStatus === 'Draft' && (
                            <Tippy content="Draft">
                                <div className="status-post-tag">
                                    <div className="ribbon-black-triagle"/>
                                </div>
                            </Tippy>
                        )}
                        {emailCampaign.emailStatus === 'Archived' && (
                            <Tippy content="Archived">
                                <div className="status-post-tag">
                                    <div className={styleStatusArchive}>
                                        <div className="ribbon-black-triagle"/>
                                    </div>
                                </div>
                            </Tippy>
                        )}
                        {emailCampaign.isHighlight && (
                            <Tippy content="Highlight" theme="star">
                                <i className="fas fa-star text-yellow-300 self-center flex"/>
                            </Tippy>
                        )}
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{emailCampaign.id}</div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{emailCampaign.subject}</div>
                    </td>

                    {/*<td className="px-2 py-4 text-center">*/}
                    {/*    <div className={styleStatusArchive}>*/}
                    {/*        {*/}
                    {/*            emailCampaign.createdBy === 'unknown' ?*/}
                    {/*                <div className={styleNullData}>*/}
                    {/*                    {emailCampaign.createdBy}*/}
                    {/*                </div>*/}
                    {/*                :*/}
                    {/*                <div>{emailCampaign.createdBy}</div>*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*</td>*/}

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            {
                                emailCampaign.createdOn === 'unknown' ?
                                    <div className={styleNullData}>
                                        {emailCampaign.createdOn}
                                    </div>
                                    :
                                    <div>{moment(emailCampaign.createdOn, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            }
                            {
                                emailCampaign.createdBy === 'unknown' ?
                                    <div className={styleNullData}>
                                        {emailCampaign.createdBy}
                                    </div>
                                    :
                                    <div className="text-primary">by {emailCampaign.createdBy}</div>
                            }
                        </div>
                    </td>

                    {/*<td className="px-2 py-4 text-center">*/}
                    {/*    <div className={styleStatusArchive}>*/}
                    {/*        {*/}
                    {/*            emailCampaign.lastUpdated === 'unknown' ?*/}
                    {/*                <div>*/}
                    {/*                    <div className={styleNullData}>{emailCampaign.lastUpdated}</div>*/}
                    {/*                    <div className={styleNullData}>{emailCampaign.lastUpdated}</div>*/}
                    {/*                </div>*/}
                    {/*                :*/}
                    {/*                <div>*/}
                    {/*                    <div>{moment(emailCampaign.lastUpdated, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>*/}
                    {/*                    <div*/}
                    {/*                        className="text-primary">{moment(new Date(emailCampaign.lastUpdated), 'DD/MM/YYYY HH:mm').fromNow()}</div>*/}
                    {/*                </div>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</td>*/}

                    <td className="px-2 py-4 text-center">
                        {
                            emailCampaign.schedule === 'unknown' ?
                                <div className={styleNullData}>
                                    {emailCampaign.schedule}
                                </div>
                                :
                                <div className={styleStatusArchive}>
                                    {moment(emailCampaign.schedule, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}
                                </div>
                        }
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{emailCampaign.emailStatus}</div>
                    </td>

                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{`${emailCampaign.totalSent} sent /`}</div>
                        <div className={styleStatusArchive}>{`${emailCampaign.totalRecipient} total`}</div>
                    </td>

                    <td className="px-2 py-4">
                        <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>
                            <i className="dropdown-trigger fas fa-ellipsis-v"/>
                            <div
                                className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>
                                <ul ref={dropdownRef} className="bg-white">
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        <Link to={`/campaign/view-email/${emailCampaign.id}`}>
                                            View
                                        </Link>
                                    </li>
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        <Link to={`/campaign/edit-email/${emailCampaign.id}`}>
                                            Edit
                                        </Link>
                                    </li>
                                    <li
                                        className="px-4 py-2 block hover:bg-blueSecondary"
                                        onClick={() => handleOnDelete(emailCampaign.id)}
                                    >
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });

        return listEmailCampaignBodyEl;
    };

    return (
        <div className="bg-white shadow-md rounded">
            <table className="w-full">
                {renderListEmailCampaignHead()}
                <tbody>
                {renderListEmailCampaignBody()}
                </tbody>
            </table>
        </div>
    )
};

export default EmailListTable
