import React, {useRef, useState} from 'react'
import InvalidFeedBack from 'components/commons/InvalidFeedback'

function TextInputMarkDown(props, {label, handleChange, value, isActive, name, errors = props.errors}) {
    const markdownRef = useRef(null);
    // const [isCopied, setIsCopied] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    const copyCodeToClipboard = () => {
        setCurrentValue(value);
        markdownRef.current.select();
        document.execCommand('copy');
        // setIsCopied(true);
    };

    return (
        <div className={props.styles}>
            <div className="flex flex-col w-full mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
                <input
                    type="text"
                    name={name || props.name}
                    className={`py-2 pl-3 border-secondary border-2 rounded-lg`}
                    value={value || props.value}
                    ref={markdownRef}
                    onClick={copyCodeToClipboard}
                />
                {/*<span className={isCopied ? 'visible' : 'invisible'}>Copied!</span>*/}

                {errors && <InvalidFeedBack message={errors}/>}
            </div>
        </div>
    )
}

export default TextInputMarkDown
