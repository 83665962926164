import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

function QrCodeList({ qrcode }) {
	let a = moment(qrcode.timestart_at).format('DD MMM YYYY')
	let b = moment(qrcode.timeend_at).format('DD MMM YYYY')
	return (
		<div className="w-1/3 mb-4 px-2">
			<div className="border-2 border-greySecondary">
				<div className="flex p-4">
					<div className="px-2">
						<img src={`data:image/svg+xml;base64,${qrcode.programbreakoutdate_qrcode}`} width="100" height="100" />
					</div>
					<div className="flex flex-col">
						<span>{a}</span>
						<span>{b}</span>
					</div>
				</div>
				<div className="bg-greySecondary px-4 py-2">
					<div className="flex justify-end">
						<Link to={`/view-qr/${qrcode.title}/${qrcode.programbreakoutdate_qrcode}/${a}/${b}`} className="bg-white p-2">
							View QR
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QrCodeList
