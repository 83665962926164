import React, { Component } from 'react'
import styled from 'styled-components'
import HomeMenu from '../menus/HomeMenu'
import NewsfeedMenu from '../menus/NewsfeedMenu'
import DevotionMenu from '../menus/DevotionMenu'
import UserManageMenu from '../menus/UserManageMenu'
import PrayerPraiseMenu from '../menus/PrayerPraiseMenu'
import SmallGroupMenu from '../menus/SmallGroupMenu'
import ClassesMenu from '../menus/ClassesMenu'
import EventMenu from '../menus/EventMenu'
import SideNavMenu from './SideNavMenu'
import CampaignMenu from '../menus/CampaignMenu'
import FileMenu from '../menus/FileMenu'
import MinistryMenu from '../menus/MinistryMenu'

const BarIcon = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 1;
	top: 5px;
	left: 10px;
	border-radius: 50%;
	padding: 12px;
	background: #dadae3;
`

const CloseIcon = styled.div`
	position: absolute;
	visibility: visible;
	cursor: pointer;
	top: 8px;
	right: 12px;
	font-size: 20px;
	color: #ddd;

	@media only screen and (min-width: 1024px) {
		visibility: hidden;
	}
`

const Side = styled.div`
	top: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	position: fixed;
	z-index: 2;
	transform: ${(props) => (props.active ? 'translateX(0)' : 'translateX(-100%)')};
	transition: all 0.6s ease-in-out;
	background-color: #fff;

	@media only screen and (min-width: 1024px) {
		transform: translateX(0);
		width: inherit;
	}
`

const SideNavChurchName = styled.h1`
	margin: 30px 4px 2px 4px;
	text-align: center;
	color: #3e3e3e;
	font-size: 16px;
	line-height: 1.19;
	letter-spacing: 1.5px;
	text-transform: uppercase;
`

const SideNavPowered = styled.div`
	color: #747474;
	font-size: 9px;
	line-height: 1.22;
	letter-spacing: 0.9px;
	text-align: center;
`

const SideNavSky = styled.div`
	display: inline;
	font-family: Lato;
	font-weight: 300;
	letter-spacing: 2.3px;
`

const SideNavPorter = styled.div`
	display: inline;
	font-family: Lato;
	letter-spacing: 2.3px;
`

const MenuContainer = styled.div`
	height: calc(100vh - 150px);
	font-size: 14px;
	margin: 32px 0 0 25px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-thumb {
		background-color: white;
	}
`

class SideNav extends Component {
	state = {
		isOpen: false,
	}

	handleOnToggle = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const access_list = JSON.parse(localStorage.getItem('access_list'))
		const { location, match } = this.props
		const { isOpen } = this.state
		return (
			<div className="h-16 fixed bottom-0 lg:relative lg:h-screen z-10 lg:w-1/6">
				<BarIcon onClick={this.handleOnToggle}>
					<i className="fas fa-bars" />
				</BarIcon>
				<Side active={isOpen}>
					<CloseIcon onClick={this.handleOnToggle}>
						<i className="fas fa-times" />
					</CloseIcon>
					<SideNavChurchName>{church.church_name}</SideNavChurchName>

					<SideNavPowered>
						powered by <SideNavSky>SKY</SideNavSky>
						<SideNavPorter>PORTER</SideNavPorter>
					</SideNavPowered>
					<MenuContainer>
						<HomeMenu location={location} />
						{access_list && access_list.includes('newsfeed') && <NewsfeedMenu location={location} match={match} />}
						{access_list && access_list.includes('devotion') && <DevotionMenu location={location} match={match} />}
						{access_list && access_list.includes('user-management') && <UserManageMenu location={location} match={match} />}
						{access_list && access_list.includes('prayer-praise') && <PrayerPraiseMenu location={location} match={match} />}
						{access_list && access_list.includes('small-group') && <SmallGroupMenu location={location} match={match} />}
						{access_list && access_list.includes('classes') && <ClassesMenu location={location} match={match} />}
						{access_list && access_list.includes('events') && <EventMenu location={location} match={match} />}
						{/*{access_list && access_list.includes('campaign') && <CampaignMenu location={location} match={match} />}*/}
						{access_list && <CampaignMenu location={location} match={match} />}
						{access_list && access_list.includes('ministry') && <MinistryMenu location={location} match={match} />}
						{access_list && access_list.includes('file') && <FileMenu location={location} match={match} />}
					</MenuContainer>
					<SideNavMenu location={location} />
				</Side>
			</div>
		)
	}
}

export default SideNav
