import React, { Component, Fragment } from 'react'
import Loading from 'components/commons/Loading'
import { attendanceCampusList } from 'services/campusServices'
import SelectOptions from '../commons/SelectOptions'
import optionsYear from 'services/optionsYear'
import optionsMonth from 'services/optionsMonth'
import { getDataServiceAttendance, getDataFirstTimer } from 'services/reportServices'

class ExportServiceAttendance extends Component {
	state = {
		campuses: [],
		campusSelected: '',
		months: optionsMonth(),
		monthSelected: '',
		years: optionsYear(),
		yearSelected: '',
		isExported: false,
	}

	async componentDidMount() {
		try {
			const {
				data: { data: results },
			} = await attendanceCampusList()
			const campuses = [{ id: '', label: 'Choose venue' }].concat(results.map((result) => ({ id: result.id, label: result.title })))
			const months = [{ id: '', label: 'Choose month' }].concat(this.state.months)
			this.setState({ campuses, months, yearSelected: this.state.years[0].id })
		} catch (error) {
			if (error.response.status === 401) {
				this.props.history.replace('/login')
			}
		}
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value })
	}

	handleExportService = () => {
		const { campusSelected, monthSelected, yearSelected } = this.state
		if (campusSelected && monthSelected && yearSelected) {
			this.setState({ isExported: true }, async () => {
				try {
					const {
						data: { data },
					} = await getDataServiceAttendance(campusSelected, yearSelected, monthSelected)
					const link = document.createElement('a')
					link.href = data
					document.body.appendChild(link)
					link.click()
					this.setState({ isExported: false })
				} catch (error) {
					if (error.response && error.response.status === 401) {
						this.props.history.replace('/login')
					}
					this.setState({ isExported: false })
				}
			})
		}
	}

	handleExportFirstTime = () => {
		const { campusSelected, monthSelected, yearSelected } = this.state
		if (campusSelected && monthSelected && yearSelected) {
			this.setState({ isExported: true }, async () => {
				try {
					const {
						data: { data },
					} = await getDataFirstTimer(campusSelected, yearSelected, monthSelected)
					const link = document.createElement('a')
					link.href = data
					document.body.appendChild(link)
					link.click()
					this.setState({ isExported: false })
				} catch (error) {
					if (error.response && error.response.status === 401) {
						this.props.history.replace('/login')
					}
					this.setState({ isExported: false })
				}
			})
		}
	}

	renderOptions = () => {
		const { campuses, campusSelected, months, monthSelected, years, yearSelected } = this.state
		return (
			<Fragment>
				<div className="w-1/5">
					<div className="mr-4">
						<SelectOptions options={campuses} value={campusSelected} name="campusSelected" onChange={this.handleChange} />
					</div>
				</div>
				<div className="w-1/5">
					<div className="mr-4">
						<SelectOptions options={months} value={monthSelected} name="monthSelected" onChange={this.handleChange} />
					</div>
				</div>
				<div className="w-1/5">
					<div className="mr-4">
						<SelectOptions options={years} value={yearSelected} name="yearSelected" onChange={this.handleChange} />
					</div>
				</div>
			</Fragment>
		)
	}

	renderButtons = () => {
		const { isExported } = this.state
		return (
			<Fragment>
				<div className="w-1/5">
					<div className="mr-4 h-full">
						<button className="bg-greyExport text-white rounded-lg h-full w-full focus:outline-none" disabled={isExported} onClick={this.handleExportService}>
							{isExported ? <Loading secondary /> : 'Export Campus Attendance'}
						</button>
					</div>
				</div>
				<div className="w-1/5">
					<div className="mr-4 h-full">
						<button className="bg-greyExport text-white rounded-lg h-full w-full focus:outline-none" disabled={isExported} onClick={this.handleExportFirstTime}>
							{isExported ? <Loading secondary /> : 'Export First Timer'}
						</button>
					</div>
				</div>
			</Fragment>
		)
	}

	render() {
		return (
			<div className="bg-white rounded-lg shadow-md p-4">
				<h3 className="font-bold mb-6">Export Service Attendance</h3>
				<div className="flex">
					{this.renderOptions()}
					{this.renderButtons()}
				</div>
			</div>
		)
	}
}

export default ExportServiceAttendance
