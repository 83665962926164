import React, {useState} from 'react';
import Button from "../../components/commons/Button";
import Loading from "../../components/commons/Loading";
import {useHistory} from "react-router-dom";

const TestPushNotifCampaign = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const handleEditClick = () => {
        history.push(`/campaign/edit-pushnotif/${props.campaignId}`)
    };

    return (
        <div className="bg-white shadow-md p-6">
            <div className="text-md font-bold ml-2">Test Notification</div>
            <input
                className="appearance-none border-2 rounded-lg p-3 mt-2 w-full leading-tight focus:outline-none"
                name="notification-mockup-input"
                placeholder="Type email here"
                onChange={() => {
                }}
                value={null}
            />
            <div className="mt-3">
                <Button handleClick={() => {
                }} type="secondary" disabled={isLoading}>
                    {isLoading ? <Loading primary/> : 'SEND'}
                </Button>
            </div>

            <div className="flex justify-end mt-20">
                <Button handleClick={handleEditClick} type="secondary" disabled={isLoading}>
                    {isLoading ? <Loading primary/> : 'EDIT'}
                </Button>
                <Button handleClick={() => {
                }} type="primary" size="ml-4"
                        disabled={isLoading}>
                    {isLoading ? <Loading secondary/> : 'PUBLISH'}
                </Button>
            </div>
        </div>
    )
};

export default TestPushNotifCampaign;
