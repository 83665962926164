import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import ReportCard from '../commons/ReportCard'
import {
	totalActiveAndLastSundayCount,
	monthlySundayAverage,
	avgGroupApplicant,
	avgGroupProbation,
	avgGroupConversion,
} from 'services/reportServices'

function OverviewsReport(props) {
	const [isLoading, setIsLoading] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [totalActiveUsers, setTotalActiveUsers] = useState(0)
	const [totalAttendaceLastSunday, setTotalAttendanceLastSunday] = useState(0)
	const [previousSundayDate, setPreviousSundayDate] = useState('')
	const [totalPreviousAttendance, setTotalPreviousAttendance] = useState(0)
	const [averageSundayAttendance, setAverageSundayAttendance] = useState(0)
	const [averageRequest, setAverageRequest] = useState(0)
	const [previousAvgRequest, setPreviousAvgRequest] = useState(0)
	const [averageProbation, setAverageProbation] = useState(0)
	const [previousAvgProbation, setPreviousAvgProbation] = useState(0)
	const [averageConversion, setAverageConversion] = useState(0)
	const [previousAvgConversion, setPreviousAvgConversion] = useState(0)
	const [registeredUsers, setRegisteredUsers] = useState(0)
	const [billedUsers, setBilledUsers] = useState(0)

	const year = moment().subtract(1, 'months').format('YYYY')
	const month = moment().subtract(1, 'months').format('M')

	useEffect(() => {
		try {
			setIsLoading(true)
			const fetchData = async () => {
				const dataActiveLastSunday = await totalActiveAndLastSundayCount()
				const dataSundayAVG = await monthlySundayAverage(
					moment().format('YYYY'),
					moment().format('M')
				)

				setAverageSundayAttendance(dataSundayAVG.data.data.selected_month)
				setTotalActiveUsers(dataActiveLastSunday.data.data.total_active_users)
				setTotalAttendanceLastSunday(
					dataActiveLastSunday.data.data.total_attendance_last_sunday
				)
				setPreviousSundayDate(dataActiveLastSunday.data.data.previous_sunday_date)
				setTotalPreviousAttendance(
					dataActiveLastSunday.data.data.total_attendance_last_sunday -
						dataActiveLastSunday.data.data.total_attendance_previous_sunday
				)

				setRegisteredUsers(dataActiveLastSunday.data.data.register_users)
				setBilledUsers(dataActiveLastSunday.data.data.billed_users)
			}
			fetchData()
			setIsLoading(false)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				props.history.push('/login')
				localStorage.removeItem('token')
				setIsRedirect(true)
			}
			setIsLoading(false)
		}
	}, [props.history])

	useEffect(() => {
		try {
			setIsLoading(true)

			const fetchData = async () => {
				const dataGroupApplicant = await avgGroupApplicant(year, month)
				const dataProbation = await avgGroupProbation(year, month)
				setAverageProbation(dataProbation.data.data.selected_month)
				setPreviousAvgProbation(
					dataProbation.data.data.selected_month -
						dataProbation.data.data.month_before
				)

				setAverageRequest(dataGroupApplicant.data.data.selected_month)
				setPreviousAvgRequest(
					dataGroupApplicant.data.data.selected_month -
						dataGroupApplicant.data.data.month_before
				)
			}
			fetchData()
			setIsLoading(false)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				props.history.push('/login')
				localStorage.removeItem('token')
				setIsRedirect(true)
			}
			setIsLoading(false)
		}
	}, [year, month, props.history])

	useEffect(() => {
		try {
			setIsLoading(true)
			const fetchData = async () => {
				const data = await avgGroupConversion(year, month)
				setAverageConversion(data.data.data.selected_month)
				setPreviousAvgConversion(
					data.data.data.selected_month - data.data.data.month_before
				)
			}
			fetchData()
			setIsLoading(false)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				props.history.push('/login')
				localStorage.removeItem('token')
				setIsRedirect(true)
			}
			setIsLoading(false)
		}
	}, [year, month, props.history])

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<React.Fragment>
			<div className="flex md:-mx-2 my-6 md:flex-no-wrap flex-wrap">
				<ReportCard
					title={`Total Active Users ${moment().format('DD MMMM YYYY')}`}
					value={totalActiveUsers}
					isLoading={isLoading}
					isPrevious={false}
				/>
				<ReportCard
					title={`Total Registered Users`}
					value={registeredUsers}
					isLoading={isLoading}
					isPrevious={false}
				/>

				<ReportCard
					title="Total Attendance Last Sunday"
					value={totalAttendaceLastSunday}
					isLoading={isLoading}
					isPrevious={true}
					previousValue={totalPreviousAttendance}
					titleHelper={`Attendance From ${moment(previousSundayDate).format(
						'DD MMM YYYY'
					)} `}
				/>
				<ReportCard
					title="Average Sunday Attendance"
					value={averageSundayAttendance}
					isLoading={isLoading}
					isPrevious={false}
				/>
			</div>
			<div className="flex md:-mx-2 my-6 md:flex-no-wrap flex-wrap">
				<ReportCard
					title={`Total Billed Users`}
					value={billedUsers}
					isLoading={isLoading}
					isPrevious={false}
				/>

				<ReportCard
					title={`Avg. Small Group Request / Day - ${moment().format('MMM YYYY')}`}
					value={averageRequest}
					isLoading={isLoading}
					isPrevious={true}
					previousValue={previousAvgRequest}
					titleHelper={`Request From ${moment().format('MMM YYYY')} `}
				/>
				<ReportCard
					title={`Avg. Approved Small Group Request / Day - ${moment().format(
						'MMM YYYY'
					)}`}
					value={averageProbation}
					isLoading={isLoading}
					isPrevious={true}
					previousValue={previousAvgProbation}
					titleHelper={`Approved From ${moment().format('MMM YYYY')} `}
				/>
				<ReportCard
					title={`Avg. Conversion to Small Group Member / Day - ${moment().format(
						'MMM YYYY'
					)}`}
					value={averageConversion}
					isLoading={isLoading}
					isPrevious={true}
					previousValue={previousAvgConversion}
					titleHelper={`Conversion From ${moment().format('MMM YYYY')} `}
				/>
			</div>
		</React.Fragment>
	)
}

export default OverviewsReport
