import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowDown } from 'assets/icons/sort-down.svg'

const Select = styled.div`
	background-color: #fff;
	border: solid 2px #fff;
	color: #48a4df;
	font-weight: bold;
	width: 125px;
	height: 44px;
	border-radius: 10px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;
`

const Dropdown = styled.div`
	position: absolute;
	top: 40px;
	display: ${(props) => (props.open ? 'block' : 'hidden')};
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
`

function ButtonDropdown({ classname, children, options }) {
	const [open, setOpen] = useState(false)

	const toggleOpen = () => {
		setOpen(!open)
	}

	return (
		<div className={`inline-block cursor-pointer relative ${classname}`}>
			<div className="flex flex-row text-center">
				<Select onClick={toggleOpen}>
					{children}
					{options.length !== 0 && (
						<ArrowDown
							style={{
								fill: '#48a4df',
								width: '12px',
								height: '12px',
								marginTop: '6px',
								marginLeft: 'auto',
							}}
						/>
					)}
				</Select>
			</div>
			{open && (
				<Dropdown>
					{options.map((option, idx) => (
						<ul className="bg-white w-full" key={idx}>
							<li className="px-4 py-2 block text-grayishBrown bg-white hover:bg-darkSkyBlue">{option}</li>
						</ul>
					))}
				</Dropdown>
			)}
		</div>
	)
}

export default ButtonDropdown
