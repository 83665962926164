import React, { useState } from 'react'
import Modal from 'react-modal'
import FilterItem from 'components/commons/FilterItem'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'

export default function MinistryFilter({ handleSelectFilter, levels, subGroups, groups, handleClearFilter, handleApplyFilter, ministrySubGroups, ministryGroups }) {
	const [tab, setTab] = useState('level')
	const [listLevel, setListLevel] = useState([
		{
			value: 'Ministry',
			name: 'Ministry',
		},
		{ value: 'Subgroup', name: 'Sub Group' },
		{ value: 'Group', name: 'Groups' },
	])

	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const customStyles = {
		content: {
			top: '10%',
			left: '17%',
			right: '25%',
			width: '1101px',
			height: `${levels == 'Ministry' ? '300px' : '180px'}`,
			backgroundColor: 'white',
			paddingLeft: '2px',
			paddingRight: '0px',
			overflow: 'hidden',
			borderRadius: '12px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0,0.8)',
		},
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('level')
	}

	const handleApply = () => {
		setModalIsOpen(!modalIsOpen)
		handleApplyFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const levelTab = tab === 'level'
	const subGroupTab = tab === 'subGroups'
	const groupTab = tab === 'groups'

	const length = subGroups.length !== 0 || groups.length !== 0 || levels !== 'Ministry'

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Filter
				</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Ministry Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'level'} type="button" value="level" onClick={handleOnTab} />
						{levels == 'Ministry' && <Input tab={tab === 'subGroups'} type="button" value="subGroups" onClick={handleOnTab} />}
						{levels == 'Ministry' && <Input tab={tab === 'groups'} type="button" value="groups" onClick={handleOnTab} />}
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{levelTab &&
								listLevel.map(({ name, value }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											classname={`${levels.includes(value) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="level"
											title={name}
											value={value}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{subGroupTab &&
								ministrySubGroups.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											classname={`${subGroups.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="subGroups"
											title={name}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{groupTab &&
								ministryGroups.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											classname={`${groups.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="groups"
											title={name}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
