import React, { Component } from 'react'
import PieChart from 'components/commons/PieChart'
import { marriageData } from 'services/reportServices'
import PiechartSkeleton from 'components/skeleton/PiechartSkeleton'

class MarriageContainer extends Component {
	state = {
		isLoading: false,
		data: [],
	}

	componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: results },
				} = await marriageData()

				const values = results.map((item) => item.total)
				const labels = results.map((item) => item.marital_status)
				const data = [{ values, labels, type: 'pie' }]
				this.setState({ data, isLoading: false })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	renderData() {
		const { length: count } = this.state.data
		const { data } = this.state
		if (!count) return <div className="text-center">No data available</div>

		return <PieChart data={data} />
	}

	render() {
		const { isLoading } = this.state
		return (
			<>
				{isLoading ? (
					<PiechartSkeleton height={355} width={400} />
				) : (
					<div className="bg-white rounded-lg shadow-md text-center">
						<h3 className="font-bold mb-6">Marriage Status</h3>
						{this.renderData()}
					</div>
				)}
			</>
		)
	}
}

export default MarriageContainer
