import React, {useEffect, useState} from 'react';
import {getCampaignDetail} from "../../libs/apis/emailCampaign";
import {Helmet} from "react-helmet";
import PageBreadcrumbs from "../../components/commons/PageBreadcrumbs";
import {ReactComponent as EventIcon} from 'assets/icons/events.svg'
import Loading from "../../components/commons/Loading";
import TestPushNotifCampaign from "./TestPushNotifCampaign";

const ViewPushNotifCampaign = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));

    const name = church.church_name ? church.church_name : '-';
    const breads = [{url: '', title: 'View Push Notification Campaign'}];

    const campaignId = props.match.params.id;

    const [pushNotifTitle, setPushNotifTitle] = useState('');
    const [descriptionPushNotif, setDescriptionPushNotif] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // setFieldValue('campaignId', campaignId);
        fetchEmailCampaignDetail();
    }, []);

    const fetchEmailCampaignDetail = async () => {
        setIsLoading(true);
        try {
            let response = await getCampaignDetail(campaignId, 'Notif');
            let pushNotifData = response.data.data.campaign.data[0];

            let pushNotifDescriptionData = pushNotifData.description;
            let pushNotifTitle = pushNotifData.title;


            setPushNotifTitle(pushNotifTitle);
            setDescriptionPushNotif(pushNotifDescriptionData);
            console.log('response: ', pushNotifData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 lg:w-5/6">
            <Helmet>
                <title>{name} - View Push Notification Campaign</title>
            </Helmet>
            <PageBreadcrumbs icon={EventIcon} title="View Push Notification Campaign" breads={breads}/>
            <div className="flex md:-mx-2 flex-wrap justify-between">
                <div className="md:w-2/4 w-full px-2 mb-4 md:mb-0">
                    <div className="bg-white shadow-md">
                        {isLoading && <Loading/>}
                        {!isLoading &&
                        <div>
                            <div className="flex flex-row p-6 justify-between">
                                <div className="flex flex-row">
                                    <div><i className="fa fa-square text-blue-700 mr-3" aria-hidden="true"/></div>
                                    <div>{name}</div>
                                </div>
                                <div>11 mins ago</div>
                            </div>
                            <hr className="w-full bg-grey-100 flex" style={{height: 1}}/>
                            <div className="p-6">
                                <span className="text-lg font-bold">{pushNotifTitle}</span>
                                <div dangerouslySetInnerHTML={{__html: descriptionPushNotif}}/>
                            </div>
                        </div>
                        }
                    </div>

                </div>
                <div className="md:w-1/3 w-full px-2">
                    <TestPushNotifCampaign campaignId={campaignId}/>
                </div>
            </div>
        </div>
    )
};

export default ViewPushNotifCampaign;
