import styled, { css } from 'styled-components'

/* ===== CAMPUS STYLED COMPONENT ====== */
export const Container = styled.div`
  background-color: #fff;
  padding: 30px 20px 10px 20px;
  display: grid;
`

export const CampusListContainer = styled.div`
  height: 90vh;
  overflow-y: scroll;
  grid-gap: 20px;
`

export const CampusesContainer = styled.div`
  display: grid;
  grid-gap: 20px;
`

export const CampusItemsContainer = styled.div`
  background-color: #fff;
  display: grid;
  grid-template-columns: 10fr 1fr;
  grid-gap: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`

export const CampusWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`

export const CampusItemCard = styled.div`
  padding: 18px 14px;
`

export const CampusItemTitle = styled.div`
  font-weight: bold;
`

export const CampusButtonWrapper = styled.div`
  display: grid;
  grid-gap: 2px;
  border-radius: 5px;
`

export const CampusCardModalTrigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #575961;
  border-radius: 7px;
  &:hover {
    cursor: pointer;
  }
`

export const ModalCampus = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.4)'
  },
  content: {
    padding: '28px 30px',
    bottom: 'none',
    borderRadius: '8px',
    top: '140px',
    left: '350px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    right: '350px',
    height: '400px', // <-- This sets the height
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
}

export const CampusWrapperTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.21;
`
/* ======= END CAMPUS STYLED COMPONENTS ======= */

/* ======= SERVICE STYLED COMPONENTS ========== */
export const ServiceFormAddContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 100px;
`

export const ServiceItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-gap: 8px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`

export const ServiceListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
`

export const FormTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 25px;
`

export const ButtonModal = styled.button`
  border-radius: 10px;
  border: solid 1px #9a9a9a;
  margin-top: 10px;
  margin-bottom: 20px;
  border-style: dashed;
  background: transparent;
  width: 100%;
  padding: 25px 0;
  text-align: center;
  color: #9a9a9a;
  font-weight: bold;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const Button = styled.button`
  padding: 10px 40px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 10px;
  color: #fff;
  ${({ primary }) =>
    primary &&
    css`
      background-color: #4da5dc;
    `}
    ${({ secondary }) =>
      secondary &&
      css`
        background-color: #ffffff;
        color: #4da5dc;
        border: solid 2px #4da5dc;
      `}
  ${({ cancel }) =>
    cancel &&
    css`
      background-color: #989898;
    `}
  ${({ btnExport }) =>
    btnExport &&
    css`
      background-color: #575961;
    `}
  ${({ saveButton }) =>
    saveButton &&
    css`
      width: 123px;
      height: 40px;
      justify-self: flex-end;
      background-color: #4da5dc;
      margin-right: 30px;
    `}
  ${({ addButton }) =>
    addButton &&
    css`
      border-radius: 10px;
      border: solid 2px #4da5dc;
      background-color: #fff;
      color: #4da5dc;
      font-weight: bold;
      font-size: 14px;
    `}
`

export const XIconService = styled.i`
  &:hover {
    cursor: pointer;
  }
`

export const DoneModalContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

export const ModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.4)'
  },
  content: {
    padding: '10px 30px',
    bottom: 'none',
    top: '30px',
    left: '350px',
    right: '350px',
    height: '700px', // <-- This sets the height
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
}

export const TimeFormAddContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 100px;
`
