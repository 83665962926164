import axios from 'axios'

export const getNewsfeedDetail = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/information/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const createNews = async (formdata) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/information/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}

export const updateNews = async (formdata) => {
	return await axios({
		method: 'post',
		data: formdata,
		url: `${process.env.REACT_APP_ENDPOINT}/information/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getListNewsfeed = async (paginate, informationCategoryId, title, churchId, sortBy, sortDirection, informationTypeName, page) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/information/get/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			informationCategoryId,
			title,
			churchId,
			sortBy,
			sortDirection,
			informationTypeName,
			page,
		},
	})
}

export const getCategories = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/informationcategory/get`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			withInformation: 0,
		},
	})
