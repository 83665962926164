import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ClassesIcon } from 'assets/icons/classes.svg'

const navigation = [
	'/classes/create',
	'/classes',
	'/classes/edit/:id',
	'/classes/registrant/:id',
	'/classes/create-code',
	'/classes/list-code',
	'/classes/edit-code/:id',
]

class ClassesMenu extends Component {
	state = { isOpenSub: false }

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = navigation.filter((menu) => menu === path)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state
		const menus = navigation.filter((menu) => menu === path)
		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkCreateClass = `${
			path === '/classes/create' ? 'text-primary' : 'text-grey-500'
		}`
		const styleLinkCreateClassCode = `${
			path === '/classes/create-code' ? 'text-primary' : 'text-grey-500'
		}`
		const styleLinkList = `${
			path === '/classes' ||
			path === '/classes/edit/:id' ||
			path === '/classes/registrant/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`

		const styleLinkClassCode = `${
			path === '/classes/list-code' || path === '/classes/edit-code/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<ClassesIcon className={styleIcon} />
					</div>
					<div className="font-bold text-grey-500">Classes</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/classes/create" className={styleLinkCreateClass}>
									Create Class
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/classes" className={styleLinkList}>
									List of All Classes
								</Link>
							</li>
							<li className="mb-4">
								<Link
									to="/classes/create-code"
									className={styleLinkCreateClassCode}
								>
									Create Class Code
								</Link>
							</li>
							<li className="mb-4">
								<Link
									to="/classes/list-code"
									className={styleLinkClassCode}
								>
									List of Class Code
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default ClassesMenu
