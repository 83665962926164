import styled from 'styled-components'

export const ListSGContainer = styled.div`
	display: grid;
	grid-gap: 20px;
`

export const RoleRibbon = styled.span`
	color: white;
	position: relative;
	top: -60px;
	width: 0;
	left: 20px;
	text-align: center;
	font-size: 18px;
	font-family: arial;
	display: block;
	transform: rotate(-45deg);
`

export const RoleContainer = styled.div`
	width: 0;
	height: 0;
	border-top: 10px solid;
	border-bottom: 10px solid transparent;
	border-right: 90px solid transparent;
	margin-bottom: -100px;
	border-top: 90px solid ${(props) => (props.color ? props.color : '#fff')};
`

export const SmallGroupHeader = styled.div`
	display: flex;
	width: 100%;

	div:last-of-type {
		margin-left: auto;
	}
`

export const TreeContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-width: 2px;
	border-radius: 9999px;
	background-color: #fff;
	margin-top: 0.5rem;
	margin-left: ${(props) => props.padding};
	:hover {
		border-color: ${(props) => props.color};
	}
`

export const Tag = styled.p`
	border-top-left-radius: 9999px;
	border-bottom-left-radius: 9999px;
	padding-left: 1rem;
	padding-right: 1rem;
	align-items: center;
	font-weight: bold;
	background-color: ${(props) => props.color};
	align-items: center;
	display: flex;
	color: #fff;
`

export const Export = styled.div`
	background-color: #fff;
	border: solid 2px #fff;
	color: #48a4df;
	font-weight: bold;
	width: 125px;
	height: 44px;
	border-radius: 10px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	padding: 0.5rem 1.25rem;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
`
