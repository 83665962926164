import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { getClassRole, getVenues, getListCode, getClassType } from 'libs/apis/courses'
import { getHeadsAndDeputy } from 'libs/apis/ministry'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import CreateClassFormik from 'components/formik/CreateClassFormik'

function CreateClass(props) {
    const [listVenue, setListVenue] = useState([])
    const [roles, setRoles] = useState([])
    const [allRolesFormik, setAllRolesFormik] = useState([])
    const [listCode, setListCode] = useState([])
    const [listType, setListType] = useState([])
    const [allVolunteer, setAllVolunteer] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchRole = async () => {
            try {
                const res = await getClassRole()
                const ministrymemberrole = res.data.data.ministrymemberrole.map((v) => {
                    const temp = {}
                    temp.id = `ministrymemberrole_id.${v.id}`
                    temp.name = v.name
                    return temp
                })
                const role = res.data.data.role.map((v) => {
                    const temp = {}
                    temp.id = `userrole_id.${v.id}`
                    temp.name = v.name

                    return temp
                })
                const smallgroupmemberrole = res.data.data.smallgroupmemberrole.map((v) => {
                    const temp = {}
                    temp.id = `smallgroupmemberrole_id.${v.id}`
                    temp.name = v.name

                    return temp
                })

                setRoles(ministrymemberrole.concat(role, smallgroupmemberrole))
                setAllRolesFormik(ministrymemberrole.concat(role, smallgroupmemberrole))
            } catch (error) {
            }
        }
        fetchRole()
    }, [])

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const res = await getVenues()
                const venues = res.data.data.campus_list.map((v) => v.campusrooms)
                const a = venues
                    .map((v) => {
                        let element
                        for (let i = 0; i < v.length; i++) {
                            element = v[i]
                        }
                        return element
                    })
                    .filter((v) => v !== undefined)
                setListVenue([...a, {id: 'OTHERS', name: 'OTHERS'}])
            } catch (error) {
            }
        }

        fetchVenues()
    }, [])

    useEffect(() => {
        const fetchClassType = async () => {
            try {
                const res = await getClassType()
                setListType(res.data.data.programtype)
            } catch (error) {
            }
        }

        fetchClassType()
    }, [])

    useEffect(() => {
        const fetchListCode = async () => {
            try {
                const res = await getListCode()
                const codes = res.data.data.programcodes.map((v) => {
                    const temp = {}
                    temp.id = v.id
                    temp.name = v.name
                    return temp
                })

                setListCode(codes)
            } catch (error) {
            }
        }

        fetchListCode()
    }, [])

	useEffect(() => {
		setIsLoading(true)
		const fetchMinistryHeads = async () => {
			try {
                const res = await getHeadsAndDeputy(100, 1)
				const data = res.data.data.user.data.map((v) => {
					let temp = {}
					temp.value = v.id
					temp.label = v.fullname
					return temp
				})
				setAllVolunteer(data)
				setIsLoading(false)
			} catch (error) {
				console.error(error)
				setIsLoading(false)
			}
		}
		fetchMinistryHeads()
	}, [])

    const church = JSON.parse(localStorage.getItem('church'))
    const name = church.church_name ? church.church_name : '-'

    const breads = [{url: '', title: 'Create Class'}]

    return (
        <div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - Create Class</title>
            </Helmet>

            <PageBreadcrumbs icon={SGIcon} title="Create Class" breads={breads}/>
            <div className="flex md:-mx-2 flex-wrap">
                <CreateClassFormik
                    {...props}
                    listCode={listCode}
                    listType={listType}
                    listVenue={listVenue}
                    roles={roles}
                    allRolesFormik={allRolesFormik}
                    allVolunteer={allVolunteer}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}

export default CreateClass
