import { withFormik } from 'formik'
import moment from 'moment'
import validationSchema from './NewsValidSchema'
import NewsfeedForm from '../forms/NewsfeedForm'
import { createNews, updateNews } from 'libs/apis/newsfeeds'
import { successMessage, failedMessage } from 'libs/utils/confirmation'
import alert from 'services/alert'

const NewsfeedFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		id: props.id || '',
		media: props.media || '2',
		image: props.image || '',
		video: props.video || '',
		category: props.category || '0',
		newCategory: props.newCategory || '',
		title: props.title || '',
		description: props.description || '',
		startDate: props.startDate || '',
		endDate: props.endDate || '',
		gender: props.gender || '',
		maritalStatus: props.maritalStatus || '',
		ageFrom: props.ageFrom || '',
		ageTo: props.ageTo || '',
		endDateCheck: props.endDate ? true : false || true,
		isDraft: false,
		isCreateForm: props.isCreateForm || false,
	}),
	validationSchema: validationSchema,
	async handleSubmit(values, { props, setSubmitting }) {
		const { image, media, video, title, category, newCategory, description, startDate, endDateCheck, endDate, isDraft, isCreateForm, id } = values

		const postStart = moment(startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')
		const postEnd = moment(endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')

		let formdata = new FormData()

		formdata.append('informationTypeId', 2)
		formdata.append('documentTypeId', media)
		formdata.append('title', title)
		formdata.append('description', description)
		formdata.append('postStart', postStart)
		formdata.append('postEnd', postEnd)

		if (media === '2') {
			if (typeof image === 'string') {
				formdata.append('file', '')
			} else {
				formdata.append('file', image)
			}
		} else {
			formdata.append('documentUrl', video)
		}

		isDraft ? formdata.append('status', 'Draft') : formdata.append('status', 'Published')

		category !== 'new' ? formdata.append('informationCategoryId', category) : formdata.append('informationCategoryId', newCategory)

		try {
			if (isCreateForm) {
				const response = await createNews(formdata)
				if (response.data.api_status) {
					await successMessage()
					props.history.push('/newsfeed/list')
				} else {
					failedMessage(response.data.message)
				}
			} else if (!isCreateForm) {
				formdata.append('id', id)
				const response = await updateNews(formdata)
				if (response.data.api_status) {
					await successMessage()
					props.history.push('/newsfeed/list')
				} else {
					failedMessage(response.data.message)
				}
			}
			setSubmitting(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					props.history.push('/login')
				} else {
					setSubmitting(false)
					alert.failed('Something Wrong', 'Cannot edit news')
				}
			}
		}
	},
})(NewsfeedForm)

export default NewsfeedFormik
