import React, { useState } from 'react'
import Modal from 'react-modal'
import { moveFile } from 'libs/apis/file'
import { useHistory } from 'react-router-dom'
import Button from 'components/commons/Button'
import Loading from 'components/commons/Loading'
import DropdownItem from 'components/commons/DropdownItem'
import alert from 'services/alert'

const customStyles = {
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		height: '292px',
		width: '600px',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
}

export default function FileMove(props) {
	const [groupId, setGroupId] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { fileName, id, folders, fromInsideFolder, handleLoadData } = props
	const history = useHistory()

	const handleMove = async () => {
		setIsLoading(true)
		try {
			const res = await moveFile('Move', 'Document', id, groupId.value ? groupId.value : null)
			if (res.data.api_status) {
				alert.succeed(`Success`, `Your file has been uploaded`)
				handleLoadData()
				setIsLoading(false)
				setModalIsOpen(false)
			} else {
				alert.failed(`Ooops`, `Cannot move your file`)
				setIsLoading(false)
				setModalIsOpen(false)
			}
		} catch (error) {
			alert.failed(`Ooops`, `Cannot move your file`)
			handleLoadData()
			setIsLoading(false)
			setModalIsOpen(false)
		}
	}

	const handleTogleModal = () => {
		setModalIsOpen(!modalIsOpen)
	}

	const handleChange = (event) => {
		setGroupId(event)
	}

	return (
		<div>
			<button className="focus:outline-none" onClick={handleTogleModal}>
				Move
			</button>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="File Move Modal" appElement={document.getElementById('root')}>
				<div className="font-bold text-sm mb-2">MOVE FILE</div>
				<hr className="border-2 border-blueSecondary" />
				<div className="flex mt-3 ml-4">
					<div className="fa fa-file mt-1 text-gray-100" />
					<input className="ml-5 text-black w-4/5 bg-white" type="text" name="file" disabled={true} value={fileName} />
				</div>

				<hr className="border-2 border-blueSecondary mt-3" />
				<div className="font-bold text-sm mb-2 mt-2">MOVE TO</div>
				<DropdownItem name="folders" value={groupId} options={folders} handleChange={handleChange} placeholder="Search folder name" />

				<div className="flex justify-center mt-10">
					<Button isLoading type="cancel" handleClick={handleTogleModal}>
						{isLoading ? <Loading secondary /> : 'Close'}
					</Button>
					<Button type="primary" style="ml-6" handleClick={handleMove}>
						{isLoading ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
