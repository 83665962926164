import { withFormik } from 'formik'
import moment from 'moment'
import CreateMultiplicationForm from 'components/forms/CreateMultiplicationForm'
import { CreateMultiplicationValidationSchema } from 'components/formik/MultiplicationValidationSchema'
import { createSmallGroups } from 'libs/apis/smallGroup'
import { successMessage, failedMessage, somethingWrong } from 'libs/utils/confirmation'

const CreateMultiplicationFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		smallGroupCampus: '',
		smallGroupLevel: '',
		parentCampus: '',
		parentSmallGroup: '',
		name: '',
		category: '',
		smallGroupLeader: '',
		smallGroupUpperLevelLeaderName: '',
		fullAddress: '',
		shortAddress: '',
		region: '',
		postalCode: '',
		day: '',
		time: '',
		status: 'Open',
		established_date: '',
		ageFrom: '',
		ageTo: '',
		description: '',
	}),
	validate: (values) => {
		const errors = {}
		if (!parseInt(values.ageTo) || !parseInt(values.ageFrom)) {
			errors.ageTo = 'Required'
			errors.ageFrom = 'Required'
		} else if (parseInt(values.ageFrom) > parseInt(values.ageTo)) {
			errors.ageTo = 'has to be higher than Age From'
		}
		return errors
	},
	validationSchema: CreateMultiplicationValidationSchema,
	handleSubmit: async (values, { props }) => {
		const {
			ageFrom,
			ageTo,
			category,
			day,
			description,
			established_date,
			fullAddress,
			name,
			postalCode,
			region,
			shortAddress,
			smallGroupCampus,
			smallGroupLeader,
			smallGroupLevel,
			smallGroupUpperLevelLeaderName,
			status,
			time,
			parentSmallGroup,
		} = values

		let formdata = new FormData()

		let date = moment(established_date).format('YYYY/MM/DD')

		formdata.append('sgCampus', parseInt(smallGroupCampus))
		formdata.append('sgLevel', parseInt(smallGroupLevel))
		formdata.append('sgParent', parseInt(parentSmallGroup))
		formdata.append('sgName', name)
		formdata.append('sgCategory', parseInt(category))
		formdata.append('sgLeader', parseInt(smallGroupLeader))
		formdata.append('sgUpperLeader', smallGroupUpperLevelLeaderName)
		formdata.append('sgFullAddress', fullAddress)
		formdata.append('sgShortAddress', shortAddress)
		formdata.append('sgRegion', region)
		formdata.append('sgPostalCode', postalCode)
		formdata.append('sgDay', parseInt(day))
		formdata.append('sgTime', time)
		formdata.append('sgStatus', status)
		formdata.append('sgEstablishedDate', date)
		formdata.append('sgAgeFrom', parseInt(ageFrom))
		formdata.append('sgAgeTo', parseInt(ageTo))
		formdata.append('sgDescription', description)
		formdata.append('type', 'Multiplication')

		try {
			/* create smallgroup */
			let res = await createSmallGroups(formdata)
			if (res.data.api_status === true) {
				successMessage()
				props.history.push('/smallgroup/all/filtered')
			} else {
				failedMessage(res.data.message)
			}
		} catch ({ response: { status } }) {
			if (status === 500) {
				somethingWrong()
			}
		}
	},
})(CreateMultiplicationForm)

export default CreateMultiplicationFormik
