import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import {ReactComponent as EmailIcon} from 'assets/icons/campaign.svg'
import {failedFetch} from 'libs/utils/messages'
import Searchbar from 'components/commons/Searchbar'
import {Header} from 'screens/courses/styles'
import Loading from "../../components/commons/Loading";
import EmailListTable from "../../components/emailList/EmailListTable";
import PaginationPage from "../../components/commons/PaginationPage";
import {deleteCampaign, getListCampaign} from "../../libs/apis/emailCampaign";
import {failedMessage} from "../../libs/utils/confirmation";

const ListEmailCampaign = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const [keyword, setKeyword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [listEmailCampaign, setlistEmailCampaign] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState('created_at');
    const [sortDir, setSortDir] = useState('DESC');

    const breads = [{url: '', title: 'List of Emails'}];

    const {location} = props;
    const parsed = queryString.parse(location.search);
    const activeLink = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

    const displayLoading = isLoading;
    const displayError = !isLoading && errorMessage;
    const displayNoData = !isLoading && !errorMessage && listEmailCampaign.length === 0;
    const displayData = !isLoading && !errorMessage && listEmailCampaign.length > 0;

    const {search} = location;
    const pageNumber = search ? queryString.parse(search).page : 1;
    const totalListPerPage = 10;
    const typeModule = 'Email';

    const handleOnSearch = event => {
        setKeyword(event.target.value)
    };

    const handleSeachInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.history.push(`${props.location.pathname}?paginate=10&page=1`);
            setSearchValue(event.target.value);
            setKeyword(event.target.value);
        }
    };

    const handleOnSort = (sort) => {
        if (sortBy !== sort) {
            setSortBy(sort);
            setSortDir('ASC')
        } else {
            if (sortDir === 'ASC') {
                setSortBy(sort);
                setSortDir('DESC')
            } else if (sortDir === 'DESC') {
                setSortBy(sort);
                setSortDir('ASC')
            }
        }
    };

    const handleOnDelete = async (campaignId) => {
        try {
            setIsLoading(true);
            const response = await deleteCampaign(campaignId);
            console.log('response delete: ', response);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            await failedMessage(error.message)
        }
    };

    useEffect(() => {
        fetchListEmailCampaign()
    }, [totalListPerPage, searchValue, sortBy, sortDir, pageNumber]);

    const formattingListEmailCampaign = (emailCampaignData) => {
        console.log('emailCampaignData: ', emailCampaignData);
        return {
            // status: 'Draft',
            // isHighlight: 'true',
            id: emailCampaignData.id,
            subject: emailCampaignData.title,
            createdBy: emailCampaignData.creator ? emailCampaignData.creator.fullname ? emailCampaignData.creator.fullname : 'unknown' : 'unknown',
            createdOn: emailCampaignData.created_at,
            lastUpdated: emailCampaignData.updated_at,
            schedule: emailCampaignData.sendstart_at ? emailCampaignData.sendstart_at : 'unknown',
            emailStatus: emailCampaignData.status,
            totalSent: emailCampaignData.total_sent,
            totalRecipient: emailCampaignData.total_recipient
        }
    };

    const fetchListEmailCampaign = async () => {
        try {
            setIsLoading(true);
            const response = await getListCampaign(totalListPerPage, searchValue, sortBy, sortDir, typeModule, pageNumber);
            const listEmailCampaignData = response.data.data.campaign.data;
            let currentPage = response.data.data.campaign.current_page;
            let lastPage = response.data.data.campaign.last_page;

            console.log('listEmailCampaignData: ', listEmailCampaignData);
            console.log('response: ', response);

            if (listEmailCampaignData) {
                let listEmailCampaign = [];
                for (const campaign of listEmailCampaignData) {
                    listEmailCampaign.push(
                        formattingListEmailCampaign(campaign)
                    )
                }
                setlistEmailCampaign(listEmailCampaign);

                setCurrentPage(currentPage);
                setLastPage(lastPage)
            } else {
                setIsLoading(false);
                setErrorMessage(failedFetch)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - List of Emails </title>
            </Helmet>
            <PageBreadcrumbs icon={EmailIcon} title="List of Emails" breads={breads}/>
            <Header>
                <Searchbar filterKeyword={keyword}
                           handleOnSearch={handleOnSearch}
                           handleSeachInputKeyPress={handleSeachInputKeyPress}
                />
            </Header>

            {displayLoading && <Loading/>}
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayNoData && <div className="text-center">No data available</div>}
            {displayData && (
                <div className="pb-10">
                    <EmailListTable listEmailCampaign={listEmailCampaign}
                                    handleOnSort={handleOnSort}
                                    handleOnDelete={handleOnDelete}
                    />
                    <PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={activeLink}
                                    length={listEmailCampaign.length} url={props.location.pathname}/>
                </div>
            )}
        </div>
    )
};

export default ListEmailCampaign;
