import React, { useState, useEffect } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getNewsfeedDetail } from 'libs/apis/newsfeeds'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import NewsfeedFormik from 'components/formik/NewsfeedFormik'
import Loading from 'components/commons/Loading'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'

// import alert from 'services/alert'

function EditNews(props) {
	const [state, setState] = useState({
		id: '',
		media: '',
		video: '',
		image: '',
		category: '',
		title: '',
		description: '',
		startDate: '',
		endDate: '',
		gender: '',
		maritalStatus: '',
		ageFrom: '',
		ageTo: '',
	})

	const [isRedirect, setIsRedirect] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage] = useState('')

	const params = useParams()
	const history = useHistory()

	const { id, title, category, description, startDate, endDate, media, video, image } = state
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	useEffect(() => {
		const fetchNewsfeedDetails = async () => {
			try {
				const res = await getNewsfeedDetail(params.id)
				const {
					id,
					name,
					description,
					poststart_at,
					postend_at,
					informationcategory_id,
					document: { documenttype, url },
				} = res.data.data.information

				if (documenttype.name === 'Video') {
					setState({
						...state,
						id,
						title: name,
						description,
						startDate: poststart_at,
						endDate: postend_at,
						media: documenttype.id,
						video: url,
						image: '',
						category: informationcategory_id,
					})
				}
				if (documenttype.name === 'Image') {
					setState({
						...state,
						id,
						title: name,
						description,
						startDate: poststart_at,
						endDate: postend_at,
						media: documenttype.id,
						image: url,
						video: '',
						category: informationcategory_id,
					})
				}
			} catch (error) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setIsRedirect(true)
					setIsLoading(false)
				}
				if (error.response.status === 500) {
					alert.failed('Failed', 'Cannot retrieve data at this moment')
					history.push('/newsfeed/list')
					setIsLoading(false)
				}
			}
		}
		fetchNewsfeedDetails(params.id)
	}, [])

	const shouldDisplayLoad = isLoading && !errorMessage
	const shouldDisplayError = !isLoading && errorMessage
	const shouldDisplayForm = !isLoading && !errorMessage
	const redirectMessage = 'We could not verify that you are not authorized to process your request'
	const breads = [
		{ url: '/newsfeed/list', title: 'List of All' },
		{ url: '', title: 'Edit News Item' },
	]

	if (isRedirect) {
		return <Redirect to={{ pathname: '/login', state: redirectMessage }} />
	}

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit News Item</title>
			</Helmet>
			<PageBreadcrumbs icon={NewsIcon} title="Edit News" breads={breads} />
			{shouldDisplayLoad && <Loading />}
			{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
			{shouldDisplayForm && (
				<NewsfeedFormik
					{...props}
					media={media.toString()}
					video={video}
					image={image}
					category={category}
					id={id}
					title={title}
					description={description}
					startDate={startDate}
					endDate={endDate}
					isCreateForm={false}
				/>
			)}
		</div>
	)
}

export default EditNews
