import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import numeral from 'numeral'
import { partial } from 'libs/utils/utils'

const BtnDetails = styled.button`
	border-width: 0;
	text-decoration: underline;
`

export const Head = () => (
	<thead>
		<tr>
			<th>DATE</th>
			<th>DESCRIPTION</th>
			<th>TOTAL PAYMENT</th>
			<th />
		</tr>
	</thead>
)

export const Body = ({ data, handleDetails }) => (
	<tbody>
		{data.map(item => {
			const Details = partial(handleDetails, item.id)
			return (
				<tr key={item.id} id={item.id}>
					<td>{moment(item.billed_at, 'YYYY-MM-DD').format('DD MMM YYYY')}</td>
					<td>{item.description ? item.description : '-'}</td>
					<td>Rp. {numeral(item.rate).format('0,0')}</td>
					<td onClick={Details}>
						<BtnDetails>Details</BtnDetails>
					</td>
				</tr>
			)
		})}
	</tbody>
)
