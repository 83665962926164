import React, { useState, useRef, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import DownloadIcon from 'assets/icons/download.svg'
import FolderIcon from 'assets/icons/folder.svg'
import { bytesToMegaBytes } from 'libs/utils/utils'
import FileViewEdit from 'screens/file/FileViewEdit'
import FileMove from 'screens/file/FileMove'

const FileListTable = (props) => {
	return (
		<div className="bg-white shadow-md">
			<table className="w-full rounded-lg">
				<TableHeadFile />
				<TableBody {...props} />
			</table>
		</div>
	)
}

export default withRouter(FileListTable)

const TableHeadFile = () => {
	return (
		<thead className="text-gray">
			<tr>
				<th className="text-left justify-center pl-10 py-4">FILE NAME</th>
				<th className="text-left">SIZE</th>
				<th className="text-left">UPLOADED ON</th>
				<th className="text-left"></th>
				<th className="py-4" />
			</tr>
		</thead>
	)
}

const TableBody = ({ data, handleDelete, handleLoadData, search, folders }) => {
	const temp2 = data.map((val) => {
		if (val.documents) {
			for (let i = 0; i < val.documents.length; i++) {
				if (val.documents[i].name.toLowerCase().includes(search)) {
					return val
				}
			}
		}
		if (val.name.toLowerCase().includes(search)) {
			return val
		}
	})

	const hasil = temp2.filter((v) => v !== undefined)

	return (
		<tbody className="borde border-solid pl-5">
			{hasil.map((value) => (
				<FileListRow key={value.id} data={data} {...value} handleDelete={handleDelete} handleLoadData={handleLoadData} folders={folders} />
			))}
		</tbody>
	)
}

const FileListRow = ({ created_at, name, handleDelete, handleLoadData, url, size, id, isFolder, data, documenttype_id, folders }) => {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	const type = isFolder ? 'Folder' : 'Document'

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`
	return (
		<tr className="border border-solid bt-blueSecondary">
			{isFolder ? (
				<Link to={`/smallgroup/folders/${id}/${name}`}>
					<div className="text-left pl-10 pt-4">{name}</div>
				</Link>
			) : (
				<td className="text-left pl-10">{name}</td>
			)}
			{/* <td className="text-left">{bytesToMegaBytes(size)}</td> */}
			<td className="text-left">-</td>
			<td className="text-left">{moment(created_at).format('LL')}</td>
			<td className="items-left">
				{isFolder ? (
					<div className="items-center">
						<img className="block" src={FolderIcon} />
					</div>
				) : (
					<a href={url} className="items-center" target={url}>
						<img className="block" src={DownloadIcon} />
					</a>
				)}
			</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v -mr-4" />
					<div className={styleDropDownOpen}>
						<ul ref={node} className="bg-white w-32">
							{isFolder ? (
								<>
									<li className="px-4 py-2 block hover:bg-darkSkyBlue">
										<Link to={`/smallgroup/folders/${id}/${name}`}>View</Link>
									</li>
									<li className="px-4 py-2 block hover:bg-darkSkyBlue">
										<FileViewEdit fileName={name} documenttype_id={documenttype_id} id={id} handleLoadData={handleLoadData} />
									</li>
									<li
										className="px-4 py-2 block hover:bg-darkSkyBlue"
										onClick={() => {
											handleDelete(type, id)
										}}
									>
										Delete
									</li>
								</>
							) : (
								<>
									<li className="px-4 py-2 block hover:bg-darkSkyBlue">
										<FileViewEdit fileName={name} documenttype_id={documenttype_id} id={id} handleLoadData={handleLoadData} />
									</li>
									<li className="px-4 py-2 block hover:bg-darkSkyBlue">
										<FileMove id={id} fileName={name} folders={folders} handleLoadData={handleLoadData} />
									</li>
									<li
										className="px-4 py-2 block hover:bg-darkSkyBlue"
										onClick={() => {
											handleDelete(type, id)
										}}
									>
										Delete
									</li>
								</>
							)}
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
