import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { getRegencyByChurchId, getSmallGroupCategory, getSmallGroupLevel } from 'libs/apis/smallGroup'
import FilterItem from 'components/commons/FilterItem'
import { constants } from 'constants/constant'
import { Button, ButtonContainer, ButtonX, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from './Styles'

const customStyles = {
	content: {
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		height: '410px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

export default function SmallGroupFilter({ handleSelectFilter, locations, days, categories, sgLevels, campuses, handleClearFilter, handleApplyFilter }) {
	const [tab, setTab] = useState('day')
	const [listlocations, setListlocations] = useState([])
	const [listCategory, setListCategory] = useState([])
	const [sgLevel, setSgLevel] = useState([])
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	let { church_id } = JSON.parse(localStorage.getItem('church'))
	let listCampus = JSON.parse(localStorage.getItem('campuses'))

	/* fetch regency dan category by church id */
	useEffect(() => {
		const fetchRegency = async () => {
			try {
				let res = await getRegencyByChurchId(church_id)
				let regency_data = res.data.data.regency.map((data) => {
					let obj = {}

					if (data.regency_id !== null) {
						obj.id = data.regency.id
						obj.name = data.regency.name
					} else {
						return obj
					}
					return obj
				})

				setListlocations(regency_data)
			} catch (error) {
			}
		}

		const fetchCategory = async () => {
			try {
				let res = await getSmallGroupCategory(church_id)
				setListCategory(res.data.data.categories)
			} catch (error) {
			}
		}
		fetchCategory()
		fetchRegency()
	}, [church_id])

	/* fetch smallgroup level */
	useEffect(() => {
		const fetchSmallGroupLevel = async () => {
			try {
				const res = await getSmallGroupLevel()
				let resData = res.data.data.smallgroup_level
				let levelData = resData.map((data) => {
					let obj = {}
					obj.id = data.level
					obj.name = data.name
					return obj
				})
				setSgLevel(levelData)
			} catch (error) {
			}
		}
		fetchSmallGroupLevel()
	}, [])

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('location')
	}

	const handleApply = () => {
		setModalIsOpen(!modalIsOpen)
		handleApplyFilter()
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearFilters = () => {
		handleClearFilter()
	}

	const locationTab = tab === 'location'
	const dayTab = tab === 'day'
	const categoryTab = tab === 'category'
	const sgLevelTab = tab === 'SG Level'
	const campusTab = tab === 'campus'

	const length = locations.length !== 0 || days.length !== 0 || categories.length !== 0 || campuses.length !== 0 || sgLevels.length !== 0

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				{length && <ButtonX onClick={handleClearFilters}>X</ButtonX>}
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Filter
				</ButtonFilter>
			</div>

			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Small Group Filter Modal" ariaHideApp={false}>
				<Header>
					<Title>Small Group Filter</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content>
						<Input tab={tab === 'location'} type="button" value={'location'} onClick={handleOnTab} />
						<Input tab={tab === 'day'} type="button" value={'day'} onClick={handleOnTab} />
						<Input tab={tab === 'category'} type="button" value={'category'} onClick={handleOnTab} />
						<Input tab={tab === 'SG Level'} type="button" value={'SG Level'} onClick={handleOnTab} />
						<Input tab={tab === 'campus'} type="button" value={'campus'} onClick={handleOnTab} />
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{locationTab &&
								listlocations.map(({ name, id }) => (
									<div key={name}>
										{name !== undefined ? (
											<FilterItem
												classname={`${locations.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
												name="location"
												title={name}
												value={id}
												onClick={handleSelectFilter}
											/>
										) : (
											<> </>
										)}
									</div>
								))}
							{dayTab &&
								constants.days.map(({ title, id }) => (
									<div className="pr-2" key={title}>
										<FilterItem
											classname={`${days.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="day"
											title={title}
											value={id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{categoryTab &&
								listCategory.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="category"
											value={id}
											title={name}
											onClick={handleSelectFilter}
											classname={`${categories.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
										/>
									</div>
								))}
							{sgLevelTab &&
								sgLevel.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="sgLevel"
											title={name}
											value={id}
											onClick={handleSelectFilter}
											classname={`${sgLevels.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
										/>
									</div>
								))}
							{campusTab &&
								listCampus.map(({ name, id }) => (
									<div className="pr-2" key={name}>
										<FilterItem
											name="campus"
											title={name}
											value={id}
											onClick={handleSelectFilter}
											classname={`${campuses.includes(id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
										/>
									</div>
								))}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearFilters}>
						<Span>Clear Filter</Span>
					</Button>
					<Button onClick={handleApply}>
						<Span> Apply Filter</Span>
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
