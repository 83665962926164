import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as NewsfeedIcon } from 'assets/icons/newsfeed.svg'

class NewsfeedMenu extends Component {
	state = {
		isOpenSub: false,
	}

	componentDidMount() {
		const {
			match: { path },
		} = this.props

		const menus = ['/newsfeed/create', '/newsfeed/list', '/newsfeed/edit/:id'].filter(
			(menu) => menu === path
		)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props

		const { isOpenSub } = this.state
		const menus = ['/newsfeed/create', '/newsfeed/list', '/newsfeed/edit/:id'].filter(
			(menu) => menu === path
		)

		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkCreate = `${
			path === '/newsfeed/create' ? 'text-primary' : 'text-grey-500'
		}`
		const styleLinkList = `${
			path === '/newsfeed/list' || path === '/newsfeed/edit/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<NewsfeedIcon className={styleIcon} />
					</div>
					<div className="font-bold text-grey-500">Newsfeed</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/newsfeed/create" className={styleLinkCreate}>
									Create News Item
								</Link>
							</li>
							<li className="mb-4">
								<Link to="/newsfeed/list" className={styleLinkList}>
									List of All
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default NewsfeedMenu
