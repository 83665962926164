import React, { Component } from 'react'
import { partial } from 'libs/utils/utils'
import ViewRequestTableRow from './ViewRequestTableRow'

class ViewRequestTable extends Component {
  render() {
    const { list, handleSort } = this.props

    return (
      <div className="bg-white shadow-md rounded">
        <table className="w-full">
          <TableHeader handleSort={handleSort} />
          <TableBody list={list} />
        </table>
      </div>
    )
  }
}

const TableHeader = ({ handleSort }) => {
  const handleSortExp = partial(handleSort, 'valid_to')
  return (
    <thead className="bt-blueSecondary border-2 border-solid">
      <tr>
        <th className="px-2 py-4 text-left">NAME</th>
        <th className="px-2 py-4 text-left">EMAIL</th>
        <th className="px-2 py-4 text-left">PHONE</th>
        <th className="px-2 py-4 text-left">GENDER</th>
        <th className="px-2 py-4 text-left">AGE</th>
        <th className="px-2 py-4 text-left">STATUS</th>
        <th className="px-2 py-4 text-left">
          EXPIRED <i className="fas fa-sort sortby" onClick={handleSortExp} />
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({ list }) => (
  <tbody className="border-2 border-solid">
    {list.map((item) => (
      <ViewRequestTableRow
        key={item.small_group_request_id}
        id={item.small_group_request_id}
        name={item.fullname}
        email={item.email}
        phone={item.phone_number}
        gender={item.gender_desc}
        age={item.age}
        status={item.status}
        expired={item.valid_to}
      />
    ))}
  </tbody>
)

export default ViewRequestTable
