import axios from "axios";

export const createEmailCampaign = async formdata => {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/create`,
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        data: formdata
    });
};

export const updateEmailCampaign = async (id, formdata) => {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
        data: formdata
    });
};

export const deleteEmailCampaign = async id => {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/delete/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
    });
};

export const getEmailCampaignDetails = async id => {
    return await axios({
        method: "get",
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/detail/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
    });
};

// export const getListEmailCampaign = async (
//   page,
//   filter_keyword,
//   order_by,
//   order_dir
// ) => {
//   return await axios({
//     method: "get",
//     url: `${process.env.REACT_APP_ENDPOINT}/campaign`,
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     params: {
//       page,
//       filter_keyword,
//       order_by,
//       order_dir
//     }
//   });
// };

export const uploadImage = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/upload/image`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const uploadAttachment = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/attachment`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const getListCampaign = async (paginate, searchValue, sortBy, sortDir, typeModule, pageNumber) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/all/filtered`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            type: typeModule,
            paginate: paginate,
            page: pageNumber,
            searchParam: searchValue,
            sortBy: sortBy,
            sortDirection: sortDir
        },
    });

export const getCampaignDetail = async (campaignId, campaignType) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/all/filtered`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            type: campaignType,
            paginate: 10,
            id: campaignId
        },
    });

export const deleteCampaign = async (campaignId) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/delete`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            id: campaignId
        },
    });

export const getRecipients = async (gender, maritalStatus, smallgroupmemberrole_id, ministrymemberrole_id, userrole_id, nonSmallGroupCheck) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/recipient`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            paginate: 100000,
            gender: gender,
            martialStatus: maritalStatus,
            sgmRoles: smallgroupmemberrole_id,
            mmRoles: ministrymemberrole_id,
            ministryGroup: null,
            userRoles: userrole_id,
            nsmRoles: nonSmallGroupCheck ? 1 : 0
        },
    });

export const getRecipientsQueryStale = async (query) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/recipient`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            paginate: 10,
            filterQuery: query
        },
    });

export const getRecipientsBasic = async (gender, maritalStatus, smallgroupmemberrole_id, ministrymemberrole_id, userrole_id, nonSmallGroupCheck, actionType, campaignId) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/recipient`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            gender: gender,
            martialStatus: maritalStatus,
            sgmRoles: smallgroupmemberrole_id,
            mmRoles: ministrymemberrole_id,
            ministryGroup: null,
            userRoles: userrole_id,
            nsmRoles: nonSmallGroupCheck ? 1 : 0,
            actionType: actionType,
            campaignId: campaignId
        },
    });

export const getRecipientsQuery = async (query, actionType, campaignId) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/recipient`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            filterQuery: query,
            actionType: actionType,
            campaignId: campaignId
        },
    });


export const createCampaign = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/create`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const updateCampaign = async (formdata, campaignId) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/create`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata,
        params: {
            id: campaignId
        },
    });

export const changeStatusDraftCampaignStale = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/create-draft`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const changeStatusPublishCampaign = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/publish`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const sendCampaignEmail = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/send-email`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const sendCampaignNotif = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/campaign/send-notif`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });
