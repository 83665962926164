import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Loading from 'components/commons/Loading'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import { getUserDetail } from 'libs/apis/usermanagement'
import { Flex, Box, Data, Value, Label, Left, Right } from './styles'

const ViewUser = () => {
	const [state, setState] = useState({
		userProfile: '',
		fullname: '',
		email: '',
		phone: '',
		genderDesc: '',
		dob: '',
		memberSince: '',
		birthplace: '',
		address: '',
		city: '',
		homeNumber: '',
		baptismChurch: '',
		baptismCity: '',
		baptismDate: '',
		lastEdu: '',
		fieldEdu: '',
		occupation: '',
		fieldJob: '',
		company: '',
		maritalStatus: '',
		spouse: '',
		childs: [],
		small_group_name: '',
		completed_class: [],
		ministryHistory: [],
		smallgroupHistory: [],
	})

	const [isLoadingData, setIsLoadingData] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const { id } = useParams()

	useEffect(() => {
		let isCancel = false

		const fetchUserDetails = async () => {
			try {
				setIsLoadingData(true)
				if (!isCancel) {
					const res = await getUserDetail(id)
					const user = res.data.data.user

					setState({
						...state,
						userProfile: '',
						fullname: user.fullname,
						email: user.email,
						phone: user.phone,
						genderDesc: user.gender,
						dob: user.birthdate,
						memberSince: '',
						birthplace: user.birthplace,
						address: user.address,
						city: user.addresscity,
						homeNumber: user.homenumber,
						baptismChurch: user.baptismchurch,
						baptismCity: user.baptismcity,
						baptismDate: user.baptismdate,
						lastEdu: user.educationlevel ? user.educationlevel.name : '',
						fieldEdu: user.education ? user.education.name : '',
						occupation: user.useroccupations.length ? user.useroccupations[0].occupation.name : '',
						fieldJob: user.useroccupations.length && user.useroccupations[0].occupationtype ? user.useroccupations[0].occupationtype.name : '',
						company: user.useroccupations.length ? user.useroccupations[0].companyname : '',
						maritalStatus: user.maritalstatus.name,
						small_group_name: user.smallgroupmembers ? user.smallgroupmembers[0].smallgroup.name : '',
						completed_class: [],
						ministryHistory: user.useractions_ministryduration ? user.useractions_ministryduration : [],
						smallgroupHistory: user.useractions_smallgroupduration ? user.useractions_smallgroupduration : [],
						spouse: user.userfamily ? user.userfamily.user2 : '',
						childs: user.userfamily ? user.userfamily.userfamilychilds : [],
					})

					setIsLoadingData(false)
				}
			} catch (error) {
				setErrorMessage('Cannot retrieve any data at this moment')
				setIsLoadingData(false)
			}
		}
		fetchUserDetails()

		return () => {
			isCancel = true
		}
	}, [])

	const displayLoading = isLoadingData && !errorMessage
	const displayData = !isLoadingData && !errorMessage
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/user-management/list', title: 'List of All Users' },
		{ url: '', title: 'View User' },
	]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View User</title>
			</Helmet>
			<PageBreadcrumbs icon={UserIcon} title="View User" breads={breads} />
			{displayLoading && <Loading />}
			{displayData && <ProfileUser data={state} />}
		</div>
	)
}

export default ViewUser

const ProfileUser = (props) => {
	return (
		<div>
			<div className="bg-white shadow-md mb-6 ">
				<ProfileHeader data={props.data} />
			</div>
			<div className="flex">
				<ProfileLeftColumn data={props.data} />
				<ProfileRightColumn data={props.data} />
			</div>
		</div>
	)
}

const ProfileHeader = ({ data }) => {
	const { profilePhoto, fullname, email, phone, genderDesc, dob, memberSince } = data

	const regexChecker = (string) => {
		var ua = navigator.userAgent.toLowerCase()
		if (ua.indexOf('safari') !== -1) {
			if (ua.indexOf('chrome') > -1) {
				let emailRegex = string.replace(new RegExp('(?<=.)[^@](?=[^@]*?@)|(?:(?<=@.)|(?!^)\\G(?=[^@]*$)).(?!$)', 'gm'), '*')
				return emailRegex
			} else {
				return string
			}
		}
	}

	const regexEmail = regexChecker(email)
	const json = JSON.parse(localStorage.getItem('church'))
	const churchCode = json.church_code

	return (
		<Flex>
			<div className="w-2/12">
				<div className="flex justify-center items-center flex-col h-full w-full">
					{profilePhoto ? (
						<img className="rounded-full h-24 w-24 flex items-center justify-center" src={profilePhoto} alt="profile" />
					) : (
						<img className="rounded-full h-24 w-24 flex items-center justify-center" src={photoplaceholder} alt="profile" />
					)}
				</div>
			</div>
			<div className="w-5/12 my-auto">
				<div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Full Name</div>
							<div className="flex-1 font-bold">{fullname}</div>
						</div>
					</div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Email</div>
							<div className="flex-1 font-bold">{churchCode === 'skybr.id' ? regexEmail : email}</div>
						</div>
					</div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Phone Number</div>
							<div className="flex-1 font-bold">{churchCode === 'skybr.id' ? '+62********' + phone.slice(11, 14) : phone}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-5/12 my-auto">
				<div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Gender</div>
							<div className="flex-1 font-bold">{genderDesc}</div>
						</div>
					</div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Birthday</div>
							<div className="flex-1 font-bold">{dob ? moment(dob, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</div>
						</div>
					</div>
					<div className="p-2">
						<div className="flex">
							<div className="flex-1 text-grayBold font-bold">Member Since</div>
							<div className="flex-1 font-bold">{memberSince ? moment(memberSince, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</div>
						</div>
					</div>
				</div>
			</div>
		</Flex>
	)
}

const ProfileLeftColumn = ({ data }) => {
	const { birthplace, address, city, baptismChurch, baptismCity, baptismDate, fieldJob, fieldEdu, company, lastEdu, occupation } = data
	return (
		<Left>
			<Box>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Birthplace</Label>
					<Value>{birthplace ? birthplace : '-'}</Value>
				</div>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Address</Label>
					<div> {address ? address : '-'}</div>
				</div>

				<div className="flex justify-right mb-4 font-bold">
					<Label>City</Label>
					<Value>{city ? city : '-'}</Value>
				</div>
			</Box>
			<Box>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Baptism Church</Label>
					<Value>{baptismChurch ? baptismChurch : '-'}</Value>
				</div>
				<div className="flex justify-right mb-4 font-bold">
					<Label> Baptism City</Label>
					<Value>{baptismCity ? baptismCity : '-'}</Value>
				</div>
				<div className="flex justify-right mb-4 font-bold">
					<Label> Baptism Date</Label>
					<Value>{baptismDate ? moment(baptismDate, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</Value>
				</div>
			</Box>
			<Box>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Occupation Fields</Label>
					<Value>{occupation ? occupation : '-'}</Value>
				</div>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Company Name</Label>
					<Value>{company}</Value>
				</div>
			</Box>
			<Box>
				<div className="flex justify-right mb-4 font-bold">
					<Label>Last Education</Label>
					<Value> {lastEdu ? lastEdu : '-'}</Value>
				</div>

				<div className="flex justify-right mb-4 font-bold">
					<Label>Field Of Education</Label>
					<Value> {fieldEdu ? fieldEdu : '-'}</Value>
				</div>
			</Box>
		</Left>
	)
}

const ProfileRightColumn = ({ data }) => {
	const { maritalStatus, small_group_name, spouse, childs, ministryHistory, smallgroupHistory } = data
	return (
		<Right>
			<Box>
				<Data>
					<Label>Relationship Status</Label>
					<Value> {maritalStatus ? maritalStatus : ''}</Value>
				</Data>
				<Data>
					<Label> Small Group</Label>
					<Value>{small_group_name ? small_group_name : '-'}</Value>
				</Data>

				<Data>
					<Label>Spouse</Label>
					<Value> {spouse ? spouse.fullname : '-'}</Value>
				</Data>

				<Data>
					<Label> Spouse's Birthday</Label>
					<Value>{spouse ? moment(spouse.birthdate, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</Value>
				</Data>

				<Data>
					<Label> Spouse's Email</Label>
					<Value>{spouse ? spouse.email : '-'}</Value>
				</Data>

				{childs.map((child, idx) => (
					<Childs data={child} key={idx} />
				))}
			</Box>

			<Box>
				<Data>
					<Label>Ministry</Label>
					<div className="flex-col">
						{ministryHistory.map((val) => (
							<div key={val.ministry.name}>
								<Value>{val.ministry.name}</Value>
								<Value>
									{moment(val.joinstart_at).format('YYYY')} - {val.joinend_at ? moment(val.joinend_at).format('YYYY') : 'Now'}
								</Value>
							</div>
						))}
					</div>
				</Data>
			</Box>

			<Box>
				<Data>
					<Label>Small Group History</Label>
					<div className="flex-col">
						{smallgroupHistory.map((val) => (
							<div key={val.smallgroup.name}>
								<Value>{val.smallgroup.name}</Value>
								<Value>
									{moment(val.joinstart_at).format('YYYY')} - {val.joinend_at ? moment(val.joinend_at).format('YYYY') : 'Now'}
								</Value>
							</div>
						))}
					</div>
				</Data>
			</Box>
		</Right>
	)
}

const Childs = ({ data }) => {
	return (
		<div>
			<Data>
				<Label>Child - Name</Label>
				<Value>{data.name}</Value>
			</Data>
			<Data>
				<Label>Child - Gender</Label>
				<Value>{data.gender}</Value>
			</Data>
			<Data>
				<Label>Child - Birthday</Label>
				<Value>{data.birthday ? moment(data.birthday, 'YYYY-MM-DD').format('DD MMMM YYYY') : '-'}</Value>
			</Data>
		</div>
	)
}
