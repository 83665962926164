import http from './httpServices'
import axios from 'axios'

export const eventRegistrantDashboard = async id => {
	return await http.get(`/event/dashboard/${id}`, {
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const eventRegistrantList = async (
	id,
	page,
	filter_keyword,
	filter_status,
	order_by,
	order_dir
) => {
	return await http.search(
		`/event/registrant/${id}`,
		{
			page,
			filter_status,
			filter_keyword,
			order_by,
			order_dir,
		},
		localStorage.getItem('token')
	)
}

export const eventRegistrantExport = async (
	id,
	filter_keyword,
	filter_status,
	order_by,
	order_dir
) => {
	return await http.get(`/event/registrant/${id}/export`, {
		params: {
			filter_keyword,
			filter_status,
			order_by,
			order_dir,
		},
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const eventConfirmation = async registrant_id => {
	return await http.post(
		'/event/registrant/reconfirm',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const eventReject = async registrant_id => {
	return await http.post(
		'/event/registrant/remove',
		{ registrant_id },
		{
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	)
}

export const attendanceToggle = async formdata => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/event/attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: formdata,
	})
}
