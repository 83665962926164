import {withFormik} from 'formik'
import CreateEventCodeForm from "../forms/CreateEventCodeForm";
import EditEventCodeForm from "../forms/EditEventCodeForm";
import {EventCodeValidationSchema} from "./EventCodeValidationSchema";
import {createEventCode, updateEventCode} from "../../libs/apis/events";
import {failedMessage, somethingWrong, successMessage} from "../../libs/utils/confirmation";

const EditEventCodeFormik = withFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: (props) => ({
        eventCodeId: '',
        eventCode: '',
        eventName: '',
        ageFrom: 0,
        ageTo: 0,
        genderList: [],
        maritalStatusList: [],
        roleList: [],
        ministryList: [],
        typeList: [],
        basicRecipientMethodList: [],
        registrationCheck: false
    }),
    validationSchema: EventCodeValidationSchema,
    handleSubmit: async (values, {props}) => {
        const {
            eventCodeId,
            eventCode,
            eventName,
            ageFrom,
            ageTo,
            genderList,
            maritalStatusList,
            roleList,
            ministryList,
            typeList,
            registrationCheck,
            isAgeValid
        } = values;

        let formattedGender = '';
        if (genderList.length === 1) {
            formattedGender = genderList[0]
        }

        let formattedReqfamily = 0;
        let formattedReqchildren = 0;
        let formattedIsCouple = 0;

        if (typeList.length >= 1) {
            if (typeList.includes('type0')) {
                formattedIsCouple = 1;
            }
            if (typeList.includes('type2')) {
                formattedReqfamily = 1;
                formattedReqchildren = 1;
            } else if (typeList.includes('type1') && !typeList.includes('type2')) {
                formattedReqfamily = 1;
                formattedReqchildren = 0;
            }
        }

        const formattedIsUser = registrationCheck ? 1 : 0;

        let formdata = new FormData();
        formdata.append('id', eventCodeId);
        formdata.append('type', "Event");
        formdata.append('classCode', eventCode);
        formdata.append('className', eventName);
        formdata.append('numberSession', '0');
        formdata.append('minimumSession', '0');
        formdata.append('gender', formattedGender);
        formdata.append('reqfamily', formattedReqfamily);
        formdata.append('reqchildren', formattedReqchildren);
        formdata.append('leaderapproval', '0');
        formdata.append('adminapproval', '0');
        formdata.append('roleId', roleList);
        formdata.append('ministryId', ministryList);
        formdata.append('classPrerequisiteId', '');
        formdata.append('maritalStatusId', maritalStatusList);
        formdata.append('isuser', formattedIsUser);
        formdata.append('agemin', ageFrom);
        formdata.append('agemax', ageTo);
        formdata.append('iscouple', formattedIsCouple);

        try {
            if(isAgeValid) {
                let res = await updateEventCode(formdata);
                if (res.data.api_status === true) {
                    successMessage();
                    props.history.push('/events/list-event-code');
                } else {
                    failedMessage(res.data.message)
                }
            }
        } catch (error) {
            somethingWrong();
        }
    },
})(EditEventCodeForm);

export default EditEventCodeFormik
