import * as Yup from 'yup'

const PushNotifCampaignValidationSchema = () =>
    Yup.object().shape({
        title: Yup.string().required('Required'),
        body: Yup.string().required('Required'),
        recipientsGender: Yup.string().nullable().required('Required'),
        recipientsMaritalStatus: Yup.string().required('Required'),
    });

export default PushNotifCampaignValidationSchema

