import React from 'react'
import { Helmet } from 'react-helmet'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EventIcon } from 'assets/icons/events.svg'
import CreateEventFormik from 'components/formik/CreateEventFormik'

function CreateEvent(props) {
	const church = JSON.parse(localStorage.getItem('church'))

	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create Event' }]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 lg:w-5/6">
			<Helmet>
				<title>{name} - Create Event</title>
			</Helmet>
			<PageBreadcrumbs icon={EventIcon} title="Create Event" breads={breads} />
			<CreateEventFormik {...props} />
		</div>
	)
}

export default CreateEvent
