import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { getListNewsfeed, getCategories } from 'libs/apis/newsfeeds'
import { ReactComponent as NewsIcon } from 'assets/icons/newsfeed.svg'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import NewsfeedTable from 'components/commons/newsfeed/NewsfeedTable'
import PaginationPage from 'components/commons/PaginationPage'
import Loading from 'components/commons/Loading'
import { helperSelectFilter } from 'libs/utils/utils'
import Searchbar from 'components/commons/Searchbar'
import { SmallGroupHeader } from 'screens/smallGroup/styles'
import NewsfeedFilter from 'components/commons/newsfeed/NewsfeedFilter'

function News(props) {
	const [state, setState] = useState([])
	const [category, setCategory] = useState([])
	const [campus, setCampus] = useState([])
	const [churchId, setChurchId] = useState([])
	const [sortBy, setSortBy] = useState('created_at')
	const [sortDirection, setSortDirection] = useState('DESC')
	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const [listCategori, setListCategori] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const location = useLocation()
	const history = useHistory()
	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ key: 'listofall', label: 'List of All' }]

	useEffect(() => {
		fetchListNewsfeed()
	}, [location.search, category, churchId, sortBy, sortDirection, searchValue])

	useEffect(() => {
		setIsLoading(true)

		const fetchCategory = async () => {
			try {
				let res = await getCategories()
				const cate = res.data.data.informationcategories

				setListCategori(cate)
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setIsLoading(false)
			}
		}
		fetchCategory()
	}, [])

	const fetchListNewsfeed = async () => {
		const { search } = location
		const page = search ? queryString.parse(search).page : 1

		try {
			setIsLoading(true)
			let res = await getListNewsfeed(10, category.toString(), keyword, campus.toString(), sortBy, sortDirection, 'Newsfeed', page)
			if (res.status === 200) {
				let newsfeedData = res.data.data.information.data.map((data) => {
					let temp = {}
					temp.id = data.id
					temp.campus = ''
					temp.title = data.name
					temp.status = data.status
					temp.createdOn = data.created_at
					temp.category = data.informationcategory_id ? data.informationcategory : '-'

					return temp
				})

				setState(newsfeedData)
				setCurrentPage(res.data.data.information.current_page)
				setLastPage(res.data.data.information.last_page)
				setIsLoading(false)
			}
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
		}
	}

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setSortBy(sort)
			setSortDirection('ASC')
		} else {
			if (sortDirection === 'ASC') {
				setSortBy(sort)
				setSortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setSortBy(sort)
				setSortDirection('ASC')
			}
		}
	}

	const handleOnSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'campus') {
			helperSelectFilter(campus, event.target.value, setCampus)
		}
		if (event.target.name === 'category') {
			helperSelectFilter(category, event.target.value, setCategory)
		}
		if (event.target.name === 'churchId') {
			helperSelectFilter(churchId, event.target.value, setChurchId)
		}
	}

	function handleClearFilter() {
		setCampus([])
		setCategory([])
		history.push(`${location.pathname}?paginate=10&page=1`)
	}

	const displayLoading = isLoading && !errorMessage
	const displayError = !isLoading && errorMessage
	const displayNoData = !isLoading && !errorMessage && state.length === 0
	const displayData = !isLoading && !errorMessage && state.length > 0

	return (
		<div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<HeadTitle title={`${name} - News`} />
			<BreadCrumb title="List of All" breads={breads} icon={NewsIcon} />
			<SmallGroupHeader>
				<NewsfeedFilter
					categories={category}
					campuses={campus}
					handleSelectFilter={handleSelectFilter}
					handleApplyFilter={fetchListNewsfeed}
					handleClearFilter={handleClearFilter}
					listCategori={listCategori}
				/>
				<Searchbar filterKeyword={keyword} handleOnSearch={handleOnSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} placeholder="Search by news title" />
			</SmallGroupHeader>

			{displayLoading && <Loading />}
			{displayError && <div className="text-center">{errorMessage}</div>}
			{displayNoData && <div className="text-center">No data available</div>}

			{displayData && (
				<div>
					<NewsfeedTable listNewsfeed={state} handleOnSort={handleSort} />
					<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={state.length} url={location.pathname} />
				</div>
			)}
		</div>
	)
}

export default News
