import React from 'react'
import moment from 'moment'

const PrayerItem = ({ category, created, prayer, praise }) => (
    <div className="bg-white shadow-md mb-4 p-4">
        <div className="mb-4">
            <p className="font-bold">
                {category} | {moment(created, 'YYYY-MM-DD').format('DD MMMM YYYY')}
            </p>
            {prayer && <p style={{ wordBreak: "break-all" }}>{prayer}</p>}
            {!prayer && <p style={{ wordBreak: "break-all" }}>{praise}</p>}
        </div>
        {praise && prayer && (
            <div>
                {prayer ? <p className="font-bold">Praise</p> : null}
                <p style={{wordBreak: "break-all"}}>{praise}</p>
            </div>
        )}
    </div>
)

export default PrayerItem