import axios from 'axios'
import search from './search'

export const eventList = async (page, filter_keyword, filter_status, order_by, order_dir) =>
    await search(`${process.env.REACT_APP_ENDPOINT}/event?page=${page}&filter_keyword=${filter_keyword}&filter_status=${filter_status}&order_by=${order_by}&order_dir=${order_dir}`)

export const archiveEvent = async (id) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/event/archive/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const highglightEvent = async (id) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/event/highlight/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const unhighlightEvent = async (id) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/event/unhighlight/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const eventCategories = async () =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/event/categories`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const eventDetail = async (id) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/event/detail/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const updateEvent = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/event/update`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata,
    })

export const getEventRoles = async (id) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/event/role-event/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    })

export const getEventMarital = async (id) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/event/marital-event/${id}`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    });

// Event

export const getProgramCodeList = async (type) =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/all-list`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            type: type,
        }
    });

export const getProgramTypeList = async (type) =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/programtype/get`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            type: type,
        }
    });

export const getRoleList = async () =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/program/available-role`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
    });

export const createEvent = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/program/create`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const editEvent = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/program/update`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const getListEvent = async (paginate, searchValue, sortBy, sortDir, typeModule, pageNumber) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/program/all/filtered`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            paginate,
            programnameorcode: searchValue,
            durationstartat: null,
            durationendat: null,
            sortBy: sortBy,
            sortDirection: sortDir,
            type: typeModule,
            page: pageNumber
        },
    });

export const archiveListEvent = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/program/archive`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const getExportLink = async (eventId, formatFile) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/export/filtered`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            registantstartat: null,
            registantendat: null,
            status: null,
            id: eventId,
            formatFile: formatFile
        },
    });

export const getEventDetail = async (eventId, typePlatform, paginate) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/program/detail`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            id: eventId,
            type: typePlatform,
            paginate: paginate,
            adminPass: process.env.REACT_APP_PASSWORD
        },
    });

export const getListEventDetailAndRegistrant = async (eventId, paginate, searchBy, searchValue, sortBy, sortDir, typePlatform, pageNumber) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/program/detail`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            id: eventId,
            type: typePlatform,
            paginate: paginate,
            sortBy: sortBy,
            sortDirection: sortDir,
            registantstartat: null,
            registantendat: null,
            status: null,
            roleId: null,
            name: null,
            searchBy: searchBy,
            searchKeyword: searchValue,
            page: pageNumber,
            adminPass: process.env.REACT_APP_PASSWORD
        },
    });

export const resendEmailConfirmation = async (registrantId, userId, email) =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/resend-email`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            programTicketId: registrantId,
            userId: userId,
            type: 'Event',
            emailTest: email
        }
    });

export const removeRegistrant = async (registrantId) =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/programregistrant/remove`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            programTicketId: registrantId,
        }
    });

export const checkUncheckAttendance = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/programticketattendance/attend/toggle`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

// Event Code

export const getRequirements = async (type) =>
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/requirements`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            type: type,
        }
    });

export const createEventCode = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/create`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const updateEventCode = async (formdata) =>
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/update`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        data: formdata
    });

export const getEventCodeDetail = async (eventCodeId) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/detail`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            id: eventCodeId,
        }
    });

export const getListEventCode = async (paginate, searchValue, sortBy, sortDir, typeModule, pageNumber) =>
    await axios({
        method: 'get',
        url: `${process.env.REACT_APP_ENDPOINT}/programcode/filtered`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        params: {
            paginate,
            programnameorcode: searchValue,
            sortBy,
            sortDirection: sortDir,
            type: typeModule,
            page: pageNumber
        },
    });
