import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { useParams, useLocation } from 'react-router-dom'
import { getProfileMemberSmallGroup } from 'libs/apis/smallGroup'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import photoplaceholder from 'assets/images/photo-placeholder.png'
import Loading from 'components/commons/Loading'

const ViewMemberSmallGroup = (props) => {
	const [state, setState] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const params = useParams()
	const location = useLocation()

	useEffect(() => {
		const fetchProfileSmallGroupMember = async () => {
			setIsLoading(true)
			try {
				let res = await getProfileMemberSmallGroup(params.id)

				let data = res.data.data

				if (data.user2 !== null) {
					if (data.user.document_id !== null) {
						setState({
							fullName: data.user.fullname,
							birtday: data.user.birthdate,
							phoneNumber: data.user.phone,
							email: data.user.email,
							gender: data.user.gender,
							memberSince: data.user.created_at,
							address: data.user.address,
							baptismChurch: data.user.baptismchurch,
							baptismCity: data.user.baptismcity,
							baptismDate: data.user.baptismdate,
							foe: data.user.useroccupations.length ? data.user.useroccupations[0].occupation.name : '',
							occupation: data.user.useroccupations.length ? data.user.useroccupations[0].companyname : '',
							foj: data.user.useroccupations.length ? data.user.useroccupations[0].occupation.name : '',
							profilePict: data.user.document.url,
							maritalStatus: data.user.maritalstatus.name,
							maritalDate: data.user.userfamily ? data.user.userfamily.marriage_at : '',
							child1: data.user.userfamily ? data.user.userfamily.userfamilychilds[0] : [],
							child2: data.user.userfamily ? data.user.userfamily.userfamilychilds[1] : [],
							user2: data.user2 ? data.user2.fullname : '',
							previousSmallGroup: data.user.useractions_smallgroupduration ? data.user.useractions_smallgroupduration : [],
							previousMinistry: data.user.useractions_ministryduration ? data.user.useractions_ministryduration : [],
						})
					} else {
						setState({
							fullName: data.user.fullname,
							birtday: data.user.birthdate,
							phoneNumber: data.user.phone,
							email: data.user.email,
							gender: data.user.gender,
							memberSince: data.user.created_at,
							address: data.user.address,
							baptismChurch: data.user.baptismchurch,
							baptismCity: data.user.baptismcity,
							baptismDate: data.user.baptismdate,
							foe: data.user.education_id,
							occupation: data.user.companyname,
							foj: data.user.educationlevel_id,
							profilePict: null,
							maritalStatus: data.user.maritalstatus.name,
							user2: data.user2 ? data.user2.fullname : '',
							maritalDate: data.user.userfamily ? data.user.userfamily.marriage_at : '',
							child1: data.user.userfamily ? data.user.userfamily.userfamilychilds[0] : [],
							child2: data.user.userfamily ? data.user.userfamily.userfamilychilds[1] : [],
							previousSmallGroup: data.user.useractions_smallgroupduration ? data.user.useractions_smallgroupduration : [],
							previousMinistry: data.user.useractions_ministryduration ? data.user.useractions_ministryduration : [],
						})
					}
				} else {
					setState({
						fullName: data.user.fullname,
						birtday: data.user.birthdate,
						phoneNumber: data.user.phone,
						email: data.user.email,
						gender: data.user.gender,
						memberSince: data.user.created_at,
						address: data.user.address,
						baptismChurch: data.user.baptismchurch,
						baptismCity: data.user.baptismcity,
						baptismDate: data.user.baptismdate,
						foe: data.user.useroccupations.length ? data.user.useroccupations[0].occupation.name : '',
						occupation: data.user.useroccupations.length ? data.user.useroccupations[0].companyname : '',
						foj: data.user.useroccupations.length ? data.user.useroccupations[0].occupation.name : '',
						profilePict: data.user.document.url,
						maritalStatus: data.user.maritalstatus.name,
						user2: null,
						maritalDate: null,
						child1: null,
						child2: null,
						previousSmallGroup: data.user.useractions_smallgroupduration ? data.user.useractions_smallgroupduration : [],
						previousMinistry: data.user.useractions_ministryduration ? data.user.useractions_ministryduration : [],
					})
				}

				setIsLoading(false)
			} catch (error) {
				setErrorMessage('Cannot retrieve any data at this moment, We currently investigating this issue.')
				setIsLoading(false)
				console.log(error)
			}
		}

		fetchProfileSmallGroupMember()
	}, [params.id])

	const displayLoading = isLoading && !errorMessage
	const displayData = !isLoading && !errorMessage && state !== undefined
	const displayNoData = !isLoading && errorMessage

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/small-group/list', title: 'List of All Groups' },
		{
			url: `/small-group/view/${location.state}`,
			title: 'View Group',
		},
		{ url: '', title: 'View Member' },
	]

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View Member</title>
			</Helmet>
			<PageBreadcrumbs icon={UserIcon} title="View Member" breads={breads} />
			{displayLoading && <Loading />}
			{displayNoData && <div className="text-center">{errorMessage}</div>}
			{displayData && (
				<Fragment>
					<Biodata data={state} />
					<div className="flex">
						<div className="w-1/2 mr-4">
							<Address data={state} />
							<BaptismInformation data={state} />
							<MinistryHistory data={state} />
						</div>
						<div className="w-1/2">
							<FamilyInformation data={state} />
							<PreviousSmallGroup data={state} />
						</div>
					</div>
				</Fragment>
			)}
		</div>
	)
}

export default ViewMemberSmallGroup

const Biodata = (props) => {
	const { fullName, birtday, phoneNumber, email, gender, memberSince, profilePict } = props.data
	let birthDay = moment(birtday).format('DD MMM YYYY')
	return (
		<div className="bg-white mb-6 shadow-md">
			<div className="flex">
				<div className="w-2/12">
					<div className="flex justify-center items-center flex-col h-full">
						{profilePict ? (
							<img className="rounded-full h-24 w-24 flex items-center justify-center" src={profilePict} alt="profile" />
						) : (
							<img className="rounded-full h-24 w-24 flex items-center justify-center" src={photoplaceholder} alt="profile" />
						)}
					</div>
				</div>
				<div className="w-5/12 my-auto">
					<div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Full Name</div>
								<div className="flex-1 font-bold">{fullName}</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Birthday</div>
								<div className="flex-1 font-bold">{birthDay ? birthDay : '-'}</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Phone Number</div>
								<div className="flex-1 font-bold">{phoneNumber ? phoneNumber : '-'}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-5/12 my-auto">
					<div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Email</div>
								<div className="flex-1 font-bold">{email ? email : '-'}</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Gender</div>
								<div className="flex-1 font-bold">{gender ? gender : '-'}</div>
							</div>
						</div>
						<div className="p-2">
							<div className="flex">
								<div className="flex-1 text-grayBold font-bold">Member Since</div>
								<div className="flex-1 font-bold">{memberSince ? memberSince : '-'}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const Address = (props) => {
	const { address } = props.data

	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Address</div>
					<div className="w-1/2 font-bold">{address ? address : '-'}</div>
				</div>
			</div>
		</div>
	)
}

const BaptismInformation = (props) => {
	const { baptismChurch, baptismCity, baptismDate } = props.data

	let baptisDate = moment(baptismDate, 'YYYY-MM-DD').format('DD MMMM YYYY')
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="w-1/2 text-grayBold font-bold text-uppercase">Baptism Information</div>

			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Baptism Church</div>
					<div className="w-1/2 font-bold">{baptismChurch ? baptismChurch : '-'}</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Baptism City</div>
					<div className="w-1/2 font-bold">{baptismCity ? baptismCity : '-'}</div>
				</div>
				<div className="flex">
					<div className="w-1/2 text-grayBold font-bold">Baptism Date</div>
					<div className="w-1/2 font-bold">{baptismDate ? baptisDate : '-'}</div>
				</div>
			</div>
		</div>
	)
}

const MinistryHistory = (props) => {
	const { foe, occupation, foj } = props.data
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="w-1/2 text-grayBold font-bold text-uppercase">Ministry History</div>

			<div className="flex flex-col">
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Field of Education</div>
					<div className="w-1/2 font-bold">{foe ? foe : '-'}</div>
				</div>
				<div className="flex mb-4">
					<div className="w-1/2 text-grayBold font-bold">Company</div>
					<div className="w-1/2 font-bold">{occupation ? occupation : '-'}</div>
				</div>
				<div className="flex">
					<div className="w-1/2 text-grayBold font-bold">Field of Job</div>
					<div className="w-1/2 font-bold">{foj ? foj : '-'}</div>
				</div>
			</div>
		</div>
	)
}

const FamilyInformation = (props) => {
	const { maritalStatus, user2, child1, child2, maritalDate } = props.data

	let maritaldate = moment(maritalDate).format('DD MMM YYYY')

	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="w-1/2 text-grayBold font-bold text-uppercase">Family Information</div>

			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Marital Status</div>
				<div className="w-1/2 font-bold">{maritalStatus ? maritalStatus : '-'}</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Spouse Name</div>
				<div className="w-1/2 font-bold">{user2 ? user2 : '-'}</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">Marital Date</div>
				<div className="w-1/2 font-bold">{maritalDate ? maritaldate : '-'}</div>
			</div>
			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">1st Child</div>
				<div className="w-1/2 font-bold">{child1 ? child1.name : '-'}</div>
			</div>

			<div className="flex mb-4">
				<div className="w-1/2 text-grayBold font-bold">2nd Child</div>
				<div className="w-1/2 font-bold">{child2 ? child2.name : '-'}</div>
			</div>
		</div>
	)
}

const PreviousSmallGroup = ({ data }) => {
	return (
		<div className="bg-white mb-4 p-4 shadow-md">
			<div className="w-1/2 text-grayBold font-bold text-uppercase">Previous Small Group</div>

			<div className="mb-4">
				{data.previousSmallGroup &&
					data.previousSmallGroup.map((d) => (
						<div className="flex-col">
							<div className="w-1/2 font-bold">{d.smallgroup.name}</div>
						</div>
					))}
			</div>
		</div>
	)
}
