import {withFormik} from 'formik'
import {failedMessage, successMessage,} from '../../libs/utils/confirmation'
import PushNotifCampaignValidationSchema from "./CampaignPushNotifValidSchema";
import {getRecipientsBasic, updateCampaign} from "../../libs/apis/emailCampaign";
import EditPushNotifCampaignForm from "../../screens/campaign/EditPushNotifCampaignForm";
import moment from "moment";

const EditPushNotifCampaignFormik = withFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: props => ({
        campaignId: '',
        title: '',
        body: '',
        basicRecipientMethodList: [],
        recipientsGender: '',
        recipientsMaritalStatus: '',
        schedule: '',
        isDraft: false,
        recipients: []
    }),
    validationSchema: PushNotifCampaignValidationSchema,
    async handleSubmit(values, {props, setSubmitting, setFieldValue}) {
        const {
            campaignId,
            title,
            body,
            recipients,
            schedule,
            isDraft,
            recipientParams
        } = values;

        // let updatedSchedule = schedule;
        // if (!schedule) {
        //     updatedSchedule = moment(new Date(), 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        // }

        let formdata = new FormData();
        formdata.append('title', title);
        formdata.append('description', body);
        formdata.append('type', 'notif');
        formdata.append('sendstart_at', schedule);

        try {
            let res = await updateCampaign(formdata, campaignId);

            if (res.data.api_status === true) {
                try {
                    if (isDraft) {
                        let resDraft = await getRecipientsBasic(recipientParams.gender, recipientParams.maritalStatus,
                            recipientParams.smallGroupMemberRole, recipientParams.ministryMemberRole,
                            recipientParams.userRole, recipientParams.nonSmallGroup, 'Draft', campaignId);
                        if (resDraft.data.api_status === true) {
                            await successMessage();
                            props.history.push('/campaign/list-pushnotif')
                        } else {
                            await failedMessage(res.data.message)
                        }
                    } else {
                        let resPublish = await getRecipientsBasic(recipientParams.gender, recipientParams.maritalStatus,
                            recipientParams.smallGroupMemberRole, recipientParams.ministryMemberRole,
                            recipientParams.userRole, recipientParams.nonSmallGroup, 'Published', campaignId);
                        if (resPublish.data.api_status === true) {
                            await successMessage();
                            props.history.push('/campaign/list-pushnotif')
                        } else {
                            await failedMessage(res.data.message)
                        }
                    }
                } catch (e) {
                    console.log('masuk A');
                    await failedMessage(e.message);
                }
            } else {
                await failedMessage(res.data.message)
            }
        } catch (e) {
            console.log('masuk B');
            await failedMessage(e.message);
        }
    },
})(EditPushNotifCampaignForm);

export default EditPushNotifCampaignFormik
