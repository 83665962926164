import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'

import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Searchbar from 'components/commons/Searchbar'
import ListEventCodeTable from 'screens/events/ListEventCodeTable'
import PaginationPage from 'components/commons/PaginationPage'

import {ReactComponent as CoursesIcon} from 'assets/icons/classes.svg'
import {Header} from 'screens/courses/styles'
import Loading from "../../components/commons/Loading";
import {getListEventCode} from "../../libs/apis/events";

function ListEventCode(props) {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const [keyword, setKeyword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [listEventCode, setListEventCode] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState('created_at');
    const [sortDir, setSortDir] = useState('DESC');

    const breads = [{url: '', title: 'List Event Code'}];

    const {location} = props;
    const parsed = queryString.parse(location.search);
    const activeLink = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

    const {search} = location;
    const pageNumber = search ? queryString.parse(search).page : 1;
    const totalListPerPage = 10;
    const typeModule = 'Event';

    const displayLoading = isLoading;
    const displayError = !isLoading && errorMessage;
    const displayNoData = !isLoading && !errorMessage && listEventCode.length === 0;
    const displayData = !isLoading && !errorMessage && listEventCode.length > 0;

    const handleOnSearch = (event) => {
        setKeyword(event.target.value)
    };

    const handleSeachInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.history.push(`${props.location.pathname}?paginate=10&page=1`);
            setSearchValue(event.target.value);
            setKeyword(event.target.value);
        }
    };

    const handleOnSort = (sort) => {
        if (sortBy !== sort) {
            setSortBy(sort);
            setSortDir('ASC')
        } else {
            if (sortDir === 'ASC') {
                setSortBy(sort);
                setSortDir('DESC')
            } else if (sortDir === 'DESC') {
                setSortBy(sort);
                setSortDir('ASC')
            }
        }
    };

    useEffect(() => {
        handleFetchListEventCode()
    }, [totalListPerPage, searchValue, sortBy, sortDir, pageNumber]);

    const formattingListEventCode = (eventCodeData) => {
        return {
            id: eventCodeData.id,
            codeName: eventCodeData.codename ?? 'unknown',
            eventName: eventCodeData.name,
            createdOn: eventCodeData.created_at ?? 'unknown',
            lastUpdated: eventCodeData.updated_at ?? 'unknown'
        }
    };

    const handleFetchListEventCode = async () => {
        setIsLoading(true);
        try {
            let response = await getListEventCode(totalListPerPage, searchValue, sortBy, sortDir, typeModule, pageNumber);
            let listEventCodeData = response.data.data.programcodes.data;
            let currentPage = response.data.data.programcodes.current_page;
            let lastPage = response.data.data.programcodes.last_page;

            if (listEventCodeData) {
                let listEventCode = [];
                for (const eventCodeData of listEventCodeData) {
                    listEventCode.push(
                        formattingListEventCode(eventCodeData)
                    )
                }
                setListEventCode(listEventCode);

                setCurrentPage(currentPage);
                setLastPage(lastPage);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - List Event Code</title>
            </Helmet>
            <PageBreadcrumbs icon={CoursesIcon} title="List Event Code" breads={breads}/>

            <Header>
                <Searchbar filterKeyword={keyword}
                           handleOnSearch={handleOnSearch}
                           handleSeachInputKeyPress={handleSeachInputKeyPress}
                           placeholder="Search by Code or Event Title"
                />
            </Header>

            {displayLoading && <Loading/>}
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayNoData && <div className="text-center">No data available</div>}
            {displayData && (
                <div className="pb-10">
                    <ListEventCodeTable listEventCode={listEventCode}
                                        handleOnSort={handleOnSort}
                    />
                    <PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={activeLink}
                                    length={listEventCode.length} url={location.pathname}/>
                </div>
            )}
        </div>
    )
}

export default ListEventCode
