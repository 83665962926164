import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg'
import Loading from 'components/commons/Loading'
import FirstTimerAttendance from 'components/inputData/FirstTimerAttendance'
import { campusList, firstTimerAttendance } from 'libs/apis/campus'

class FirstTimer extends React.Component {
	state = {
		campuses: [],
		campusSelected: '',
		week: '',
		year: '',
		isLoading: false,
		isRedirect: false,
		errorMessage: '',
		services: [],
	}

	async componentDidMount() {
		try {
			const campuses = await campusList()
			if (campuses.data.api_status) {
				this.setState({ campuses: campuses.data.data })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.props.history.push('/login')
					this.setState({ isRedirect: true })
				}
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { year, week, campusSelected } = this.state
		if (year && week && campusSelected && (prevState.campusSelected !== campusSelected || prevState.week !== week || prevState.year !== year)) {
			this.setState({ isLoading: true }, async () => {
				try {
					const services = await firstTimerAttendance(campusSelected, year, week)
					if (services.data.api_status) {
						this.setState({ isLoading: false, services: services.data.data })
					} else {
						this.setState({ isLoading: false })
					}
				} catch (error) {}
			})
		}
	}

	handleOnSelectedCampus = event => {
		this.setState({ campusSelected: event.target.value })
	}

	handleOnDate = date => {
		const newYear = parseInt(moment(date[0]).format('YYYY'))
		const newWeek = moment(date[0]).isoWeek()
		this.setState({ year: newYear, week: newWeek })
	}

	render() {
		const { campuses, campusSelected, isLoading, isRedirect, errorMessage, services, week, year } = this.state
		const church = JSON.parse(localStorage.getItem('church'))
		const displayLoading = isLoading && !errorMessage
		const displayNoData = !isLoading && !errorMessage && !services.length
		const diplayData = !isLoading && !errorMessage && services.length > 0

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content flex-1 bg-gray-100 pb-24 md:p-6">
				<Helmet>
					<title>{church.church_name} - First Timer</title>
				</Helmet>
				<PageBreadcrumbs title="First Timer" icon={HomeIcon} breads={[{ url: '', title: 'First Timer' }]} />
				<div className="mb-6">
					<div className="inline-block">
						<select className="form-select border-0 rounded-lg shadow-md mr-6" value={campusSelected} onChange={this.handleOnSelectedCampus}>
							<option disabled value="">
								Choose
							</option>
							{campuses.map(item => (
								<option key={item.id} value={item.id}>
									{item.title}
								</option>
							))}
						</select>
					</div>
					<div className="inline-block">
						<div className="shadow-md flex rounded-lg bg-white px-3 py-2 mr-6">
							<div>
								<Flatpickr
									placeholder="Choose"
									options={{
										onChange: this.handleOnDate,
										plugins: [new weekSelectPlugin({})],
										altInput: true,
										weekNumbers: true,
										altFormat: '\\Week: W \\Year: Y',
										dateFormat: 'd/m/Y',
										locale: { firstDayOfWeek: 1 },
									}}
								/>
								<i className="far fa-calendar-alt" />
							</div>
						</div>
					</div>
				</div>
				{displayLoading && <Loading />}
				{displayNoData && <div className="text-center">No Data available</div>}
				{diplayData && <AttendanceInput services={services} week={week} year={year} />}
			</div>
		)
	}
}

const AttendanceInput = ({ services, week, year }) => (
	<div className="flex flex-wrap">
		{services.map((item, index) => (
			<FirstTimerAttendance
				key={index}
				id={item.campus_id}
				year={year}
				week={week}
				day={item.service_time_day_desc}
				hour={item.service_time_hours}
				minute={item.service_time_minutes}
				isInputted={item.is_inputted}
				attendances={item.attendances}
			/>
		))}
	</div>
)

export default FirstTimer
