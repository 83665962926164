import * as Yup from 'yup'

export const EventValidationSchema = () =>
    Yup.object().shape({
        eventTitle: Yup.string().nullable().required('Required'),
        eventDescription: Yup.string().nullable().required('Required'),
        eventStartDate: Yup.string().nullable().required('Required'),
        eventEndDate: Yup.string().nullable().required('Required'),
        venueName: Yup.string().nullable().required('Required'),
        venueAddressLine: Yup.string().nullable().required('Required'),
        startSharingDate: Yup.string().nullable().required('Required'),
        additionalInformation: Yup.string().nullable().required('Required'),
        programCodeId: Yup.string().nullable().required('Required'),
        quota: Yup.string().nullable().required('Required'),
        venue: Yup.string().nullable().required('Required'),
        programTypeId: Yup.string().nullable().required('Required'),
    });
