import React from 'react'
import Plot from 'react-plotly.js'

const PieChart = ({ data }) => {
	return (
		<Plot
			data={data}
			layout={{
				height: 300,
				width: 300,
				margin: { l: 0, r: 0, b: 0, t: 0 },
				legend: { orientation: 'h' },
			}}
		/>
	)
}

export default PieChart
