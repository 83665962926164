import React from 'react'
import moment from 'moment'
import ReactQuill from 'react-quill'
import { ReactComponent as SelectLocation } from 'assets/icons/selectlocation.svg'
import { ReactComponent as Calender } from 'assets/icons/schedule.svg'

function ClassPreview({ classTitle, classCode, classSessions, classDescription, classStartDate, classEndDate, classStartRegis, classEndRegis, classVenueName, classVenueAddress }) {
	const startDate = classStartDate ? moment(classStartDate, 'DD/MM/YYYY').format('DD MMMM YYYY') : 'Date'
	const times = classStartDate && classEndDate ? `${moment(classStartDate, 'YYYY/MM/DD HH:mm').format('HH:mm')} - ${moment(classEndDate, 'YYYY/MM/DD HH:mm').format('HH:mm')}` : 'Time'

	const startRegis = classStartRegis ? moment(classStartRegis, 'DD/MM/YYYY').format('DD MMMM YYYY') : ' '
	const endRegis = classEndRegis ? moment(classEndRegis, 'DD/MM/YYYY').format('DD MMMM YYYY') : ' '

	return (
		<div className="bg-white shadow-md p-6">
			<p className="font-bold mb-4">MOBILE SCREEN PREVIEW</p>
			<div className="bg-blueSecondary">
				<div className="p-3 break-words overflow-auto h-64">
					<div className="font-bold text-md">{classTitle ? `${classCode} ${classTitle}` : 'Class Code/Title'}</div>
					<div className="my-4 border-2 border-dashed" />
					<div className="flex -mx-2">
						<div className="w-1/12 px-2">
							<div>
								<Calender width="18px" height="18px" />
							</div>
						</div>
						<div className="w-11/12 px-2">
							<div>
								<div className="text-gray-500 text-xs">{startDate}</div>
								<div className="text-gray-500 text-xs">{times}</div>
							</div>
						</div>
					</div>
					<div className="my-4 border-2 border-dashed" />

					<div className="flex ml-4">
						<div className="px-2">
							<div className="text-gray-500 text-xs text-bold">Class Session</div>
							{classSessions.map((v,index) => (
								<div key={index}>
									<div className="text-gray-500 text-xs">{v.date ? moment(v.date).format('DD MMM YYYY') : ''}</div>
									<div className="text-gray-500 text-xs">
										{v.timeStartAt && v.timeEndAt ? `${moment(v.timeStartAt, 'HH:mm').format('HH:mm')} - ${moment(v.timeEndAt, 'HH:mm').format('HH:mm')}` : ''}
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="my-4 border-2 border-dashed" />

					<div className="flex -mx-2">
						<div className="w-1/12 px-2">
							<div>
								<SelectLocation width="18px" height="18px" />
							</div>
						</div>
						<div className="w-11/12 px-2">
							<div key>
								<div className="text-gray-500 text-xs">Venue Name</div>
								<div className="text-gray-500 text-xs">{classVenueName}</div>

								<div className="text-gray-500 text-xs">Address</div>
								<div className="text-gray-500 text-xs">{classVenueAddress}</div>
							</div>
						</div>
					</div>

					<div className="my-4 border-2 border-dashed" />
					<div className="flex -mx-2">
						<div className="w-11/12 px-2">
							<div className="font-bold text-gray-500 text-xs">Open Registration</div>
							<div className="text-gray-500 text-xs">
								{startRegis} - {endRegis}
							</div>
						</div>
					</div>
					<div className="my-4 border-2 border-dashed" />
					<div className="font-bold text-xs">Description</div>

					<div style={{ marginLeft: '8px', overflowWrap: 'normal' }}>
						<div dangerouslySetInnerHTML={{ __html: classDescription }} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ClassPreview
