import React from 'react'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'

const MinistryDetails = (props) => {
	const { name, description, status, created_at, upperlevel, ministryheads, keyvolunteers, level } = props
	return (
		<div className="bg-white shadow-md rounded p-4">
			<h3 className="font-bold text-lg mb-4">{name}</h3>
			<div className="font-bold mb-2">TEAM DETAIL</div>

			<label className="text-sm font-bold">Ministry Head</label>
			{ministryheads.map((v) => (
				<div key={v.user.id} className="text-sm mb-4">
					{v.user.fullname}
				</div>
			))}

			<label className="text-sm font-bold">Deputy / Key Volunteers</label>
			{keyvolunteers.map((v) => (
				<div key={v.user.id} className="text-sm mb-4">
					{v.user.fullname}
				</div>
			))}

			<hr className="border-2 border-blueSecondary" />

			<div className="font-bold mb-2 mt-3">MINISTRY DETAIL</div>

			<label className="text-sm font-bold">Level</label>
			<div className="text-sm mb-4">{level}</div>

			<label className="text-sm font-bold">Upper Level</label>
			<div className="text-sm mb-4">{upperlevel}</div>

			<label className="text-sm font-bold">Description</label>
			<ReactQuill modules={{ toolbar: false }} className="text-sm h-24 -ml-4 h-auto" theme={null} value={description ? description : ''} formats={null} />

			<label className="text-sm font-bold">Established Date</label>
			<div className="text-sm mb-4">{moment(created_at).format('LL')}</div>

			<hr className="border-2 border-blueSecondary mt-2" />
			<div className="font-bold mt-3">REQUIREMENT STATUS</div>
			<h1 className="text-primary text-xl">{status}</h1>
		</div>
	)
}

export default MinistryDetails
