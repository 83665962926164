import React from 'react'
import InputDate from 'components/commons/InputDate'
import InputTime from 'components/commons/InputTime'
import CheckBox from 'components/commons/Checkbox'

function DaySession({ handleRemoveDay, length, startTimeDay, endTimeDay, handleTime, date, idx, errorDate, errorStartTime, errorEndTime, isAllDay, handleOnCheckAllDays }) {

	return (
		<div>
			<div className="my-4 border-2 border-dashed" />
			<div className="flex w-full flex-wrap mt-2">
				<div className="w-full flex">
					<div className="w-1/2 flex items-center">
						<div>
							<InputDate name="date" value={date} withSec={false} handleChange={(e) => handleTime(e, idx, 'date')} errors={errorDate} />
						</div>
						<div className="w-1/2 ml-3">
							<CheckBox checked={isAllDay} name="allDays"
								handleCheck={() => handleOnCheckAllDays(idx)} label="ALL DAYS" />
						</div>
					</div>
					{
						!isAllDay ? 
							<div className="flex w-1/2 ml-4">
								<div className="w-40 mr-1">
									<InputTime name="timeStartAt" value={startTimeDay} handleChange={(e) => handleTime(e, idx, 'timeStartAt')} errors={errorStartTime} />
								</div>
								<div className="w-1/2">
									<InputTime name="timeEndAt" value={endTimeDay} handleChange={(e) => handleTime(e, idx, 'timeEndAt')} errors={errorEndTime} />
								</div>
							</div> : null
					}
					<div>{length > 1 && <i className="fas fa-times mt-4 text-red-400 cursor-pointer" onClick={(e) => handleRemoveDay(e, idx)} />}</div>
				</div>
			</div>
			{
				startTimeDay > endTimeDay && startTimeDay && endTimeDay ?
					<div className="w-full flex justify-end text-red-500 text-xs italic">
						End Time should be greater than Start Time
					</div> : null
			}
		</div>
	)
}

export default DaySession