import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { converDate, converTime } from 'libs/utils/utils'
import { partial } from 'libs/utils/utils'

function AppointmentsTable({ list, handleSort, sgView }) {
	const handleSortType = partial(handleSort, 'smallgroup_id')
	const handleSortTitle = partial(handleSort, 'title')
	const handleSortSgName = partial(handleSort, 'smallgroup_id')
	const handleSortDate = partial(handleSort, 'appointmentdatetime')

	return (
		<div className="bg-white shadow-md rounded">
			<table className="w-full">
				<thead className="bt-blueSecondary py-4  uppercase text-gray">
					<tr>
						<th className="px-4 py-4">
							type
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortType} />
						</th>
						<th className="px-4 py-4">
							title
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortTitle} />
						</th>
						<th className="px-4 py-4">
							sg name
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortSgName} />
						</th>
						<th className="px-4 py-4">
							date
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortDate} />
						</th>
						<th className="px-4 py-4">time</th>
						<th className="px-4 py-4">created by</th>
						<th className="px-4 py-4">attendee</th>
					</tr>
				</thead>
				<tbody className="border-2 bt-blueSecondary border-solid text-greyishBrown">
					{list.length ? (
						list.map((val) => (
							<AppointmentsRow
								key={val.id}
								id={val.id}
								type={val.smallgroupappointmenttype ? val.smallgroupappointmenttype.name : '-'}
								title={val.name || val.title}
								sgName={val.smallgroup.name}
								date={val.appointmentdatetime}
								time={val.appointmentdatetime}
								createdBy={val.created_by}
								totalAttendee={val.smallgroupappointmentattendances_count}
								totalHadir={val.smallgroupappointmentattendances_present_count}
							/>
						))
					) : (
						<></>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default AppointmentsTable

function AppointmentsRow(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}
	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const { id, type, title, date, sgName, time, createdBy, handleDelete, totalAttendee, totalHadir } = props

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`

	return (
		<tr className="border border-solid border-4 bt-blueSecondary text-center">
			<td className="px-2 py-4">{type}</td>
			<td className="px-2 py-4">{title}</td>
			<td className="px-2 py-4">{sgName}</td>
			<td className="px-2 py-4">{converDate(date)}</td>
			<td className="px-2 py-4">{converTime(time)}</td>
			<td className="px-4 py-4">{createdBy}</td>
			<td className="px-4 py-4">
				<span className="font-bold">{totalAttendee}</span> / <span>{totalHadir}</span>
			</td>
			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div ref={node} className={styleDropDownOpen}>
						<ul className="bg-white">
							<li>
								<Link className="px-4 py-2 block hover:bg-darkSkyBlue text-left" to={`/smallgroup/appointment-detail/${id}`}>
									View Details
								</Link>
							</li>
							{/* <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left" onClick={() => handleDelete(id)}>
								Delete
							</li> */}
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
