import Swal from 'sweetalert2'
const confirmButtonColor = '#4da5dc'
const cancelButtonColor = '#989898'

const succeed = (title, message) => {
	Swal.fire({
		icon: 'success',
		title: title || 'Succeed',
		text: message,
		focusConfirm: false,
		confirmButtonColor,
	})
}

const failed = (title, message) => {
	Swal.fire({
		icon: 'error',
		title: title || 'There something wrong',
		text: message,
		focusConfirm: false,
		confirmButtonColor,
	})
}

const confirmDelete = async () =>
	Swal.fire({
		text: 'Are you sure you want to delete this item ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmArchive = async () =>
	Swal.fire({
		title: '<strong>Are you sure you want to archive this item ?</strong>',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmComplete = async (text) => {
	return Swal.fire({
		text,
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})
}

const confirmDraft = async () => {
	return Swal.fire({
		text: 'Are you sure you want to save this item as draft ?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})
}

const confirmCancel = async () => {
	return await Swal.fire({
		text: 'Cancel means all data input will be deleted. Cancel now?',
		showCancelButton: true,
		focusCancel: false,
		focusConfirm: false,
		reverseButtons: true,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		confirmButtonColor,
		cancelButtonColor,
	})
}

const confirmResendEmail = async () =>
	Swal.fire({
		text: 'Are you sure you want to resend confirmation email ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmReject = async () =>
	Swal.fire({
		text: 'Are you sure you want to reject this registrant ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmAccept = async () =>
	Swal.fire({
		text: 'Are you sure you want to accept this registrant ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmCloseMinistry = async () =>
	Swal.fire({
		text: 'Are you sure want to close this ministry',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmDeleteMemberMinistry = async () =>
	Swal.fire({
		title: 'Are you sure want to remove this member?',
		customClass: {
			title: 'font-bold text-lg',
		},
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmExportSmallGroup = async () =>
	Swal.fire({
		title: 'Are you sure want to export small group member?',
		customClass: {
			title: 'text-xl font-bold leading-tight items-center',
		},
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		width: '380px',
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmExport = async (text) =>
	Swal.fire({
		title: `Are you sure want to export ${text} member?`,
		customClass: {
			title: 'text-xl font-bold leading-tight items-center',
		},
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		cancelButtonText: 'No',
		confirmButtonText: 'Yes',
		width: '380px',
		confirmButtonColor,
		cancelButtonColor,
	})

const networkError = async () =>
	Swal.fire({
		icon: 'error',
		title: 'Whoops...',
		text: 'No internet connection found. Please check your connection and try again.',
		timer: 3300,
		showConfirmButton: false,
	})

const warningMessage = async (message) =>
	Swal.fire({
		icon: 'info',
		title: 'Whoops...',
		text: message,
		timer: 3300,
		showConfirmButton: false,
	})

const confirmAcceptReq = async () =>
	Swal.fire({
		text: 'Are you sure you want to accept this member ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmRejectReq = async () =>
	Swal.fire({
		text: 'Are you sure you want to reject this member ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

const confirmDeactiveUser = async () =>
	Swal.fire({
		text: 'Are you sure you want to deactivate this user ?',
		showCancelButton: true,
		reverseButtons: true,
		focusConfirm: false,
		confirmButtonColor,
		cancelButtonColor,
	})

export default {
	succeed,
	failed,
	confirmDelete,
	confirmArchive,
	confirmDraft,
	confirmCancel,
	confirmComplete,
	confirmResendEmail,
	confirmReject,
	confirmAccept,
	confirmCloseMinistry,
	confirmDeleteMemberMinistry,
	networkError,
	warningMessage,
	confirmExportSmallGroup,
	confirmExport,
	confirmAcceptReq,
	confirmRejectReq,
	confirmDeactiveUser,
}
