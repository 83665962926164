import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { partial } from 'libs/utils/utils'

function ListClassCodeTable({ listClassCode, handleSort, handleRemoveClassCode }) {
	const sortCreatedOn = partial(handleSort, 'created_at')
	const sortLastUpdated = partial(handleSort, 'updated_at')

	return (
		<div className="bg-white shadow-md rounded">
			<table className="w-full">
				<thead className="bt-blueSecondary py-4  uppercase text-gray">
					<tr>
						<th className="px-4 py-4">CODE</th>
						<th className="px-4 py-4 text-left pl-2">CLASS NAME</th>
						<th className="px-4 py-4">
							CREATED ON
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={sortCreatedOn} />
						</th>
						<th className="px-4 py-4">
							LAST UPDATED
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={sortLastUpdated} />
						</th>
					</tr>
				</thead>
				<tbody className="border-2 bt-blueSecondary border-solid text-greyishBrown">
					{listClassCode.map((v,index) => (
						<ListClassCodeRow key={index} {...v} handleRemoveClassCode={handleRemoveClassCode} />
					))}
				</tbody>
			</table>
		</div>
	)
}

export default ListClassCodeTable

function ListClassCodeRow({ id, code, name, createdOn, lastUpdate, handleRemoveClassCode }) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [])

	function handleClick(e) {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}
	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const styleDropDownOpen = `z-20 shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`

	return (
		<tr className="border border-solid border-4 bt-blueSecondary text-center">
			<td className="px-2 py-4">{code}</td>
			<td className="px-2 py-4 text-left">{name}</td>
			<td className="px-2 py-4">{createdOn}</td>
			<td className="px-2 py-4">{lastUpdate}</td>

			<td className="px-2 py-4">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<div ref={node} className={styleDropDownOpen}>
						<ul className="bg-white">
							<li>
								<Link className="px-4 py-2 block hover:bg-darkSkyBlue text-left" to={`/classes/edit-code/${id}`}>
									View & Edit
								</Link>
							</li>
							<li className="px-4 py-2 block hover:bg-darkSkyBlue text-left" onClick={() => handleRemoveClassCode(id)}>
								Delete
							</li>
						</ul>
					</div>
				</div>
			</td>
		</tr>
	)
}
