import React from 'react'
import { Helmet } from 'react-helmet'
import CreateClassCodeFormik from 'components/formik/CreateClassCodeFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as CoursesIcon } from 'assets/icons/classes.svg'

function CreateClassCode(props) {
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'Create Class Code' }]

	return (
		<div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Class Code</title>
			</Helmet>
			<PageBreadcrumbs icon={CoursesIcon} title="Create Class Code" breads={breads} />
			<div className="flex bg-white shadow-md pl-4 pt-4">
				<CreateClassCodeFormik {...props}/>
			</div>
		</div>
	)
}

export default CreateClassCode
