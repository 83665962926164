import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import {ReactComponent as EmailIcon} from 'assets/icons/campaign.svg'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import {failedFetch} from 'libs/utils/messages'
import PushNotifTable from 'components/pusNotifList/PushNotifTable'
import Searchbar from 'components/commons/Searchbar'
import {Header} from 'screens/courses/styles'
import {getListCampaign} from "../../libs/apis/emailCampaign";
import {failedMessage} from "../../libs/utils/confirmation";

const ListPushNotif = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const [keyword, setKeyword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [listPushNotifCampaign, setListPushNotifCampaign] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState('created_at');
    const [sortDir, setSortDir] = useState('DESC');

    const breads = [{url: '', title: 'List of Push Notifications'}];

    const {location} = props;
    const parsed = queryString.parse(props.location.search);
    const activeLink = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

    const displayLoading = isLoading;
    const displayError = !isLoading && errorMessage;
    const displayNoData = !isLoading && !errorMessage && listPushNotifCampaign.length === 0;
    const displayData = !isLoading && !errorMessage && listPushNotifCampaign.length > 0;

    const {search} = location;
    const pageNumber = search ? queryString.parse(search).page : 1;
    const totalListPerPage = 10;
    const typeModule = 'Notif';

    const handleOnSearch = event => {
        setKeyword(event.target.value)
    };

    const handleSeachInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.history.push(`${props.location.pathname}?paginate=10&page=1`);
            setSearchValue(event.target.value);
            setKeyword(event.target.value);
        }
    };

    const handleOnSort = (sort) => {
        if (sortBy !== sort) {
            setSortBy(sort);
            setSortDir('ASC')
        } else {
            if (sortDir === 'ASC') {
                setSortBy(sort);
                setSortDir('DESC')
            } else if (sortDir === 'DESC') {
                setSortBy(sort);
                setSortDir('ASC')
            }
        }
    };

    useEffect(() => {
        fetchListPushNotification()
    }, [totalListPerPage, searchValue, sortBy, sortDir, pageNumber]);

	const formattingListPushNotifCampaign = (pushNotifCampaignData) => {
		return {
			// status: 'Draft',
			// isHighlight: 'true',
			no: pushNotifCampaignData.id,
			subject: pushNotifCampaignData.title,
            createdBy: pushNotifCampaignData.creator ? pushNotifCampaignData.creator.fullname ? pushNotifCampaignData.creator.fullname : 'unknown' : 'unknown',
			createdOn: pushNotifCampaignData.created_at,
			lastUpdated: pushNotifCampaignData.updated_at,
			schedule: pushNotifCampaignData.sendstart_at ? pushNotifCampaignData.sendstart_at : 'unknown',
			notifStatus: pushNotifCampaignData.status,
			totalSent: pushNotifCampaignData.total_sent,
			totalRecipient: pushNotifCampaignData.total_recipient
		}
	};

    const fetchListPushNotification = async () => {
        try {
            setIsLoading(true);
            const response = await getListCampaign(totalListPerPage, searchValue, sortBy, sortDir, typeModule, pageNumber);
            const listPushNotifCampaignData = response.data.data.campaign.data;
            let currentPage = response.data.data.campaign.current_page;
            let lastPage = response.data.data.campaign.last_page;

            console.log('listPushNotifCampaignData: ', listPushNotifCampaignData);
            console.log('response: ', response);

            if (listPushNotifCampaignData) {
                let listPushNotifCampaign = [];
                for (const campaign of listPushNotifCampaignData) {
                    listPushNotifCampaign.push(
                        formattingListPushNotifCampaign(campaign)
                    )
                }
                setListPushNotifCampaign(listPushNotifCampaign);

                setCurrentPage(currentPage);
                setLastPage(lastPage)
            } else {
                setIsLoading(false);
                setErrorMessage(failedFetch)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };


    return (
        <div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - List of Push Notifications </title>
            </Helmet>
            <PageBreadcrumbs icon={EmailIcon} title="List of Push Notifications" breads={breads}/>
            <Header>
                <Searchbar filterKeyword={keyword}
                           handleOnSearch={handleOnSearch}
                           handleSeachInputKeyPress={handleSeachInputKeyPress}
                />
            </Header>

            {isLoading && !errorMessage && <Loading/>}
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayNoData && <div className="text-center">No data available</div>}
            {displayData && (
                <div className="pb-10">
                    <PushNotifTable listPushNotifCampaign={listPushNotifCampaign}
                                    handleOnSort={handleOnSort}/>
                    <PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={activeLink}
                                    length={listPushNotifCampaign.length} url={props.location.pathname}/>
                </div>
            )}
        </div>
    )
};

export default ListPushNotif;
