import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as PrayerPraiseIcon } from 'assets/icons/prayerpraise.svg'

const navigation = [
	'/prayer-praise/prayer-request',
	'/prayer-praise/praise-report',
	'/report-history/:id',
	'/request-history/:id',
]

class PrayerPraiseMenu extends Component {
	state = { isOpenSub: false }

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = navigation.filter((menu) => menu === path)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state
		const menus = navigation.filter((menu) => menu === path)
		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkPrayer = `${
			path === '/prayer-praise/prayer-request' || path === '/request-history/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`
		const styleLinkPraise = `${
			path === '/prayer-praise/praise-report' || path === '/report-history/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<PrayerPraiseIcon className={styleIcon} />
					</div>
					<div className="font-bold text-grey-500">Prayer & Praise</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link
									to="/prayer-praise/prayer-request"
									className={styleLinkPrayer}
								>
									Prayer Request
								</Link>
							</li>
							<li className="mb-4">
								<Link
									to="/prayer-praise/praise-report"
									className={styleLinkPraise}
								>
									Praise Report
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default PrayerPraiseMenu
