import React from 'react'
import ContentLoader from 'react-content-loader'

const BarCart = () => (
	<ContentLoader className="bg-white mb-6 rounded-lg shadow-md text-center" speed={1} width={745} height={613} viewBox="0 0 744 613" backgroundColor="#ededed" foregroundColor="#ffff">
		<rect x="20" y="22" rx="0" ry="0" width="181" height="15" />
		<rect x="540" y="20" rx="0" ry="0" width="181" height="15" />
		<rect x="125" y="421" rx="0" ry="0" width="27" height="22" />
		<rect x="126" y="463" rx="0" ry="0" width="27" height="22" />
		<rect x="344" y="422" rx="0" ry="0" width="27" height="22" />
		<rect x="344" y="459" rx="0" ry="0" width="27" height="22" />
		<rect x="161" y="429" rx="0" ry="0" width="143" height="8" />
		<rect x="164" y="471" rx="0" ry="0" width="143" height="8" />
		<rect x="377" y="430" rx="0" ry="0" width="143" height="8" />
		<rect x="379" y="468" rx="0" ry="0" width="143" height="8" />
		<rect x="532" y="80" rx="0" ry="0" width="74" height="273" />
		<rect x="410" y="147" rx="0" ry="0" width="74" height="207" />
		<rect x="287" y="198" rx="0" ry="0" width="74" height="156" />
		<rect x="152" y="247" rx="0" ry="0" width="74" height="106" />
	</ContentLoader>
)

export default BarCart
