import React, { useState, useEffect } from 'react'
import styles from 'styled-components'
import { useParams } from 'react-router-dom'
import TextInput from 'components/commons/TextInput'
import Label from 'components/commons/Label'
import InputCheckBox from 'components/commons/form/InputCheckBox'
import Button from 'components/commons/Button'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { dummyTypes } from '../events/EventDummyObject'
import { getRequirements, getListCode, getClassCodeDetail } from '../../libs/apis/courses'
import { getSmallGroupLevel } from '../../libs/apis/smallGroup'
import Loading from 'components/commons/Loading'

// import Loading from 'components/commons/Loading'

const Eligibility = styles.div` 
    background-color: #9d9d9d;
    width: 6rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
`

function EditClassCodeForm(props) {
	const [prerequisite, setPrerequisite] = useState([])
	const [listProgramCode, setListProgramCode] = useState([])
	const [listMaritalStatus, setListMaritalStatus] = useState([])
	const [listSmallGroupMemberRole, setListSmallGroupMemberRole] = useState([])
	const [listMinistry, setListMinistry] = useState([])
	const [reqRole, setReqRole] = useState([])

	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const { className, classCode, ageFrom, ageTo, genderList, maritalStatusList, roleList, ministryList, typeList, prerequisiteClass, reqLeaderApproval, reqAdminApproval, minimumOfSession } = props.values
	const { setFieldValue, isSubmitting, handleSubmit, errors, handleChange } = props

	const { id } = useParams()

	useEffect(() => {
		ageFrom >= ageTo ? setFieldValue('isAgeValid', false) : setFieldValue('isAgeValid', true)
	}, [ageFrom, ageTo])

	useEffect(() => {
		setFieldValue('id', id)
		fetchClassCodeDetail()
		fetchRequirements()
	}, [])

	useEffect(() => {
		const fetchReqRole = async () => {
			try {
				const res = await getListCode()
				setReqRole(res.data.data.programcodes)
			} catch (error) {
				console.log(error)
			}
		}
		fetchReqRole()
	}, [])

	const fetchRequirements = async () => {
		console.log(props)
		setIsLoading(true)
		try {
			let response = await getRequirements('Class')
			const responseSmallGroupMemberData = await getSmallGroupLevel()
			const smallGroupMemberData = responseSmallGroupMemberData.data.data.smallgroup_level
			const programCodeData = response.data.data.preprogramcodes
			const maritalStatusData = response.data.data.maritalstatuses
			const ministryData = response.data.data.ministrymemberroles

			if (programCodeData) {
				let listProgramCode = []
				for (const programCode of programCodeData) {
					listProgramCode.push({
						id: programCode.id,
						name: programCode.name,
					})
				}
				setListProgramCode(listProgramCode)
			}

			if (maritalStatusData) {
				let listMaritalStatus = []
				for (const maritalStatus of maritalStatusData) {
					listMaritalStatus.push({
						id: maritalStatus.id,
						name: maritalStatus.name,
					})
				}
				setListMaritalStatus(listMaritalStatus)
			}

			if (maritalStatusData) {
				let listSmallGroup = smallGroupMemberData.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				setListSmallGroupMemberRole(listSmallGroup)
			}

			if (maritalStatusData) {
				let listMinistryGroup = []
				for (const ministry of ministryData) {
					listMinistryGroup.push({
						id: ministry.id,
						name: ministry.name,
					})
				}
				setListMinistry(listMinistryGroup)
			}

			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			setErrorMessage(error.message)
		}
	}

	const formattingTypeList = (programCodeDetailData) => {
		let formattedTypeList = []
		if (programCodeDetailData.iscouple === 1) {
			formattedTypeList.push('type0')
		}
		if (programCodeDetailData.reqfamily === 1 && programCodeDetailData.reqchildren === 1) {
			formattedTypeList.push('type2', 'type1')
		} else if (programCodeDetailData.reqfamily === 1 && programCodeDetailData.reqchildren === 0) {
			formattedTypeList.push('type1')
		}

		return formattedTypeList
	}

	const formattingProgramCodeDetail = (programCodeDetailData) => {
		return {
			classCode: programCodeDetailData.codename,
			className: programCodeDetailData.name,
			genderList: programCodeDetailData.reqgender === null ? ['Male', 'Female'] : [programCodeDetailData.reqgender],
			typeList: formattingTypeList(programCodeDetailData),
		}
	}

	const fetchClassCodeDetail = async () => {
		setIsLoading(true)
		let programCodeDetail = {}
		try {
			let response = await getClassCodeDetail(id)
			const programCodeDetailData = response.data.data.programcode[0]

			if (programCodeDetailData) {
				programCodeDetail = formattingProgramCodeDetail(programCodeDetailData)
			}

			setFieldValue('classCode', programCodeDetail.classCode)
			setFieldValue('className', programCodeDetail.className)
			setFieldValue('genderList', programCodeDetail.genderList)
			setFieldValue('typeList', programCodeDetail.typeList)
			// setFieldValue('numberOfSession', programCodeDetail.numbersession)
			setFieldValue('minimumOfSession', programCodeDetail.numbersession)
			setFieldValue('reqLeaderApproval', programCodeDetail.leaderapproval)
			setFieldValue('reqAdminApproval', programCodeDetail.adminapproval)

			const cloneprerequisiteClass = [...prerequisiteClass]
			for (const req of programCodeDetailData.programcodereqprcodes) {
				cloneprerequisiteClass.push(req.programcodereq_id)
				prerequisite.push(req.programcodereq_id)
			}
			setFieldValue('prerequisiteClass', cloneprerequisiteClass)

			const cloneMaritalStatusList = [...maritalStatusList]
			for (const maritalStatus of programCodeDetailData.programcodereqmarstatuses) {
				cloneMaritalStatusList.push(maritalStatus.maritalstatus_id)
			}
			setFieldValue('maritalStatusList', cloneMaritalStatusList)

			const cloneRoleList = [...roleList]
			for (const role of programCodeDetailData.programcodereqsgroles) {
				cloneRoleList.push(role.smallgroupmemberrole_id)
			}
			setFieldValue('roleList', cloneRoleList)

			const cloneMinistryList = [...ministryList]
			for (const ministry of programCodeDetailData.programcodereqministries) {
				cloneMinistryList.push(ministry.ministry_id)
			}
			setFieldValue('ministryList', cloneMinistryList)

			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			setErrorMessage(error.message)
		}
	}

	const handleAddParticipant = () => {
		setPrerequisite([
			...prerequisite,
			{
				id: '',
			},
		])
	}

	const handleRemovePrerequisite = (index) => {
		const list = [...prerequisite]
		list.splice(index, 1)
		setPrerequisite(list)
		setFieldValue('prerequisiteClass', list)
	}

	const handlePrerequisite = (e, index) => {
		const { value, name } = e.target
		const list = [...prerequisite]
		list[index][name] = value
		setPrerequisite(list)
		setFieldValue('prerequisiteClass', list)
	}

	const handleClassCodeChange = (event) => {
		setFieldValue('classCode', event.target.value)
	}

	const handleClassNameChange = (event) => {
		setFieldValue('className', event.target.value)
	}

	const handleReqLeaderApproval = () => {
		setFieldValue('reqLeaderApproval', !reqLeaderApproval)
	}

	const handleReqAdminApproval = () => {
		setFieldValue('reqAdminApproval', !reqAdminApproval)
	}

	const handleGenderCheck = (gender) => {
		const cloneGenderList = [...genderList]
		const toDeleteIndex = cloneGenderList.findIndex((itrGender) => itrGender === gender)

		if (toDeleteIndex < 0) {
			cloneGenderList.push(gender)
			console.log(cloneGenderList)

			setFieldValue('genderList', cloneGenderList)
		} else {
			cloneGenderList.splice(toDeleteIndex, 1)
			setFieldValue('genderList', cloneGenderList)
		}
	}

	const handleMaritalStatusChange = (status) => {
		const cloneMaritalStatusList = [...maritalStatusList]
		const toDeleteIndex = cloneMaritalStatusList.findIndex((itrMaritalStatus) => itrMaritalStatus === status)

		if (toDeleteIndex < 0) {
			cloneMaritalStatusList.push(status)
			setFieldValue('maritalStatusList', cloneMaritalStatusList)
		} else {
			cloneMaritalStatusList.splice(toDeleteIndex, 1)
			setFieldValue('maritalStatusList', cloneMaritalStatusList)
		}
	}

	const renderMaritalStatus = () => {
		let maritalStatusEl = []

		for (const itrMaritalStatus of listMaritalStatus) {
			maritalStatusEl.push(
				<InputCheckBox
					key={itrMaritalStatus.id}
					value={itrMaritalStatus.id}
					checked={maritalStatusList.includes(itrMaritalStatus.id)}
					onChange={() => handleMaritalStatusChange(itrMaritalStatus.id)}
					name="maritalStatus"
					label={itrMaritalStatus.name}
				/>
			)
		}

		return maritalStatusEl
	}

	const handleRoleChange = (role) => {
		const cloneRoleList = [...roleList]
		const toDeleteIndex = cloneRoleList.findIndex((itrRole) => itrRole === role)

		if (toDeleteIndex < 0) {
			cloneRoleList.push(role)
			setFieldValue('roleList', cloneRoleList)
		} else {
			cloneRoleList.splice(toDeleteIndex, 1)
			setFieldValue('roleList', cloneRoleList)
		}
	}

	const renderRoles = () => {
		let rolesEl = []

		for (const itrRole of listSmallGroupMemberRole) {
			rolesEl.push(
				<div className="w-1/2" key={itrRole.id}>
					<InputCheckBox value={itrRole.id} checked={roleList.includes(itrRole.id)} onChange={() => handleRoleChange(itrRole.id)} name="role" label={itrRole.name} />
				</div>
			)
		}

		return rolesEl
	}

	const handleMinistryChange = (ministry) => {
		const cloneMinistryList = [...ministryList]
		const toDeleteIndex = cloneMinistryList.findIndex((itrMinistry) => itrMinistry === ministry)

		if (toDeleteIndex < 0) {
			cloneMinistryList.push(ministry)
			setFieldValue('ministryList', cloneMinistryList)
		} else {
			cloneMinistryList.splice(toDeleteIndex, 1)
			setFieldValue('ministryList', cloneMinistryList)
		}
	}

	const renderMinistries = () => {
		let ministriesEl = []

		for (const itrMinistry of listMinistry) {
			ministriesEl.push(
				<div className="w-1/2" key={itrMinistry.id}>
					<InputCheckBox
						value={itrMinistry}
						checked={ministryList.includes(itrMinistry.id)}
						onChange={() => handleMinistryChange(itrMinistry.id)}
						name="ministry"
						label={itrMinistry.name}
					/>
				</div>
			)
		}

		return ministriesEl
	}

	const handleTypeChange = (type) => {
		const cloneTypeList = [...typeList]
		const toDeleteIndex = cloneTypeList.findIndex((itrType) => itrType === type)

		if (toDeleteIndex < 0) {
			cloneTypeList.push(type)
			if (type === 'type2' && !cloneTypeList.includes('type1')) {
				cloneTypeList.push('type1')
			}
			setFieldValue('typeList', cloneTypeList)
		} else {
			cloneTypeList.splice(toDeleteIndex, 1)
			if (type === 'type1' && cloneTypeList.includes('type2')) {
				const toDeleteIndexType2 = cloneTypeList.findIndex((itrType) => itrType === 'type2')
				if (toDeleteIndexType2 >= 0) {
					cloneTypeList.splice(toDeleteIndexType2, 1)
				}
			}
			setFieldValue('typeList', cloneTypeList)
		}
	}

	const renderTypes = () => {
		let typesEl = []

		for (const itrType of dummyTypes) {
			typesEl.push(
				<InputCheckBox key={itrType.id} value={itrType.id} checked={typeList.includes(itrType.id)} onChange={() => handleTypeChange(itrType.id)} name="type" label={itrType.label} />
			)
		}

		return typesEl
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			props.history.push('/classes/list-code')
		}
	}

	return (
		isLoading ? 
			<div className="mb-5 flex justify-center w-full">
				<Loading />
			</div>
			:
			<div className="w-full">
				<TextInput styles="pr-4 mb-4" value={classCode} name="classCode" label="CLASS CODE" onChange={handleClassCodeChange} errors={errors.classCode} />
				<TextInput styles="pr-4 mb-4" value={className} name="className" label="CLASS NAME" onChange={handleClassNameChange} errors={errors.className} />
				<div className="w-full">
					<Label>PRE-REQUISITE CLASS</Label>
					{prerequisite.map((v, idx) => (
						<PrerequisiteClass id={v} idx={idx} handleRemovePrerequisite={handleRemovePrerequisite} name="prerequisiteClass" handleChange={handlePrerequisite} codes={reqRole} />
					))}
					<div className="flex justify-end mr-4">
						<button className="bg-primary mb-2 mt-2 border-primary border-2 text-sm font-bold shadow-md p-1 rounded-lg text-white focus:outline-none" onClick={handleAddParticipant}>
							Add Class Code
							</button>
					</div>
				</div>
				<div className="flex">
					<TextInput styles="pr-4 mb-4 w-1/2" name="minimumOfSession" label="MINIMUN SESSION TO PASS" value={minimumOfSession} onChange={handleChange} />

					{/* <TextInput styles="pr-4 mb-4 w-1/2" name="numberOfSession" label="NUMBER OF SESSION" onChange={handleChange} /> */}
				</div>

				<Eligibility>ELIGIBILITY</Eligibility>
				<div className="mb-4">
					<Label>GENDER</Label>
					<InputCheckBox value={'Male'} checked={genderList.includes('Male')} name="genderList" label="Male" onChange={() => handleGenderCheck('Male')} />
					<InputCheckBox value={'Female'} checked={genderList.includes('Female')} name="genderList" label="Female" onChange={() => handleGenderCheck('Female')} />
				</div>
				<div className="mb-4">
					<Label>MARITAL STATUS</Label>
					{renderMaritalStatus()}
				</div>
				<div className="mb-4">
					<Label>ROLE</Label>
					<div className="flex flex-row flex-wrap w-1/2">{renderRoles()}</div>
				</div>
				<div className="mb-4">
					<Label>MINISTRY</Label>
					<div className="flex flex-row flex-wrap w-1/2">{renderMinistries()}</div>
				</div>
				<div className="mb-4">
					<Label>TYPE</Label>
					{renderTypes()}
				</div>

				<InputCheckBox value={reqLeaderApproval} checked={reqLeaderApproval} name="reqLeaderApproval" label="REQUIRES LEADER'S APPROVAL" onChange={handleReqLeaderApproval} />
				<InputCheckBox value={reqAdminApproval} checked={reqAdminApproval} name="reqAdminApproval" label="REQUIRES ADMIN'S APPROVAL" onChange={handleReqAdminApproval} />

				<div className="flex justify-between mb-6 pr-4 mt-4">
					<Button type="cancel" handleClick={handleCancel}>
						{isSubmitting ? <Loading secondary /> : 'Cancel'}
					</Button>
					<Button type="primary" handleClick={handleSubmit}>
						{isSubmitting ? <Loading secondary /> : 'Save'}
					</Button>
				</div>
			</div>
	)
}

export default EditClassCodeForm

const PrerequisiteClass = ({ id, idx, handleRemovePrerequisite, handleChange, codes }) => {
	return (
		<div className="flex w-full mt-4">
			<div className="flex w-full">
				<div className="w-1/2">
					<select className="form-select h-11 block w-full border-2 rounded-lg focus:border-secondary" name="id" value={id} onChange={(e) => handleChange(e, idx)}>
						<option key={idx} value="">
							Choose
						</option>
						{codes.map((option, idx) => (
							<option key={idx} value={option.id}>
								{option.name}
							</option>
						))}
					</select>
				</div>
				<i className="fas fa-times mt-4 text-red-400 cursor-pointer ml-2" onClick={() => handleRemovePrerequisite(idx)} />
			</div>
		</div>
	)
}
