import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Loading from 'components/commons/Loading'
import { Button, ButtonContainer, ButtonFilter, X, Span, Header, Title, Body, Content, Input } from 'components/smallGroup/Styles'
import InputDateFilterExport from 'components/commons/InputDateFilterExport'
import FilterItem from 'components/commons/FilterItem'
import moment from 'moment'

import { getPrayerCategories, exportPrayerPraise } from 'libs/apis/prayerpraise'
import { getCampusServices } from 'libs/apis/campusServices'
import { helperSelectFilter } from 'libs/utils/utils'
import alert from 'services/alert'

const customModalStyles = {
	content: {
		position: 'relative',
		top: '10%',
		left: '17%',
		right: '25%',
		width: '1101px',
		backgroundColor: 'white',
		paddingLeft: '2px',
		paddingRight: '0px',
		paddingBottom: '0px',
		overflow: 'hidden',
		borderRadius: '12px',
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.8)',
	},
}

const customInputStyles = {
	width: 'auto',
	paddingRight: '15px'
}

const customModalContentStyles = {
	justifyContent: 'normal'
}

export default function PrayerExport() {
	const prayerPraiseType = 1 // 1 is Prayer Request
	const [tab, setTab] = useState('campus service')
	const [active, setActive] = useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	
	const [listCampus, setListCampus] = useState([])
	const [listCategory, setListCategory] = useState([])
	const [campusServices, setCampusServices] = useState('')
	const [categories, setCategories] = useState('')
	const [dateFrom, setDateFrom] = useState('')
	const [dateTo, setDateTo] = useState('')

	const [loading, setLoading] = useState(false)

	const fetchPrayerCategories = async () => {
		setLoading(true)
		try {
			const res = await getPrayerCategories()
			setListCategory(res.data.data.prayercategories)
		} catch (error) {
			console.error(error)
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
				} else if (error.response.status >= 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setLoading(false)
				}
			}
		}
	}

	const fetchCampusServices = async () => {
		setLoading(true)
		try {
			const res = await getCampusServices()
			setListCampus(res.data.data.campusservices)

		} catch (error) {
			console.error(error)
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
				} else if (error.response.status >= 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setLoading(false)
				}
			}
		} finally {
			setLoading(false)
		}
	}

	const handleSelectFilter = (event) => {
		if (event.target.name === 'campusId') {
			helperSelectFilter(campusServices, event.target.value, setCampusServices)
		} else if (event.target.name === 'categoryId') {
			helperSelectFilter(categories, event.target.value, setCategories)
		}
	}

	const handleDateFrom = (date) => {
		let myDate = new Date(date)
		let result = `${moment(myDate).format('YYYY-MM-DD')} 00:00:00`
		setDateFrom(result)
	}

	const handleDateTo = (date) => {
		let myDate = new Date(date)
		let result = `${moment(myDate).format('YYYY-MM-DD')} 23:59:59`
		setDateTo(result)
	}

	const handleToggleModal = () => {
		setModalIsOpen(!modalIsOpen)
		setTab('campus service')
	}

	const handleOnTab = (event) => {
		setTab(event.target.value)
		setActive(!active)
	}

	const handleClearExport = () => {
		setListCampus('')
		setListCategory('')
		setCampusServices('')
		setCategories('')
		setDateFrom('')
		setDateTo('')
		setModalIsOpen(false)
	}

	const handleExport = async () => {
		try {
			setLoading(true)
			let res = await exportPrayerPraise(prayerPraiseType.toString(), campusServices.toString(), categories.toString(), dateFrom, dateTo)

			if (res.data.api_status) {
				setLoading(false)
				const link = document.createElement('a')
				link.href = res.data.data.path
				link.download = res.data.data.path
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 500) {
					alert.failed('Failed', 'An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setLoading(false)
				}
			}
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchPrayerCategories()
		fetchCampusServices()
	}, [])

	const campusTab = tab === 'campus service'
	const categoryTab = tab === 'category'
	const dateSubmittedTab = tab === 'date'
	
	const length = (campusServices.length !== 0) || (categories.length !== 0)

	return (
		<div className={`${length ? 'mr-16' : 'mr-6'}`}>
			<div className="flex flex-row relative cursor-pointer">
				<ButtonFilter onClick={handleToggleModal} empty={length}>
					Export
				</ButtonFilter>
			</div>

			<Modal
				isOpen={modalIsOpen}
				style={customModalStyles}
				contentLabel="Prayer Export Modal"
				ariaHideApp={false}
			>
				<Header>
					<Title>Prayer Export</Title>
					<X onClick={handleToggleModal}>X</X>
				</Header>
				<Body>
					<Content style={customModalContentStyles}>
						<Input
							style={customInputStyles}
							className={`${tab === 'campus service'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
								}`}
							type="button"
							value="campus service"
							onClick={handleOnTab}
						/>
						<Input
							style={customInputStyles}
							className={`${tab === 'category'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
								}`}
							type="button"
							value="category"
							onClick={handleOnTab}
						/>
						<Input
							style={customInputStyles}
							className={`${tab === 'date'
									? 'bg-white shadow-md'
									: 'bg-gray-200 border-grey-600 border-2'
								}`}
							type="button"
							value="date"
							onClick={handleOnTab}
						/>
					</Content>
					<div className="border-2 border-grey-600 mx-4 mt-2 h-auto" />
					<div>
						<div className="flex flex-wrap justify-arround">
							{campusTab &&
								listCampus.map((item,index) => (
									<div key={index} className="pr-2">
										<FilterItem
											classname={`${campusServices.includes(item.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="campusId"
											title={item.campus.name}
											subtitle={item.servicetime}
											value={item.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{categoryTab &&
								listCategory.map((item,index) => (
									<div key={index} className="pr-2">
										<FilterItem
											classname={`${categories.includes(item.id.toString()) ? 'bg-white shadow-md border-none' : 'bg-gray-200'}`}
											name="categoryId"
											title={item.name}
											value={item.id}
											onClick={handleSelectFilter}
										/>
									</div>
								))}
							{dateSubmittedTab && (
								<div className="flex mt-2">
									<div className="pt-2 flex flex-row ">
										<InputDateFilterExport label="DATE FROM" value={dateFrom} name="dateFrom" handleChange={handleDateFrom} />
										<InputDateFilterExport label="TO" name="dateTo" value={dateTo} startDate={dateFrom} handleChange={handleDateTo} />
									</div>
								</div>
							)}
						</div>
					</div>
				</Body>
				<ButtonContainer>
					<Button clear onClick={handleClearExport}>
						{loading ? <Loading secondary /> : <Span>Cancel</Span>}
					</Button>
					<Button onClick={handleExport}>
						{loading ? <Loading secondary /> : <Span>Export</Span>}
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	)
}
