import styled from 'styled-components'

export const TableContainer = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 0.25rem;
`

export const Table = styled.table`
	width: 100%;
`

export const THead = styled.thead`
	border-top: 2px solid #f2f6f9;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-transform: uppercase;
	color: gray;
`

export const TBody = styled.tbody`
	border: 2px solid #f2f6f9;
	color: #3e3e3e;
`

export const THeader = styled.th`
	height: 60px;
	text-align: left;
	padding-left: 4rem;
`

export const TRow = styled.tr`
	border-top: 2px solid #f2f6f9;
	text-align: center;
`
