import React from 'react'

const YoutubeIframe = (props) => {
	return (
		<iframe
			width="100%"
			title={props.id}
			src={`https://www.youtube.com/embed/${props.id}`}
			frameBorder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	)
}

export default YoutubeIframe
