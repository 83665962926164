import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import BillingBread from 'components/breadcrumbs/BillingBread'
import BillingInfo from 'components/widgets/BillingInfo'
import PaymentHistory from 'components/paymentHistory/PaymentHistory'
import { billingList, exportPDF } from 'libs/apis/billings'
import { totalActiveAndLastSundayCount } from 'services/reportServices'

const Wrapper = styled.div`
	display: grid;
	grid-gap: 20px;
`

class Billing extends Component {
	state = {
		isLoading: false,
		isRedirect: false,
		errorMessage: '',
		filterStatus: '',
		orderBy: '',
		orderDir: '',
		rateUser: 0,
		billedUsers: 0,
		currentPage: 0,
		lastPage: 1,
		data: [],
	}

	componentDidMount() {
		this.LoadData()
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props
		if (location.search && location.search !== prevProps.location.search) {
			this.LoadData()
		}
	}

	LoadData = async () => {
		const message = 'An unexpected error has occurred. We are currently investigating the issue, please try again later.'

		try {
			const { filterStatus, orderBy, orderDir } = this.state
			const parsed = queryString.parse(this.props.location.search)
			const page = parsed.page || 1
			this.setState({ isLoading: true })
			const response = await billingList(page, filterStatus, orderBy, orderDir)

			const res = await totalActiveAndLastSundayCount()

			if (response.data.api_status) {
				this.setState({
					isLoading: false,
					rateUser: response.data.data.rate.rate_per_user,
					currentPage: response.data.data.billings.current_page,
					lastPage: response.data.data.billings.last_page,
					data: response.data.data.billings.data,
					billedUsers: res.data.data.billed_users,
				})
			} else {
				this.setState({ isLoading: false })
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					await localStorage.removeItem('token')
					this.setState({ isRedirect: true })
				} else if (error.response.status >= 500) {
					this.setState({ isLoading: false, errorMessage: message })
				}
			}
		}
	}

	renderData = () => {
		const { location } = this.props
		const parsed = queryString.parse(location.search)
		const active = `${location.pathname}?page=${parsed.page || 1}`
		const { currentPage, lastPage, data, rateUser, billedUsers } = this.state
		return (
			<Wrapper>
				<BillingInfo rate={rateUser} billedUsers={billedUsers} />
				<PaymentHistory data={data} handleDetails={this.handleOnDetails} />
				<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} url={location.pathname} />
			</Wrapper>
		)
	}

	handleOnDetails = async (id) => {
		const response = await exportPDF(id)
		const link = document.createElement('a')
		link.href = response.data.data
		document.body.appendChild(link)
		link.click()
	}

	render() {
		const church = JSON.parse(localStorage.getItem('church'))
		const name = church.church_name ? church.church_name : '-'
		const { isLoading, isRedirect, errorMessage, data } = this.state
		const shouldDisplayLoad = isLoading && !errorMessage
		const shouldDisplayData = !isLoading && data.length > 0
		const shouldDisplayNoData = !isLoading && data.length === 0
		const shouldDisplayError = !isLoading && errorMessage

		if (isRedirect) {
			return <Redirect to="/login" />
		}

		return (
			<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
				<Helmet>
					<meta charSet="utf-8" />
					<title>{name} - Billing</title>
				</Helmet>
				<BillingBread />
				{shouldDisplayLoad && <Loading />}
				{shouldDisplayData && this.renderData()}
				{shouldDisplayNoData && <div className="align-center">No Data Available</div>}
				{shouldDisplayError && <div className="align-center">{errorMessage}</div>}
			</div>
		)
	}
}
export default Billing
