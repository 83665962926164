import axios from 'axios'

export const churchProfile = async () => {
  return await axios({
    method: 'get',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    url: `${process.env.REACT_APP_ENDPOINT}/church`
  })
}

export const getAllCampus = async () =>
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_ENDPOINT}/church/all-campus`,
      headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
    });
