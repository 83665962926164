import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Tippy from '@tippy.js/react'
import { partial } from 'libs/utils/utils'
import { TableContainer, Table, THead, THeader, TBody, TRow } from './Styles'

function SmallGroupTable(props) {
	const { handleOnSort, handleOnClose, listSmallGroup } = props
	const handleSortCampus = partial(handleOnSort, 'campus')
	const handleSortName = partial(handleOnSort, 'smallgroupmember_id')
	const handleSortLevel = partial(handleOnSort, 'smallgroupmember_id')
	const handleSortCategory = partial(handleOnSort, 'smallgroupcategory_id')
	const handleSortTime = partial(handleOnSort, 'appointmentdefaulttime')
	const handleSortDay = partial(handleOnSort, 'appointmentdefaultday')

	return (
		<TableContainer>
			<Table>
				<THead>
					<tr>
						<th className="text-left" />
						<THeader>
							campus
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortCampus} />
						</THeader>
						<THeader>
							sg name
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortName} />
						</THeader>
						<THeader>
							sg level
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortLevel} />
						</THeader>
						<THeader>
							sg category
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortCategory} />
						</THeader>
						<THeader>sg leader (s)</THeader>
						<THeader>members</THeader>
						<THeader>
							day
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortDay} />
						</THeader>
						<THeader>
							time
							<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortTime} />
						</THeader>
						<THeader>status</THeader>
						<th className="px-2 py-4" />
					</tr>
				</THead>
				<TBody>
					{listSmallGroup.length ? (
						listSmallGroup.map(({ id, campus, sgName, sgLevel, sgCategory, sgLeaders, members, day, time, status }) => (
							<SmallGroupTableRow
								key={id}
								id={id}
								campus={campus}
								sgName={sgName}
								sgLevel={sgLevel}
								sgCategory={sgCategory}
								sgLeaders={sgLeaders}
								members={members}
								day={day}
								time={time}
								status={status}
								handleOnClose={handleOnClose}
							/>
						))
					) : (
						<></>
					)}
				</TBody>
			</Table>
		</TableContainer>
	)
}

export default SmallGroupTable

function SmallGroupTableRow(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const wrapperRef = useRef(null)
	const { id, campus, sgName, sgLevel, sgCategory, sgLeaders, members, day, time, status, handleOnClose } = props

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	function handleClose() {
		handleOnClose(id)
	}

	const leaders = sgLeaders.join(' & ')
	const styleDropDownOpen = `shadow-md absolute right-20px ${dropDownOpen ? 'block' : 'hidden'}`
	const leaderEmpty = `${sgLeaders === null ? 'opacity-50' : 'opacity-100'}`
	const styleTdStatus = status ? 'relative pr-6' : 'absolute pr-6'

	return (
		<TRow>
			<td className={styleTdStatus}>
				{status === 'Closed' && (
					<Tippy content="Closed">
						<div className="status-post-tag">
							<div className="ribbon-black-triagle" />
						</div>
					</Tippy>
				)}
			</td>
			<td style={{ width: '100px' }} className="px-6">
				{campus}
			</td>
			<td className="text-center">{sgName}</td>
			<td className="text-center" style={{ width: '120px' }}>
				{sgLevel}
			</td>
			<td style={{ width: '150px' }}>{sgCategory}</td>
			<td>{leaders}</td>
			<td style={{ width: '95px' }}>{members + sgLeaders.length}</td>
			<td style={{ width: '70px' }}>{day ? day : '-'}</td>
			<td>{time ? time : '-'}</td>
			<td style={{ width: '95px' }}>{status ? status : '-'}</td>
			<td className="px-2 py-4">
				{status !== 'Closed' && (
					<div className="cursor-pointer" onClick={handleToggle}>
						<i className="dropdown-trigger fas fa-ellipsis-v" />
						<div className={styleDropDownOpen}>
							<ul ref={wrapperRef} className="bg-white">
								<li>
									<Link className="px-4 py-2 block hover:bg-darkSkyBlue" to={`/small-group/view/${id}`}>
										View
									</Link>
								</li>
								<li>
									<Link className="px-4 py-2 block hover:bg-darkSkyBlue" to={`/small-group/edit/${id}`}>
										Edit
									</Link>
								</li>
								<li className="px-4 py-2 block hover:bg-darkSkyBlue" onClick={handleClose}>
									Close Group
								</li>
							</ul>
						</div>
					</div>
				)}
			</td>
		</TRow>
	)
}
