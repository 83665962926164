import React from 'react'
import {Tooltip} from 'react-tippy'

function ImageInput({label, onChange, imageName, errors, isImage}) {
    return (
        <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
            <div className="p-0 rounded-none border-0 relative inline-block w-full border-secondary">
                <Tooltip
                    title="Recommended image size 900x506 pixels"
                    position="bottom"
                    trigger="mouseenter"
                >
                    <input
                        type="file"
                        accept={isImage ? "image/*" : null}
                        multiple={false}
                        onChange={onChange}
                        className="relative z-10 w-full h-full opacity-0 py-6 cursor-pointer"
                    />
                </Tooltip>
                <label
                    className="flex items-center justify-center border-2 rounded-lg border-dashed text-center h-full w-full absolute inset-x-0 top-0">
                    <span className="text-secondary">{imageName}</span>
                </label>
            </div>
            {errors && <div className="text-red-500 text-xs italic">{errors}</div>}
        </div>
    )
}

export default ImageInput
