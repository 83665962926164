import React from 'react'
import moment from 'moment'

const CampaignEmailPreview = ({values}) => {
    const schedule = moment(values.schedule, 'DD/MM/YYYY HH:mm').format('D MMM YYYY hh:mm A');
    const church = JSON.parse(localStorage.getItem('church'));
    return (
        <div className="bg-white shadow-md p-6">
            <h3 className="font-bold mb-4 ml-4">EMAIL PREVIEW</h3>
            <div className="bg-gray-100 reak-words overflow-auto h-64">
                {/*{!values.image_url && <DefaultIframe/>}*/}
                {/*<div className="flex justify-center">{values.image_url && <ImageIframe img={values.image_url}/>}</div>*/}
                <div className="p-3 break-words h-auto">
                    <div className="flex justify-between bg-white-100 h-12 rounded">
                        <div
                            className="font-bold text-md text-black-200 ml-2 pt-2">{church.church_name || 'Email Campaign'}</div>
                    </div>
                    <div className="my-2 border-2 border-solid"/>
                    <div className="text-gray-500">
                        <div className="text-sm font-bold ml-2">{values.subject || 'Subject'}</div>

                        <div style={{marginLeft: "8px"}}>
                            <td dangerouslySetInnerHTML={{__html: values.emailPreviewContent}}/>
                        </div>

                        {/*<ReactQuill modules={{toolbar: false}} className="-mt-2 text-sm h-24 -ml-2" theme={null}*/}
                        {/*            value={dummyHTML} formats={null}/>*/}
                        <div className="text-sm font-bold ml-2">Schedule</div>
                        <div className="ml-2 text-sm">{schedule === 'Invalid date' ? ' ' : schedule}</div>
                    </div>
                    <div className="my-2 border-2 border-solid"/>
                </div>
            </div>
        </div>
    )
}

export default CampaignEmailPreview
