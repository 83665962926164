import React, { Component } from 'react'
import moment from 'moment'
import SelectOptions from 'components/commons/SelectOptions'
import ToggleSwitch from 'components/commons/ToggleSwitch'
import ServiceAttendanceChart from 'components/dataReport/ServiceAttendanceChart'
import { serviceAttendance } from 'services/reportServices'
import { attendanceCampusList } from 'services/campusServices'
import BarChart from 'components/skeleton/BarChart'

class ServiceAttendance extends Component {
	state = {
		campuses: [],
		campusSelected: '',
		attendances: [],
		isChartBar: false,
		isLoading: false,
	}

	async componentDidMount() {
		try {
			const {
				data: { data: results },
			} = await attendanceCampusList()

			const campuses = results.map((result) => ({
				id: result.id,
				label: result.title,
			}))

			this.setState({ campuses, campusSelected: campuses[0].id }, () => {
				this.doLoadChart()
			})
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.props.history.replace('/login')
			}
		}
	}

	doLoadChart = async () => {
		this.setState({ isLoading: true }, async () => {
			try {
				const {
					data: { data: attendances },
				} = await serviceAttendance(this.state.campusSelected)

				this.setState({ attendances, isLoading: false })
			} catch (error) {
				if (error.response && error.response.status === 401) {
					this.props.history.replace('/login')
				}
				this.setState({ isLoading: false })
			}
		})
	}

	handleChange = ({ currentTarget: input }) => {
		this.setState({ [input.name]: input.value }, () => {
			this.doLoadChart()
		})
	}

	handleToggle = () => {
		this.setState({ isChartBar: !this.state.isChartBar })
	}

	renderData = () => {
		const { isChartBar, attendances } = this.state
		let data = []

		if (isChartBar) {
			data = attendances.map((item) => {
				const x = item.chart.map((x) =>
					moment(x.week_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
				)
				const y = item.chart.map((y) => parseInt(y.total))
				return { name: item.label, type: 'scatter', x, y }
			})
		} else {
			data = attendances.map((item) => {
				const x = item.chart.map((x) =>
					moment(x.week_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
				)
				const y = item.chart.map((y) => parseInt(y.total))
				return { name: item.label, type: 'bar', x, y }
			})
		}

		return <ServiceAttendanceChart data={data} />
	}

	render() {
		const { campuses, campusSelected, isLoading, isChartBar } = this.state
		const { length: count } = this.state.attendances

		if (isLoading || !count) return <BarChart />

		return (
			<div className="mb-6 rounded-lg bg-white shadow-md p-6">
				<div className="flex justify-between ">
					<h3 className="font-bold mb-6">Service Attendance</h3>
					<ToggleSwitch
						label="Toggle Line Graph"
						checked={isChartBar}
						onToggle={this.handleToggle}
					/>
				</div>
				<div className="block mb-4">
					<SelectOptions
						options={campuses}
						value={campusSelected}
						name="campusSelected"
						onChange={this.handleChange}
					/>
				</div>
				{this.renderData()}
			</div>
		)
	}
}

export default ServiceAttendance
