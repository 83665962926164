import React from 'react'
import photoplaceholder from 'assets/images/placeholder_picture.jpg'

function UploadImage({ imageUrl, handleFileChange }) {
	return (
		<div className="w-3/6">
			<div className="mb-5 mt-4">
				<label className="font-bold">DISPLAY PICTURE</label>
				<div className="flex flex-row mt-3">
					<div className="bg-white rounded-lg border-2 flex justify-center w-40">
						<img
							className="rounded-lg "
							src={
								imageUrl === 'null' || imageUrl === null || imageUrl === ''
									? photoplaceholder
									: imageUrl
							}
							alt="ministry_photo_profile"
						/>
					</div>
					<div className="ml-5 mt-32">
						<label htmlFor="fileupload" className="text-primary cursor-pointer">
							Upload picture
							<input
								id="fileupload"
								type="file"
								accept="image/*"
								onChange={handleFileChange}
								style={{ display: 'none' }}
							/>
						</label>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UploadImage
