import axios from 'axios'

export const getFileRole = async () => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/document/all-role`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const deleteFile = async (action, type, id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			action,
			type,
			id,
		},
	})
}

export const deleteFolder = async (action, type, groupId) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: {
			action,
			type,
			groupId,
		},
	})
}

export const renameFolder = async (action, type, name, groupId) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: {
			action,
			type,
			name,
			groupId,
		},
	})

export const renameFile = async (action, type, name, id) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: {
			action,
			type,
			name,
			id,
		},
	})

export const uploadFile = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formdata,
	})

export const uploadFileToFolder = async (formdata) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formdata,
	})

export const moveFile = async (action, type, id, groupId) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data: { action, type, id, groupId },
	})

export const uploadFolder = async (action, type, name) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/document/action`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: {
			action,
			type,
			name,
		},
	})

export const getFileList = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/document/all-list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getFileDetail = async (documentgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/document/all-list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			documentgroupId,
		},
	})

export const getFolders = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupfile/get/by-role-id`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const updateRoleResources = async (resources_id, filter_role) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/update-role-resources`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		params: { resources_id, filter_role },
	})
}
