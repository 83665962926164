import styled from 'styled-components'

export const MainContainer = styled.div`
	display: grid;
	grid-gap: 20px;
`

export const ViewUserInfo = styled.div`
	background-color: #fff;
	padding: 16px 25px;
	margin-left: -25px;
	margin-right: -25px;
	margin-bottom: 20px;
`

export const ViewUserDetail = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
`

export const UserDetailCard = styled.div`
	padding: 20px;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
	margin-bottom: 20px;
`

export const ViewUserInfoContainer = styled.div`
	display: grid;
	grid-template-columns: 100px 1fr 1fr;
	grid-gap: 20px;
`

export const UserInfoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
`

export const UserInfoTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: #adadad;
`

export const UserInfoData = styled.div`
	font-size: 16px;
	font-weight: bold;
`

export const UsersHeader = styled.div`
	display: flex;
	width: 100%;

	div:last-of-type {
		margin-left: auto;
	}
`

export const Flex = styled.div`
	display: flex;
`

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
export const Left = styled.div`
	width: 50%;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
`
export const Right = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
`
export const Box = styled.div`
	background-color: #fff;
	margin-bottom: 1rem;
	padding: 1rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`
export const Data = styled.div`
	display: flex;
	text-align: left;
	margin-bottom: 1rem;
`
export const Label = styled.div`
	color: #adadad;
	font-weight: bold;
	width: 50%;
`

export const Value = styled.div`
	width: auto%;
	font-weight: bold;
	justify-content: right;
	display: flex;
`
