import React from 'react'

const CheckBox = (props) => {
	return (
		<div className="flex flex-row items-center mb-2">
			<input type="checkbox" name={props.name} className="form-checkbox border-2 mr-2" checked={props.checked} onChange={(e) => props.handleCheck(e)} />
			{props.label && <label className="block text-gray-700 text-sm font-bold">{props.label}</label>}
		</div>
	)
}

export default CheckBox
