import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import CampaignEmailFormik from 'components/formik/CampaignEmailFormik'
import CampaignPushNotifFormik from 'components/formik/CampaignPushNotifFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as EmailIcon } from 'assets/icons/megaphone.svg'

function CreateCampaign(props) {
	const [tab, setTab] = useState('email');

	const styleCreateEmail = `nav-link cursor-pointer ${tab === 'email' ? 'active' : ''}`.trim();
	const styleCreatePushNotif = `nav-link cursor-pointer ${tab === 'pushnotif' ? 'active' : ''}`.trim();
	const displayEmailTab = tab === 'email';
	const displayPushNotifTab = tab === 'pushnotif';
	const church = JSON.parse(localStorage.getItem('church'));
	const name = church.church_name ? church.church_name : '-';
	const breads = [{ url: '', title: 'Create Campaign' }];

	const handleOnTab = event => {
		setTab(event.target.id)
	};

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Campaign</title>
			</Helmet>
			<PageBreadcrumbs icon={EmailIcon} title="Create Campaign" breads={breads} />
			<ul className="flex border-b">
				<li className="-mb-px mr-1">
					<div className={styleCreateEmail} name="tab" id="email" onClick={handleOnTab}>
						Email
					</div>
				</li>
				<li className="mr-1">
					<div className={styleCreatePushNotif} name="tab" id="pushnotif" onClick={handleOnTab}>
						Notification
					</div>
				</li>
			</ul>
			<div>
				{displayEmailTab && <CampaignEmailFormik {...props} isCreatedForm={true} />}
				{displayPushNotifTab && <CampaignPushNotifFormik {...props} isCreateForm={true} />}
			</div>
		</div>
	)
}

export default CreateCampaign
