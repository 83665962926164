import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as MinistryIcon } from 'assets/icons/agreement.svg'
import { ministryDetail } from 'libs/apis/ministry'
import EditMinistryFormik from 'components/formik/EditMinistryFormik'
import { getMinistry } from 'libs/apis/ministry'

function EditMinistry(props) {
	const [state, setState] = useState({
		ministryName: '',
		ministryImageURL: '',
		imagePreview: '',
		ministryDescription: '',
		ministryStatus: '',
		ministryGender: '',
	})
	const [ministryLevel, setMinistryLevel] = useState({ value: 'Ministry', label: 'Ministry' })
	const [ministryUpperLevel, setMinistryUpperLevel] = useState({})
	const [ministryHeads, setMinistryHeads] = useState([])
	const [keyVolunteers, setKeyVolunteers] = useState([])

	const [ministryAllGroup, setMinistryAllGroup] = useState([])
	const [ministryGroups, setMinistryGroups] = useState([])
	const [ministrySubGroups, setMinistrySubGroups] = useState([])

	const { id } = useParams()

	useEffect(() => {
		const fetchGroups = async () => {
			try {
				const res = await getMinistry('Ministry')
				const res2 = await getMinistry('Subgroup')
				const res3 = await getMinistry('Group')

				const subgroups = res2.data.data.ministryList.data.map((v) => {
					let temp = {}
					temp.id = `${v.name}.${v.id}`
					temp.label = v.name
					return temp
				})

				const groups = res3.data.data.ministryList.data.map((v) => {
					let temp = {}
					temp.id = `${v.name}.${v.id}`
					temp.label = v.name
					return temp
				})

				setMinistryAllGroup(res.data.data.ministryList.data)
				setMinistryGroups(groups)
				setMinistrySubGroups(subgroups)
			} catch (error) {
				console.log(error)
			}
		}

		fetchGroups()
	}, [])

	useEffect(() => {
		const fecthDetailMinistry = async () => {
			try {
				const res = await ministryDetail(id)
				const v = res.data.data.ministry

				const a = v.ministrymembers.map((v) => {
					let temp = {}
					if (v.ministrymemberrole.name == 'Key Volunteer') {
						temp.id = v.user_id
						temp.label = v.user.fullname
					} else {
						temp.id = ''
						temp.label = ''
					}
					return temp
				})

				const b = v.ministrymembers.map((v) => {
					let temp = {}
					if (v.ministrymemberrole.name == 'Ministry Head') {
						temp.id = v.user_id
						temp.label = v.user.fullname
					} else {
						temp.id = ''
						temp.label = ''
					}
					return temp
				})

				const c = a.filter((v) => v.id !== '')
				const d = b.filter((v) => v.id !== '')

				if (v.ministrysubgroup_id) {
					setMinistryUpperLevel({ value: v.ministrysubgroup.id, label: v.ministrysubgroup.name })
				} else if (v.ministrygroup_id) {
					setMinistryUpperLevel({ value: v.ministrygroup.id, label: v.ministrygroup.name })
				} else {
					setMinistryUpperLevel({ value: '', label: '' })
				}

				setMinistryHeads(d)
				setKeyVolunteers(c)

				setState({
					...state,
					ministryName: v.name,
					ministryGender: { value: v.gender ? v.gender : '', label: v.gender ? v.gender : 'Both' },
					ministryStatus: { value: v.status, label: v.status },
					ministryDescription: v.description,
					ministryImageURL: v.document.url,
				})
			} catch (error) {
				console.log(error)
			}
		}
		fecthDetailMinistry()
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/ministry', title: 'List Ministry' },
		{ url: '', title: 'Edit Ministry' },
	]

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Ministry</title>
			</Helmet>
			<PageBreadcrumbs icon={MinistryIcon} title="Edit Ministry" breads={breads} />
			<EditMinistryFormik
				{...state}
				{...props}
				mId={id}
				ministryUpperLevel={ministryUpperLevel}
				ministryLevel={ministryLevel}
				ministryGroups={ministryGroups}
				ministrySubGroups={ministrySubGroups}
				ministryAllGroup={ministryAllGroup}
				ministryHeads={ministryHeads}
				keyVolunteers={keyVolunteers}
			/>
		</div>
	)
}

export default EditMinistry
