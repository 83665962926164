import React from 'react'

const CourseStatusCard = ({ color, title, value, icon }) => {
	return (
		<div className="w-1/3 px-2">
			<div className="bg-white shadow-md rounded-lg flex h-32">
				<div className="w-5 mr-5">
					<div className={`${color} rounded-l-lg h-full`} />
				</div>
				<div className="w-9/12 md:w-5/12">
					<div className="flex flex-col flex-wrap justify-center h-full">
						<div className="font-bold text-3xl">{value}</div>
						<div>{title}</div>
					</div>
				</div>
				<div className="w-2/12 md:w-5/12">
					<div className="flex flex-col flex-wrap justify-center h-full p-3 md:p-6">{icon}</div>
				</div>
			</div>
		</div>
	)
}

export default CourseStatusCard
