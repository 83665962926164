import React from 'react'
import { Helmet } from 'react-helmet'

import EditClassCodeFormik from 'components/formik/EditClassCodeFormik'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as CoursesIcon } from 'assets/icons/classes.svg'

function EditClassCode(props) {
	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/classes/list-code', title: 'List Class Code' },
		{ url: '', title: 'Edit Class Code' },
	]

	return (
		<div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Class Code</title>
			</Helmet>
			<PageBreadcrumbs icon={CoursesIcon} title="Edit Class Code" breads={breads} />
			<div className="flex bg-white shadow-md pl-4 pt-4">
				<EditClassCodeFormik {...props} />
			</div>
		</div>
	)
}

export default EditClassCode
