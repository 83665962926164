import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactQuill from 'react-quill'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as MinistryIcon } from 'assets/icons/agreement.svg'
import { getMinistryHeadsAndDeputy } from 'libs/apis/ministry'
import DropdownAsync from 'components/commons/DropdownAsync'

import TextInput from 'components/commons/TextInput'
import Button from 'components/commons/Button'
import DropdownItem from 'components/commons/DropdownItem'
import UploadImage from 'components/commons/UploadImage'
import Loading from 'components/commons/Loading'
import { ContainerWrapper, ButtonWrapper } from 'screens/ministry/Styles.js'
import { cancelConfirmation } from 'libs/utils/confirmation'

function CreateMinistryForm(props) {
	const [imagePreview, setImagePreview] = useState('')
	const [inputValueDeputy, setInputValueDeputy] = useState('')
	const [inputValueHead, setInputValueHead] = useState('')
	const history = useHistory()

	const { handleChange, handleSubmit, isSubmitting, setFieldValue, errors, isLoading, defaultOptions } = props
	const { ministryName, ministryLevel, ministryDescription, ministryStatus, ministryGender, ministryUpperLevel, ministryHeads, keyVolunteers } = props.values

	const handleDescription = (value) => {
		setFieldValue('ministryDescription', value)
	}

	const handleSelectMinistryLevel = (e) => {
		setFieldValue('ministryLevel', e)
	}

	const handleSelectMinistryUpperLevel = (e) => {
		let a = e.value.split('.')
		setFieldValue('ministryUpperLevels', { value: a[1], label: e.label })
	}

	const handleSelectGender = (e) => {
		setFieldValue('ministryGender', e)
	}

	const handleSelectStatus = (e) => {
		setFieldValue('ministryStatus', e)
	}

	const handleFileChange = (e) => {
		setImagePreview(URL.createObjectURL(e.target.files[0]))
		setFieldValue('ministryImageURL', e.target.files[0])
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			history.push('/ministry')
		}
	}

	function UpperLevels() {
		if (ministryLevel.value == 'Ministry') {
			const a = props.ministryGroups.concat(props.ministrySubGroups)
			return a
		}

		if (ministryLevel.value == 'SubGroup') {
			return props.ministrySubGroups
		}

		if (ministryLevel.value == 'Group') {
			return props.ministryGroups
		}

		return []
	}

	const handleInputDeputy = (value) => {
		setInputValueDeputy(value)
	}

	const handleInputHead = (value) => {
		setInputValueHead(value)
	}

	const handleDeputy = (e) => {
		setFieldValue('keyVolunteers', e)
	}

	const handleSelectHead = (value) => {
		setFieldValue('ministryHeads', value)
	}

	const loadHead = async () => {
		if (inputValueHead.length >= 3) {
			const res = await getMinistryHeadsAndDeputy(inputValueHead)
			const data = res.data.data.user

			if (res.data.api_status) {
				const options = data.map((item) => ({
					value: item.id,
					label: item.fullname,
				}))
				return options
			}
		}
	}

	const loadDeputy = async () => {
		if (inputValueDeputy.length >= 3) {
			const res = await getMinistryHeadsAndDeputy(inputValueDeputy)
			const data = res.data.data.user

			if (res.data.api_status) {
				const options = data.map((item) => ({
					value: item.id,
					label: item.fullname,
				}))
				return options
			}
		}
	}

	const genders = [
		{ id: 'Female', name: 'Female' },
		{ id: 'Male', name: 'Male' },
		{ id: '', name: 'Both' },
	]
	const listLevels = [
		{ id: 'Ministry', name: 'Ministry' },
		{ id: 'Group', name: 'Ministry Group' },
		{ id: 'SubGroup', name: 'Ministry SubGroup' },
	]
	// const statuses = [
	// 	{ id: 'Open', name: 'Open' },
	// 	{ id: 'Full', name: 'Full' },
	// 	{ id: 'Closed', name: 'Closed' },
	// ]

	const breads = [{ url: '', title: 'Create Ministry' }]

	return (
		<div>
			<PageBreadcrumbs icon={MinistryIcon} title={`Create ${ministryLevel.value ? ministryLevel.value : ''}`} breads={breads} />
			<ContainerWrapper>
				<div className="flex -mb-4">
					<div className="mb-6 w-1/2">
						<TextInput name="ministryName" label="NAME" value={ministryName} onChange={handleChange} errors={errors.ministryName} />
					</div>

					<div className="mb-6 w-1/2">
						<DropdownItem
							name="ministryLevel"
							label="LEVEL"
							value={ministryLevel}
							handleChange={handleSelectMinistryLevel}
							options={listLevels}
							isMulti={false}
							errors={errors.ministryLevel}
							styles="ml-4"
						/>
					</div>
				</div>

				{(ministryLevel.value !== 'Group' || ministryLevel.value === undefined) && (
					<div className="mb-6 w-1/2">
						<DropdownItem
							name="ministryUpperLevels"
							label="UPPER LEVEL"
							value={ministryUpperLevel}
							options={UpperLevels()}
							handleChange={handleSelectMinistryUpperLevel}
							isMulti={false}
						/>
					</div>
				)}

				<div className="mb-6 w-1/2">
					<DropdownAsync
						label="HEAD (S)"
						value={ministryHeads}
						loadOptions={loadHead}
						handleInputChange={handleInputHead}
						onChange={handleSelectHead}
						defaultOptions={defaultOptions}
						isLoading={isLoading}
						errors={errors.ministryHeads}
						isMulti={ministryLevel.value === 'Ministry' ? true : false}
					/>
				</div>
				<div className="mb-6 w-1/2">
					<DropdownAsync
						label="DEPUTY / KEY VOLUNTEER(S)"
						value={keyVolunteers}
						loadOptions={loadDeputy}
						handleInputChange={handleInputDeputy}
						onChange={handleDeputy}
						errors={errors.keyVolunteers}
						defaultOptions={defaultOptions}
						isLoading={isLoading}
						isMulti={ministryLevel.value === 'Ministry' ? true : false}
					/>
				</div>

				{/* {(ministryLevel.value === 'Ministry' || ministryLevel.value === undefined) && (
					<div className="mb-6 w-1/2">
						<DropdownItem name="ministryGender" label="GENDER" value={ministryGender} options={genders} handleChange={handleSelectGender} isMulti={false} />
					</div>
				)} */}

				{(ministryLevel.value === 'Ministry' || ministryLevel.value === undefined) && (
					<div className="mb-6 w-1/2">
						<label className="ml-1 block text-gray-700 text-sm font-bold mb-2">DESCRIPTION</label>
						<ReactQuill value={ministryDescription} onChange={handleDescription} />
					</div>
				)}

				{/* {(ministryLevel.value === 'Ministry' || ministryLevel.value === undefined) && (
					<div className="mb-6 w-1/2">
						<DropdownItem
							label="RECRUITMENT STATUS"
							name="ministryStatus"
							options={statuses}
							value={ministryStatus}
							handleChange={handleSelectStatus}
							isMulti={false}
							errors={errors.ministryStatus}
						/>
					</div>
				)} */}

				{(ministryLevel.value === 'Ministry' || ministryLevel.value === undefined) && <UploadImage imageUrl={imagePreview} handleFileChange={handleFileChange} />}

				<ButtonWrapper>
					<Button type="cancel" handleClick={handleCancel}>
						{isSubmitting ? <Loading secondary /> : 'Cancel'}
					</Button>
					<Button type="primary" handleClick={handleSubmit}>
						{isSubmitting ? <Loading secondary /> : 'Publish'}
					</Button>
				</ButtonWrapper>
			</ContainerWrapper>
		</div>
	)
}

export default CreateMinistryForm
