import React from 'react';

const EmailFooter = () => {
    return (
        <div className="bg-gray-200 text-center py-6 mt-8">
            <div className="flex flex-row justify-center">
                <div className="w-6 mx-2">
                    <a href="http://www.twitter.com/jpcc" target="_blank">
                        <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/gray-twitter-48.png"
                             alt=""
                        />
                    </a>
                </div>
                <div className="w-6 mx-2">
                    <a href="http://www.facebook.com/jpcchurch" target="_blank">
                        <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/gray-facebook-48.png"
                            alt=""/>
                    </a>
                </div>
                <div className="w-6 mx-2">
                    <a href="http://www.youtube.com/jpcctv" target="_blank">
                        <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/gray-youtube-48.png"
                             alt=""
                        />
                    </a>
                </div>
                <div className="w-6 mx-2">
                    <a href="http://instagram.com/jpcc" target="_blank">
                        <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/gray-instagram-48.png"
                             alt=""
                        />
                    </a>
                </div>
                <div className="w-6 mx-2">
                    <a href="http://www.jpcc.org" target="_blank">
                        <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/gray-link-48.png"
                             alt=""
                        />
                    </a>

                </div>
            </div>
            <div className="flex justify-center my-4">
                <hr className="w-1/3 bg-grey-100 flex" style={{height: 1}}/>
            </div>
            <div className="mb-6">
                Copyright © 2020 JPCC, All rights reserved. <br/>
                You are receiving this email because you are a member of MyJPCC
            </div>
            <div>
                <b>Our mailing address is:</b><br/>
                JPCC<br/>
                DIPO Tower, DIPO Business Center 5th Floor <br/>
                Jl. Jend. Gatot Subroto Kav. 51-52 <br/>
                Jakarta 10260 <br/>
                Indonesia
            </div>
        </div>
)
};

export default EmailFooter;
