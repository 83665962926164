import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { getMinistry, getHeadsAndDeputy } from 'libs/apis/ministry'
import CreateMinistryFormik from 'components/formik/CreateMinistryFormik'

function CreateMinistry(props) {
	const [ministryUpperLevel] = useState({})
	const [ministryAllGroup, setMinistryAllGroup] = useState([])
	const [ministryGroups, setMinistryGroups] = useState([])
	const [ministrySubGroups, setMinistrySubGroups] = useState([])
	const [defaultOptions, setDefaultOptions] = useState([])

	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const fetchGroups = async () => {
			try {
				const res = await getMinistry('Ministry')
				const res2 = await getMinistry('Subgroup')
				const res3 = await getMinistry('Group')

				const subgroups = res2.data.data.ministryList.data.map((v) => {
					let temp = {}
					temp.id = `${v.name}.${v.id}`
					temp.label = v.name
					return temp
				})

				const groups = res3.data.data.ministryList.data.map((v) => {
					let temp = {}
					temp.id = `${v.name}.${v.id}`
					temp.label = v.name
					return temp
				})

				setMinistryAllGroup(res.data.data.ministryList.data)
				setMinistryGroups(groups)
				setMinistrySubGroups(subgroups)
			} catch (error) {
				console.log(error)
			}
		}

		fetchGroups()
	}, [])

	useEffect(() => {
		setIsLoading(true)
		const fetchHeadsDeputy = async () => {
			try {
				const res = await getHeadsAndDeputy(100, 1)
				const datas = res.data.data.user.data.map((v) => {
					let t = {}
					t.value = v.id
					t.label = v.fullname
					return t
				})

				setDefaultOptions(datas)
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setIsLoading(false)
			}
		}
		fetchHeadsDeputy()
	}, [])

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Create Ministry </title>
			</Helmet>
			<CreateMinistryFormik
				{...props}
				ministryUpperLevel={ministryUpperLevel}
				ministryGroups={ministryGroups}
				ministrySubGroups={ministrySubGroups}
				ministryAllGroup={ministryAllGroup}
				isLoading={isLoading}
				defaultOptions={defaultOptions}
			/>
		</div>
	)
}

export default CreateMinistry
