import React from 'react'
import moment from 'moment'
import DefaultIframe from 'components/commons/DefaultIframe'
import ImageIframe from 'components/commons/ImageIframe'
import {ReactComponent as SelectLocation} from 'assets/icons/selectlocation.svg'
import {ReactComponent as ScanIcon} from 'assets/icons/scan.svg'

const EventPreview = ({eventBanner, eventTitle, startDate, endDate, eventSessionDetails, venues, venue, venueName, venueAddressLine, eventDescription}) => {
    // const category = categories.find((item) => item.id === parseInt(eventCategory))
    const venueSelectedName = venue ? venues.find((item) => item.id === parseInt(venue)) : '';

    const renderSessionDetail = (sessionDetail) => {
        let sessionDetailEl = [];

        if (sessionDetail.isAllDay) {
            sessionDetailEl.push(
                <div>All Day</div>
            );
        } else if(sessionDetail.timeStartAt && sessionDetail.timeEndAt) {
            sessionDetailEl.push(
                <div>
                    {moment(sessionDetail.timeStartAt, 'HH:mm').format('HH:mm')}
                    -
                    {moment(sessionDetail.timeEndAt, 'HH:mm').format('HH:mm')}
                </div>
            )
        }

        return sessionDetailEl;
    };

    const renderEventSessionDetails = () => {
        let startEndTimeEl = [];

        eventSessionDetails.forEach((sessionDetail, index) => {
            startEndTimeEl.push(
                <div key={index} className="mt-2">
                    <div className="flex flex-row font-medium">
                        <div>{sessionDetail.date ? moment(sessionDetail.date, 'DD/MM/YYYY').format('DD MMMM YYYY') : 'Date'}</div>
                    </div>
                    <div>
                        {renderSessionDetail(sessionDetail)}
                        {/*{sessionDetail.timeStartAt && sessionDetail.timeEndAt && !sessionDetail.isAllDay?*/}
                        {/*    `${moment(sessionDetail.timeStartAt, 'HH:mm').format('HH:mm')} */}
                        {/*    - */}
                        {/*    ${moment(sessionDetail.timeEndAt, 'HH:mm').format('HH:mm')}`*/}
                        {/*    :*/}
                        {/*    'All Day'*/}
                        {/*}*/}
                    </div>
                </div>
            )
        });

        return startEndTimeEl;
    };

    return (
        <div className="bg-white shadow-md p-6">
            <h3 className="font-bold mb-4">PREVIEW</h3>
            <div className="bg-blueSecondary">
                {!eventBanner && <DefaultIframe/>}
                {eventBanner && <ImageIframe img={eventBanner}/>}
                <div className="p-3 break-words overflow-auto h-64">
                    <div className="font-bold text-xl">{eventTitle || 'Title'}</div>
                    {/* <div className="">{category ? category.title : 'Category'}</div> */}
                    <div className="my-4 border-2 border-dashed"/>
                    <div className="flex -mx-2 text-gray-500 text-xs">
                        <div className="w-1/12 px-2">
                            <i className="far fa-calendar-alt"/>
                        </div>
                        <div className="w-11/12 px-2">
                            <div className="font-bold">
                                {startDate && endDate
                                    ? `${moment(startDate, 'DD/MM/YYYY').format('dddd')} ${startDate !== endDate ? `- ${moment(endDate, 'DD/MM/YYYY').format('dddd')}` : ''}`
                                    : 'Date'}
                            </div>
                            <div className="flex flex-row">
                                <div>{startDate ? moment(startDate, 'DD/MM/YYYY').format('DD MMMM YYYY') : 'Date'}</div>
                                <span className="mx-2">-</span>
                                <div>{endDate ? moment(endDate, 'DD/MM/YYYY').format('DD MMMM YYYY') : 'Date'}</div>
                            </div>
                            <div className="mt-3 font-bold">Event Session Details</div>
                            {renderEventSessionDetails()}
                        </div>
                    </div>
                    <div className="my-4 border-2 border-dashed"/>
                    <div className="flex -mx-2">
                        <div className="w-1/12 px-2">
                            <div>
                                <SelectLocation width="10px" height="14px"/>
                            </div>
                        </div>
                        <div className="w-11/12 px-2">
                            <div
                                className="font-bold text-gray-500 text-xs">{venueSelectedName.campus_name || venueName || 'Venue Name'}</div>
                            {/*<div className="text-gray-500 text-xs">{venueSelectedName.address || venueAddress || 'Address'}</div>*/}
                            {venueAddressLine &&
                            <div className="text-gray-500 text-xs"
                                 dangerouslySetInnerHTML={{__html: venueAddressLine}}/>}
                        </div>
                    </div>
                    <div className="my-4 border-2 border-dashed"/>
                    <div className="flex -mx-2">
                        <div className="w-1/12 px-2">
                            <div>
                                <ScanIcon width="13px" height="13px"/>
                            </div>
                        </div>
                        <div className="w-11/12 px-2">
                            <div className="text-gray-500 text-xs">Check-in will be available 30 minutes prior to event
                                time
                            </div>
                        </div>
                    </div>
                    <div className="my-4 border-2 border-dashed"/>
                    <div className="font-bold text-xs">Description</div>
                    {eventDescription && <div dangerouslySetInnerHTML={{__html: eventDescription}}/>}
                </div>
            </div>
        </div>
    )
}

export default EventPreview
