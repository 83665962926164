import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import queryString from 'query-string'
import {ReactComponent as EventIcon} from 'assets/icons/events.svg'
import Loading from 'components/commons/Loading'
import PaginationPage from 'components/commons/PaginationPage'
import EventListTable from 'components/events/EventListTable'
import Searchbar from 'components/commons/Searchbar'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import {archiveEvent, highglightEvent, unhighlightEvent} from 'libs/apis/events'
import {
    findEventById,
    setEventArchive,
    setEventHighlight,
    setEventUnHighlight,
    updateEvents
} from 'libs/utils/eventshelper'
import {archiveItemConfirm} from 'libs/utils/confirmation'
import {dummyListEvent} from "../../components/events/EventDummyObject";
import {archiveListEvent, getListEvent, getListEventCode} from "../../libs/apis/events";
import {Header} from 'screens/courses/styles';
import {failedMessage, successMessage} from "../../libs/utils/confirmation";

function ListEvent(props) {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const [filterKeyword, setFilterKeyword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [listEvent, setListEvent] = useState(dummyListEvent);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState('created_at');
    const [sortDir, setSortDir] = useState('DESC');

    const breads = [{url: '', title: 'List of All Events'}];

    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const activeLink = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

    const displayLoading = isLoading && !errorMessage;
    const displayError = !isLoading && errorMessage;
    const displayNoData = !isLoading && !errorMessage && listEvent.length === 0;
    const displayData = !isLoading && !errorMessage && listEvent.length > 0;

    const handleOnArchive = async (id) => {
        const confirm = await archiveItemConfirm();
        if (confirm.value) {
            setIsLoading(true);
            try {
                let formdata = new FormData();
                formdata.append('id', id);
                let response = await archiveListEvent(formdata);
                console.log('response: ', response);
                await successMessage();
                handleFetchListEvent();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                handleFetchListEvent();
                await failedMessage(error.message)
                // setErrorMessage(error.message);
            }
        }
    };

    // have not been used
    const handleOnHighlight = (id) => {
        this.setState({isloading: true}, async () => {
            try {
                const {events} = this.state;
                const response = await highglightEvent(id);
                if (response.data.api_status) {
                    const event = findEventById(id, events);
                    const updatedEvent = setEventHighlight(event);
                    const updatedEvents = updateEvents(events, updatedEvent);
                    this.setState({isloading: false, events: updatedEvents})
                } else {
                    this.setState({isloading: false})
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.filterstatus === 401) {
                        await localStorage.removeItem('token');
                        this.setState({isredirect: true})
                    } else {
                        this.setState({
                            isloading: false,
                            error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
                        })
                    }
                }
            }
        })
    };

    const handleOnUnhighlight = (id) => {
        this.setState({isloading: true}, async () => {
            try {
                const {events} = this.state;
                const response = await unhighlightEvent(id);
                if (response.data.api_status) {
                    const event = findEventById(id, events);
                    const updatedEvent = setEventUnHighlight(event);
                    const updatedEvents = updateEvents(events, updatedEvent);
                    this.setState({isloading: false, events: updatedEvents})
                } else {
                    this.setState({isloading: false})
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.filterstatus === 401) {
                        await localStorage.removeItem('token');
                        this.setState({isredirect: true})
                    } else {
                        this.setState({
                            isloading: false,
                            error: 'An unexpected error has occurred. We are currently investigating the issue, please try again later.',
                        })
                    }
                }
            }
        })
    };
    // have not been used

    const handleOnSearch = (event) => {
        setFilterKeyword(event.target.value)
    };


    const handleSeachInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.history.push(`${props.location.pathname}?paginate=10&page=1`);
            setSearchValue(event.target.value);
            setFilterKeyword(event.target.value);
        }
    };

    const handleOnSort = (sort) => {
        if (sortBy !== sort) {
            setSortBy(sort);
            setSortDir('ASC')
        } else {
            if (sortDir === 'ASC') {
                setSortBy(sort);
                setSortDir('DESC')
            } else if (sortDir === 'DESC') {
                setSortBy(sort);
                setSortDir('ASC')
            }
        }
    };

    const {search} = props.location;
    const totalListPerPage = 10;
    const typeModule = 'Event';
    const pageNumber = search ? queryString.parse(search).page : 1;

    useEffect(() => {
        handleFetchListEvent()
    }, [totalListPerPage, searchValue, sortBy, sortDir, pageNumber]);

    const formattingListEvent = (eventData) => {
        console.log('eventData: ', eventData);
        return {
            id: eventData.id,
            title: eventData.name ?? 'unknown',
            startDate: eventData.programstart_at ?? 'unknown',
            endDate: eventData.programend_at ?? 'unknown',
            createdOn: eventData.created_at ?? 'unknown',
            createdBy: eventData.creator ? eventData.creator.fullname : 'unknown',
            lastUpdated: eventData.updated_at ?? 'unknown',
            status: eventData.status ?? 'unknown',
            isHighlight: eventData.ishighlight ?? 'unknown'
        }
    };

    const handleFetchListEvent = async () => {
        setIsLoading(true);
        try {
            let response = await getListEvent(totalListPerPage, searchValue, sortBy, sortDir, typeModule, pageNumber);
            let listEventData = response.data.data.programs.data;
            let currentPage = response.data.data.programs.current_page;
            let lastPage = response.data.data.programs.last_page;

            if (listEventData) {
                let listEvent = [];
                for (const eventData of listEventData) {
                    listEvent.push(
                        formattingListEvent(eventData)
                    )
                }
                setListEvent(listEvent);

                setCurrentPage(currentPage);
                setLastPage(lastPage);
                setIsLoading(false);
            } else {
                setListEvent([])
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
            <Helmet>
                <title>{name} - List of All Events</title>
            </Helmet>
            <PageBreadcrumbs icon={EventIcon} title="List of All Events" breads={breads}/>

            <Header>
                <Searchbar filterKeyword={filterKeyword}
                           handleOnSearch={handleOnSearch}
                           handleSeachInputKeyPress={handleSeachInputKeyPress}
                           placeholder="Search by ID or Event Title"
                />
            </Header>

            {displayLoading && <Loading/>}
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayNoData && <div className="text-center">No Data available</div>}
            {displayData && (
                <div>
                    <EventListTable
                        listEvent={listEvent}
                        handleOnSort={handleOnSort}

                        handleOnArchive={handleOnArchive}
                        handleOnHighlight={handleOnHighlight}
                        handleOnUnhighlight={handleOnUnhighlight}
                    />
                    <PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={activeLink}
                                    length={listEvent.length} url={location.pathname}/>
                </div>
            )}
        </div>
    )
}

export default ListEvent
