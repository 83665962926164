import React from 'react'
import {Link} from 'react-router-dom'
import QRCode from 'qrcode.react'
import moment from "moment";

const EventQRCodesList = ({title, code, startTime, endTime}) => {
    return (
        <div className="w-1/3 mb-4 px-2">
            <div className="border-2 border-greySecondary">
                <div className="flex p-4">
                    <div className="px-2">
                        {/*<QRCode value={code}/>*/}
                        <img src={`data:image/svg+xml;base64,${code}`} width="100" height="100" />
                    </div>
                    <div>
                        <h3 className="font-bold text-lg">{title}</h3>
                        {moment(startTime, 'YYYY-MM-DD HH:mm').format('HH:mm')}
                        -
                        {moment(endTime, 'YYYY-MM-DD HH:mm').format('HH:mm')}
                    </div>
                </div>
                <div className="bg-greySecondary px-4 py-2">
                    <div className="flex justify-end">
                        <Link target="_blank" to={`/view-qr/${title}/${code}/${startTime}/${endTime}`} className="bg-white p-2">
                            View QR
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EventQRCodesList
