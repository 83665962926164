import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useParams, Link, withRouter } from 'react-router-dom'
import ReactQuill from 'react-quill'
import moment from 'moment'

import 'react-quill/dist/quill.snow.css'
import { getDetailSmallGroup, exportSmallGroupMemberById, getMembersSmallGroup } from 'libs/apis/smallGroup'

import photoplaceholder from 'assets/images/photo-placeholder.png'
import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'

import Button from 'components/commons/Button'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import TransferMember from 'components/smallGroup/TransferMember'
import Loading from 'components/commons/Loading'
import alert from 'services/alert'
import { RoleContainer, RoleRibbon } from '../../screens/smallGroup/styles'
import { SmallGroupHeader } from '../../screens/smallGroup/styles'
import { converDate } from 'libs/utils/utils'

function ViewSmallGroup() {
	const [leaderDetails, setLeaderDetails] = useState({})
	const [sgDetails, setSgDetails] = useState({})
	const [multiplicationDetails, setMultiplicationDetails] = useState()
	const [sgParentDetails, setSgParentDetails] = useState()
	const [leaders, setLeaders] = useState([])
	const [pengganti, setPengganti] = useState([])

	const [sgMembers, setSgMembers] = useState([])
	const [sgRequest, setSgRequest] = useState([])
	const [sgTransfer] = useState([])
	const [sgProbations] = useState([])

	const [openCard, setOpenCard] = useState({
		members: true,
		transfers: false,
		requests: false,
		probations: false,
	})
	const [isRedirect] = useState(false)
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [errorMessage] = useState('')
	const { id } = useParams()

	const handleTogle = (event) => {
		setOpenCard({ ...openCard, [event.target.id]: !openCard[event.target.id] })
	}

	useEffect(() => {
		fetchViewSmallGroupMember()
	}, [id])

	useEffect(() => {
		fetchMembersSmallGroup()
	}, [id])

	const fetchViewSmallGroupMember = async () => {
		setIsLoadingData(true)

		try {
			let res = await getDetailSmallGroup(id)

			if (res.data.api_status) {
				let sg = res.data.data
				if (sg !== null) {
					setSgDetails({
						name: sg.smallgroup.name,
						level: sg.smallgroup.smallgroupleader.smallgroupmemberrole.name,
						campus: sg.smallgroup.campus,
						category: sg.smallgroup.smallgroupcategory.name,
						ageRange: `${sg.smallgroup.agemin} - ${sg.smallgroup.agemax}`,
						fullAddress: sg.smallgroup.addressfull,
						shortAddress: sg.smallgroup.address,
						day: sg.smallgroup.appointmentdefaultday,
						time: sg.smallgroup.appointmentdefaulttime,
						status: sg.smallgroup.status,
						established_in: sg.smallgroup.established_at,
						description: sg.smallgroup.description,
					})

					if (sg.smallgroup.smallgroupleader !== null && sg.smallgroup.smallgroupleader2 !== null) {
						setLeaderDetails({
							leader1: sg.smallgroup.smallgroupleader,
							leader2: sg.smallgroup.smallgroupleader2,
						})
					} else {
						setLeaderDetails({
							leader1: sg.smallgroup.smallgroupleader,

							leader2: null,
						})
					}

					setMultiplicationDetails(sg.smallgroup_multiplicatorlist)
					setSgParentDetails({
						facilitator: sg.smallgroupmember_upperleader,
						facilitatorGroup: sg.smallgroup_leadersmallgroup,
						headFacilitator: sg.smallgroupmember_upperupperleader,
						headFacilitatorGroup: sg.smallgroup_upperleadersmallgroup,
					})
					setSgRequest(sg.smallgroup.smallgrouprequests)
				} else {
					setSgDetails({})
					setLeaderDetails({})
					setLeaderDetails({})
					setSgParentDetails({})
					setSgMembers([])
					setSgRequest([])
				}
				setIsLoadingData(false)
			}
		} catch (error) {
			setIsLoadingData(false)
		}
	}

	const fetchMembersSmallGroup = async () => {
		try {
			const res = await getMembersSmallGroup(id)

			const tempPengganti = []
			const leaders = []

			if (res.data.data.smallgroupmember_leader) {
				if (res.data.data.smallgroupmember_leader.user2) {
					leaders[0] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,
						iscoreteam: true,
						...res.data.data.smallgroupmember_leader.user,
					}
					leaders[1] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,
						iscoreteam: true,
						...res.data.data.smallgroupmember_leader.user2,
					}
				} else {
					leaders[0] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,
						iscoreteam: true,
						...res.data.data.smallgroupmember_leader.user,
					}
				}
			} else {
				setLeaders([])
			}

			const tempuser1 = res.data.data.smallgroupmembers.map((val) => {
				const temp = {
					smallgroupmemberrole: val.smallgroupmemberrole,
					iscoreteam: val.iscoreteam,
					...val.user,
				}
				return temp
			})

			const tempuser2 = res.data.data.smallgroupmembers
				.map((val) => {
					if (val.user2) {
						const temp = {
							smallgroupmemberrole: val.smallgroupmemberrole,
							iscoreteam: val.iscoreteam,
							...val.user2,
						}
						return temp
					} else {
						return
					}
				})
				.filter((val) => val !== undefined)

			if (res.data.data.smallgroupmember_leader2) {
				if (res.data.data.smallgroupmember_leader2.user2) {
					tempPengganti[0] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,

						...res.data.data.smallgroupmember_leader2.user,
					}

					tempPengganti[1] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,

						...res.data.data.smallgroupmember_leader2.user2,
					}
				} else {
					tempPengganti[0] = {
						smallgroupmemberrole: res.data.data.smallgroupmember_leader.smallgroupmemberrole,

						...res.data.data.smallgroupmember_leader2.user,
					}
				}
			} else {
				setPengganti([])
			}

			const temp = tempuser1.concat(tempuser2)

			setSgMembers(temp)
			setLeaders(leaders)
			setPengganti(tempPengganti)
		} catch (error) {}
	}

	const handleExportSmallGroup = async () => {
		let res = await alert.confirmExportSmallGroup()
		if (res.value) {
			try {
				let res = await exportSmallGroupMemberById(id)
				if (res.data.api_status) {
					const link = document.createElement('a')
					link.href = res.data.data.path
					link.download = res.data.data.path
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				}
			} catch (error) {
				alert.failed('Failed', 'There is an issue. Cannot export this small group members')
			}
		}
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	const displayLoading = isLoadingData
	const displayError = !isLoadingData && errorMessage
	const displayData = !isLoadingData
	const breads = [
		{ url: '/smallgroup/all/filtered', title: 'List of All Groups' },
		{ url: '', title: 'View Group' },
	]

	if (isRedirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - View Group</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="View Group" breads={breads} />
			<SmallGroupHeader>
				<Link
					to={{
						pathname: `/smallgroup/list-appointments/${id}`,
					}}
				>
					<Button type={'tertiary'}>Appointments</Button>
				</Link>
				<Button style="ml-6" type={'tertiary'} handleClick={handleExportSmallGroup}>
					Export
				</Button>
				<TransferMember sgMembers={sgMembers} smallgroupId={id} refresh={fetchViewSmallGroupMember} />
			</SmallGroupHeader>
			{displayLoading && <Loading />}
			{displayData && (
				<div className="flex mb-4 mt-4">
					<div className="w-1/4">
						<div className="bg-white shadow-md rounded p-4">
							<div className="font-bold mb-2">{sgDetails.name !== undefined ? sgDetails.name : ''}</div>

							<SGLeaderDetails leaderDetails={leaderDetails} />

							<hr className="w-full border-2 border-blueSecondary" />

							<SGDetail sgDetails={sgDetails} />

							<hr className="w-full border-2 border-blueSecondary mt-2" />

							<SGMultiplicationDetails sgParentDetails={sgParentDetails} />
						</div>
					</div>

					<div className="ml-4 md:w-full">
						<SGMemberProbationRequest
							smallgroupId={id}
							leaders={leaders}
							pengganti={pengganti}
							members={sgMembers}
							requests={sgRequest}
							probations={sgProbations}
							transfers={sgTransfer}
							openCard={openCard}
							handleTogle={handleTogle}
						/>
					</div>
				</div>
			)}
			{displayError && <div className="align-center">{errorMessage}</div>}
		</div>
	)
}

export default withRouter(ViewSmallGroup)

const SGLeaderDetails = ({ leaderDetails }) => {
	const { leader1, leader2 } = leaderDetails

	return (
		<>
			<div className="font-bold mb-2 mt-3">LEADER DETAILS</div>

			<div>
				<label className="text-sm font-bold">Small Group Leader</label>

				{leader1 && <p className="text-sm">{leader1.user.fullname}</p>}
				{leader1 && <p className="text-sm">{leader1.user2 ? leader1.user2.fullname : ''}</p>}

				{leader2 && <p className="text-sm">{leader2.user.fullname}</p>}
				{leader2 && <p className="text-sm">{leader2.user2 ? leader2.user2.fullname : ''}</p>}
			</div>
		</>
	)
}

const SGDetail = ({ sgDetails }) => {
	const { level, campus, category, ageRange, fullAddress, shortAddress, day, status, established_in, description, time } = sgDetails
	let a = moment().day(parseInt(day)).format('dddd')

	return (
		<>
			<div className="font-bold mb-2 mt-3">SMALL GROUP DETAIL</div>

			<div>
				<label className="text-sm font-bold">Campus</label>
				<p className="text-sm mb-4">{campus ? campus.name : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Level</label>
				<p className="text-sm mb-4">{level ? level : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Category</label>
				<p className="text-sm mb-4">{category ? category : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Age Range</label>
				<p className="text-sm mb-4">{ageRange ? ageRange : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Full Address</label>
				<div className="text-sm mb-4 break-words">{fullAddress ? fullAddress : ''}</div>
			</div>

			<div>
				<label className="text-sm font-bold">Short Address</label>
				<p className="text-sm mb-4">{shortAddress ? shortAddress : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Day | Time</label>
				<p className="text-sm mb-4">
					{a} | {time}
				</p>
			</div>

			<div>
				<label className="text-sm font-bold">Status</label>
				<p className="text-sm mb-4">{status !== undefined ? status : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Established in</label>
				<p className="text-sm mb-4">{established_in ? converDate(established_in) : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Small Group Description</label>
				<ReactQuill modules={{ toolbar: false }} className="text-sm h-24 -ml-4 h-auto" theme={null} value={description} formats={null} />
			</div>
		</>
	)
}

const SGMultiplicationDetails = ({ sgParentDetails }) => {
	if (sgParentDetails === undefined) {
		return <></>
	}

	return (
		<>
			<hr className="border-2 border-blueSecondary mt-2" />
			<div className="font-bold mb-2 mt-3">SMALL GROUP PARENT DETAIL</div>
			<div>
				<label className="text-sm font-bold">Facilitator</label>
				<p className="text-sm">{sgParentDetails.facilitator !== null ? sgParentDetails.facilitator.user.fullname : ''}</p>
				<p className="text-sm mb-4">{sgParentDetails.facilitator !== null && sgParentDetails.facilitator.user2 ? sgParentDetails.facilitator.user2.fullname : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Facilitator Group</label>
				<p className="text-sm mb-4">{sgParentDetails.facilitatorGroup ? sgParentDetails.facilitatorGroup.name : ''}</p>
			</div>

			<div>
				<label className="text-sm font-bold">Head Facilitator</label>
				<p className="text-sm"></p>
				<p className="text-sm mb-4"></p>
			</div>

			<div>
				<label className="text-sm font-bold">Head Facilitator Group</label>
				<p className="text-sm"></p>
				<p className="text-sm mb-4"></p>
			</div>
		</>
	)
}

const SGMemberProbationRequest = ({ smallgroupId, openCard, handleTogle, members, leaders, pengganti, requests, probations, transfers }) => {
	const res1 = leaders.concat(pengganti)

	const memberstemp = members
		.filter((val) => val.iscoreteam)
		.map((val) => {
			const temp = {
				iscoreteam: val.iscoreteam,
				smallgroupmemberrole: {
					shortname: 'CT',
					admincolor1: val.smallgroupmemberrole.admincolor1,
				},
				fullname: val.fullname,
				email: val.email,
				id: val.id,
			}
			return temp
		})

	const memberstemp2 = members.filter((val) => !val.iscoreteam)

	const reqtemp = requests.map((val) => {
		const temp = {
			...val.smallgroupmember.user,
			smallgroupmemberrole: val.smallgroupmemberrole_id
				? val.smallgroupmemberrole
				: {
						admincolor1: 'white',
						shortname: '',
				  },
		}

		return temp
	})

	const res2 = res1.concat(memberstemp, memberstemp2)

	return (
		<div>
			<hr className="w-full border-2 border-gray-300 mx-4" />

			<div className="flex justify-between">
				<h4 className="font-bold">USERS ({res2.length})</h4>
				<i id="members" className={`fa ${openCard.members ? 'fa-angle-up' : 'fa-angle-down'} text-3xl flex justify-center text-lg`} onClick={handleTogle} />
			</div>

			{openCard.members && (
				<div className="flex flex-row flex-wrap">{res2.length !== 0 ? res2.map((member, idx) => <Card key={idx} data={member} smallgroupId={smallgroupId} />) : <></>}</div>
			)}

			<hr className="w-full border-2 border-gray-300 mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">NEW TRANSFER ({transfers.length})</h4>
					<i id="transfers" className={`fa ${openCard.transfers ? 'fa-angle-up' : 'fa-angle-down'} text-3xl flex justify-center text-lg`} onClick={handleTogle} />
				</div>
				{openCard.transfers && (
					<div className="flex flex-row flex-wrap">
						{transfers.length !== 0 && transfers.map((transfer, idx) => <Card key={idx} data={transfer} smallgroupId={smallgroupId} />)}
					</div>
				)}
			</div>

			<hr className="w-full border-2 border-gray-300 mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">NEW REQUEST ({requests.length})</h4>
					<i id="requests" className={`fa ${openCard.requests ? 'fa-angle-up' : 'fa-angle-down'} text-3xl flex justify-center text-lg`} onClick={handleTogle} />
				</div>
				{openCard.requests && (
					<div className="flex flex-row flex-wrap">{reqtemp.length !== 0 && reqtemp.map((request, idx) => <Card key={idx} data={request} smallgroupId={smallgroupId} />)}</div>
				)}
			</div>

			<hr className="w-full border-2 border-gray-300 mx-4" />

			<div>
				<div className="flex justify-between">
					<h4 className="font-bold">PROBATIONS ({probations.length})</h4>
					<i id="probations" className={`fa ${openCard.probations ? 'fa-angle-up' : 'fa-angle-down'} text-3xl flex justify-center text-lg`} onClick={handleTogle} />
				</div>
				{openCard.probations && (
					<div className="flex flex-row flex-wrap">
						{probations.length !== 0 && probations.map((probation, idx) => <Card key={idx} data={probation} smallgroupId={smallgroupId} />)}
					</div>
				)}
			</div>
		</div>
	)
}

const Card = (props) => {
	const { data } = props

	const ct = data.iscoreteam ? data.smallgroupmemberrole.shortname : 'CT'

	const color = data.iscoreteam ? data.smallgroupmemberrole.admincolor1 : 'white'

	return (
		<div key={data.id} className="flex flex-col ml-6 mb-4">
			<Link
				to={{
					pathname: `/small-group/view-member/${data.id}`,
					state: props.smallgroupId,
				}}
			>
				<div className="shadow-lg bg-white relative cursor-pointer" style={{ width: '250px' }}>
					<RoleContainer color={color}>
						<RoleRibbon>{ct}</RoleRibbon>
					</RoleContainer>

					<div className="pt-4 flex justify-center">
						<img src={data.document ? data.document.url : photoplaceholder} className="rounded-full flex items-center justify-center w-24 h-24" alt="ministryMember" />
					</div>
					<div className="font-bold text-center my-2">{data.fullname}</div>

					<hr className="border-2 border-blueSecondary mx-4" />
					<div className="flex text-center flex-col mb-4 pb-4">
						<p className="text-sm">{data.email}</p>
					</div>
				</div>
			</Link>
		</div>
	)
}
