import React from 'react'
import moment from 'moment'

const CourseInfoCard = ({ color, courseName, startDate, endDate, startTime, endTime, venueName, venueAddress, isEvent, handleLinkBannerChange }) => {
	const styleLinkBanner = "text-blue-500 underline cursor-pointer ";

	return (
		<div className="flex bg-white shadow-md rounded-lg" style={{ height: '330px' }}>
			<div className="w-5 mr-5">
				<div className={`${color} rounded-l-lg h-full`} />
			</div>
			<div className="w-10/12">
				<div className="py-4 break-words">
					<div>
						<div className="flex justify-between">
							<label className="font-bold text-sm">{isEvent ? 'Event Name:' : 'Code & Class Name:'}</label>
							<a className={styleLinkBanner.concat(isEvent ? '' : 'invisible')} target="_blank" rel="noopener noreferrer"
							   onClick={handleLinkBannerChange}
							  >View Event Banner</a>
						</div>
						<p>{courseName}</p>

					</div>
					<div className="mt-2">
						<label className="font-bold text-sm">Date & Time:</label>
						{startDate && endDate && <div>{`${moment(startDate, 'YYYY-MM-DD HH:mm').format('DD MMMM YYYY')} - ${moment(endDate, 'YYYY-MM-DD HH:mm').format('DD MMMM YYYY')}`}</div>}
						<div>{startTime && endTime ? `${startTime} - ${endTime}` : ''}</div>
					</div>
					<div className="mt-2">
						<label className="font-bold text-sm">Venue:</label>
						<p>{venueName}</p>
						<p>{venueAddress}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CourseInfoCard
