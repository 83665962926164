import {withFormik} from 'formik'
import EditEventForm from "../forms/EditEventForm";
import {EventValidationSchema} from "./EventValidationSchema";
import {editEvent} from "../../libs/apis/events";
import {failedMessage, somethingWrong, successMessage} from "../../libs/utils/confirmation";

const EditEventFormik = withFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: (props) => ({
        eventId: '',
        eventTitle: '',
        eventDescription: '',
        speaker: '',
        termConditionCheck: false,
        eventTermCondition: '',
        eventStartDate: '',
        eventEndDate: '',
        eventSessionDetails: [],
        programCodeId: '',
        programTypeId: '',
        venue: '',
        venueName: '',
        venueAddressLine: '',
        eventBannerCheck: false,
        eventBannerImage: '',
        registrationCheck: false,
        registrationStartDate: '',
        registrationEndDate: '',
        quota: 0,
        checkInCheck: false,
        startSharingDate: '',
        endSharingCheck: false,
        endSharingDate: '',
        sendCampaignCheck: false,
        campaignType: '',
        highlightCheck: false,
        eventPaymentCheck: false,
        eventPayment: false,
        pricePerRoleCheck: false,
        participants: [],
        priceAllRoles: 0,
        additionalInformation: '',
        isDraft: false,
        isDateValid: true,
        listRoles: [],
        volunteers: [],
        status: ''
    }),
    validationSchema: EventValidationSchema,
    handleSubmit: async (values, {props}) => {
        const {
            eventId,
            eventTitle,
            eventDescription,
            speaker,
            eventStartDate,
            eventEndDate,
            venue,
            venueName,
            venueAddressLine,
            startSharingDate,
            endSharingCheck,
            endSharingDate,
            additionalInformation,
            programCodeId,
            programTypeId,
            quota,
            eventSessionDetails,
            participants,
            pricePerRoleCheck,
            priceAllRoles,
            highlightCheck,
            registrationCheck,
            registrationStartDate,
            registrationEndDate,
            checkInCheck,
            eventTermCondition,
            eventBannerImage,
            isDateValid,
            sendCampaignCheck,
            campaignType,
            listRoles,
            volunteers,
            status
        } = values;

        let formdata = new FormData();

        let updatedVolunteers = null;
        if (checkInCheck && volunteers.length > 0) {
            updatedVolunteers = volunteers.map((v) => v.value).join();
        }

        let toUpdatedSessionDetails = [...eventSessionDetails];
        const toUpdatedIndexes = [];
        toUpdatedSessionDetails.forEach((session, index) => {
            if (session.isAllDay === 1) {
                toUpdatedIndexes.push(index);
            }
        });

        for (const index of toUpdatedIndexes) {
            const toUpdatedObject = toUpdatedSessionDetails[index];

            const updatedObject = Object.assign({}, toUpdatedObject, {
                timeStartAt: null,
                timeEndAt: null
            });

            toUpdatedSessionDetails = [
                ...toUpdatedSessionDetails.slice(0, index),
                updatedObject,
                ...toUpdatedSessionDetails.slice(index + 1)
            ];
        }

        let campaignByMail = 0;
        let campaignByNotification = 0;

        if (sendCampaignCheck) {
            if (campaignType === 'cpg0') {
                campaignByMail = 1;
                campaignByNotification = 0;
            } else if (campaignType === 'cpg1') {
                campaignByMail = 0;
                campaignByNotification = 1;
            } else if (campaignType === 'cpg2') {
                campaignByMail = 1;
                campaignByNotification = 1;
            } else {
                campaignByMail = 0;
                campaignByNotification = 0;
            }
        }

        let updatedParticipants = [];

        if (participants.length > 0) {
            for (const participant of participants) {
                const splittedId = participant.id.split('.');
                updatedParticipants.push(
                    {
                        smallgroupmemberrole_id: participant.id.includes('smallgroupmemberrole_id') ? splittedId[1] : null,
                        userrole_id: participant.id.includes('userrole_id') ? splittedId[1] : null,
                        ministrymemberrole_id: participant.id.includes('ministrymemberrole_id') ? splittedId[1] : null,
                        price: participant.price
                    }
                );
            }
        } else {
            updatedParticipants.push(
                {
                    smallgroupmemberrole_id: null,
                    userrole_id: null,
                    ministrymemberrole_id: null,
                    price: pricePerRoleCheck ? 0 : priceAllRoles
                }
            )
        }

        let updatedRegistrationStartDate = registrationStartDate;
        let updatedRegistrationEndDate = registrationEndDate;

        if (!registrationCheck) {
            updatedRegistrationStartDate = '';
            updatedRegistrationEndDate = '';
        }

        let updatedEndSharingDate = endSharingDate;
        if (!endSharingCheck) {
            updatedEndSharingDate = ''
        }


        formdata.append('id', eventId);
        formdata.append('priceList', JSON.stringify(updatedParticipants));
        formdata.append('dateList', JSON.stringify(toUpdatedSessionDetails));
        formdata.append('title', eventTitle);
        formdata.append('description', eventDescription);
        formdata.append('programStartAt', eventStartDate);
        formdata.append('programEndAt', eventEndDate);
        formdata.append('location', venueName);
        formdata.append('locationAddress', venueAddressLine);
        formdata.append('minimumSession', 0);
        formdata.append('startSharingAt', startSharingDate);
        formdata.append('endSharingAt', updatedEndSharingDate);
        formdata.append('additionalInformation', additionalInformation);
        formdata.append('programCodeId', programCodeId);
        formdata.append('maxSeating', quota);
        formdata.append('speaker', speaker);
        formdata.append('campusRoomId', venue);
        formdata.append('isHighlight', highlightCheck ? 1 : 0);
        formdata.append('registerStartAt', updatedRegistrationStartDate);
        formdata.append('registerEndAt', updatedRegistrationEndDate);
        formdata.append('reqCheckIn', checkInCheck ? 1 : 0);
        formdata.append('termCondition', eventTermCondition);
        formdata.append('banner', eventBannerImage);
        formdata.append('programTypeId', programTypeId);
        formdata.append('status', status);
        // formdata.append('campaignByMail', campaignByMail);
        // formdata.append('campaignByNotification', campaignByNotification);
        formdata.append('volunteerList', updatedVolunteers);

        try {
            if (isDateValid) {
                let res = await editEvent(formdata);
                if (res.data.api_status === true) {
                    successMessage();
                    props.history.push('/events/list');
                } else {
                    failedMessage(res.data.message)
                }
            }
        } catch (error) {
            somethingWrong();
        }
    },
})(EditEventForm);

export default EditEventFormik
