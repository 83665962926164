import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {partial} from "../../libs/utils/utils";
import moment from "moment";
import Tippy from "@tippy.js/react";

const ListEventCodeTable = ({listEventCode, handleOnSort}) => {
    const handleSortByCode = partial(handleOnSort, 'codename');
    const handleSortByEventName = partial(handleOnSort, 'name');
    const handleSortByCreatedOn = partial(handleOnSort, 'created_at');
    const handleSortByLastUpdated = partial(handleOnSort, 'updated_at');

    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [indexDropDownOpen, setIndexDropDownOpen] = useState(0);

    const styleTableRow = `border border-solid bt-blueSecondary`;
    const styleDropDown = 'z-20 shadow-md absolute right-20px ';
    const styleNullData = ' text-red-500 text-xs italic';

    const dropdownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, []);

    const handleClickOutside = (event) => {
        if (String(event.target).includes('/events/edit-code/')) {
            return;
        }
        setDropDownOpen(false)
    };

    const handleEllipsisClick = (index) => {
        setDropDownOpen(!dropDownOpen);
        setIndexDropDownOpen(index);
    };

    const renderListEventCodeHead = () => {
        return (
            <thead className="bt-blueSecondary py-4  uppercase text-gray">
            <tr>
                <th className="px-2 py-4"/>
                <th className="px-4 py-4">
                    CODE
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByCode}/>
                </th>
                <th className="px-4 py-4">
                    EVENT NAME
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByEventName}/>
                </th>
                <th className="px-4 py-4">
                    CREATED ON
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByCreatedOn}/>
                </th>
                <th className="px-4 py-4">
                    LAST UPDATED
                    <i className="fas fa-sort sortby cursor-pointer ml-1" onClick={handleSortByLastUpdated}/>
                </th>
            </tr>
            </thead>
        );
    };

    const renderListEventCodeBody = () => {
        let listEventCodeBodyEl = [];

        listEventCode.forEach((eventCode, index) => {
            const styleTdStatus = eventCode.isHighlight ? 'relative mr-6' : 'absolute mr-6';
            const styleStatusArchive = `${eventCode.status === 'Archived' ? 'opacity-50' : 'opacity-100'}`;

            listEventCodeBodyEl.push(
                <tr className={styleTableRow} key={index}>
                    <td className={styleTdStatus}>
                        {eventCode.status === 'Draft' && (
                            <Tippy content="Draft">
                                <div className="status-post-tag">
                                    <div className="ribbon-black-triagle"/>
                                </div>
                            </Tippy>
                        )}
                        {eventCode.status === 'Archived' && (
                            <Tippy content="Archived">
                                <div className="status-post-tag">
                                    <div className={styleStatusArchive}>
                                        <div className="ribbon-black-triagle"/>
                                    </div>
                                </div>
                            </Tippy>
                        )}
                        {eventCode.isHighlight && (
                            <Tippy content="Highlight" theme="star">
                                <i className="fas fa-star text-yellow-300 self-center flex"/>
                            </Tippy>
                        )}
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div
                            className={styleStatusArchive.concat(eventCode.codeName === 'unknown' ? styleNullData : '')}>
                            {eventCode.codeName}
                        </div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>{eventCode.eventName}</div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            {
                                eventCode.createdOn === 'unknown' ?
                                    <div className={styleNullData}>
                                        {eventCode.createdOn}
                                    </div>
                                    :
                                    <div>{moment(eventCode.createdOn, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                            }
                        </div>
                    </td>
                    <td className="px-2 py-4 text-center">
                        <div className={styleStatusArchive}>
                            {
                                eventCode.lastUpdated === 'unknown' ?
                                    <div>
                                        <div className={styleNullData}>{eventCode.lastUpdated}</div>
                                        <div className={styleNullData}>{eventCode.lastUpdated}</div>
                                    </div>
                                    :
                                    <div>
                                        <div>{moment(eventCode.lastUpdated, 'YYYY/MM/DD HH:mm').format('DD MMM YYYY')}</div>
                                        <div
                                            className="text-primary">{moment(new Date(eventCode.lastUpdated), 'DD/MM/YYYY HH:mm').fromNow()}</div>
                                    </div>
                            }
                        </div>
                    </td>

                    <td className="px-2 py-4">
                        <div className="cursor-pointer" onClick={() => handleEllipsisClick(index)}>
                            <i className="dropdown-trigger fas fa-ellipsis-v"/>
                            <div
                                className={index === indexDropDownOpen && dropDownOpen ? styleDropDown.concat('block') : styleDropDown.concat('hidden')}>
                                <ul ref={dropdownRef} className="bg-white">
                                    <li className="px-4 py-2 block hover:bg-darkSkyBlue text-left">
                                        <Link
                                              to={`/events/edit-code/${eventCode.id}`}>
                                            View & Edit
                                        </Link>
                                    </li>
                                    {/*<li className="px-4 py-2 block hover:bg-darkSkyBlue text-left" onClick={() => {*/}
                                    {/*}}>*/}
                                    {/*    Delete*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        });

        return listEventCodeBodyEl;
    };

    return (
        <div className="bg-white shadow-md rounded">
            <table className="w-full">
                {renderListEventCodeHead()}
                <tbody>
                    {renderListEventCodeBody()}
                </tbody>
            </table>
        </div>
    )
};

export default ListEventCodeTable
