import { withFormik } from 'formik'
import { EditMinistrySchema } from './MinistryValidationSchema'
import EditMinistryForm from 'components/forms/EditMinistryForm'
import alert from 'services/alert'
import { failedMessage } from 'libs/utils/confirmation'
import { editMinistry } from 'libs/apis/ministry'

const EditMinistryFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		ministryName: props.ministryName,
		ministryLevel: props.ministryLevel,
		ministryImageURL: props.ministryImageURL,
		imagePreview: props.ministryImageURL,
		ministryUpperLevel: props.ministryUpperLevel,
		ministryHeads: props.ministryHeads,
		volunteers: props.volunteers,
		ministryDescription: props.ministryDescription,
		ministryStatus: props.ministryStatus,
		ministryGender: props.ministryGender,
		keyVolunteers: props.keyVolunteers,
	}),
	validationSchema: EditMinistrySchema,
	async handleSubmit(values, { props, setSubmitting }) {
		const formdata = new FormData()
		formdata.append('mId', props.mId)
		formdata.append('mChurchId', 1)
		formdata.append('mName', values.ministryName)
		formdata.append('mDescription', values.ministryDescription)
		formdata.append('mGender', values.ministryGender.value)
		formdata.append('mStatus', values.ministryStatus.value)

		if (values.ministryLevel.value == 'SubGroup') {
			formdata.append('mSubgroupId', values.ministryUpperLevel.value)
		}

		if (values.ministryLevel.value == 'Ministry') {
			formdata.append('mGroupId', values.ministryUpperLevel.value)
		}

		if (typeof values.ministryImageURL === 'string') {
			formdata.append('mImage', '')
		}

		if (typeof values.ministryImageURL === 'object') {
			formdata.append('mImage', values.ministryImageURL)
		}
		try {
			const res = await editMinistry(formdata)
			if (res.data.api_status) {
				alert.succeed('Success')
				props.history.push('/ministry')
			} else {
				failedMessage(res.data.message)
			}
		} catch (error) {
		}
	},
})(EditMinistryForm)

export default EditMinistryFormik
