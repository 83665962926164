import React from 'react'
import Loading from 'components/commons/Loading'
import numeral from 'numeral'

const ReportCard = ({ title, value, isLoading, isPrevious, previousValue, titleHelper }) => {
	const renderValue = () => {
		let style = 'inline-block'
		if (previousValue < 0) {
			style += 'text-red-500'
		} else if (previousValue > 0) {
			style += 'text-green-500'
		} else {
			style += 'text-black'
		}

		let formatValue = numeral(previousValue).format('0.0[0000]')

		return (
			<div>
				<div className={style}>
					{previousValue < 0 && <i className="fas fa-sort-down" />}
					{previousValue > 0 && <i className="fas fa-sort-up" />}
					{` ${formatValue}`}
				</div>
				{` ${titleHelper}`}
			</div>
		)
	}

	return (
		<div className="md:w-1/3 md:mx-2 w-full rounded-lg mb-6 md:mb-0 bg-white shadow-md p-6 items-center">
			<div className="flex">
				<div className="font-bold w-1/2">{title}</div>
				<div className="font-bold text-5xl w-1/2 text-right">{isLoading ? <Loading /> : value}</div>
			</div>
			{isPrevious && <div>{isLoading ? <Loading /> : renderValue()}</div>}
		</div>
	)
}

export default ReportCard
