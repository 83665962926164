import * as Yup from 'yup'

export const EditMultiplicationValidationSchema = () =>
	Yup.object().shape({
		smallGroupCampus: Yup.number().nullable().required('Required'),
		smallGroupLevel: Yup.string().nullable().required('Required'),
		parentSmallGroup: Yup.string().required('Required'),
		name: Yup.string().required('Required'),
		category: Yup.string().required('Required'),
		smallGroupLeader: Yup.string().required('Required'),
		smallGroupUpperLevelLeaderName: Yup.object()
			.shape({
				value: Yup.string().required('Required'),
				label: Yup.string().required('Required'),
			})
			.nullable()
			.required('Required'),
		fullAddress: Yup.string().nullable().required('Required'),
		shortAddress: Yup.string().nullable().required('Required'),
		region: Yup.object()
			.shape({
				value: Yup.string().required('Required'),
				label: Yup.string().required('Required'),
			})
			.nullable()
			.required('Required'),
		postalCode: Yup.string().nullable().required('Required'),
		day: Yup.string().nullable().required('Required'),
		time: Yup.string().nullable().required('Required'),
		status: Yup.string().nullable().required('Required'),
		established_date: Yup.string().nullable().required('Required'),
		description: Yup.string().nullable().required('Required'),
	})

export const CreateMultiplicationValidationSchema = () =>
	Yup.object().shape({
		smallGroupCampus: Yup.number().nullable().required('Required'),
		smallGroupLevel: Yup.string().nullable().required('Required'),
		parentSmallGroup: Yup.string().required('Required'),
		name: Yup.string().required('Required'),
		category: Yup.string().required('Required'),
		smallGroupLeader: Yup.string().required('Required'),
		smallGroupUpperLevelLeaderName: Yup.string().nullable().required('Required'),
		fullAddress: Yup.string().nullable().required('Required'),
		shortAddress: Yup.string().nullable().required('Required'),
		region: Yup.string().nullable().required('Required'),
		postalCode: Yup.string().nullable().required('Required'),
		day: Yup.string().nullable().required('Required'),
		time: Yup.string().nullable().required('Required'),
		status: Yup.string().nullable().required('Required'),
		established_date: Yup.string().nullable().required('Required'),
		description: Yup.string().nullable().required('Required'),
	})
