import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { getClassCodeList } from 'libs/apis/courses'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Searchbar from 'components/commons/Searchbar'
import ListClassCodeTable from 'screens/courses/ListClassCodeTable'
import PaginationPage from 'components/commons/PaginationPage'
import Loading from 'components/commons/Loading'
import { ReactComponent as CoursesIcon } from 'assets/icons/classes.svg'
import { Header } from './styles'

function ListClassCode(props) {
	const [listClassCode, setListClassCode] = useState([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [keyword, setKeyword] = useState('')
	const [searchValue, setSearchValue] = useState('')

	const [sortBy, setsortBy] = useState('')
	const [sortDirection, setsortDirection] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [lastPage, setLastPage] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const location = useLocation()

	const fetchData = async () => {
		const { search } = location
		const page = search ? queryString.parse(search).page : 1
		setIsLoading(true)

		try {
			const res = await getClassCodeList(10, searchValue, sortBy, sortDirection, 'Class', page)
			const data = res.data.data.programcodes.data.map((v) => {
				const temp = {}
				temp.id = v.id
				temp.code = v.codename
				temp.name = v.name
				temp.createdOn = v.created_at
				temp.lastUpdate = v.updated_at
				return temp
			})
			setListClassCode(data)
			setCurrentPage(res.data.data.programcodes.current_page)
			setLastPage(res.data.data.programcodes.last_page)
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [location.search, startDate, endDate, sortBy, sortDirection, searchValue])

	const handleSearch = (event) => {
		setKeyword(event.target.value)
	}

	const handleSeachInputKeyPress = (event) => {
		if (event.key === 'Enter') {
			setSearchValue(event.target.value)
			setKeyword(event.target.value)
			props.history.push(`${props.location.pathname}?paginate=10&page=1`)
		}
	}

	const handleRemoveClassCode = async (programTicketId) => {
		try {
			// const isRemove = await alert.confirmDelete()

			// if (isRemove.value) {
			// 	const res = await removeRegistrant(programTicketId)
			// 	if (res.status === 200) {
			// 		alert.succeed('Succeed')
			// 		fetchRegistrant()
			// 	}
			// }
		} catch (error) {
			console.log(error)
		}
	}

	// const handleComplete = async (course) => {
	// 	const { value } = await alert.confirmComplete('Are you sure?')
	// 	if (value) {
	// 		try {
	// 			setIsLoading(true)
	// 			const { data } = await completeCourse(course.class_id)
	// 			if (data.api_status) {
	// 				const courseses = [...courses]
	// 				const index = courseses.indexOf(course)
	// 				courseses[index] = { ...courseses[index] }
	// 				courseses[index].status = 'Completed'
	// 				setCourses(courseses)
	// 			} else {
	// 				alert.warningMessage(data.message)
	// 			}
	// 			setIsLoading(false)
	// 		} catch (error) {
	// 			if (error.response) {
	// 				if (error.response.status === 401) {
	// 					return props.history.replace('/login')
	// 				}
	// 			}
	// 			setIsLoading(false)
	// 		}
	// 	}
	// }

	// const handleArchive = async (course) => {
	// 	const { value } = await alert.confirmArchive()
	// 	if (value) {
	// 		try {
	// 			setIsLoading(true)
	// 			const { data } = await archiveCourse(course.class_id)
	// 			if (data.api_status) {
	// 				const courses = [...courses]
	// 				const index = courses.indexOf(course)
	// 				courses[index] = { ...courses[index] }
	// 				courses[index].status = 'Archived'
	// 				this.setState({ courses })
	// 			}
	// 			setIsLoading(false)
	// 		} catch (error) {
	// 			if (error.response) {
	// 				if (error.response.status === 401) {
	// 					return props.history.replace('/login')
	// 				}
	// 			}
	// 			setIsLoading(false)
	// 		}
	// 	}
	// }

	const handleSort = (sort) => {
		if (sortBy !== sort) {
			setsortBy(sort)
			setsortDirection('ASC')
		} else {
			if (sortDirection === 'ASC') {
				setsortBy(sort)
				setsortDirection('DESC')
			} else if (sortDirection === 'DESC') {
				setsortBy(sort)
				setsortDirection('ASC')
			}
		}
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [{ url: '', title: 'List Class Code' }]

	const parsed = queryString.parse(location.search)
	const active = `${location.pathname}?paginate=10&page=${parsed.page || 1}`

	const displayNoData = !isLoading && !listClassCode.length
	const displayData = !isLoading && listClassCode.length

	return (
		<div className="main-content bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - List Class Code</title>
			</Helmet>
			<PageBreadcrumbs icon={CoursesIcon} title="List Class Code" breads={breads} />

			<Header>
				{/* <ListClassCodeFilter
					handleStartDate={handleStartDate}
					handleEndDate={handleEndDate}
					handleClearFilter={handleClearFilter}
					handleApplyFilter={handleApplyFilter}
					startDate={startDate}
					endDate={endDate}
				/> */}

				<Searchbar filterKeyword={keyword} handleOnSearch={handleSearch} handleSeachInputKeyPress={handleSeachInputKeyPress} />
			</Header>
			{isLoading && <Loading />}
			{displayNoData && <div className="text-center">No data available</div>}

			{displayData && (
				<div>
					<ListClassCodeTable listClassCode={listClassCode} handleSort={handleSort} handleRemoveClassCode={handleRemoveClassCode} />
					<PaginationPage currentPage={currentPage} lastPage={lastPage} activeLink={active} length={listClassCode.length} url={location.pathname} />
				</div>
			)}
		</div>
	)
}

export default ListClassCode
