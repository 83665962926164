import React from 'react'
import Select from 'react-select'

export function DropdownItem({ options, label, handleChange, value, errors, name, isLoading, placeholder, disabled, styles, isMulti }) {
	const customStyles = {
		control: (styles, state) => ({
			...styles,
			border: 'none',
			text: 'white',
			backgroundColor: state.isDisabled ? '#f7fafc' : 'white',
		}),
	}

	let data = options.map((option) => {
		let obj = {}
		obj.value = option.id
		obj.label = option.name || option.fullname || option.label
		return obj
	})
	return (
		<div className={styles}>
			<label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
			<Select
				styles={customStyles}
				className={`rounded-lg pl-2 border-secondary border-2 w-full ${disabled ? 'bg-gray-100' : 'bg-white'}`}
				onChange={handleChange}
				value={value}
				isClearable={true}
				isSearchable={true}
				isDisabled={disabled}
				isMulti={isMulti}
				name={name}
				options={data}
				isLoading={isLoading}
				placeholder={placeholder}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: '#4da5dc',
						primary: '#4da5dc',
					},
				})}
			/>
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}

export default DropdownItem
