import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { getTreeSmallGroup, promoteSmallGroupMember, demoteSmallGroupMember } from 'libs/apis/smallGroup'

import { ReactComponent as SGIcon } from 'assets/icons/small-group.svg'
import { SmallGroupHeader } from './styles'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import Tree from 'components/smallGroup/Tree'
import Loading from 'components/commons/Loading'
import alert from 'services/alert'
// import Searchbar from 'components/commons/Searchbar'
// import SmallGroupTreeExport from 'components/smallGroup/SmallGroupTreeExport'

function SmallGroupTree() {
	const [state, setState] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [redirect, setRedirect] = useState(false)
	const [loading, setLoading] = useState(false)

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'

	useEffect(() => {
		fetchSmallGroupTree()
	}, [])

	const fetchSmallGroupTree = async () => {
		setLoading(true)
		try {
			let res = await getTreeSmallGroup()
			let data = res.data.data.smallgroupmembers
			setState({ data })
			setLoading(false)
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.removeItem('token')
					setLoading(false)
					setRedirect(true)
				} else if (error.response.status >= 500) {
					alert.failed('', error.response.statusText)
					setErrorMessage('An unexpected error has occurred. We are currently investigating the issue, please try again later.')
					setLoading(false)
				}
			}
		}
	}

	const handlePromote = async (event) => {
		try {
			let res = await promoteSmallGroupMember('Promote', event.target.id)
			if (res.status === 200) {
				alert.succeed('Success')
				fetchSmallGroupTree()
			}
		} catch (error) {
			alert.failed('Failed', 'Cannot promote member at this moment')
		}
	}

	const handleDemote = async (event) => {
		try {
			let res = await demoteSmallGroupMember('Demote', event.target.id)
			if (res.data.message === 'Success') {
				alert.succeed('Success')
				fetchSmallGroupTree()
			}
		} catch (error) {
			alert.failed('Failed', 'Cannot demote member at this moment')
		}
	}

	const DisplayData = () => {
		return (
			<>
				{state.data.map((hdf, idx) => (
					<Tree
						id={hdf.id}
						role={hdf.smallgroupmemberrole.shortname}
						color={hdf.smallgroupmemberrole.admincolor1}
						padding={hdf.smallgroupmemberrole.adminpadding}
						key={idx}
						handleDemote={handleDemote}
						data={[hdf.user, hdf.user2]}
						lvl={1}
					>
						{hdf.smallgroups !== null ? (
							hdf.smallgroups.smallgroupmembers.map((df, idx) => (
								<Tree
									id={df.id}
									role={df.smallgroupmemberrole.shortname}
									color={df.smallgroupmemberrole.admincolor1}
									padding={df.smallgroupmemberrole.adminpadding}
									key={idx}
									handlePromote={handlePromote}
									handleDemote={handleDemote}
									handleDemo
									data={[df.user, df.user2]}
								>
									{df.smallgroups !== null ? (
										df.smallgroups.smallgroupmembers.map((dl, idx) => (
											<Tree
												id={dl.id}
												role={dl.smallgroupmemberrole.shortname}
												color={dl.smallgroupmemberrole.admincolor1}
												padding={dl.smallgroupmemberrole.adminpadding}
												key={idx}
												handlePromote={handlePromote}
												handleDemote={handleDemote}
												data={[dl.user, dl.user2]}
											>
												{dl.smallgroups !== null ? (
													dl.smallgroups.smallgroupmembers.map((dm, idx) => (
														<Tree
															id={dm.id}
															role={dm.smallgroupmemberrole.shortname}
															color={dm.smallgroupmemberrole.admincolor1}
															padding={dm.smallgroupmemberrole.adminpadding}
															key={idx}
															handlePromote={handlePromote}
															data={[dm.user, dm.user2]}
														/>
													))
												) : (
													<></>
												)}
											</Tree>
										))
									) : (
										<></>
									)}
								</Tree>
							))
						) : (
							<></>
						)}
					</Tree>
				))}
			</>
		)
	}

	const displayLoading = loading || state.data === undefined
	const displayData = state.data !== undefined && !loading && !errorMessage && state.data.length !== 0
	const displayError = state.error !== null
	const displayNoData = !loading && !errorMessage && state.length === undefined && state.data.length === 0

	if (redirect) {
		return <Redirect to="/login" />
	}

	return (
		<div className="main-content rounded-lg bg-bgPrimary pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Date Tree</title>
			</Helmet>
			<PageBreadcrumbs icon={SGIcon} title="Date Tree" breads={[{ url: '', title: 'Tree' }]} />

			{displayLoading && <Loading />}
			{displayError && <div className="text-center">{errorMessage}</div>}
			{displayNoData && <div className="text-center">No Data Available</div>}
			{displayData && DisplayData()}
		</div>
	)
}

export default SmallGroupTree
