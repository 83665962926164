import React from 'react'
import Label from 'components/commons/Label'
import Select from 'components/commons/Select'

const SelectOptions = ({ options, value, onChange, name, label, disabled, errors, title, size }) => {
	let sizes = {
		sm: 'w-48',
		md: 'w-1/2',
		lg: 'w-full',
	};
	let classSize = sizes[size];

	return (
		<div className={classSize}>
			{label && <Label>{label}</Label>}
			<Select title={title} name={name} value={value ? value : ''} onChange={onChange} options={options} disabled={disabled} />
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
};

export default SelectOptions
