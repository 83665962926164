import { withFormik } from 'formik'
import { LoginValidationSchema } from 'components/formik/LoginValidationSchema'
import { loginUser } from 'services/authServices'
import LoginForm from 'components/forms/LoginForm'
import alert from 'services/alert'

const LoginFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: true,
	mapPropsToValues: (props) => ({
		username: '',
		password: '',
		isRememberMe: false,
		errorMessage: '',
		isSubmit: false,
	}),
	validationSchema: LoginValidationSchema,
	handleSubmit: async (values, { props, setFieldValue }) => {
		setFieldValue('isSubmit', true)

		try {
			const { username, password } = values
			const res = await loginUser({ username, password, type: 'Admin' })

			if (res.data.api_status) {
				const { accesslists, token, user, church } = res.data.data
				const campuses = church.campuses
				campuses.push({ id: 0, name: 'NO CAMPUS' })
				const accessLists = accesslists.filter((access) => access.type === 'web').map((v) => v.name)

				const churchData = {
					church_code: church.code,
					church_id: church.id,
					church_name: church.name,
					church_qr_code_link: church.qrcode,
					client_photo: null,
					office_address: null,
					office_email: church.officeemail,
					office_phone_number: null,
					support_email: church.supportemail,
				}

				localStorage.setItem('user', JSON.stringify(user))
				localStorage.setItem('church', JSON.stringify(churchData))
				localStorage.setItem('campuses', JSON.stringify(campuses))
				localStorage.setItem('access_list', JSON.stringify([...accessLists]))

				localStorage.setItem('token', token)
				window.sessionStorage.setItem('token', token)

				// props.history.replace('/smallgroup/all/filtered')
				props.history.replace('/events/list-event-code')

				setFieldValue('isSubmit', false)
			} else {
				setFieldValue('errorMessage', res.data.message)
				setFieldValue('isSubmit', false)
			}
		} catch (error) {
			if (error.response.status === 500) {
				alert.failed('', error.response.statusText)
				setFieldValue('isSubmit', false)
			} else if (error.response.status === 400) {
				alert.failed('', 'User is not registered. Invalid credentials')
				setFieldValue('isSubmit', false)
			}
		}
	},
})(LoginForm)

export default LoginFormik
