import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Field } from 'formik'
import 'react-tippy/dist/tippy.css'
import moment from 'moment'

import Loading from 'components/commons/Loading'
import SingleImageUpload from 'components/commons/SingleImageUpload'
import StartDate from 'components/commons/StartDate'
import EndDate from 'components/commons/EndDate'
import NewsfeedPreview from 'components/formik/NewsfeedPreview'
import Label from 'components/commons/Label'
import InvalidFeedback from 'components/commons/InvalidFeedback'
import CheckBox from 'components/commons/Checkbox'
import TextInput from 'components/commons/TextInput'
import DescriptionBox from 'components/commons/DescriptionBox'
import Button from 'components/commons/Button'
import SelectInput from 'components/commons/form/SelectInput'

import { saveDraftConfirmation } from 'libs/utils/confirmation'
import { getCategories } from 'libs/apis/newsfeeds'
import { cancelConfirmation } from 'libs/utils/confirmation'
import { constants } from 'constants/constant'
import { Flex, NewsfeedContainer, Form, Preview } from './Styles'

function NewsfeedForm(props) {
	const [isRedirect, setIsRedirect] = useState(false)
	const [categories, setCategories] = useState([])

	const { handleSubmit } = props
	const { values, errors, isSubmitting, setFieldValue } = props

	useEffect(() => {
		const fetchCategory = async () => {
			try {
				let res = await getCategories()
				const cate = res.data.data.informationcategories.map((val) => {
					const temp = {}
					temp.id = val.id
					temp.label = val.name
					return temp
				})
				setFieldValue('category', cate[0].id)
				setCategories(cate)
			} catch (error) {
			}
		}
		fetchCategory()
	}, [])

	const handleOnChange = (event) => {
		const { setFieldValue } = props
		setFieldValue(event.target.name, event.target.value)
	}

	const handleStartDate = (date) => {
		const { setFieldValue } = props
		setFieldValue('startDate', moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleEndDate = (date) => {
		const { setFieldValue } = props
		setFieldValue('endDate', moment(date[0]).format('YYYY-MM-DD HH:mm'))
	}

	const handleOnDraft = async (e) => {
		const { setFieldValue, handleSubmit } = props
		const confirm = await saveDraftConfirmation()

		if (confirm.value) {
			setFieldValue('isDraft', true)
			handleSubmit(e)
		}
	}

	const handleOnCheck = () => {
		const { setFieldValue, values } = props
		setFieldValue('endDateCheck', !values.endDateCheck)
		setFieldValue('postEnd', ' ')
	}

	const handleCancel = async (event) => {
		event.preventDefault()
		const response = await cancelConfirmation()
		if (response.value) {
			setIsRedirect(true)
		}
	}

	const handleDescription = (value) => {
		const { setFieldValue } = props
		setFieldValue('description', value, false)
	}

	if (isRedirect) {
		return <Redirect to="/newsfeed/list" />
	}

	return (
		<NewsfeedContainer>
			<Form>
				<div className="bg-white p-6 shadow-md">
					<SelectInput label="TYPE" name="media" options={constants.medias} styles="mb-6 w-1/2 pr-4" />
					<div className="mb-6 w-full flex flex-row">
						<SelectInput label="CATEGORY" name="category" value={values.category} options={categories} styles="w-1/2 pr-4" />
						{values.category === '2' && <TextInput label="NEW CATEGORY" name="newCategory" value={values.newCategory} styles="w-1/2" onChange={handleOnChange} />}
					</div>
					{values.media === '2' ? (
						<div className="mb-6">
							<Label>UPLOAD IMAGE</Label>
							<Field name="image" component={SingleImageUpload} />
							{errors.file && <InvalidFeedback message={errors.image} />}
						</div>
					) : (
						<div className="mb-6">
							<Label>VIDEO URL</Label>
							<Field
								type="text"
								name="video"
								className="appearance-none border-2 rounded-lg w-full p-3 leading-tight focus:outline-none border-secondary"
								placeholder="Ex: http://www.abcdefghijklmn.com"
							/>
							{errors.video && <InvalidFeedback message={errors.video} />}
						</div>
					)}

					<TextInput name="title" label="TITLE" onChange={handleOnChange} value={values.title} errors={errors.title} styles="mb-6" />

					<DescriptionBox label="ARTICLE" value={values.description} onChange={handleDescription} style="mb-6" errors={errors.description} />

					<div className="flex mb-4">
						<div className="w-1/2">
							<div className="mb-2 mr-4">
								<StartDate name="startDate" label="START SHARING ON" value={values.startDate} handleChange={handleStartDate} errors={errors.startDate} />
							</div>
						</div>
						<div className="w-1/2">
							<EndDate name="endDate" label="END SHARING ON" value={values.endDate} startDate={values.startDate} handleChange={handleEndDate} errors={errors.endDate} />
						</div>
					</div>

					<Flex>
						<div className="w-1/2">
							<Button type="cancel" handleClick={handleCancel}>
								{isSubmitting ? <Loading secondary /> : 'Cancel'}
							</Button>
						</div>
						<div className="w-1/2">
							<div className="flex justify-end">
								<Button type="secondary" handleClick={handleOnDraft}>
									{isSubmitting ? <Loading primary /> : 'Save as Draft'}
								</Button>
								<Button type="primary" style="ml-4" handleClick={handleSubmit}>
									{isSubmitting ? <Loading secondary /> : 'Submit'}
								</Button>
							</div>
						</div>
					</Flex>
				</div>
			</Form>
			<Preview>
				<div className="bg-white p-6 shadow-md">
					<NewsfeedPreview {...props} />
				</div>
			</Preview>
		</NewsfeedContainer>
	)
}

export default NewsfeedForm

{
	/* <div>
						<Label>RECIPIENTS</Label>
						<div className="flex ml-2">
							<div className="flex-col">
								{roles.slice(0, roles.length / 2 + 1).map((val, idx) => (
									<div key={idx}>
										<Field
											as={InputCheckBox}
											name="filter_roles"
											className="mr-2"
											type="checkbox"
											value={val.role_id}
											// checked={props.values.filter_roles.includes(
											// 	val.role_id.toString()
											// )}
											label={val.show_as}
										/>
									</div>
								))}
							</div>
							<div className="flex-col ml-40">
								{roles.slice(roles.length / 2 + 1).map((val, idx) => (
									<div key={idx} className="mt-2">
										<Field
											as={InputCheckBox}
											name="filter_roles"
											className="mr-2"
											type="checkbox"
											value={val.role_id}
											// checked={props.values.filter_roles.includes(
											// 	val.role_id.toString()
											// )}
											label={val.show_as}
										/>
									</div>
								))}
							</div>
						</div>
					</div> */
}
