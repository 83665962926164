import React from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

const InputDateFilterExport = ({ name, label, value, handleChange, startDate, errors }) => {
	let temp = startDate ? moment(startDate).format('Y-MM-DD') : ''

	let optionsNosec = {
		dateFormat: 'Y/m/d',
		altFormat: 'F j, Y',
		enableTime: false,
		altInput: true,
		minDate: temp,
	}

	return (
		<div>
			<div className="flex flex-row w-full ">
				{label && <label className="block text-gray-700 text-sm font-bold mt-0 mt-auto my-auto mx-0  pl-4 mb-2">{label}</label>}
				<div className="flex py-2 ml-4 p-4 w-58 text-left border-grey-600 border-2 rounded-full bg-gray-200 focus:outline-none">
					<Flatpickr name={name} value={value} onChange={handleChange} options={optionsNosec} placeholder="Choose date" className="w-full bg-gray-200" />
					<div className="input-group-prepend -mr-1">
						<i className="far fa-calendar-alt" onClick={handleChange} />
					</div>
				</div>
			</div>

			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}

export default InputDateFilterExport
