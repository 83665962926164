import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as UserIcon } from 'assets/icons/usermanagement.svg'

class UserManageMenu extends Component {
	state = {
		isOpenSub: false,
	}

	componentDidMount() {
		const {
			match: { path },
		} = this.props
		const menus = ['/user-management/list', '/user-management/users/:id'].filter(
			(menu) => menu === path
		)
		if (menus.length > 0) {
			this.setState({ isOpenSub: true })
		}
	}

	handleOpenSub = () => {
		this.setState({ isOpenSub: !this.state.isOpenSub })
	}

	render() {
		const {
			match: { path },
		} = this.props
		const { isOpenSub } = this.state
		const menus = ['/user-management/list', '/user-management/users/:id'].filter(
			(menu) => menu === path
		)
		const styleIcon = `${menus.length > 0 ? 'w-4 h-4 fill-primary' : 'w-4 h-4 fill-gray'}`
		const styleLinkList = `${
			path === '/user-management/list' || path === '/user-management/users/:id'
				? 'text-primary'
				: 'text-grey-500'
		}`

		return (
			<div className="mb-6">
				<div className="flex cursor-pointer" onClick={this.handleOpenSub}>
					<div className="mr-4">
						<UserIcon className={styleIcon} />
					</div>
					<div className="font-bold text-grey-500">User Management</div>
				</div>
				{isOpenSub && (
					<div className="ml-8 mt-2">
						<ul>
							<li className="mb-4">
								<Link to="/user-management/list" className={styleLinkList}>
									List of All Users
								</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		)
	}
}

export default UserManageMenu
