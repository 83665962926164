import React from 'react'
import QRCode from 'qrcode.react'
import { ViewQRContainer, TitleQR } from './styles'

const ViewQR = (props) => {
	return (
		<ViewQRContainer>
			{/* <TitleQR>{props.match.params.eventname}</TitleQR> */}
			{/* <QRCode value={props.match.params.qrcode} /> */}
			<img src={`data:image/svg+xml;base64,${props.match.params.qrcode}`} width="100" height="100" />

			<p className="mt-10">
				{props.match.params.starttime} - {props.match.params.endtime}
			</p>
		</ViewQRContainer>
	)
};

export default ViewQR
