import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'

const PaginationPage = ({ currentPage, lastPage, url, activeLink }) => {
	const [startPage, setStartPage] = useState(0)
	const [endPage, setEndPage] = useState(0)

	useEffect(() => {
		if (currentPage > 0) {
			if (lastPage <= 4) {
				setStartPage(1)
				setEndPage(lastPage)
			} else {
				if (currentPage <= 2) {
					setStartPage(1)
					setEndPage(4)
				} else if (currentPage + 2 >= lastPage) {
					setStartPage(lastPage - 3)
					setEndPage(lastPage)
				} else {
					setStartPage(currentPage - 1)
					setEndPage(currentPage + 2)
				}
			}
		}
	}, [currentPage, lastPage])

	const stylePagePrev = ` bg-white text-gray-700 border-solid shadow-lg p-3 rounded block cursor-pointer ${
		currentPage > 1 ? 'bg-primary text-white font-bold' : 'bg-white font-bold text-grey-500'
	}`

	const stylePageNext = ` bg-primary text-white border-solid shadow-lg p-3 rounded block cursor-pointer ${currentPage === lastPage ? 'text-grey-500 font-bold' : 'bg-white font-bold text-white'}`
	return (
		<nav className="block mx-2 md:mx-0">
			<div className="md:flex justify-between">
				<ul className="flex my-6">
					{startPage > 1 && (
						<Fragment>
							<li className="inline-block font-bol mr-4">
								<Link to={`${url}?paginate=10&page=1`} className="p-3 rounded block mr-2 shadow-lg bg-white text-grey-500 font-bold block">
									1
								</Link>
							</li>
							<li className="mr-4 inline-block">...</li>
						</Fragment>
					)}
					{[...Array(endPage + 1 - startPage)].map((x, index) => {
						const stylePage = `p-3 rounded shadow-lg border-solid hover:bg-gray-100 ${
							activeLink === `${url}?paginate=10&page=${startPage + index}` ? 'block bg-primary text-white font-bold' : 'block bg-white text-grey-500 font-bold'
						}`
						return (
							<li key={index} className="inline-block mr-2">
								<Link
									disabled={activeLink === `${url}?paginate=10&page=${startPage + index}`}
									className={stylePage}
									to={`${url}?paginate=10&page=${startPage + index}`}
								>
									{startPage + index}
								</Link>
							</li>
						)
					})}
					{lastPage !== endPage && (
						<Fragment>
							<li className="mx-4 inline-block">...</li>
							<li className="inline-block mr-4">
								<Link className="p-3 bg-white rounded text-grey-500 font-bold block" to={`${url}?paginate=10&page=${lastPage}`}>
									{lastPage}
								</Link>
							</li>
						</Fragment>
					)}
				</ul>
				<ul className="flex my-6">
					<li className="inline-block mr-4">
						{currentPage < 2 ? (
							<button className="bg-white font-bold text-grey-500 border-solid shadow-lg p-3 rounded block cursor-not-allowed">Previous</button>
						) : (
							<Link className={stylePagePrev} disabled={currentPage === 1} to={`${url}?paginate=10&page=${currentPage - 1}`}>
								Previous
							</Link>
						)}
						
					</li>
					<li className="inline-block">
						{currentPage === lastPage ? (
							<button className="bg-white font-bold text-grey-500 border-solid shadow-lg p-3 rounded block cursor-not-allowed">Next</button>
						) : (
							<Link className={stylePageNext} to={`${url}?paginate=10&page=${currentPage + 1}`}>
								Next
							</Link>
						)}
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default PaginationPage
