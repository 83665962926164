import React from 'react'
import ReactQuill from 'react-quill'

function DescriptionBox(props, { label, size, handleChange, errors = props.errors }) {
	const { value, style } = props

	const btnSize = {
		small: 'w-64 mt-3',
		medium: 'w-3/6 mt-3',
		large: 'w-full mt-3',
	}

	// getValue = () => {
	// 	return console.log('zzzzzzaaaaa');
	// };

	return (
		<div className={style}>
			{props.label || label ? <label className="ml-1 block text-gray-700 text-sm font-bold mb-2">{label || props.label}</label> : null}
			<ReactQuill className={btnSize[size]} value={value || ''} onChange={handleChange || props.onChange} />
			{errors && <div className="text-red-500 text-xs italic">{errors}</div>}
		</div>
	)
}
export default DescriptionBox
