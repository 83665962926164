import React, { Component } from 'react'
import PiechartSkeleton from 'components/skeleton/PiechartSkeleton'
import PieChart from 'components/commons/PieChart'
import { getTotalAttendance } from 'libs/apis/smallGroup'

class MemberAndVisitor extends Component {
	state = {
		isLoading: false,
		data: [],
	}

	async componentDidMount() {
		this.setState({ isLoading: true }, async () => {
			try {
				let data = await this.handleGetPie()

				this.setState({
					...this.state,
					data,
					isLoading: false,
				})
			} catch (error) {
				this.setState({ ...this.state, isLoading: false })
			}
		})
	}

	handleGetPie = async () => {
		let labels = ['Member', 'Visitor']

		let values
		try {
			let res = await getTotalAttendance()
			const getData = (data) => {
				let r = []
				data.map((v) =>
					r.push.apply(r, [
						parseInt(v.count_total_member_attendance),
						parseInt(v.count_total_visitor_attendance),
					])
				)
				return r
			}

			values = getData(res.data.data)

			let data = [{ values, labels, type: 'pie' }]

			return data
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.props.history.push('/login')
				localStorage.removeItem('token')
			}
		}
	}

	renderData() {
		const { length: count } = this.state.data
		const { data } = this.state

		if (!count) return <div className="text-center">No data available</div>

		return <PieChart data={data} />
	}

	render() {
		const { isLoading } = this.state

		return (
			<>
				{isLoading ? (
					<PiechartSkeleton height={355} width={400} />
				) : (
					<div className="bg-white rounded-lg shadow-md text-center mt-5">
						<h3 className="font-bold mb-6">Member and Visitor</h3>
						{this.renderData()}
					</div>
				)}
			</>
		)
	}
}

export default MemberAndVisitor
