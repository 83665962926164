import axios from 'axios'
import search from './search'

export const getAppointmentType = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointmenttype/get/raw`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const postAppointmentAttendance = async (id, status) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointmentattendance/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
			status,
		},
	})

export const smallGroupTranserMember = async (memberId, smallgroupId, reason) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgrouprequest/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			memberId,
			smallgroupId,
			status: 'Transfered',
			reason,
		},
	})

export const getSmallGroupByCampus = async (campus) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/all/raw`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			campus,
		},
	})

export const exportSmallGroupMemberById = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/export/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const exportSmallGroupRequest = async (location, day, category, level, campus, dateFrom, dateTo) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export/filtered
	`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			location,
			day,
			category,
			level,
			campus,
			dateFrom,
			dateTo,
		},
	})

export const exportSmallGroupMember = async (level, campus, location, day, category, coreTeam, name, address, smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/export/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			location,
			day,
			category,
			level,
			campus,
			coreTeam,
			name,
			address,
			smallgroupId,
		},
	})

export const getAppointmentDetails = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointment/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const getProfileMemberSmallGroup = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/user/profile`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const exportAppointments = async (smallgroupCategory, smallgroupLevel, appointmentCategory, smallgroupCampus, dateFrom, dateTo) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointment/export/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupCategory,
			smallgroupLevel,
			appointmentCategory,
			smallgroupCampus,
			dateFrom,
			dateTo,
		},
	})

export const promoteSmallGroupMember = async (action, promotedSmallgroupMemberId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/role/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			action,
			promotedSmallgroupMemberId,
		},
	})

export const demoteSmallGroupMember = async (action, demotedSmallgroupMemberId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/role/action`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			action,
			demotedSmallgroupMemberId,
		},
	})

export const getAppointmentList = async (paginate, smallgroupCategory, smallgroupLevel, smallgroupCampus, appointmentType, sortBy, sortDirection, dateFrom, dateTo, name, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointment/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			smallgroupCategory,
			smallgroupLevel,
			smallgroupCampus,
			appointmentType,
			sortBy,
			sortDirection,
			dateFrom,
			dateTo,
			name,
			page,
		},
	})

export const getAppointmentBySmallGroupId = async (
	paginate,
	smallgroupCategory,
	smallgroupLevel,
	smallgroupCampus,
	appointmentType,
	sortBy,
	sortDirection,
	dateFrom,
	dateTo,
	name,
	smallgroupId,
	page
) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupappointment/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			smallgroupCategory,
			smallgroupLevel,
			smallgroupCampus,
			appointmentType,
			sortBy,
			sortDirection,
			dateFrom,
			dateTo,
			name,
			smallgroupId,
			page,
		},
	})

export const closeSmallGroup = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/delete`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const exportSmallGroup = async (location, day, category, level, campus) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export/filtered
		`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			location,
			day,
			category,
			level,
			campus,
		},
	})

export const getDetailSmallGroup = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/detail`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const getMembersSmallGroup = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/member-list`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const updateSmallGroup = async (data) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/update`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data,
	})

export const getUpperLeaderByLevel = async (level) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/upperLeader/all/by-level`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			level,
		},
	})

export const getRegencies = async (churchId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/regency/all/filtered-raw`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			churchId,
		},
	})

export const getSmallGroupLevel = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/all-level`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getSmallGroupRegions = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/location/by-church-id`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			churchId: id,
		},
	})

export const getSmallGroupCategory = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/category/by-church-id`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			churchId: id,
		},
	})

export const getLeaderByParentId = async (smallgroupId, coreTeam) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
			coreTeam,
		},
	})

export const getLeaderByLevel = async (level, coreTeam) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			level,
			coreTeam,
		},
	})

export const getUpperByLevel = async (level) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			level,
		},
	})

export const getUpperByParrentId = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const getLeaderDefault = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/leader`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const getDetailUpperLeader = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/upperupperleader`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const getUpperLeaderDefault = async (smallgroupId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/upperleader`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			smallgroupId,
		},
	})

export const getSmallGroupList = async (paginate, locations, sglevel, categories, campuses, days, name, sortBy, sortDirection, page) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/all/filtered`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			paginate,
			page,
			regency: locations,
			level: sglevel,
			category: categories,
			campus: campuses,
			day: days,
			sortBy,
			sortDirection,
			name,
		},
	})

export const getParentSmallGroup = async (level, campus) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/all/raw`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			level,
			campus,
		},
	})

export const createSmallGroups = async (data) =>
	await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/create`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		data,
	})

export const getRegencyByChurchId = async (churchId) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/location/by-church-id`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			churchId,
		},
	})

export const getTreeSmallGroup = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/tree/all`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getViewSmallGroupMember = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroupmember/profile`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			id,
		},
	})

export const getRole = async () => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/role`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const smallGroupExport = async (filter_keyword, filter_category, filter_day, filter_time, filter_status, order_by, order_dir) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			filter_category,
			filter_day,
			filter_time,
			filter_status,
			order_by,
			order_dir,
		},
	})
}

export const smallGroupDetail = async (id) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const viewRequest = async (id, page, filter_keyword, order_by, order_dir) =>
	await search(`${process.env.REACT_APP_ENDPOINT}/smallgroup/request/${id}?page=${page}&filter_keyword=${filter_keyword}&order_by=${order_by}&order_dir=${order_dir}`)

export const viewRequestExport = async (id, filter_keyword, order_by, order_dir) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/request/${id}/export`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: {
			filter_keyword,
			order_by,
			order_dir,
		},
	})

export const smallGroupCategories = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/categories`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const smallGroupStatuses = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/statuses`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const smallGroupSearchUser = async (filter_keyword, small_group_id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/users`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword, small_group_id },
	})

export const smallGroupRegencies = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/regencies`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const smallGroupOptions = async (filter_keyword) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/options`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { filter_keyword },
	})

export const getTotalAttendance = async () =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/total-attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const getSmallGroupAttendance = async (page, filter_keyword, order_by, order_dir) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { page, filter_keyword, order_by, order_dir },
	})

export const getDetailAttendance = async (id) =>
	await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/attendance-detail/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})

export const exportAttendance = async (monthSelected, yearSelected) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/export-attendance/${monthSelected}/${yearSelected}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const deleteAttendance = async (id) => {
	return await axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/delete-attendance/${id}`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	})
}

export const getSmallGroupAttendanceWeek = async (year_from, year_to, month_from, month_to, last_month) => {
	return await axios({
		method: 'get',
		url: `${process.env.REACT_APP_ENDPOINT}/smallgroup/total-attendance`,
		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		params: { year_from, year_to, month_from, month_to, last_month },
	})
}
