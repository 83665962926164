import { withFormik } from 'formik'
import { CreateMinistryValidationSchema } from './MinistryValidationSchema'
import CreateMinistryForm from 'components/forms/CreateMinistryForm'
import { createMinistry, createSubGroup, createGroup } from 'libs/apis/ministry'
import { failedMessage } from 'libs/utils/confirmation'
import alert from 'services/alert'

const CreateMinistryFormik = withFormik({
	enableReinitialize: true,
	validateOnBlur: false,
	validateOnChange: false,
	mapPropsToValues: (props) => ({
		ministryName: '',
		ministryLevel: '',
		ministryImageURL: '',
		ministryUpperLevels: '',
		ministryHeads: [],
		keyVolunteers: [],
		ministryDescription: '',
		ministryStatus: 'Closed',
		ministryGender: '',
	}),
	validationSchema: CreateMinistryValidationSchema,
	handleSubmit: async (values, { props }) => {
		try {
			let formdata = new FormData()

			if (values.ministryLevel.value == 'Ministry') {
				const b = values.ministryHeads.map((v) => v.value).join()
				const c = values.keyVolunteers.map((v) => v.value).join()

				formdata.append('mName', values.ministryName)
				formdata.append('mChurchId', 1)
				formdata.append('mStatus', values.ministryStatus.value)
				formdata.append('mhUserId', b)
				formdata.append('kvUserId', c)
				formdata.append('mDescription', values.ministryDescription)
				formdata.append('mImage', values.ministryImageURL)
				formdata.append('mGroupId', values.ministryUpperLevels.value)
				// formdata.append('mGender', values.ministryGender.value)

				try {
					const res = await createMinistry(formdata)
					if (res.data.api_status) {
						alert.succeed('Success')
						props.history.push('/ministry')
					} else {
						failedMessage(res.data.message)
					}
				} catch (error) {
				}
			}

			if (values.ministryLevel.value == 'SubGroup') {
				formdata.append('msgName', values.ministryName)
				formdata.append('msgShortname', values.ministryName)
				formdata.append('msgMinistrygroupId', values.ministryUpperLevels.value)
				formdata.append('msgUserId', values.ministryHeads.value)
				formdata.append('msgUser2Id', values.keyVolunteers.value)

				try {
					const res = await createSubGroup(formdata)
					if (res.data.api_status) {
						alert.succeed('Success')
						props.history.push('/ministry')
					} else {
						failedMessage(res.data.message)
					}
				} catch (error) {
				}
			}

			if (values.ministryLevel.value == 'Group') {
				formdata.append('mgChurchId', 1)
				formdata.append('mgName', values.ministryName)
				formdata.append('mgShortname', values.ministryName)
				formdata.append('mgUserId', values.ministryHeads.value)
				formdata.append('mgUser2Id', values.keyVolunteers.value)

				try {
					const res = await createGroup(formdata)
					if (res.data.api_status) {
						alert.succeed('Success')
						props.history.push('/ministry')
					} else {
						failedMessage(res.data.message)
					}
				} catch (error) {
				}
			}
		} catch (error) {
		}
	},
})(CreateMinistryForm)

export default CreateMinistryFormik
