import React, {useEffect, useState} from 'react'
import HeadTitle from 'components/commons/HeadTitle'
import BreadCrumb from 'components/commons/Breadcrumb'
import Loading from 'components/commons/Loading'
import CourseInfoCard from 'components/courses/CourseInfoCard'
import CourseStatusCard from 'components/courses/CourseStatusCard'
import EventRegistrantTable from 'components/events/EventRegistrantTable'
import {ReactComponent as EventIcon} from 'assets/icons/events.svg'
import {ReactComponent as AppliedIcon} from 'assets/icons/applied.svg'
import {ReactComponent as ApprovedIcon} from 'assets/icons/check-approved.svg'
import {ReactComponent as QuotaIcon} from 'assets/icons/quota.svg'
import {ReactComponent as PendingIcon} from 'assets/icons/pending.svg'
import {ReactComponent as CancelIcon} from 'assets/icons/cancel.svg'
import {ReactComponent as PassFailIcon} from 'assets/icons/pass-fail.svg'
import {eventConfirmation, eventReject,} from 'services/eventServices'
import alert from 'services/alert'
import Searchbar from 'components/commons/Searchbar'
import SelectOptions from 'components/commons/SelectOptions'
import Button from 'components/commons/Button'
import {constants} from 'constants/constant'
import {
    checkUncheckAttendance,
    getExportLink,
    getListEventDetailAndRegistrant,
    removeRegistrant,
    resendEmailConfirmation
} from "../../libs/apis/events";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import moment from "moment";
import PaginationPage from "../../components/commons/PaginationPage";
import Modal from "react-modal";
import EventAttendanceTable from "../../components/events/EventAttendanceTable";
import EventQRCodesList from "../../components/registrants/EventQRCodesList";
import {
    confirmRemoveRegistrant,
    confirmSendEmail,
    deleteItemSuccess,
    failedMessage, sendItemSuccess
} from "../../libs/utils/confirmation";

const EventRegistrants = (props) => {
    const church = JSON.parse(localStorage.getItem('church'));
    const name = church.church_name ? church.church_name : '-';
    const [navTab, setNavTab] = useState('registrant');
    const [filterStatus] = useState('');
    const [filterKeyword, setFilterKeyword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [eventDetail, setEventDetail] = useState({});
    const [listEventRegistrant, setListEventRegistrant] = useState([]);
    const [attendanceCount, setAttendanceCount] = useState(0);
    const [listEventAttendance, setListAttendance] = useState([]);

    const [isExported, setIsExported] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [sortDir, setSortDir] = useState('DESC');
    const [searchBy, setSearchBy] = useState('');

    const [isModalBannerOpen, setIsModalBannerOpen] = useState(false);

    const [qrCodes, setQrCodes] = useState('');

    const breads = [
        {path: '/events/list', label: 'List of All Events'},
        {key: 'listofall', label: 'Registrant List'},
    ];

    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const activeLink = `${props.location.pathname}?paginate=10&page=${parsed.page || 1}`;

    const displayLoading = isLoading && !errorMessage;
    const displayError = !isLoading && errorMessage;
    const displayNoData = !isLoading && !errorMessage && listEventRegistrant.length === 0;
    const displayDataRegistrant = !isLoading && !errorMessage && listEventRegistrant.length > 0;

    const eventId = props.match.params.id;
    const formatFile = 'csv';

    const handleLinkBannerChange = () => {
        setIsModalBannerOpen(!isModalBannerOpen);
    };

    const renderOverview = () => {
        return (
            <div className="flex -mx-2">
                <div className="md:w-4/12 px-2">
                    <CourseInfoCard
                        color="bg-greyExport"
                        courseName={eventDetail.eventName}
                        startDate={eventDetail.startDate}
                        endDate={eventDetail.endDate}
                        startTime={eventDetail.startTime}
                        endTime={eventDetail.endTime}
                        venueName={eventDetail.venueName}
                        venueAddress={eventDetail.venueAddress}
                        isEvent={true}
                        handleLinkBannerChange={handleLinkBannerChange}
                    />
                </div>
                <div className="md:w-8/12 px-2">
                    <div className="flex flex-wrap -mb-4 content-between h-full">
                        <CourseStatusCard
                            color="bg-yellow-300"
                            title="Applied"
                            value={eventDetail.applied}
                            icon={<AppliedIcon fill="#fde81a"/>}
                        />
                        <CourseStatusCard
                            color="bg-green-300"
                            title="Approved"
                            value={eventDetail.approved}
                            icon={<ApprovedIcon fill="#58c340"/>}
                        />
                        <CourseStatusCard
                            color="bg-primary"
                            title="Quota"
                            value={`${eventDetail.quota} | ${eventDetail.quotaBooked}`}
                            icon={<QuotaIcon fill="#4da5dc"/>}
                        />
                        <CourseStatusCard
                            color="bg-grey-300"
                            title="Pending"
                            value={eventDetail.pending}
                            icon={<PendingIcon fill="#989898"/>}
                        />
                        <CourseStatusCard
                            color="bg-red-500"
                            title="Rejected"
                            value={eventDetail.rejected}
                            icon={<CancelIcon fill="#e34f4f"/>}
                        />
                        <CourseStatusCard
                            color="bg-black"
                            title="Pass | Fail"
                            value={`${eventDetail.pass} | ${eventDetail.fail}`}
                            icon={<PassFailIcon fill="#404040"/>}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderRegistrantHelper = () => {
        return (
            <div className="flex justify-between my-6">
                <div className="flex flex-row">
                    <Button type="export" handleClick={handleFetchExportLink}>
                        {isExported ? <Loading secondary/> : 'Export to .csv'}
                    </Button>
                </div>
                <div className="flex flex-row">
                    <div>
                        <Searchbar
                            filterKeyword={filterKeyword}
                            filterStatus={filterStatus}
                            handleOnSearch={handleOnSearch}
                            handleSeachInputKeyPress={handleSeachInputKeyPress}
                        />
                    </div>
                    <div className="ml-6">
                        <SelectOptions
                            name="filterStatus"
                            title="All"
                            value={searchBy}
                            onChange={handleSelectOptionChange}
                            options={constants.optionsListEventRegistrant}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const handleOnToggle = (event) => {
        setNavTab(event.target.id)
    };

    const renderNavTab = () => {
        const registrantStyle = `nav-link ${navTab === 'registrant' ? 'active bg-white shadow-md' : ''}`.trim();
        const attendanceStyle = `nav-link ${navTab === 'attendance' ? 'active bg-white shadow-md' : ''}`.trim();
        const checkInStyle = `nav-link ${navTab === 'checkIn' ? 'active bg-white shadow-md' : ''}`.trim();

        return (
            <ul className="nav nav-tabs -mt-6">
                <li className="nav-item bg-white">
                    <div className={registrantStyle} id="registrant" onClick={handleOnToggle}>
                        REGISTRANT
                    </div>
                </li>
                <li className="nav-item bg-white">
                    <div className={attendanceStyle} id="attendance" onClick={handleOnToggle}>
                        ATTENDANCE
                    </div>
                </li>
                <li className="nav-item bg-white">
                    <div className={checkInStyle} id="checkIn" onClick={handleOnToggle}>
                        CHECK-IN
                    </div>
                </li>
            </ul>
        )
    };

    // have not been used
    const handleResendEmail = async (registrant) => {
        const response = await alert.confirmResendEmail();
        if (response.value) {
            try {
                const {data: response} = await eventConfirmation(
                    registrant.event_registration_id
                );
                if (response.api_status) {
                    alert.succeed('Success', 'Email has been sent.')
                } else {
                    alert.failed('Failed', response.message)
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        return this.props.history.replace('/login')
                    }
                }
            }
        }
    };

    const handleReject = async (registrant) => {
        const response = await alert.confirmReject();
        if (response.value) {
            try {
                const {data: response} = await eventReject(registrant.event_registration_id)
                if (response.api_status) {
                    if (this.state.navTab === 'registrant') {
                        const registrants = [...this.state.registrants];
                        const index = registrants.indexOf(registrant);
                        registrants[index] = {...registrants[index]};
                        registrants[index].status = 'Rejected';
                        this.setState({registrants})
                    } else {
                        const {currentPage, lastPage, attendances} = this.state;
                        if (currentPage === lastPage && attendances.length === 1) {
                            this.setState({currentPage: currentPage - 1})
                        }
                        this.doLoadAttendance(this.state.currentPage)
                    }
                } else {
                    alert.failed('Failed', response.message)
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        return this.props.history.replace('/login')
                    }
                }
            }
        }
    };
    // have not been used

    const handleOnSearch = (event) => {
        setFilterKeyword(event.target.value)
    };

    const handleSeachInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            props.history.push(`${props.location.pathname}?paginate=10&page=1`);
            setSearchValue(event.target.value);
            setFilterKeyword(event.target.value);
            setIsSearchEntered(true);
        }
    };

    const handleOnSort = (sort) => {
        if (sortBy !== sort) {
            setSortBy(sort);
            setSortDir('ASC')
        } else {
            if (sortDir === 'ASC') {
                setSortBy(sort);
                setSortDir('DESC')
            } else if (sortDir === 'DESC') {
                setSortBy(sort);
                setSortDir('ASC')
            }
        }
    };

    const handleResendConfirmationEmail = async (registrantId, userId) => {
        console.log('confirmation email: ', registrantId);
        const confirm = await confirmSendEmail();
        if (confirm.value) {
            setIsLoading(true);
            try {
                let response = await resendEmailConfirmation(registrantId, userId, null);
                console.log('response: ', response);
                sendItemSuccess();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                await failedMessage(error.message)
                // setErrorMessage(error.message);
            }
        }
    };

    const handleRemoveRegistrant = async (registrantId) => {
        console.log('remove registrant: ', registrantId);
        const confirm = await confirmRemoveRegistrant();
        if (confirm.value) {
            try {
                let response = await removeRegistrant(registrantId);
                console.log('response: ', response);
                await deleteItemSuccess();
                handleFetchListEventRegistrant();
            } catch (error) {
                await failedMessage(error.message);
                handleFetchListEventRegistrant();
                // setErrorMessage(error.message);
            }
        }
    };

    const {search} = props.location;
    const totalListPerPage = 10;
    const pageNumber = search ? queryString.parse(search).page : 1;
    const typePlatform = 'Web';

    const [isSearchEntered, setIsSearchEntered] = useState(false);

    useEffect(() => {
        handleFetchListEventRegistrant();
    }, [totalListPerPage, searchValue, sortBy, sortDir, pageNumber, isSearchEntered]);

    const formattingEventDetailData = (eventDetailData) => {
        return {
            eventName: eventDetailData.name,
            startDate: eventDetailData.programstart_at,
            endDate: eventDetailData.programend_at,
            startTime: moment(eventDetailData.programstart_at, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            endTime: moment(eventDetailData.programend_at, 'YYYY-MM-DD HH:mm').format('HH:mm'),
            venueName: eventDetailData.location ?? '',
            venueAddress: eventDetailData.locationaddress ?? '',
            applied: eventDetailData.pending,
            approved: eventDetailData.approved,
            quota: eventDetailData.quota,
            quotaBooked: eventDetailData.quota_booked,
            pending: eventDetailData.pending,
            rejected: eventDetailData.rejected,
            pass: eventDetailData.statuspassed,
            fail: eventDetailData.statusfailed
        }
    };

    const formattingQrCodes = (unformattedProgrambreakouts) => {
        let formattedQrCodes = [];

        for (const breakout of unformattedProgrambreakouts) {
            // console.log('breakout: ', breakout)
            for (const breakoutDate of breakout.programbreakoutdates) {
                if (breakoutDate !== null) {
                    // console.log('breakoutDate: ', breakoutDate);
                    formattedQrCodes.push(
                        {
                            code: breakoutDate.programbreakoutdate_qrcode,
                            startTime: breakoutDate.timestart_at,
                            endTime: breakoutDate.timeend_at,
                            // title: breakout.name ?? 'unknown'
                            title: undefined
                        }
                    )
                }
            }
        }

        return formattedQrCodes;
    };

    const extractArray = (array) => {
        if (array === null || array === undefined) {
            array = [];
        }
        let updatedArray = [];

        array.forEach((element) => {
            updatedArray.push(element.name)
        });

        return updatedArray.join(', ');
    };

    const formattingListRegistrant = (registrantData) => {
        console.log('registrantData: ', registrantData);
        return {
            id: registrantData.id,
            userId: registrantData.user_id,
            name: registrantData.user.fullname,
            name2: registrantData.user2 ? registrantData.user2.fullname : '',
            groupName: extractArray(registrantData.user.smallgroups),
            role: extractArray(registrantData.user.smallgroupmemberroles),
            appliedOn: registrantData.created_at,
            paymentValidUntil: registrantData.paymentdetails[0] ? registrantData.paymentdetails[0].payment ? registrantData.paymentdetails[0].payment.expired_at : 'unknown' : 'unknown',
            paymentValidUntil2: registrantData.paymentdetails[1] ? registrantData.paymentdetails[1].payment ? registrantData.paymentdetails[1].payment.expired_at : 'unknown' : 'unknown',
            invoiceId: registrantData.bookingcode ?? 'unknown',
            paymentStatus: registrantData.paymentdetails[0] ? registrantData.paymentdetails[0].payment ? registrantData.paymentdetails[0].payment.status : 'unknown' : 'unknown',
            updatedAtPaymentStatus: registrantData.paymentdetails[0] ? registrantData.paymentdetails[0].payment ? registrantData.paymentdetails[0].payment.transacted_at : 'unknown' : 'unknown',
            paymentStatus2: registrantData.paymentdetails[1] ? registrantData.paymentdetails[1].payment ? registrantData.paymentdetails[1].payment.status : 'unknown' : 'unknown',
            updatedAtPaymentStatus2: registrantData.paymentdetails[1] ? registrantData.paymentdetails[1].payment ? registrantData.paymentdetails[1].payment.transacted_at : 'unknown' : 'unknown',
            status: registrantData.status ? registrantData.status : 'unknown'
        }
    };

    const extractAttendance = (attendances, id) => {
        let attendanceList = [];

        for (const attendance of attendances) {
            if (id === attendance.programticket_id) {
                attendanceList.push(
                    {
                        id: attendance.id,
                        status: !!attendance.ispresent
                    }
                );
            }
        }

        return attendanceList;
    };

    const formattingListAttendance = (registrantData) => {
        return {
            name: registrantData.user.fullname,
            name2: registrantData.user2 ? registrantData.user2.fullname : '',
            groupName: extractArray(registrantData.user.smallgroups),
            attendanceStatus: extractAttendance(registrantData.user.programticketattendance, registrantData.id)
        }
    };

    const handleFetchListEventRegistrant = async () => {
        setIsLoading(true);
        try {
            let response = await getListEventDetailAndRegistrant(625, totalListPerPage, searchBy, searchValue, sortBy, sortDir, typePlatform, pageNumber);

            console.log('response.data.data: ', response.data.data);
            let eventDetailData = response.data.data.program;
            let registrantListData = response.data.data.registrantlists.data;
            let attendanceCount = response.data.data.program.programbreakouts ?
                response.data.data.program.programbreakouts[0].programbreakoutdates_count ?
                    response.data.data.program.programbreakouts[0].programbreakoutdates_count
                    : 0
                : 0;
            let currentPage = response.data.data.registrantlists.current_page;
            let lastPage = response.data.data.registrantlists.last_page;

            setAttendanceCount(attendanceCount);
            if (eventDetailData) {
                setEventDetail(formattingEventDetailData(eventDetailData));
                console.log('formattingQrCodes(eventDetailData.programbreakouts): ', formattingQrCodes(eventDetailData.programbreakouts));
                setQrCodes(formattingQrCodes(eventDetailData.programbreakouts));
            }

            if (registrantListData) {
                let listEventRegistrant = [];
                for (const registrant of registrantListData) {
                    listEventRegistrant.push(
                        formattingListRegistrant(registrant)
                    );
                }
                setListEventRegistrant(listEventRegistrant);
            }

            setAttendanceCount(attendanceCount);
            if (registrantListData) {
                let listEventAttendances = [];
                for (const registrant of registrantListData) {
                    listEventAttendances.push(
                        formattingListAttendance(registrant)
                    );
                }
                setListAttendance(listEventAttendances);
            }

            setCurrentPage(currentPage);
            setLastPage(lastPage);
            setIsSearchEntered(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const handleFetchExportLink = async () => {
        setIsExported(true);
        try {
            let response = await getExportLink(eventId, formatFile);
            let exportLink = response.data.data.path;

            if (exportLink) {
                const link = document.createElement('a');
                link.href = exportLink;
                document.body.appendChild(link);
                link.click();
                setIsExported(false);
            }
        } catch (error) {
            setIsExported(false);
            setErrorMessage(error.message);
        }
    };

    const handleSelectOptionChange = (event) => {
        console.log('event.target.value: ', event.target.value);
        setSearchBy(event.target.value);
    };

    const handleCheckUncheckClick = async (programticketattendanceId) => {
        console.log('programticketattendanceId: ', programticketattendanceId);
        setIsLoading(true);
        try {
            let formdata = new FormData();
            formdata.append('adminPass', process.env.REACT_APP_PASSWORD);
            formdata.append('id', programticketattendanceId);
            await checkUncheckAttendance(formdata);
            setNavTab('attendance');
            handleFetchListEventRegistrant();
        } catch (error) {
            setIsLoading(false);
            await failedMessage(error.message);
            setNavTab('attendance');
            handleFetchListEventRegistrant();
            // setErrorMessage(error.message);
        }
    };

    const renderRegistrant = () => {
        return (
            <div>
                <EventRegistrantTable
                    listEventRegistrant={listEventRegistrant}
                    handleOnSort={handleOnSort}
                    onResendEmail={handleResendEmail}
                    onReject={handleReject}

                    handleResendConfirmationEmail={handleResendConfirmationEmail}
                    handleRemoveRegistrant={handleRemoveRegistrant}
                />
                <PaginationPage
                    currentPage={currentPage}
                    lastPage={lastPage}
                    activeLink={activeLink}
                    length={listEventRegistrant.length}
                    url={location.pathname}
                />
            </div>
        )
    };

    const renderAttendance = () => {
        return (
            <div>
                <EventAttendanceTable
                    attendanceCount={attendanceCount}
                    listEventAttendance={listEventAttendance}
                    handleCheckUncheckClick={handleCheckUncheckClick}

                    onResendEmail={handleResendEmail}
                    onReject={handleReject}
                />
                <PaginationPage
                    currentPage={currentPage}
                    lastPage={lastPage}
                    activeLink={activeLink}
                    length={listEventRegistrant.length}
                    url={location.pathname}
                />
            </div>
        )
    };

    const renderQRCodes = () => {
        let qrCodesEl = [];

        for (const qrCode of qrCodes) {
            // console.log('qrCode: ', qrCode);
            qrCodesEl.push(
                <EventQRCodesList key={qrCode.title}
                                  code={qrCode.code}
                                  title={qrCode.title}
                                  startTime={qrCode.startTime}
                                  endTime={qrCode.endTime}
                />
            )
        }

        return qrCodesEl;
    };

    const renderCheckIn = () => {
        const count = qrCodes.length;

        if (isLoading) return <Loading/>;
        if (!count) return <div className="text-center">No data available</div>;

        return (
            // <div>
            //     Check In
            // </div>
            <div className="bg-white shadow-md">
                <div className="p-4">
                    <div className="flex flex-wrap -mb-4 -mx-2">
                        {renderQRCodes()}
                    </div>
                </div>
            </div>
        )
    };

    const customStyles = {
        content: {
            top: '15vh',
            left: '25vw',
            right: '25vw',
            maxWidth: '50vw',
            maxHeight: '70vh',
            padding: '0',
            border: 'none',
            overflow: 'auto',
            background: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.6)',
        },
    };

    return (
        <div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
            <HeadTitle title={`${name} - Event`}/>
            <BreadCrumb icon={EventIcon} title="Registrant List" breads={breads}/>
            {!isLoading && renderOverview()}
            {!isLoading && renderRegistrantHelper()}
            {displayLoading && <Loading/>}
            {!displayLoading && renderNavTab()}
            {displayError && <div className="text-center">{errorMessage}</div>}
            {displayNoData && <div className="text-center">No Data available</div>}
            {displayDataRegistrant && navTab === 'registrant' && renderRegistrant()}
            {displayDataRegistrant && navTab === 'attendance' && renderAttendance()}
            {displayDataRegistrant && navTab === 'checkIn' && renderCheckIn()}
            <Modal isOpen={isModalBannerOpen} style={customStyles} contentLabel="Event Code Filter Modal"
                   appElement={document.getElementById('root')} onRequestClose={handleLinkBannerChange}>
                <img src="https://winpoin.com/wp-content/uploads/2016/06/wallpaperwindowsxp_1.jpg" alt=""/>
            </Modal>
        </div>
    )
};

export default EventRegistrants
