import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import Loading from 'components/commons/Loading'
import ServiceList from './ServiceList'
import TimeList from './TimeList'
import SpecialList from './SpecialList'
import { ServiceFormAddContainer, Button, TimeFormAddContainer } from './styles'
import { failedFetch } from 'libs/utils/messages'
import {
  getServiceTypes,
  createServiceTypes,
  updateServiceTypes,
  getCampusDetail,
  createServiceTime,
  updateServiceTime,
  createSpecialServiceType,
  updateSpecialServiceType
} from 'libs/apis/campus'

const day = [
  {
    id: 1,
    title: 'Monday'
  },
  {
    id: 2,
    title: 'Tuesday'
  },
  {
    id: 3,
    title: 'Wednesday'
  },
  {
    id: 4,
    title: 'Thursday'
  },
  {
    id: 5,
    title: 'Friday'
  },
  {
    id: 6,
    title: 'Saturday'
  },
  {
    id: 0,
    title: 'Sunday'
  }
]

class ServiceContainer extends Component {
  state = {
    dataServices: [],
    timeServices: [],
    isLoadingDataServices: false,
    isLoadingTimeServices: false,
    errorDataServices: '',
    errorTimeServices: '',
    serviceTyping: '',
    dayTyping: '',
    timeTyping: '',

    specialServiceTyping: '',
    specialDayTyping: '',
    specialTimeTyping: '',

    isAddServiceSubmit: false,
    isAddTimeSubmit: false,
    isAddSpecialSubmit: false,
    addServiceError: '',
    addTimeError: '',
    addSpecialError: '',
    isEditServices: false,
    isEditTime: false,
    isEditSpecial: false,
    isRedirect: false,
    idEditService: '',
    editServiceTyping: '',
    idTime: '',
    editDayTyping: '',
    editTimeTyping: '',
    idSpecial: '',
    editSpecialType: '',
    editSpecialDay: '',
    editSpecialTime: '',
    isEditServiceLoading: false,
    isEditTimeLoading: false,
    isEditSpecialLoading: false,
    editServiceError: '',
    editTimeError: '',
    editSpecialError: '',

    errors: {
      service: ''
    }
  }

  componentDidMount() {
    this.setState({ isLoadingDataServices: true, isLoadingTimeServices: true }, async () => {
      try {
        const resServices = await getServiceTypes(this.props.id)
        const resTime = await getCampusDetail(this.props.id)
        if (resServices.data.api_status) {
          this.setState({ dataServices: resServices.data.data, isLoadingDataServices: false })
        } else {
          this.setState({ isLoadingDataServices: false, errorDataServices: failedFetch })
        }
        if (resTime.data.api_status) {
          this.setState({ isLoadingTimeServices: false, timeServices: resTime.data.data.services })
        } else {
          this.setState({ isLoadingTimeServices: false, errorTimeServices: failedFetch })
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            await localStorage.removeItem('token')
            this.setState({ isRedirect: true })
          } else if (error.response.status >= 500) {
            this.setState({
              isLoadingDataServices: false,
              isLoadingTimeServices: false,
              errorDataServices: failedFetch,
              errorTimeServices: failedFetch
            })
          }
        }
      }
    })
  }

  // Change Form Add
  handleOnChangeTypingService = (event) => {
    this.setState({ serviceTyping: event.target.value })
  }

  handleOnChangeDay = (event) => this.setState({ dayTyping: parseInt(event.target.value) })

  handleOnChangeTime = (time, timeStr) => {
    this.setState({ timeTyping: timeStr })
  }

  handleOnChangeSpecialService = (event) => {
    this.setState({ specialServiceTyping: event.target.value })
  }

  handleOnChangeSpecialDay = (event) => this.setState({ specialDayTyping: parseInt(event.target.value) })

  handleOnChangeSpecialTime = (time, timeStr) => {
    this.setState({ specialTimeTyping: timeStr })
  }

  handleOnAddService = () => {
    if (this.state.serviceTyping && this.state.serviceTyping.length <= 20) {
      this.setState({ isAddServiceSubmit: true }, async () => {
        try {
          const { serviceTyping } = this.state
          const response = await createServiceTypes(this.props.id, serviceTyping)
          if (response.data.api_status) {
            this.setState({
              dataServices: [...this.state.dataServices, { id: response.data.data.service_type_id, title: serviceTyping }],
              serviceTyping: '',
              isAddServiceSubmit: false,
              addServiceError: ''
            })
          } else {
            this.setState({ isAddServiceSubmit: false, addServiceError: response.data.message })
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              await localStorage.removeItem('token')
              this.setState({ isRedirect: true })
            } else if (error.response.status >= 500) {
              this.setState({ isAddServiceSubmit: false, addServiceError: failedFetch })
            }
          }
        }
      })
    }
  }

  handleOnAddTime = () => {
    const { dayTyping, timeTyping } = this.state
    if (dayTyping !== '' && timeTyping) {
      this.setState({ isAddTimeSubmit: true }, async () => {
        try {
          const id = this.props.id
          const hour = parseInt(moment(timeTyping, 'HH:mm').format('H'))
          const minute = parseInt(moment(timeTyping, 'HH:mm').format('mm'))
          const response = await createServiceTime(id, dayTyping, hour, minute)
          if (response.data.api_status) {
            const dayDesc = day.find((item) => item.id === dayTyping)
            this.setState({
              timeServices: [
                ...this.state.timeServices,
                {
                  id: response.data.data.service_id,
                  campus_id: id,
                  service_time_day: dayTyping,
                  service_time_hours: moment(timeTyping, 'HH:mm').format('HH'),
                  service_time_minutes: moment(timeTyping, 'HH:mm').format('mm'),
                  service_time_day_desc: dayDesc.title
                }
              ],
              isAddTimeSubmit: false,
              timeTyping: '',
              dayTyping: '',
              addTimeError: ''
            })
          } else {
            this.setState({ isAddTimeSubmit: false, addTimeError: response.data.message })
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              await localStorage.removeItem('token')
              this.setState({ isRedirect: true })
            } else if (error.response.status >= 500) {
              this.setState({ isAddTimeSubmit: false, addTimeError: failedFetch })
            }
          }
        }
      })
    }
  }

  handleOnSpecialService = () => {
    const { specialServiceTyping, specialDayTyping, specialTimeTyping } = this.state
    if (specialServiceTyping && specialServiceTyping.length <= 20 && specialDayTyping !== '' && specialTimeTyping) {
      this.setState({ isAddSpecialSubmit: true }, async () => {
        try {
          const hour = parseInt(moment(specialTimeTyping, 'HH:mm').format('H'))
          const minute = parseInt(moment(specialTimeTyping, 'HH:mm').format('mm'))

          const response = await createSpecialServiceType(this.props.id, specialServiceTyping, specialDayTyping, hour, minute)
          if (response.data.api_status) {
            const dayDesc = day.find((item) => item.id === specialDayTyping)
            this.setState({
              specialServiceTyping: '',
              specialDayTyping: '',
              specialTimeTyping: '',
              isAddSpecialSubmit: false,
              dataServices: [
                ...this.state.dataServices,
                { id: response.data.data.service_type_id, title: specialServiceTyping, is_special: true }
              ],
              timeServices: [
                ...this.state.timeServices,
                {
                  special_id: response.data.data.service_type_id,
                  campus_id: this.props.id,
                  service_time_day: specialDayTyping,
                  service_time_hours: moment(specialTimeTyping, 'HH:mm').format('HH'),
                  service_time_minutes: moment(specialTimeTyping, 'HH:mm').format('mm'),
                  service_time_day_desc: dayDesc.title
                }
              ]
            })
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              await localStorage.removeItem('token')
              this.setState({ isRedirect: true })
            } else if (error.response.status >= 500) {
              this.setState({ isAddSpecialSubmit: false, addTimeError: failedFetch })
            }
          }
        }
      })
    }
  }

  handleOnDeleteService = async (id) => {
    const { dataServices } = this.state
    const list = dataServices.filter((item, j) => item.id !== id)
    this.setState({ dataServices: list })
  }

  handleOnDeleteTime = (id) => {
    const { timeServices } = this.state
    const list = timeServices.filter((item, j) => item.id !== id)
    this.setState({ timeServices: list })
  }

  handleOnDeleteSpecial = (id) => {
    const { dataServices, timeServices } = this.state
    const list = dataServices.filter((item, j) => item.id !== id)
    const time = timeServices.filter((item, j) => item.special_id !== id)
    this.setState({ dataServices: list, timeServices: time })
  }

  handleOnEditService = (id, title) => {
    this.setState({ isEditServices: true, idEditService: id, editServiceTyping: title })
  }

  handleOnEditTime = (id, dayinput, time) => {
    const valueDay = day.find((item) => item.title === dayinput)
    this.setState({ isEditTime: true, idTime: id, editDayTyping: valueDay.id, editTimeTyping: time })
  }

  handleOnEditSpecial = (idSpecial, editSpecialType, editSpecialDay, editSpecialTime) => {
    this.setState({ isEditSpecial: true, idSpecial, editSpecialType, editSpecialDay, editSpecialTime })
  }

  handleOnCancelEditService = () => {
    this.setState({ isEditServices: false, idEditService: '', editServiceTyping: '' })
  }

  handleOnCancelEditTime = () => {
    this.setState({ isEditTime: false, idTime: '', editDayTyping: '', editTimeTyping: '' })
  }

  handleOnCancelSpecial = () => {
    this.setState({ isEditSpecial: false, idSpecial: '', editSpecialType: '', editSpecialDay: '', editSpecialTime: '' })
  }

  handleOnChangeEditService = (event) => {
    this.setState({ editServiceTyping: event.target.value })
  }

  handleOnChangeEditDay = (event) => {
    this.setState({ editDayTyping: parseInt(event.target.value) })
  }

  handleOnChangeEditTime = (time, timeStr) => {
    this.setState({ editTimeTyping: timeStr })
  }

  handleOnChangeEditSpecialService = (event) => {
    this.setState({ editSpecialType: event.target.value })
  }

  handleOnChangeEditSpecialDay = (event) => {
    this.setState({ editSpecialDay: event.target.value })
  }

  handleOnChangeEditSpecialTime = (time, timeStr) => {
    this.setState({ editSpecialTime: timeStr })
  }

  handleOnSaveEditService = () => {
    if (this.state.editServiceTyping && this.state.editServiceTyping.length <= 20) {
      this.setState({ isEditServiceLoading: true, isSaved1: false }, async () => {
        try {
          const response = await updateServiceTypes(this.props.id, this.state.idEditService, this.state.editServiceTyping)
          if (response.data.api_status) {
            const { dataServices, idEditService, editServiceTyping } = this.state
            const service = dataServices.find((item) => item.id === idEditService)
            const newService = { ...service, title: editServiceTyping }
            const updatedIndex = dataServices.findIndex((item) => item.id === newService.id)
            const updatedServices = [...dataServices.slice(0, updatedIndex), newService, ...dataServices.slice(updatedIndex + 1)]
            this.setState({ dataServices: updatedServices, isEditServiceLoading: false })
          } else {
            this.setState({ isEditServiceLoading: false, editServiceError: response.data.message })
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              await localStorage.removeItem('token')
              this.setState({ isRedirect: true })
            } else if (error.response.status >= 500) {
              this.setState({ isEditServiceLoading: false, editServiceError: failedFetch })
            }
          }
        }
      })
    }
  }

  handleOnSaveEditTimes = () => {
    this.setState({ isEditTimeLoading: true }, async () => {
      try {
        const { timeServices, idTime, editDayTyping, editTimeTyping } = this.state
        const hour = parseInt(moment(editTimeTyping, 'HH:mm').format('HH'))
        const minute = parseInt(moment(editTimeTyping, 'HH:mm').format('mm'))
        const response = await updateServiceTime(idTime, editDayTyping, hour, minute)
        if (response.data.api_status) {
          const time = timeServices.find((item) => item.id === idTime)
          const dayValue = day.find((item) => item.id === editDayTyping)
          const newTime = {
            ...time,
            service_time_day: editDayTyping,
            service_time_day_desc: dayValue.title,
            service_time_hours: moment(editTimeTyping, 'HH:mm').format('HH'),
            service_time_minutes: moment(editTimeTyping, 'HH:mm').format('mm')
          }

          const updatedIndex = timeServices.findIndex((item) => item.id === newTime.id)
          const updatedTime = [...timeServices.slice(0, updatedIndex), newTime, ...timeServices.slice(updatedIndex + 1)]

          this.setState({ timeServices: updatedTime, isEditTimeLoading: false })
        } else {
          this.setState({ isEditTimeLoading: false, editTimeError: response.data.message })
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            await localStorage.removeItem('token')
            this.setState({ isRedirect: true })
          } else if (error.response.status >= 500) {
            this.setState({ isEditTimeLoading: false, editTimeError: failedFetch })
          }
        }
      }
    })
  }

  handleOnSaveEditSpecial = () => {
    this.setState({ isEditSpecialLoading: true }, async () => {
      try {
        const { idSpecial, editSpecialType, editSpecialDay, editSpecialTime } = this.state
        const hour = parseInt(moment(editSpecialTime, 'HH:mm').format('HH'))
        const minute = parseInt(moment(editSpecialTime, 'HH:mm').format('mm'))
        const response = await updateSpecialServiceType(this.props.id, idSpecial, editSpecialType, editSpecialDay, hour, minute)
        if (response.data.api_status) {
          const { dataServices, idSpecial, editSpecialType, timeServices } = this.state
          const service = dataServices.find((item) => item.id === idSpecial)
          const newService = { ...service, title: editSpecialType }
          const updatedIndex = dataServices.findIndex((item) => item.id === newService.id)
          const updatedServices = [...dataServices.slice(0, updatedIndex), newService, ...dataServices.slice(updatedIndex + 1)]
          const time = timeServices.find((item) => item.special_id === idSpecial)
          const dayValue = day.find((item) => item.id === editSpecialDay)
          const newTime = {
            ...time,
            service_time_day: editSpecialDay,
            service_time_day_desc: dayValue.title,
            service_time_hours: moment(editSpecialTime, 'HH:mm').format('HH'),
            service_time_minutes: moment(editSpecialTime, 'HH:mm').format('mm')
          }
          const updatedIndexTime = timeServices.findIndex((item) => item.special_id === newTime.special_id)
          const updatedTime = [...timeServices.slice(0, updatedIndexTime), newTime, ...timeServices.slice(updatedIndexTime + 1)]
          this.setState({ dataServices: updatedServices, timeServices: updatedTime, isEditSpecialLoading: false })
        } else {
          this.setState({ isEditSpecialLoading: false, editSpecialError: response.data.message })
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            await localStorage.removeItem('token')
            this.setState({ isRedirect: true })
          } else if (error.response.status >= 500) {
            this.setState({ isEditSpecialLoading: false, editSpecialError: failedFetch })
          }
        }
      }
    })
  }

  render() {
    const {
      isLoadingDataServices,
      isLoadingTimeServices,
      errorDataServices,
      errorTimeServices,
      dataServices,
      timeServices,
      isRedirect,
      serviceTyping,
      dayTyping,
      timeTyping,
      specialServiceTyping,
      specialDayTyping,
      specialTimeTyping,
      isAddServiceSubmit,
      isAddTimeSubmit,
      isAddSpecialSubmit,
      addServiceError,
      addTimeError,
      addSpecialError,
      isEditServices,
      isEditTime,
      isEditSpecial,
      editServiceTyping,
      editDayTyping,
      editTimeTyping,
      editSpecialType,
      editSpecialDay,
      editSpecialTime,
      isEditServiceLoading,
      isEditTimeLoading,
      isEditSpecialLoading,
      editServiceError,
      editTimeError,
      editSpecialError
    } = this.state

    const displayLoadingServices = isLoadingDataServices && !errorDataServices
    const displayDataServices = !isLoadingDataServices && !errorDataServices && dataServices.length > 0
    const displayNoDataServices = !isLoadingDataServices && !errorDataServices && dataServices.length === 0
    const displayErrorServices = !isLoadingDataServices && errorDataServices

    const displayLoadingTimes = isLoadingTimeServices && !errorTimeServices
    const displayTimesService = !isLoadingTimeServices && !errorTimeServices && timeServices.length > 0
    const displayNoTimesServices = !isLoadingTimeServices && !errorTimeServices && timeServices.length === 0
    const displayErrorTimes = !isLoadingTimeServices && errorTimeServices

    if (isRedirect) {
      return <Redirect to="/login" />
    }

    return (
      <div>
        <div>
          <div className="form-group">
            <label>ADD SERVICE TYPE</label>
            <ServiceFormAddContainer>
              <input
                className="form-control"
                placeholder="Type name"
                name="serviceTyping"
                value={serviceTyping}
                onChange={this.handleOnChangeTypingService}
              />
              <Button secondary onClick={this.handleOnAddService} disabled={isAddServiceSubmit}>
                {isAddServiceSubmit ? <Loading /> : 'Add'}
              </Button>
              {addServiceError && <div className="text-invalid">addServiceError</div>}
            </ServiceFormAddContainer>
          </div>
          {displayLoadingServices && <Loading />}
          {displayDataServices && (
            <ServiceFormAddContainer>
              <ServiceList
                list={dataServices}
                isEditServices={isEditServices}
                handleOnDelete={this.handleOnDeleteService}
                handleOnEditService={this.handleOnEditService}
                handleOnCancelEditService={this.handleOnCancelEditService}
              />
            </ServiceFormAddContainer>
          )}

          {isEditServices && (
            <div className="form-group">
              <ServiceFormAddContainer>
                <input
                  className="form-control"
                  placeholder="Type name"
                  name="editServiceTyping"
                  value={editServiceTyping}
                  onChange={this.handleOnChangeEditService}
                />
                <Button addButton onClick={this.handleOnSaveEditService} disabled={isEditServiceLoading}>
                  {isEditServiceLoading ? <Loading /> : 'Save'}
                </Button>
                {editServiceError && <div className="text-invalid">{editServiceError}</div>}
              </ServiceFormAddContainer>
            </div>
          )}

          {displayNoDataServices && <div className="align-center">No data available</div>}
          {displayErrorServices && <div className="align-center">{errorDataServices}</div>}
        </div>

        <div>
          <div className="form-group">
            <label>ADD SERVICE TIME</label>
            <TimeFormAddContainer>
              <select className="form-control" name="dayTyping" value={dayTyping} onChange={this.handleOnChangeDay}>
                <option value="">Choose Day</option>
                {day.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              <Flatpickr
                value={timeTyping}
                className="form-control"
                placeholder="Choose Time"
                onClose={this.handleOnChangeTime}
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  defaultHour: moment().format('H'),
                  defaultMinute: moment().format('m')
                }}
              />
              <Button addButton onClick={this.handleOnAddTime} disabled={isAddTimeSubmit}>
                {isAddTimeSubmit ? <Loading /> : 'Add'}
              </Button>
              {addTimeError && <div className="text-invalid">{addTimeError}</div>}
            </TimeFormAddContainer>
          </div>
          {displayLoadingTimes && <Loading />}
          {displayTimesService && (
            <ServiceFormAddContainer>
              <TimeList
                list={timeServices}
                isEditTime={isEditTime}
                handleOnDelete={this.handleOnDeleteTime}
                handleOnEditTime={this.handleOnEditTime}
                handleOnCancelEditTime={this.handleOnCancelEditTime}
              />
            </ServiceFormAddContainer>
          )}

          {isEditTime && (
            <div className="form-group">
              <TimeFormAddContainer>
                <select className="form-control" name="editDayTyping" value={editDayTyping} onChange={this.handleOnChangeEditDay}>
                  <option value="" disabled>
                    Choose Day
                  </option>
                  {day.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <Flatpickr
                  value={editTimeTyping}
                  className="form-control"
                  placeholder="Choose Time"
                  onChange={this.handleOnChangeEditTime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i'
                  }}
                />
                <Button addButton onClick={this.handleOnSaveEditTimes} disabled={isEditTimeLoading}>
                  {isEditTimeLoading ? <Loading /> : 'Save'}
                </Button>
                {editTimeError && <div className="text-invalid">{editTimeError}</div>}
              </TimeFormAddContainer>
            </div>
          )}

          {displayNoTimesServices && <div className="align-center">No data available</div>}
          {displayErrorTimes && <div className="align-center">{errorTimeServices}</div>}
        </div>

        <div>
          <div className="form-group">
            <label>ADD SPECIAL SERVICE</label>
            <ServiceFormAddContainer>
              <input
                className="form-control"
                placeholder="Type name"
                name="specialServiceTyping"
                value={specialServiceTyping}
                onChange={this.handleOnChangeSpecialService}
              />
              <Button addButton onClick={this.handleOnSpecialService} disabled={isAddSpecialSubmit}>
                {isAddSpecialSubmit ? <Loading /> : 'Add'}
              </Button>
            </ServiceFormAddContainer>
          </div>
          <div className="form-group">
            <TimeFormAddContainer>
              <select className="form-control" name="specialDayTyping" value={specialDayTyping} onChange={this.handleOnChangeSpecialDay}>
                <option value="">Choose Day</option>
                {day.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              <Flatpickr
                value={specialTimeTyping}
                className="form-control"
                placeholder="Choose Time"
                onClose={this.handleOnChangeSpecialTime}
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  defaultHour: moment().format('H'),
                  defaultMinute: moment().format('m')
                }}
              />
              {addSpecialError && <div className="text-invalid">{addSpecialError}</div>}
            </TimeFormAddContainer>
          </div>
          {displayTimesService && displayDataServices && (
            <ServiceFormAddContainer>
              <SpecialList
                timeList={timeServices}
                typeList={dataServices}
                isEditSpecial={isEditSpecial}
                handleOnDelete={this.handleOnDeleteSpecial}
                handleOnEdit={this.handleOnEditSpecial}
                handleOnCancel={this.handleOnCancelSpecial}
              />
            </ServiceFormAddContainer>
          )}

          {isEditSpecial && (
            <>
              <div className="form-group">
                <ServiceFormAddContainer>
                  <input
                    className="form-control"
                    placeholder="Type name"
                    name="editSpecialType"
                    value={editSpecialType}
                    onChange={this.handleOnChangeEditSpecialService}
                  />
                  <Button addButton onClick={this.handleOnSaveEditSpecial} disabled={isEditSpecialLoading}>
                    {isEditSpecialLoading ? <Loading /> : 'Save'}
                  </Button>
                </ServiceFormAddContainer>
              </div>
              <div className="form-group">
                <TimeFormAddContainer>
                  <select
                    className="form-control"
                    name="editSpecialDay"
                    value={editSpecialDay}
                    onChange={this.handleOnChangeEditSpecialDay}>
                    <option value="">Choose Day</option>
                    {day.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  <Flatpickr
                    value={editSpecialTime}
                    className="form-control"
                    placeholder="Choose Time"
                    onClose={this.handleOnChangeEditSpecialTime}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      defaultHour: moment().format('H'),
                      defaultMinute: moment().format('m')
                    }}
                  />
                  {editSpecialError && <div className="text-invalid">{editSpecialError}</div>}
                </TimeFormAddContainer>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default ServiceContainer
