import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { converDate, partial } from 'libs/utils/utils'

const TableContainer = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	border-radius: 0.25rem;
`

const Table = styled.table`
	width: 100%;
`

const THead = styled.thead`
	border-top: 2px solid #f2f6f9;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-transform: uppercase;
	color: gray;
	text-align: left;
`
const THeader = styled.th`
	padding: 1rem 1rem;
	width: 300px;
	margin-left: 2rem;
`

function PrayersTable(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const node = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, false)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, false)
		}
	}, [])

	const handleClickOutside = (event) => {
		if (node.current && !node.current.contains(event.target)) {
			setDropDownOpen(false)
		}
	}

	const { list, handleSort } = props
	const handleSortCategory = partial(handleSort, 'prayercategory_id')
	const handleSortRequest = partial(handleSort, 'description')
	const handleSortCampus = partial(handleSort, 'campusservice_id')
	const handleSortDateSubmitted = partial(handleSort, 'created_at')

	const headers = [
		{ label: 'Name', name: 'name' },
		{ label: 'Email', name: 'email' },
		{ label: 'Category', name: 'category', handleSort: handleSortCategory },
		{ label: 'Request', name: 'request', handleSort: handleSortRequest },
		{ label: 'Campus', name: 'campus', handleSort: handleSortCampus },
		{ label: 'Date Submitted', name: 'date_submitted', handleSort: handleSortDateSubmitted }
	]

	return (
		<TableContainer>
			<Table>
				<THead>
					<tr>
						{headers.map((header,index) => (
							<THeader key={index}>
								{header.label}
								{header.name !== 'email' && header.name !== 'name' ?
									<i className="fas fa-sort sortby cursor-pointer ml-1" onClick={header.handleSort} name={header.name} /> : ''
								}
							</THeader>
						))}
					</tr>
				</THead>
				<tbody className="border-2 border-solid">
					{list.map((item,index) => (
						<PrayersItem
							key={index}
							item={item}
							id={item.id}
							userId={item.user_id}
							name={item.user && item.user.fullname}
							campus={item.campusservice && item.campusservice.campus.name}
							email={item.user && item.user.email}
							category={item.prayercategory && item.prayercategory.name}
							request={item.description || ''}
							dateSubmitted={converDate(item.created_at || '')}
						/>
					))}
				</tbody>
			</Table>
		</TableContainer>
	)
}

export default PrayersTable

function PrayersItem(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false)
	const location = useLocation()
	const praiseReport = '/prayer-praise/praise-report'
	const node = useRef()
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleClickOutside = (e) => {
		if (node.current.contains(e.target)) {
			return
		}
		setDropDownOpen(false)
	}

	const handleToggle = () => {
		setDropDownOpen(!dropDownOpen)
	}

	const regexChecker = (string) => {
		var ua = navigator.userAgent.toLowerCase()
		if (ua.indexOf('safari') !== -1) {
			if (ua.indexOf('chrome') > -1) {
				let emailRegex = string.replace(new RegExp('(?<=.)[^@](?=[^@]*?@)|(?:(?<=@.)|(?!^)\\G(?=[^@]*$)).(?!$)', 'gm'), '*')
				return emailRegex
			} else {
				return string
			}
		}
	}

	const { id, userId, name, campus, email, category, request, dateSubmitted } = props

	const regexEmail = regexChecker(email)

	let json = JSON.parse(localStorage.getItem('church'))
	let churchCode = json.church_code

	const styleDropDownOpen = `z-20 shadow-md absolute bg-white right-20px ${dropDownOpen ? 'block' : 'hidden'}`

	return (
		<tr className="border border-solid bt-blueSecondary" key={id}>
			<td style={{ wordBreak: 'break-all' }} className="pl-4 py-4 px-2">
				<Link to={`/user-management/users/${id}`}>{name}</Link>
			</td>
			<td style={{wordBreak: 'break-all'}} className="py-4 px-2">{churchCode === 'skybr.id' ? regexEmail : email}</td>
			<td style={{ wordBreak: 'break-all' }} className="py-4 px-2">{category}</td>
			<td style={{wordBreak: 'break-all'}} className="py-4 px-2">{request}</td>
			<td style={{ wordBreak: 'break-all' }} className="py-4 px-2">{campus}</td>
			<td style={{ wordBreak: 'break-all' }} className="py-4 px-2">{dateSubmitted}</td>

			<td className="py-4 pl-4 px-2">
				<div className="cursor-pointer" onClick={handleToggle}>
					<i className="dropdown-trigger fas fa-ellipsis-v" />
					<ul ref={node} className={styleDropDownOpen}>
						<li>
							<Link
								className="px-4 py-2 block hover:bg-blueSecondary"
								to={location.pathname === praiseReport ? `/report-history/${userId}` : `/request-history/${userId}`}>
								View User
							</Link>
						</li>
					</ul>
				</div>
			</td>
		</tr>
	)
}
